import { Paginate } from '@admin/components/Paginate';
import { StyledTable } from '@admin/components/Styled/Table';
import { repositoryClient } from '@admin/repository';
import type { Member, Pageable } from '@admin/repository/types';
import { repositoryUtils } from '@admin/repository/utils';
import { utils } from '@admin/utils';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const columnMap: Record<string, string> = {
  email: 'email',
  phone: 'phone',
  serviceCode: 'serviceCode',
  name: 'username',
};

export const MemberTable = () => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const pageIndex = useMemo(() => {
    const p = Number(searchParams.get('page'));
    return Number.isNaN(p) ? 0 : p;
  }, [searchParams]);

  const columnSearchParams = useMemo(() => {
    const column = searchParams.get('column');
    const query = searchParams.get('query');
    if (!query || !column) {
      return;
    }
    const key = columnMap[column];
    invariant(key, 'Invalid column.');
    return { [key]: query };
  }, [searchParams]);

  const registerDateSearchParams = useMemo(() => {
    const since = searchParams.get('since');
    const till = searchParams.get('till');
    if (!since || !till) {
      return;
    }
    return { since, till };
  }, [searchParams]);

  const query = useQuery({
    queryKey: [
      '/member/all',
      {
        page: pageIndex,
        ...columnSearchParams,
        ...registerDateSearchParams,
      },
    ] as const,
    queryFn: ({ queryKey: [path, params], signal }) =>
      repositoryClient.get<{ data: Pageable<Member[]> }>(path, {
        params,
        signal,
      }),
    select: ({
      data: {
        data: { data, totalElements, totalPages },
      },
    }) => ({
      elements: data,
      totalElements,
      totalPages: totalPages || 1,
    }),
    onSuccess: ({ totalPages }) => {
      if (pageIndex >= totalPages) {
        setSearchParams(
          (prev) => {
            const params = new URLSearchParams(prev);
            if (totalPages > 1) {
              params.set('page', (totalPages - 1).toString());
            } else {
              params.delete('page');
            }
            return params;
          },
          { replace: true },
        );
      }
    },
  });

  invariant(query.data, 'Invalid query data.');

  return (
    <>
      <div
        css={css`
          height: 100%;
          overflow: auto;
        `}
      >
        <StyledTable id="userTable">
          <thead>
            <tr>
              <th>이메일</th>
              <th>이름</th>
              <th>전화번호</th>
              <th>역할</th>
              <th>서비스 코드</th>
              <th>가입일</th>
            </tr>
          </thead>
          <tbody>
            {query.data.elements.map((member) => {
              const registerDate = repositoryUtils.parseDate(
                member.registerDate,
              );
              if (!registerDate) {
                throw new Error('Invalid register date.');
              }
              return (
                <tr
                  key={member.uid}
                  css={css`
                    &:hover {
                      background: ${utils.style.color.grey100};
                    }
                    cursor: pointer;
                  `}
                  onClick={() => {
                    const encodedEmail = encodeURIComponent(member.email);
                    const params = new URLSearchParams({
                      'service-code': member.serviceCode,
                    });
                    navigate(`./edit/${encodedEmail}?${params}`);
                  }}
                >
                  <td>{member.email}</td>
                  <td>{member.name}</td>
                  <td>{member.phone}</td>
                  <td>{member.role}</td>
                  <td>{member.serviceCode}</td>
                  <td>{format(registerDate, 'yyyy-MM-dd')}</td>
                </tr>
              );
            })}
          </tbody>
        </StyledTable>
      </div>

      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
        `}
      >
        <Paginate
          forcePage={Math.min(pageIndex, query.data.totalPages - 1)}
          onPageChange={(index) =>
            setSearchParams((prev) => {
              const params = new URLSearchParams(prev);
              if (index === 0) {
                params.delete('page');
              } else {
                params.set('page', index.toString());
              }
              return params;
            })
          }
          pageCount={query.data.totalPages}
        />
      </div>
    </>
  );
};
