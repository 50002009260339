import { repositoryClient } from '@admin/repository';
import type { CategoryTag } from '@admin/repository/types';
import { css } from '@emotion/react';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { voiceTagFormAtoms } from '../atom';
import { TagGroup } from './TagGroup';

export const TagGrid = () => {
  const searchKeyword = useRecoilValue(voiceTagFormAtoms['search-keyword']);

  const query = useQuery({
    queryKey: ['/tag'] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ data: CategoryTag[] }>(path, { signal }),
    select: ({ data: { data } }) => data,
  });

  const filteredCategoryTags = useMemo(() => {
    if (query.status !== 'success') {
      return [];
    }
    if (!searchKeyword) {
      return query.data;
    }
    return query.data.map(({ tags, ...rest }) => ({
      tags: tags.filter(({ tagLabel }) => tagLabel.includes(searchKeyword)),
      ...rest,
    }));
  }, [query.data, query.status, searchKeyword]);

  return (
    <ul
      css={css`
        margin: 0;

        width: 100%;
        height: 100%;

        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        gap: 16px;

        padding: 16px 24px;
        overflow: auto;
      `}
    >
      {filteredCategoryTags.map(
        (categoryTag) =>
          categoryTag.tags.length > 0 && (
            <TagGroup key={categoryTag.id} categoryTag={categoryTag} />
          ),
      )}
    </ul>
  );
};
