import { ContentBox } from '@admin/components/ContentBox';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledForm } from '@admin/components/Styled/Form';
import { StyledH1 } from '@admin/components/Styled/H1';
import { repositoryClient } from '@admin/repository';
import { repositoryUtils } from '@admin/repository/utils';
import { ActivationButton } from '@admin/routes/eventPage/edit/ActivationButton';
import { ButtonList } from '@admin/routes/eventPage/edit/ButtonList';
import {
  eventPageAtom,
  mouseClickAtom,
} from '@admin/routes/eventPage/edit/context/atom';
import { EventPageContextInitializer } from '@admin/routes/eventPage/edit/context/initializer';
import { Expirationdate } from '@admin/routes/eventPage/edit/ExpirationDate';
import { ImageUpload } from '@admin/routes/eventPage/edit/ImageUpload';
import { PreviewEventPage } from '@admin/routes/eventPage/edit/Preview';
import { SaveButton } from '@admin/routes/eventPage/edit/SaveButton';
import { css } from '@emotion/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';

export const EventManagingEditPage = () => {
  const queryClient = useQueryClient();
  const { eventPageId } = useParams();
  const [eventPageState] = useRecoilState(eventPageAtom);
  const [, setMouseClickState] = useRecoilState(mouseClickAtom);
  const { openModal } = useModal();

  /* 이벤트 페이지 저장 */
  const { mutate: saveEventPage, isLoading: isSaving } = useMutation({
    onError: (e) => {
      let message = repositoryUtils.getErrorMessage(e);
      if (e instanceof AxiosError && e.response) {
        if (e?.response.status === 400) {
          message = `이미 존재하는 이벤트와 유효 기간이 중복됩니다.\n유효 기간을 다시 설정 후 저장해주세요.`;
        }
      }
      openModal(<AlertDialog title="에러" message={message} />);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`/admin/eventPage/${eventPageId}`],
      });

      queryClient.invalidateQueries({
        queryKey: [`/admin/eventPage/${eventPageId}/images`],
      });
      openModal(
        <AlertDialog title="확인" message="이벤트 페이지가 저장되었습니다." />,
      );
      setMouseClickState((prevArray) => {
        const updatedArray = prevArray.map(() => false);
        return updatedArray;
      });
    },
    mutationFn: () => {
      const formData = new FormData();
      const payload = JSON.stringify({
        code: null, // 추후 code 수정 기능 추가할 경우 변경
        startDate: [
          eventPageState.since.getFullYear(),
          eventPageState.since.getMonth() + 1,
          eventPageState.since.getDate(),
        ],
        endDate: [
          eventPageState.till.getFullYear(),
          eventPageState.till.getMonth() + 1,
          eventPageState.till.getDate(),
        ],
        pcButtonNum: eventPageState.pcEventButtonList.length,
        mobileButtonNum: eventPageState.mobileEventButtonList.length,
        pcButtons: JSON.stringify(eventPageState.pcEventButtonList),
        mobileButtons: JSON.stringify(eventPageState.mobileEventButtonList),
      });
      formData.set(
        'updateEventPageRequest',
        new Blob([payload], { type: 'application/json' }),
      );
      if (eventPageState.pcImageFile.size !== 0) {
        formData.set('pc', eventPageState.pcImageFile);
      }
      if (eventPageState.mobileImageFile.size !== 0) {
        formData.set('mobile', eventPageState.mobileImageFile);
      }
      return repositoryClient.post(`/admin/eventPage/${eventPageId}`, formData);
    },
  });

  /* 이벤트 페이지 이미지 업로드 여부 확인 */
  const validateEventPageImages = useCallback(() => {
    if (eventPageState.isPCBasicImage && eventPageState.isMobileBasicImage) {
      return 'PC 이벤트 이미지\nMobile 이벤트 이미지';
    }
    if (eventPageState.isPCBasicImage) {
      return 'PC 이벤트 이미지';
    }
    if (eventPageState.isMobileBasicImage) {
      return 'Mobile 이벤트 이미지';
    }
    return '';
  }, [eventPageState.isMobileBasicImage, eventPageState.isPCBasicImage]);

  return (
    /** 서버로부터 받은 데이터로 초기화 */
    <EventPageContextInitializer>
      <ContentBox
        css={css`
          height: 100%;
          gap: 24px;
          flex-direction: column;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 16px;
          `}
        >
          <StyledH1>이벤트 페이지 관리</StyledH1>
        </div>
        <div
          css={css`
            width: 100%;
            height: 90%;
            display: flex;
            justify-content: space-between;
          `}
        >
          <div
            css={css`
              width: 40%;
            `}
          >
            <StyledForm
              onSubmit={(e) => {
                e.preventDefault();
                const result = validateEventPageImages();
                if (result !== '') {
                  return openModal(
                    <AlertDialog
                      title="알림"
                      message={`아래 이미지 파일을 업로드 해야 저장할 수 있습니다.\n\n${result}`}
                    />,
                  );
                }
                saveEventPage();
              }}
              css={css`
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
              `}
            >
              <div>
                {/** 유효 기간 */}
                <Expirationdate />

                {/** 이미지 업로드 */}
                <ImageUpload />
              </div>

              {/** 버튼 속성 */}
              <ButtonList />
              <div
                css={css`
                  display: flex;
                  justify-content: space-between;
                `}
              >
                {/** 저장 버튼 */}
                <SaveButton isSaving={isSaving} />

                {/* 활성화 버튼 */}
                <ActivationButton />
              </div>
            </StyledForm>
          </div>
          <PreviewEventPage />
        </div>
      </ContentBox>
    </EventPageContextInitializer>
  );
};
