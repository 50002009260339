import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { useModal } from '@admin/components/Modal/hooks';
import { repositoryClient } from '@admin/repository';
import { repositoryUtils } from '@admin/repository/utils';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useRef } from 'react';

export const LogoPreviewPage = () => {
  const { openModal } = useModal();

  const queryKey = [
    '/file/static',
    { resourceDomain: 'voices', resourceName: 'LOGO' },
  ] as const;

  const query = useQuery({
    queryKey,
    queryFn: async ({
      queryKey: [path, { resourceDomain, resourceName }],
      signal,
    }) => {
      const { data } = await repositoryClient.get<Blob>(
        `${path}/${resourceDomain}`,
        {
          params: { staticType: resourceName },
          responseType: 'blob',
          signal,
        },
      );
      return URL.createObjectURL(data);
    },
    staleTime: 5 * 60 * 1000,
    behavior: {
      onFetch: ({ state: { data: src } }) => {
        if (src) {
          URL.revokeObjectURL(src);
        }
      },
    },
  });

  invariant(query.data, 'Invalid query data.');

  const imageInputRef = useRef<HTMLInputElement>(null);

  const queryClient = useQueryClient();

  const { mutate: uploadImage } = useMutation({
    onError: (e) => {
      openModal(
        <AlertDialog
          title="에러"
          message={repositoryUtils.getErrorMessage(e)}
        />,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
    },
    mutationFn: () => {
      const { current: imageInput } = imageInputRef;
      if (imageInput === null) {
        throw new Error('no input');
      }
      const image = imageInput.files?.item(0);
      if (!image) {
        throw new Error('선택된 이미지가 없습니다.');
      }
      const [path, { resourceDomain, resourceName }] = queryKey;
      const formData = new FormData();
      formData.append('uploadFile', image);
      formData.append(
        'voiceStaticDto',
        new Blob([JSON.stringify({ staticType: resourceName })], {
          type: 'application/json',
        }),
      );
      return repositoryClient.post(`${path}/${resourceDomain}`, formData);
    },
  });

  return (
    <ContentBox
      css={css`
        gap: 20px;
      `}
    >
      <div
        css={css`
          font-weight: bold;
          font-size: 30px;
        `}
      >
        로고 이미지
      </div>
      <img src={query.data} alt="img_logo" />
      <input
        ref={imageInputRef}
        type="file"
        accept={'image/*'}
        css={css`
          width: 400px;
          border: 1px solid black;
        `}
      />
      가능한 이미지 형식: PNG/JPG, 용량 : 최대 5MB
      <Button onClick={uploadImage}>업로드</Button>
    </ContentBox>
  );
};
