import { utils } from '@admin/utils';
import styled from '@emotion/styled';

export const StyledLabel = styled.label`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${utils.style.font['title-m']};
  color: ${utils.style.color.black};

  & > * {
    font-weight: initial;
    font-size: initial;
    line-height: initial;
    color: initial;
  }
`;
