import { Button } from '@admin/components/Button';
import { Dialog } from '@admin/components/Dialog';
import { Divider } from '@admin/components/Divider';
import { useModal } from '@admin/components/Modal/hooks';
import { repositoryClient } from '@admin/repository';
import type { Voice } from '@admin/repository/types';
import { utils } from '@admin/utils';
import { css } from '@emotion/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash-es';
import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { AssignedTable } from './AssignedTable';
import { UnassignedTable } from './UnassignedTable';

interface Props {
  readonly assignedVoiceIds: ReadonlyArray<Voice['voiceId']>;
  readonly userEmail: string;
  readonly voices: ReadonlyArray<Voice>;
}

export const AssignCustomVoiceDialog = (props: Props) => {
  const [searchParams] = useSearchParams();

  const serviceCode = searchParams.get('service-code');

  const { closeModal } = useModal();

  const [currentAssignedVoiceIds, setCurrentAssignedVoiceIds] = useState(
    props.assignedVoiceIds,
  );

  const currentAssignedVoiceIdSet = useMemo(
    () =>
      currentAssignedVoiceIds.reduce(
        (set, id) => set.add(id),
        new Set<Voice['voiceId']>(),
      ),
    [currentAssignedVoiceIds],
  );

  const currentUnassignedVoiceIds = useMemo(
    () =>
      _.chain(props.voices)
        .filter({ voiceType: 'CUSTOM' })
        .map('voiceId')
        .reject((id) => currentAssignedVoiceIdSet.has(id))
        .value(),
    [currentAssignedVoiceIdSet, props.voices],
  );

  const queryClient = useQueryClient();

  const { mutate: save, isLoading: isSaving } = useMutation({
    onError: () => {
      // TODO: 에러 메시지 표시
      // 현재 모달이 이중으로 띄울 수 있게 구현되어 있지 않아서 현재 다이얼로그를 유지한 채
      // 에러 메시지를 표시할 수 있는 방법이 없음.
      // 텍스트로 표시하는 방법도 있지만, 레이아웃이 변경될 수 있기에 일단 에러메시지 표시는 보류
    },
    onSuccess: () => {
      // TODO: 성공 메시지 표시
      // 위와 같은 이유로 보류
      queryClient.invalidateQueries({ queryKey: ['/voice-permission/user'] });
    },
    mutationFn: () =>
      repositoryClient.post('/voice-permission/user', {
        serviceCode,
        userEmail: props.userEmail,
        voiceIds: currentAssignedVoiceIds,
      }),
  });

  return (
    <Dialog title="마이 AI 보이스 추가 할당 편집">
      <div
        css={css`
          display: flex;
          flex-direction: column;
          width: calc(100vw - 60px);
          height: calc(100vh - 140px);
          padding: 24px;
          gap: 24px;
        `}
      >
        <div
          css={css`
            display: flex;
            height: calc(100% - 88px);
            gap: 24px;
            overflow-x: auto;
          `}
        >
          <UnassignedTable
            items={currentUnassignedVoiceIds}
            onClickAssign={(voiceId) =>
              setCurrentAssignedVoiceIds((prev) => [voiceId, ...prev])
            }
            voices={props.voices}
          />
          <AssignedTable
            items={currentAssignedVoiceIds}
            onClickDismiss={(voiceId) =>
              setCurrentAssignedVoiceIds((prev) => _.without(prev, voiceId))
            }
            voices={props.voices}
          />
        </div>

        <Divider />

        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: space-between;
          `}
        >
          <Button
            baseColor={utils.style.color.subBlack}
            onClick={() => setCurrentAssignedVoiceIds([])}
          >
            전부 해제
          </Button>
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 16px;
            `}
          >
            <Button disabled={isSaving} onClick={save}>
              적용
            </Button>
            <Button
              disabled={isSaving}
              onClick={() => {
                closeModal();
                save();
              }}
            >
              확인
            </Button>
            <Button
              textColor={utils.style.color.grey500}
              baseColor={utils.style.color.grey300}
              onClick={closeModal}
            >
              닫기
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
