import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { Divider } from '@admin/components/Divider';
import { ErrorText } from '@admin/components/ErrorText';
import { StyledForm } from '@admin/components/Styled/Form';
import { StyledH1 } from '@admin/components/Styled/H1';
import { repositoryClient } from '@admin/repository';
import { repositoryUtils } from '@admin/repository/utils';
import { utils } from '@admin/utils';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useMutation } from '@tanstack/react-query';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const PasswordExpiredPage = () => {
  const navigate = useNavigate();

  const formRef = useRef<HTMLFormElement>(null);

  const [oldPasswordValueKey, newPasswordValueKey, newPasswordConfirmValueKey] =
    ['old-password', 'new-password', 'new-password-confirm'];

  const [errorText, setErrorText] = useState('');

  const { mutate: changePassword } = useMutation({
    onError: (e) => {
      setErrorText(repositoryUtils.getErrorMessage(e));
    },
    onMutate: () => {
      setErrorText('');
    },
    onSuccess: () => {
      utils.token.clear();
      setTimeout(() => navigate('/'), 0);
    },
    mutationFn: async () => {
      const { current: form } = formRef;
      invariant(form, 'form is not mounted.');

      const formData = new FormData(form);
      const oldPassword = formData.get(oldPasswordValueKey) as string;
      const newPassword = formData.get(newPasswordValueKey) as string;
      const newPasswordConfirm = formData.get(
        newPasswordConfirmValueKey,
      ) as string;

      if (newPassword !== newPasswordConfirm) {
        throw new Error('패스워드 확인이 일치하지 않습니다');
      }

      const [oldPasswordEncrypted, newPasswordEncrypted] = await Promise.all(
        [oldPassword, newPassword].map((password) =>
          repositoryUtils.encryptRawPassword(password),
        ),
      );

      return repositoryClient.post('/member/change-password', {
        currentPassword: oldPasswordEncrypted,
        newPassword: newPasswordEncrypted,
      });
    },
  });

  return (
    <div
      css={css`
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;

        background-color: ${utils.style.color.background};
      `}
    >
      <ContentBox
        css={css`
          width: 600px;
          gap: 20px;
        `}
      >
        <StyledForm
          onSubmit={(e) => {
            e.preventDefault();
            changePassword();
          }}
        >
          <StyledH1>비밀번호가 만료되어 변경이 필요합니다.</StyledH1>

          <label>
            현재 패스워드
            <input
              required
              name={oldPasswordValueKey}
              type="password"
              autoComplete="current-password"
            />
          </label>

          <Divider />

          <label>
            새 패스워드
            <input
              required
              name={newPasswordValueKey}
              type="password"
              autoComplete="new-password"
            />
          </label>

          <label>
            새 패스워드 확인
            <input
              required
              name={newPasswordConfirmValueKey}
              type="password"
              autoComplete="new-password"
            />
          </label>

          <ErrorText errorText={errorText} />

          <div
            css={css`
              display: flex;
              gap: 16px;
            `}
          >
            <Button form>변경하기</Button>

            <Button
              baseColor={utils.style.color.highlight1}
              onClick={() => navigate('/', { replace: true })}
            >
              다음에 변경하기
            </Button>
          </div>
        </StyledForm>
      </ContentBox>
    </div>
  );
};
