import { Button } from '@admin/components/Button';
import { Dialog } from '@admin/components/Dialog';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { InputFile } from '@admin/components/InputFile';
import { useModal } from '@admin/components/Modal/hooks';
import { utils } from '@admin/utils';
import { css } from '@emotion/react';
import { useState } from 'react';
import type { Design } from 'react-email-editor';

export const LoadDesignDialog = (props: {
  onConfirm: (desgin: Design) => void;
}) => {
  const { openModal, closeModal } = useModal();

  const [file, setFile] = useState<File | undefined>();

  return (
    <Dialog title="메일 불러오기">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (!file) {
            return;
          }
          const reader = new FileReader();
          reader.onload = () => {
            if (typeof reader.result === 'string') {
              props.onConfirm(JSON.parse(reader.result));
              closeModal();
              return;
            }
            openModal(
              <AlertDialog
                title="에러"
                message="올바르지 않은 메일 파일입니다."
              />,
            );
          };
          reader.readAsText(file);
        }}
        css={css`
          width: 400px;
          padding: 24px;
          display: flex;
          flex-direction: column;
          gap: 24px;
        `}
      >
        <InputFile
          accept=".json"
          onChange={({ file }) => {
            setFile(file);
          }}
        />

        <div
          css={css`
            width: 100%;
            display: flex;
            justify-content: flex-end;
            gap: 16px;
          `}
        >
          <Button disabled={!file} form>
            불러오기
          </Button>

          <Button
            onClick={closeModal}
            textColor={utils.style.color.grey500}
            baseColor={utils.style.color.grey200}
          >
            취소
          </Button>
        </div>
      </form>
    </Dialog>
  );
};
