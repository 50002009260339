import { Button } from '@admin/components/Button';
import { Dialog } from '@admin/components/Dialog';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { ErrorText } from '@admin/components/ErrorText';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledForm } from '@admin/components/Styled/Form';
import { repositoryClient } from '@admin/repository';
import { repositoryUtils } from '@admin/repository/utils';
import { utils } from '@admin/utils';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useLayoutEffect, useRef, useState } from 'react';

export const VoiceMappingFormDialog = () => {
  const { closeModal, openModal } = useModal();

  const [idFieldKey, nameFieldKey] = ['speaker-id', 'voice-name'];

  const formRef = useRef<HTMLFormElement>(null);

  const [errorText, setErrorText] = useState('');

  useLayoutEffect(() => {
    const idFieldElement = formRef.current?.elements.namedItem(idFieldKey);
    if (idFieldElement instanceof HTMLInputElement) {
      idFieldElement.focus();
    }
  }, [idFieldKey]);

  const queryClient = useQueryClient();

  const { mutate: create, isLoading: isCreating } = useMutation({
    onError: (e) => {
      setErrorText(repositoryUtils.getErrorMessage(e));
    },
    onMutate: () => {
      setErrorText('');
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['/tts/relations'] });
      const { current: form } = formRef;
      if (form) {
        const formData = new FormData(form);
        const id = formData.get(idFieldKey);
        const name = formData.get(nameFieldKey);
        openModal(
          <AlertDialog
            title="알림"
            message={`${name}이 ${id}에 성공적으로 매핑되었습니다.`}
          />,
        );
      }
    },
    mutationFn: () => {
      const { current: form } = formRef;
      invariant(form, 'form is not mounted.');
      const formData = new FormData(form);
      return repositoryClient.post('/tts/relation', {
        speaker_id: Number(formData.get(idFieldKey)),
        voice_name: formData.get(nameFieldKey),
      });
    },
  });

  return (
    <Dialog title="보이스 맵핑 추가">
      <StyledForm
        ref={formRef}
        onSubmit={(e) => {
          e.preventDefault();
          create();
        }}
        css={css`
          display: flex;
          flex-direction: column;
          width: 480px;
          padding: 24px 30px;
          gap: 24px;
        `}
      >
        <label>
          화자 ID
          <input required type="number" min={0} name={idFieldKey} />
        </label>

        <label>
          보이스 이름
          <input required name={nameFieldKey} />
        </label>

        <div
          css={css`
            display: flex;
            justify-content: flex-end;
            gap: 16px;
          `}
        >
          <Button form disabled={isCreating}>
            생성
          </Button>

          <Button
            onClick={closeModal}
            textColor={utils.style.color.grey500}
            baseColor={utils.style.color.grey200}
          >
            취소
          </Button>
        </div>
      </StyledForm>

      <ErrorText errorText={errorText} />
    </Dialog>
  );
};
