import { Button } from '@admin/components/Button';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { LoadingDialog } from '@admin/components/Dialog/LoadingDialog';
import { useModal } from '@admin/components/Modal/hooks';
import { repositoryClient } from '@admin/repository';
import { repositoryUtils } from '@admin/repository/utils';
import { ScheduleSendDialog } from '@admin/routes/email/create/Dialog/ScheduleSendDialog';
import { validateEmail } from '@admin/routes/email/create/utils';
import { useMutation } from '@tanstack/react-query';
import CryptoJS from 'crypto-js';
import type { Design, EditorRef } from 'react-email-editor';
import { useNavigate } from 'react-router-dom';

interface Props {
  getAllEmailAddressList: () => Promise<string[]>;
  propsObject: {
    emailTitle: string;
    fileRef: React.MutableRefObject<File | undefined>;
    mailEditorRef: React.RefObject<EditorRef>;
    editorLoaded: boolean;
  };
}

export const ScheduleEmailButton = (property: Props) => {
  const { openModal, closeModal } = useModal();

  const navigate = useNavigate();

  const props = {
    ...property.propsObject,
    getAllEmailAddressList: property.getAllEmailAddressList,
  };

  const scheduleEmail = useMutation({
    mutationFn: async ({
      htmlString,
      design,
      dateTime,
    }: {
      htmlString: string;
      design: Design;
      dateTime: Date;
    }) => {
      const { current: file } = props.fileRef;
      // 방화벽 이슈로 인해 base64로 인코딩하여 전송
      const base64HTMLString = CryptoJS.enc.Base64.stringify(
        CryptoJS.enc.Utf8.parse(htmlString),
      );
      const base64DesignString = CryptoJS.enc.Base64.stringify(
        CryptoJS.enc.Utf8.parse(JSON.stringify(design)),
      );
      const payload = {
        renderingInfo: base64DesignString,
        contents: base64HTMLString,
        date: [
          dateTime.getFullYear(),
          dateTime.getMonth() + 1,
          dateTime.getDate(),
        ],
        time: [dateTime.getHours(), dateTime.getMinutes()],
        title: props.emailTitle,
        to: await validateEmail(props.emailTitle, props.getAllEmailAddressList),
      } as const;
      const formData = new FormData();
      formData.append(
        'createReservationMailRequest',
        new Blob([JSON.stringify(payload)], { type: 'application/json' }),
      );
      if (file) {
        formData.append('attachment', file);
      }
      return repositoryClient.post('/admin-mail/reservation', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    },
    onMutate: () => {
      openModal(<LoadingDialog title="알림" message="메일 예약 중입니다." />);
    },
    onError: (e) => {
      closeModal();
      openModal(
        <AlertDialog
          title="에러"
          message={repositoryUtils.getErrorMessage(e)}
        />,
      );
    },
    onSuccess: () => {
      closeModal();
      openModal(
        <AlertDialog title="알림" message="메일 예약에 성공하였습니다." />,
      );
      navigate('..');
    },
  });

  return (
    <Button
      disabled={!props.editorLoaded}
      onClick={() => {
        if (props.emailTitle.length === 0) {
          openModal(
            <AlertDialog title="에러" message="제목을 입력해주세요." />,
          );
          return;
        }
        openModal(
          <ScheduleSendDialog
            onConfirm={(dateTime) => {
              if (props.mailEditorRef.current === null) {
                throw new Error('메일 에디터가 로드되지 않았습니다.');
              }
              props.mailEditorRef.current.exportHtml(
                ({ html: htmlString, design }) => {
                  scheduleEmail.mutate({
                    htmlString,
                    design,
                    dateTime,
                  });
                },
                {
                  cleanup: true,
                  minify: true,
                },
              );
            }}
          />,
        );
      }}
    >
      예약 발송
    </Button>
  );
};
