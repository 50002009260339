import { ContentBox } from '@admin/components/ContentBox';
import { StyledH1 } from '@admin/components/Styled/H1';
import { css } from '@emotion/react';

import { Filter } from './Filter';
import { VoiceTable } from './VoiceTable';

export const SpecialVoiceSettlementListPage = () => {
  return (
    <ContentBox
      css={css`
        height: 100%;
        gap: 24px;
      `}
    >
      <StyledH1>특별 보이스 통계/정산</StyledH1>
      <Filter />
      <VoiceTable />
    </ContentBox>
  );
};
