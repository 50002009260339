import { Button } from '@admin/components/Button';
import { ErrorText } from '@admin/components/ErrorText';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledForm } from '@admin/components/Styled/Form';
import { utils } from '@admin/utils';
import { css } from '@emotion/react';
import { useCallback, useState } from 'react';

import { Dialog } from '..';

export const DeleteDialog = (props: {
  type: string;
  subjectText: string;
  confirmText: string;
  onConfirm: () => void;
  onCancel?: () => void;
}) => {
  const { closeModal } = useModal();

  const [errorText, setErrorText] = useState('');
  const [confirmText, setConfirmText] = useState('');

  const checkConfirmText = useCallback(() => {
    if (props.confirmText !== confirmText) {
      setErrorText('확인 문구가 일치하지 않습니다.');
      return;
    }
    props.onConfirm();
    closeModal();
  }, [closeModal, confirmText, props]);

  return (
    <Dialog title={`${props.type} 삭제 확인`}>
      <StyledForm
        onSubmit={(e) => {
          e.preventDefault();
          checkConfirmText();
        }}
        css={css`
          padding: 24px 30px;
          width: 500px;
          min-height: 200px;
          max-height: 400px;
          gap: 24px;

          ${utils.style.font['text-l']};
        `}
      >
        <p>
          {`정말로 ${props.type} '${props.subjectText}'을(를) 삭제하시겠습니까?`}
        </p>
        <p>
          {`삭제하시려면 아래 입력란에 '${props.confirmText}'을(를) 입력하고 삭제를 클릭하세요.`}
        </p>
        <p
          css={css`
            color: ${utils.style.color.highlight1};
            font-weight: bold;
          `}
        >
          이 작업은 되돌릴 수 없습니다!
        </p>
        <input
          required
          name="confirm-text"
          value={confirmText}
          onChange={(e) => {
            setConfirmText(e.target.value);
          }}
        />

        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 20px;
          `}
        >
          <ErrorText errorText={errorText} />
          <div
            css={css`
              flex: 1 0;
            `}
          />
          <Button baseColor={utils.style.color.highlight1} form>
            삭제
          </Button>

          <Button
            onClick={() => {
              closeModal();
              props.onCancel?.();
            }}
            textColor={utils.style.color.grey500}
            baseColor={utils.style.color.grey200}
          >
            취소
          </Button>
        </div>
      </StyledForm>
    </Dialog>
  );
};
