import { Navigate, Outlet } from 'react-router-dom';

export const ProtectedLayout = (props: {
  isAllowed: boolean;
  redirectPath: string;
}) => {
  if (props.isAllowed) {
    return <Outlet />;
  }

  return <Navigate to={props.redirectPath} replace />;
};
