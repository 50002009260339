import type { EventPage } from '@admin/routes/eventPage/edit/context/type';
import { atom } from 'recoil';

const blob = new Blob([]);
export const initialEventButtonList = {
  url: '',
  width: 0.0,
  height: 0.0,
  top: 0.0,
  left: 0.0,
};
export const initialEventPageState = {
  pcImageFile: new File([blob], ''),
  mobileImageFile: new File([blob], ''),
  pcImageUrl: '',
  mobileImageUrl: '',
  since: new Date(),
  till: new Date(),
  pcEventButtonList: [initialEventButtonList],
  mobileEventButtonList: [initialEventButtonList],
  focusedButtonIndex: 0,
  isPCBasicImage: true,
  isMobileBasicImage: true,
  isActive: false,
};

export const eventPageAtom = atom<EventPage>({
  key: 'atom/event-page',
  default: initialEventPageState,
});

export const mouseClickAtom = atom<boolean[]>({
  key: 'atom/event-page/is-clicked',
  default: [],
});

export const selectedTypeAtom = atom<string>({
  key: 'atom/event-page/selected-type',
  default: 'PC',
});
