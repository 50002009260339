import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { PromptDialog } from '@admin/components/Dialog/PromptDialog';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledForm } from '@admin/components/Styled/Form';
import { StyledH1 } from '@admin/components/Styled/H1';
import { repositoryClient } from '@admin/repository';
import { DISPLAY_NAMES_MEMBER } from '@admin/repository/constant';
import type { Member } from '@admin/repository/types';
import { repositoryUtils } from '@admin/repository/utils';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash-es';
import { useMemo, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import type { UpdateMemberPayload } from '../type';

type Payload = Pick<
  UpdateMemberPayload,
  | 'birth'
  | 'language'
  | 'name'
  | 'phone'
  | 'region'
  | 'isCheckMarketingTerm'
  | 'isCheckUnifiedRegistration'
>;

export const MemberUpdateForm = () => {
  const [searchParams] = useSearchParams();

  const serviceCode = searchParams.get('service-code');

  const { email: userEmail } = useParams();

  const { openModal } = useModal();

  const formRef = useRef<HTMLFormElement>(null);

  const query = useQuery({
    queryKey: ['/member/single', { serviceCode, userEmail }] as const,
    queryFn: ({ queryKey: [path, { serviceCode, userEmail }], signal }) =>
      repositoryClient.get<{ data: Member }>(`${path}/${userEmail}`, {
        params: { serviceCode },
        signal,
      }),
    select: ({ data: { data } }) =>
      ({
        birth: data.birth,
        language: data.language,
        name: data.name,
        phone: data.phone,
        region: data.region,
        isCheckMarketingTerm: data.isCheckMarketingTerm,
        isCheckUnifiedRegistration: data.isCheckUnifiedRegistration,
      } satisfies Payload),
  });

  invariant(query.data, 'Invalid query data.');

  const [payload, _setPayload] = useState<Readonly<Payload>>(query.data);

  const setPayload = <K extends keyof Payload>(key: K, value: Payload[K]) =>
    _setPayload((prev) => prev && { ...prev, [key]: value });

  const changedFields = useMemo<Partial<Payload>>(
    () =>
      _.fromPairs(
        _.differenceWith(_.toPairs(payload), _.toPairs(query.data), _.isEqual),
      ),
    [payload, query],
  );

  const queryClient = useQueryClient();

  const { mutate: save, isLoading: isSaving } = useMutation({
    onError: (e) => {
      // 포커스 해제
      for (const el of formRef.current?.elements ?? []) {
        if (el instanceof HTMLInputElement) {
          el.blur();
        }
      }
      openModal(
        <AlertDialog
          title="에러"
          message={repositoryUtils.getErrorMessage(e)}
        />,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['/member/single', { serviceCode, userEmail }],
      });
      openModal(
        <AlertDialog
          title="알림"
          message="변경 사항이 성공적으로 저장되었습니다."
        />,
      );
    },
    mutationFn: () => {
      if (!serviceCode) {
        throw new Error('invalid service code');
      }
      if (!userEmail) {
        throw new Error('invalid email');
      }
      if (!payload) {
        throw new Error('invalid payload');
      }
      return repositoryClient.patch(
        `/member/${userEmail}`,
        {
          ...changedFields,
          password: '', // 이거 안넣으면 에러남
        },
        { params: { serviceCode } },
      );
    },
  });

  return (
    <ContentBox
      css={css`
        gap: 1rem;
      `}
    >
      <StyledH1>가입 정보</StyledH1>

      <StyledForm
        ref={formRef}
        onSubmit={(e) => {
          e.preventDefault();
          openModal(
            <PromptDialog
              title="저장"
              message="변경 사항을 저장하시겠습니까?"
              onConfirm={save}
            />,
          );
        }}
      >
        <label>
          이름
          <input
            required
            value={payload.name}
            onChange={({ currentTarget: { value } }) =>
              setPayload('name', value)
            }
          />
        </label>

        <label>
          전화번호
          <input
            required
            value={payload.phone}
            onChange={({ currentTarget: { value } }) =>
              setPayload('phone', value)
            }
          />
        </label>

        <label>
          생년월일
          <input
            required
            type="date"
            value={payload.birth}
            onChange={({ currentTarget: { value } }) =>
              setPayload('birth', value)
            }
            onKeyDown={(e) => e.preventDefault()}
          />
        </label>

        <label>
          지역
          <select
            required
            value={payload.region}
            onChange={({ currentTarget: { value } }) =>
              setPayload('region', value as Member['region'])
            }
          >
            <option value="KOREA">대한민국</option>
            <option value="JAPAN">일본</option>
            <option value="UNITED_STATES">미국</option>
            <option value="UNITED_KINGDOM">영국</option>
            <option value="CHINA">중국</option>
          </select>
        </label>

        <label>
          언어
          <select
            required
            value={payload.language}
            onChange={({ currentTarget: { value } }) =>
              setPayload('language', value as Member['language'])
            }
          >
            <option value="de">기본</option>
            <option value="ko">한국어</option>
            <option value="en">영어</option>
            <option value="ja">일본어</option>
            <option value="zh">중국어</option>
            <option value="es">스페인어</option>
          </select>
        </label>

        <label>
          {DISPLAY_NAMES_MEMBER.isCheckMarketingTerm}
          <input
            disabled
            type="checkbox"
            defaultChecked={payload.isCheckMarketingTerm}
          />
        </label>

        <label>
          {DISPLAY_NAMES_MEMBER.isCheckUnifiedRegistration} (AI 휴먼스튜디오)
          <input
            type="checkbox"
            checked={payload.isCheckUnifiedRegistration}
            onChange={({ currentTarget: { checked } }) =>
              setPayload('isCheckUnifiedRegistration', checked)
            }
          />
        </label>

        <Button form disabled={isSaving || _.isEmpty(changedFields)}>
          저장
        </Button>
      </StyledForm>
    </ContentBox>
  );
};
