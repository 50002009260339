import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { useModal } from '@admin/components/Modal/hooks';
import { Search } from '@admin/components/Search';
import { StyledH1 } from '@admin/components/Styled/H1';
import { StyledTable } from '@admin/components/Styled/Table';
import { repositoryClient } from '@admin/repository';
import type { VoiceMapping } from '@admin/repository/types';
import { utils } from '@admin/utils';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash-es';
import { useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { VoiceMappingFormDialog } from './VoiceMappingFormDialog';

export const VoiceMappingListPage = () => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const { openModal } = useModal();

  const query = useQuery({
    queryKey: ['/tts/relations'] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ datas: VoiceMapping[] }>(path, { signal }),
    select: ({ data: { datas } }) => _.sortBy(datas, 'speaker_id'),
  });

  invariant(query.data, 'Invalid query data.');

  const filteredData = useMemo(() => {
    const { data } = query;
    if (!data) {
      return [];
    }
    const searchString = searchParams.get('q') || '';
    if (!searchString) {
      return data;
    }
    return data.filter(
      ({ speaker_id, voice_name }) =>
        speaker_id === Number(searchString) ||
        voice_name.includes(searchString),
    );
  }, [query, searchParams]);

  return (
    <ContentBox
      css={css`
        height: 100%;
        gap: 24px;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <StyledH1>보이스 맵핑</StyledH1>

        <Button onClick={() => openModal(<VoiceMappingFormDialog />)}>
          보이스 맵핑 추가
        </Button>
      </div>

      <Search
        defaultValue={searchParams.get('q') || ''}
        onSubmit={(searchString) =>
          setSearchParams((prev) => {
            const params = new URLSearchParams(prev);
            if (searchString) {
              params.set('q', searchString);
            } else {
              params.delete('q');
            }
            return params;
          })
        }
      />

      <div
        css={css`
          height: 100%;
          overflow: auto;
        `}
      >
        <StyledTable>
          <thead>
            <tr>
              <th>ID</th>
              <th>보이스 이름</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map(({ speaker_id, voice_name }) => (
              <tr
                key={speaker_id}
                onClick={() => navigate(`./edit/${speaker_id}`)}
                css={css`
                  &:hover {
                    background: ${utils.style.color.grey100};
                  }
                  cursor: pointer;
                `}
              >
                <td>{speaker_id}</td>
                <td>{voice_name}</td>
              </tr>
            ))}
          </tbody>
        </StyledTable>
      </div>
    </ContentBox>
  );
};
