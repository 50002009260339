import { ContentBox } from '@admin/components/ContentBox';
import { InputDateRange } from '@admin/components/InputDateRange';
import { StyledH1 } from '@admin/components/Styled/H1';
import { StyledTable } from '@admin/components/Styled/Table';
import { css } from '@emotion/react';
import { useState } from 'react';

export const PlanStatisticsPage = () => {
  const [dateRange, setDateRange] = useState(() => {
    const [start, end] = [new Date(), new Date()];
    start.setDate(end.getDate() - 7);
    return { start, end };
  });

  return (
    <ContentBox
      css={css`
        gap: 24px;
      `}
    >
      <StyledH1>요금제 통계</StyledH1>

      <InputDateRange
        enableShortcuts
        onChange={setDateRange}
        value={dateRange}
      />

      <StyledTable>
        <thead>
          <tr>
            <th>구분</th>
            <th>이용자 수</th>
            <th>실제 사용자 수</th>
            <th>차감 자 수</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>전체</td>
            <td>0명</td>
            <td>0명</td>
            <td>0자</td>
          </tr>
          <tr>
            <td>Free</td>
            <td>0명</td>
            <td>0명</td>
            <td>0자</td>
          </tr>
          <tr>
            <td>Lite</td>
            <td>0명</td>
            <td>0명</td>
            <td>0자</td>
          </tr>
          <tr>
            <td>Super</td>
            <td>0명</td>
            <td>0명</td>
            <td>0자</td>
          </tr>
          <tr>
            <td>Super Plus</td>
            <td>0명</td>
            <td>0명</td>
            <td>0자</td>
          </tr>
        </tbody>
      </StyledTable>
    </ContentBox>
  );
};
