import { utils } from '@admin/utils';
import { css } from '@emotion/react';

export const VerticalDivider: React.FC = () => {
  return (
    <div
      css={css`
        height: 100%;
        border-left: 1px solid ${utils.style.color.grey300};
      `}
    />
  );
};
