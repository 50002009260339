import { Button } from '@admin/components/Button';
import { StyledInput } from '@admin/components/Styled/Input';
import { repositoryClient } from '@admin/repository';
import { queryClient } from '@admin/repository/query';
import { repositoryUtils } from '@admin/repository/utils';
import { utils } from '@admin/utils';
import { css } from '@emotion/react';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';

// 발음 교정 단어가 1000개가 넘을 시, input에 렉 발생하여 컴포넌트 분리
export const AddPronunciationMapsTable = ({
  setErrorText,
}: {
  setErrorText: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const [fieldInput, setFieldInput] = useState<{
    text: string;
    pronunciation: string;
  }>({ text: '', pronunciation: '' });

  // 발음 교정 단어 추가
  const { mutate: pronunciationMapCreate } = useMutation({
    mutationFn: () => {
      if (!fieldInput.text || !fieldInput.pronunciation) {
        throw new Error('입력값은 필수입니다.');
      }
      return repositoryClient.post(`/pronunciation-maps/global`, {
        text: fieldInput.text,
        pronunciation: fieldInput.pronunciation,
      });
    },
    onSuccess: () => {
      setFieldInput({ text: '', pronunciation: '' });
      queryClient.invalidateQueries({
        queryKey: [`/pronunciation-maps/global`],
      });
    },
    onError: (e) => {
      const message = repositoryUtils.getErrorMessage(e);
      setErrorText(message);
    },
  });

  const onChangeInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFieldInput((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <tr
      css={css`
        height: 60px;
        position: sticky;
        top: 60px;
        z-index: ${utils.style['z-index'].cover};
        background: white;
        td > input {
          width: 100%;
        }
      `}
    >
      <td>
        <StyledInput
          placeholder="단어를 입력해주세요."
          name="text"
          onChange={onChangeInputValue}
          value={fieldInput.text}
        />
      </td>
      <td>
        <StyledInput
          placeholder="발음을 입력해주세요."
          name="pronunciation"
          onChange={onChangeInputValue}
          value={fieldInput.pronunciation}
        />
      </td>
      <td>
        <Button
          onClick={() => pronunciationMapCreate()}
          css={css`
            display: inline;
          `}
        >
          발음 교정 단어 추가
        </Button>
      </td>
    </tr>
  );
};
