import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { InputFile } from '@admin/components/InputFile';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledLabel } from '@admin/components/Styled/Label';
import type { emailConstants } from '@admin/routes/email/constants';
import { css } from '@emotion/react';
import { useRef } from 'react';

export const FileAttachment = ({
  emailQuery,
  updateFileRef,
}: {
  emailQuery:
    | {
        reservationMailId: number;
        toEmailList: string[];
        title: string;
        date: number[];
        time: number[];
        status: keyof typeof emailConstants.status;
        fileName: string;
        hasFile: boolean;
        renderingInfo: string;
      }
    | undefined;
  updateFileRef: (ref: React.MutableRefObject<File | undefined>) => void;
}) => {
  const { openModal } = useModal();
  const fileRef = useRef<File | undefined>(undefined);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 8px;
      `}
    >
      <StyledLabel>
        파일 첨부 (파일 이름에는 영어와 숫자만 들어갈 수 있으며, 최대 용량은 5MB
        입니다)
      </StyledLabel>
      <InputFile
        queryFileName={emailQuery?.fileName}
        onChange={({ file, reset }) => {
          if (!file) {
            return;
          }
          const normalizedFileName = file.name.normalize('NFC');
          const nameConstraint = /^[\d\s\w\d\s\w,.+-]+/g;
          const match = normalizedFileName.match(nameConstraint);
          if (!match || match.join('') !== normalizedFileName) {
            openModal(
              <AlertDialog
                title="에러"
                message="유효하지 않은 파일 이름입니다."
              />,
            );
            reset();
            return;
          }
          if (file.size > 5 * 1024 * 1024) {
            openModal(
              <AlertDialog
                title="에러"
                message="파일의 최대 용량은 5MB 입니다."
              />,
            );
            reset();
            return;
          }
          fileRef.current = new File([file], normalizedFileName, {
            lastModified: file.lastModified,
            type: file.type,
          });
          updateFileRef(fileRef);
        }}
      />
    </div>
  );
};
