import { Button } from '@admin/components/Button';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { PromptDialog } from '@admin/components/Dialog/PromptDialog';
import { useModal } from '@admin/components/Modal/hooks';
import { repositoryClient } from '@admin/repository';
import type { Voice } from '@admin/repository/types';
import { repositoryUtils } from '@admin/repository/utils';
import { invariant } from '@admin/utils/invariant';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash-es';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { voiceTagFormAtoms } from '../atom';

export const SaveButton = () => {
  const { name } = useParams();

  const { openModal } = useModal();

  const selectedTags = useRecoilValue(voiceTagFormAtoms['selected-tags']);

  const { data: voice } = useQuery({
    queryKey: ['/voice/all'] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ data: Voice[] }>(path, { signal }),
    select: ({ data: { data } }) => data.find((v) => v.name === name),
  });

  invariant(voice, 'Invalid voice data.');

  const queryClient = useQueryClient();

  const { mutate: save, isLoading: isSaving } = useMutation({
    onError: (e) => {
      openModal(
        <AlertDialog
          title="에러"
          message={repositoryUtils.getErrorMessage(e)}
        />,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['/voice/all']);
      openModal(
        <AlertDialog
          title="알림"
          message="보이스 태그가 성공적으로 저장되었습니다."
        />,
      );
    },
    mutationFn: () =>
      repositoryClient.post('/voice-tag', {
        voiceName: voice.name,
        tags: selectedTags,
      }),
  });

  const isSameAsOriginal = useMemo(
    () => _.isEqual(_.sortBy(selectedTags, 'id'), _.sortBy(voice.tags, 'id')),
    [selectedTags, voice.tags],
  );

  return (
    <Button
      disabled={isSaving || _.isEmpty(selectedTags) || isSameAsOriginal}
      onClick={() =>
        openModal(
          <PromptDialog
            title="태그 저장 확인"
            message="선택된 태그를 저장하시겠습니까?"
            onConfirm={save}
            confirmLabel="저장"
          />,
        )
      }
    >
      태그 저장
    </Button>
  );
};
