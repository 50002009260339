import { Button } from '@admin/components/Button';
import { DISPLAY_NAMES_VOICE_TYPE } from '@admin/repository/constant';
import type { Emotion, Voice } from '@admin/repository/types';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';

const DISPLAY_NAMES_VOICE = {
  createdAt: '마이AI보이스 생성일',
  creatorEmail: '마이AI보이스 생성자 계정',
  displayName: '표시 이름',
  isEnabled: '활성화 여부',
  name: '내부 이름',
  voiceId: '보이스 ID',
  voiceType: '유형',
  planName: '최소 요금제',
  recordTime: '마이AI보이스 녹음 등록일',
  trainingCount: '마이AI보이스 재학습 횟수',
  cloudId: 'KT Cloud ID',
  N: '중립',
  H: '즐거움',
  A: '화남',
  S: '슬픔',
  C: '침착함',
};

export const DownloadVoiceList = ({ data }: { data: Voice[] }) => {
  const getEmotionData = (emotions: readonly Emotion[]) => {
    return emotions.reduce((acc, cur) => {
      const matchingKey =
        DISPLAY_NAMES_VOICE[cur.name as 'N' | 'H' | 'A' | 'S' | 'C'];

      return matchingKey
        ? { ...acc, [matchingKey]: cur.isEnabled ? '지원' : '미지원' }
        : acc;
    }, {});
  };

  const createXlsx = () => {
    const changeHeader = data.map((item) => ({
      [DISPLAY_NAMES_VOICE.voiceType]: DISPLAY_NAMES_VOICE_TYPE[item.voiceType],
      [DISPLAY_NAMES_VOICE.voiceId]: item.voiceId,
      [DISPLAY_NAMES_VOICE.name]: item.name,
      [DISPLAY_NAMES_VOICE.displayName]: item.displayName,
      [DISPLAY_NAMES_VOICE.isEnabled]: item.isEnabled ? '활성' : '비활성',
      [DISPLAY_NAMES_VOICE.cloudId]: item.cloudId ?? '',
      [DISPLAY_NAMES_VOICE.planName]: item.planName,
      ...getEmotionData(item.emotions),
      [DISPLAY_NAMES_VOICE.creatorEmail]:
        item.voiceType === 'CUSTOM' ? item.creatorEmail : '',
      [DISPLAY_NAMES_VOICE.recordTime]:
        item.voiceType === 'CUSTOM' &&
        dayjs(item.recordTime as string).isValid()
          ? dayjs(item.recordTime as string).format('YYYY.MM.DD')
          : '',
      [DISPLAY_NAMES_VOICE.createdAt]:
        item.voiceType === 'CUSTOM'
          ? dayjs(item.createdAt as string).format('YYYY.MM.DD')
          : '',
      [DISPLAY_NAMES_VOICE.trainingCount]:
        item.voiceType === 'CUSTOM' ? item.trainingCount : '',
    }));

    const worksheet = XLSX.utils.json_to_sheet(changeHeader);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Voice list');

    XLSX.writeFile(
      workbook,
      `KT AI Voice 보이스 리스트 ${dayjs().format('YYYY.MM.DD HH_mm_ss')}.xlsx`,
    );
  };

  return <Button onClick={createXlsx}>Excel 내보내기</Button>;
};
