import { Button } from '@admin/components/Button';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledLabel } from '@admin/components/Styled/Label';
import { LoadDesignDialog } from '@admin/routes/email/component/LoadDesignDialog';
import { css } from '@emotion/react';
import { useCallback } from 'react';
import type { Design, EditorRef } from 'react-email-editor';

export const MailImportExportButton = ({
  editorLoaded,
  mailEditorRef,
  loadDesign,
}: {
  editorLoaded: boolean;
  mailEditorRef: React.RefObject<EditorRef>;
  loadDesign: (design: Design) => void;
}) => {
  const { openModal } = useModal();

  const exportDesign = useCallback(() => {
    mailEditorRef.current?.saveDesign((design) => {
      const downloadAnchorNode = document.createElement('a');
      downloadAnchorNode.setAttribute(
        'href',
        URL.createObjectURL(
          new Blob([JSON.stringify(design)], { type: 'application/json' }),
        ),
      );
      downloadAnchorNode.setAttribute('download', 'design.json');
      downloadAnchorNode.click();
      URL.revokeObjectURL(downloadAnchorNode.href);
      downloadAnchorNode.remove();
    });
  }, [mailEditorRef]);
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
      `}
    >
      <StyledLabel>메일 내용</StyledLabel>

      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 16px;
        `}
      >
        이 페이지에서 저장한 메일만 불러올 수 있습니다.
        <Button
          disabled={!editorLoaded}
          onClick={() => openModal(<LoadDesignDialog onConfirm={loadDesign} />)}
        >
          메일 파일 불러오기
        </Button>
        <Button disabled={!editorLoaded} onClick={exportDesign}>
          메일 파일로 저장하기
        </Button>
      </div>
    </div>
  );
};
