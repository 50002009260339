import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { DeleteDialog } from '@admin/components/Dialog/DeleteDialog';
import { ErrorText } from '@admin/components/ErrorText';
import { InputText } from '@admin/components/InputText';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledH1 } from '@admin/components/Styled/H1';
import { StyledLabel } from '@admin/components/Styled/Label';
import { StyledSelect } from '@admin/components/Styled/Select';
import { TextEditor } from '@admin/components/textEditor/TextEditor';
import { repositoryClient } from '@admin/repository';
import type { Notice } from '@admin/repository/types';
import { repositoryUtils } from '@admin/repository/utils';
import { utils } from '@admin/utils';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash-es';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const NoticeEditorPage = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const { openModal } = useModal();

  const [errorText, setErrorText] = useState('');

  const noticeQuery = useQuery({
    queryKey: ['/notice/private', { noticeId: id }] as const,
    queryFn: ({ queryKey: [path, { noticeId }], signal }) =>
      repositoryClient.get<{ data: Notice }>(`${path}/${noticeId}`, { signal }),
    select: ({ data: { data: notice } }) => notice,
  });

  invariant(noticeQuery.data, 'Invalid query data.');

  const [formValue, setFormValue] = useState<
    Pick<Notice, 'category' | 'contents' | 'title'>
  >(() => _.pick(noticeQuery.data, ['category', 'contents', 'title']));

  const queryClient = useQueryClient();

  const { mutate: save, isLoading: isSaving } = useMutation({
    onError: (e) => {
      setErrorText(repositoryUtils.getErrorMessage(e));
    },
    onMutate: () => {
      setErrorText('');
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['/notice/private'] });
      openModal(
        <AlertDialog title="알림" message="공지사항이 저장되었습니다." />,
      );
    },
    mutationFn: () => repositoryClient.patch(`/notice/${id}`, formValue),
  });

  const { mutate: deleteNotice } = useMutation({
    onError: (e) => {
      setErrorText(repositoryUtils.getErrorMessage(e));
    },
    onMutate: () => {
      setErrorText('');
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['/notice/private'] });
      navigate('..', { replace: true });
    },
    mutationFn: () => repositoryClient.delete(`/notice/${id}`),
  });

  return (
    <ContentBox
      css={css`
        gap: 24px;
      `}
    >
      <StyledH1>공지사항 정보</StyledH1>
      <StyledLabel>
        ID
        <InputText readonly value={noticeQuery.data.id.toString()} />
      </StyledLabel>

      <StyledLabel>
        카테고리
        <StyledSelect
          defaultValue={noticeQuery.data.category}
          onChange={({ target: { value: category } }) =>
            setFormValue((prev) => prev && { ...prev, category })
          }
        >
          <option value="" disabled hidden>
            선택 필요
          </option>
          <option value="service">서비스 안내</option>
          <option value="system">시스템 안내</option>
          <option value="update">업데이트</option>
          <option value="etc">기타</option>
        </StyledSelect>
      </StyledLabel>

      <StyledLabel>
        제목
        <InputText
          value={formValue?.title}
          onChange={(title) =>
            setFormValue((prev) => prev && { ...prev, title })
          }
        />
      </StyledLabel>

      {/* htmlFor를 지정하지 않으면 StyledLabel이 텍스트 에디터 내부의 file input과 연결되어
        StyledLabel 내부 영역 전체가 file input을 작동시킴 */}
      <StyledLabel htmlFor="content">
        내용
        <TextEditor
          initialContent={noticeQuery.data.contents}
          onChange={(contents) =>
            setFormValue((prev) => prev && { ...prev, contents })
          }
        />
      </StyledLabel>

      <StyledLabel>
        작성자
        <InputText readonly value={noticeQuery.data.creatorEmail} />
      </StyledLabel>

      <StyledLabel>
        수정자
        <InputText readonly value={noticeQuery.data.modifierEmail} />
      </StyledLabel>

      <StyledLabel>
        작성일자
        <InputText
          readonly
          value={new Date(noticeQuery.data.createdAt).toLocaleString()}
        />
      </StyledLabel>

      <StyledLabel>
        수정일자
        <InputText
          readonly
          value={new Date(noticeQuery.data.modifiedAt).toLocaleString()}
        />
      </StyledLabel>

      <div
        css={css`
          display: flex;
          gap: 16px;
        `}
      >
        <Button
          disabled={
            isSaving ||
            (!!formValue &&
              Object.values(formValue).some((v) => v.trim() === ''))
          }
          onClick={save}
        >
          저장
        </Button>

        <Button
          baseColor={utils.style.color.highlight1}
          onClick={() =>
            openModal(
              <DeleteDialog
                type="공지사항"
                subjectText={`${noticeQuery.data.id}번`}
                confirmText={noticeQuery.data.id.toString()}
                onConfirm={deleteNotice}
              />,
            )
          }
        >
          삭제
        </Button>
        <ErrorText errorText={errorText} />
      </div>
    </ContentBox>
  );
};
