import { atom } from 'recoil';

const blob = new Blob([], {
  type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
});

export const excelFileAtom = atom<File>({
  key: 'atom/event-coupon',
  default: new File([blob], '', {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  }),
});
