import type { Inquiry } from '@admin/repository/types';

export const inquiryConstants = {
  categories: {
    SERVICE: '서비스',
    ACCOUNT: '계정',
    PAYMENT: '결제',
    COMPLAIN: '불편사항',
    OTHER: '기타',
    AFFILIATE: '제휴',
    API: 'API 사용 문의',
    CREATE: '제작 문의',
  } satisfies Record<Inquiry['inquiryCategory'], string>,
  status: {
    READY: '미답변',
    COMPLETE: '답변 완료',
  } satisfies Record<Inquiry['status'], string>,
} as const;
