import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { DeleteDialog } from '@admin/components/Dialog/DeleteDialog';
import { InputSelect } from '@admin/components/InputSelect';
import { InputText } from '@admin/components/InputText';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledLabel } from '@admin/components/Styled/Label';
import { repositoryClient } from '@admin/repository';
import type { Category, Tag } from '@admin/repository/types';
import { repositoryUtils } from '@admin/repository/utils';
import { utils } from '@admin/utils';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash-es';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const TagEditorPage = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const { closeModal, openModal } = useModal();

  const singleVoiceTagQuery = useQuery({
    queryKey: ['/tag', { id }] as const,
    queryFn: ({ queryKey: [path, { id }], signal }) =>
      repositoryClient.get<{ data: Tag }>(`${path}/${id}`, { signal }),
    select: ({ data: { data } }) => data,
  });

  const voiceTagCategoryQuery = useQuery({
    queryKey: ['/category'] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ data: Category[] }>(path, { signal }),
    select: ({ data: { data } }) => data,
  });

  invariant(singleVoiceTagQuery.data, 'Invalid tag data.');

  invariant(voiceTagCategoryQuery.data, 'Invalid tag category data.');

  const [formValue, setFormValue] = useState<
    Pick<Tag, 'categoryName' | 'tagLabel' | 'tagName'>
  >(() =>
    _.pick(singleVoiceTagQuery.data, ['categoryName', 'tagLabel', 'tagName']),
  );

  const queryClient = useQueryClient();

  const { mutate: save, isLoading: isSaving } = useMutation({
    onError: (e) => {
      openModal(
        <AlertDialog
          title="에러"
          message={repositoryUtils.getErrorMessage(e)}
        />,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['/tag'] });
      queryClient.invalidateQueries({ queryKey: ['/category'] });
      openModal(
        <AlertDialog title="알림" message="태그 정보가 저장되었습니다." />,
      );
    },
    mutationFn: () => {
      if (id === undefined) {
        throw new Error('invalid tag id');
      }
      if (formValue === undefined) {
        throw new Error('invalid form value');
      }
      return repositoryClient.patch('/tag', {
        category: formValue.categoryName,
        changeName: formValue.tagName,
        changeLabel: formValue.tagLabel,
        id,
      });
    },
  });

  const { mutate: deleteTag, isLoading: isDeleting } = useMutation({
    onError: (e) => {
      openModal(
        <AlertDialog
          title="에러"
          message={repositoryUtils.getErrorMessage(e)}
        />,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['/tag'] });
      queryClient.invalidateQueries({ queryKey: ['/category'] });
      navigate('..', { replace: true });
    },
    mutationFn: () => {
      if (id === undefined) {
        throw new Error('invalid tag id');
      }
      return repositoryClient.delete(`/tag/${id}`);
    },
  });

  return (
    <ContentBox
      css={css`
        gap: 20px;
      `}
    >
      <div
        css={css`
          font-weight: bold;
          font-size: 30px;
        `}
      >
        태그 정보
      </div>

      <StyledLabel>
        카테고리
        <InputSelect
          value={formValue?.categoryName}
          onChange={(categoryName) =>
            setFormValue((prev) => prev && { ...prev, categoryName })
          }
        >
          {voiceTagCategoryQuery.data.map(
            ({ categoryLabel, categoryName, id }) => (
              <option key={id} value={categoryName}>
                {categoryLabel}
              </option>
            ),
          )}
        </InputSelect>
      </StyledLabel>

      <StyledLabel>
        내부 이름
        <InputText
          value={formValue?.tagName}
          onChange={(tagName) =>
            setFormValue((prev) => prev && { ...prev, tagName })
          }
        />
      </StyledLabel>

      <StyledLabel>
        표시 이름
        <InputText
          value={formValue?.tagLabel}
          onChange={(tagLabel) =>
            setFormValue((prev) => prev && { ...prev, tagLabel })
          }
        />
      </StyledLabel>

      <div
        css={css`
          display: flex;
          gap: 20px;
        `}
      >
        <Button
          disabled={
            isDeleting ||
            isSaving ||
            (!!formValue &&
              Object.values(formValue).some((v) => v.trim() === ''))
          }
          onClick={save}
        >
          저장
        </Button>

        <Button
          onClick={() =>
            openModal(
              <DeleteDialog
                type="태그"
                subjectText={singleVoiceTagQuery.data.tagLabel}
                confirmText={singleVoiceTagQuery.data.tagLabel}
                onConfirm={() => {
                  deleteTag();
                  closeModal();
                }}
              />,
            )
          }
          baseColor={utils.style.color.highlight1}
        >
          삭제
        </Button>
      </div>
    </ContentBox>
  );
};
