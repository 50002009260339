import { Button } from '@admin/components/Button';
import { useModal } from '@admin/components/Modal/hooks';
import { utils } from '@admin/utils';
import { css } from '@emotion/react';

import { Dialog } from '..';

export const AlertDialog = (props: { title: string; message: string }) => {
  const { closeModal } = useModal();

  return (
    <Dialog title={props.title}>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          padding: 24px 30px;
          width: 400px;
          min-height: 200px;
          max-height: 300px;
          gap: 24px;
        `}
      >
        <div
          css={css`
            flex: 1;
            display: flex;
            overflow-y: auto;
          `}
        >
          <p
            css={css`
              margin: auto;
              max-width: 100%;
              user-select: text;
              white-space: pre-wrap;

              text-align: center;

              ${utils.style.font['text-l']};
            `}
          >
            {props.message}
          </p>
        </div>
        <div
          css={css`
            display: flex;
            justify-content: flex-end;
          `}
        >
          <Button onClick={() => closeModal()}>확인</Button>
        </div>
      </div>
    </Dialog>
  );
};
