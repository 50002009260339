import { Button } from '@admin/components/Button';
import { InputSelect } from '@admin/components/InputSelect';
import { InputText } from '@admin/components/InputText';
import { InputTextarea } from '@admin/components/InputTextarea';
import { ModalContainer } from '@admin/components/ModalContainer';
import { StyledLabel } from '@admin/components/Styled/Label';
import { repositoryClient } from '@admin/repository';
import type { Script, ScriptTag } from '@admin/repository/types';
import { repositoryUtils } from '@admin/repository/utils';
import { invariant } from '@admin/utils/invariant';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash-es';
import { useState } from 'react';

export const CreateScriptModal = (props: { onClose: VoidFunction }) => {
  const [formValue, setFormValue] = useState<
    Pick<Script, 'scriptContentList' | 'source' | 'tagName' | 'title'>
  >({
    title: '',
    scriptContentList: [],
    tagName: '낭독',
    source: '',
  });

  const [errorText, setErrorText] = useState('');

  const scriptTagQuery = useQuery({
    queryKey: ['/scripts/tags'] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ data: ScriptTag[] }>(path, { signal }),
    select: ({ data: { data } }) => data,
  });

  invariant(scriptTagQuery.data, 'Invalid script tag data.');

  const queryClient = useQueryClient();

  const { mutate: createScript, isLoading: isCreating } = useMutation({
    onError: (e) => {
      setErrorText(repositoryUtils.getErrorMessage(e));
    },
    onMutate: () => {
      setErrorText('');
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['/scripts'] });
      props.onClose();
    },
    mutationFn: () => {
      const { scriptContentList: scriptContentRequestList } = formValue;
      const payload = _.chain(formValue)
        .cloneDeep()
        .assign({ scriptContentRequestList })
        .omit('scriptContentList')
        .value();
      return repositoryClient.post('/scripts', payload);
    },
  });

  return (
    <ModalContainer
      onClose={props.onClose}
      title="스크립트 추가"
      errorText={errorText}
      fields={
        <>
          <StyledLabel>
            제목
            <InputText
              value={formValue.title}
              onChange={(title) => setFormValue((prev) => ({ ...prev, title }))}
            />
          </StyledLabel>

          <StyledLabel>
            내용
            <InputTextarea
              value={formValue.scriptContentList
                .map((script) => script.content)
                .join('\n')}
              onChange={(value) => {
                const scriptContentList = value
                  .split('\n')
                  .map((content, index) => ({ orders: index + 1, content }));
                setFormValue((prev) => ({ ...prev, scriptContentList }));
              }}
            />
          </StyledLabel>

          {/* TODO: check if deprecated */}
          <StyledLabel>
            태그
            <InputSelect readonly defaultValue={formValue.tagName}>
              <option value="" disabled hidden>
                선택 필요
              </option>
              {scriptTagQuery.data.map((tag) => (
                <option key={tag.tagName} value={tag.tagName}>
                  {tag.tagLabel}
                </option>
              ))}
            </InputSelect>
          </StyledLabel>

          <StyledLabel>
            출처
            <InputText
              value={formValue.source}
              onChange={(source) =>
                setFormValue((prev) => ({ ...prev, source }))
              }
            />
          </StyledLabel>
        </>
      }
      actions={
        <>
          <Button
            disabled={
              isCreating ||
              Object.values(formValue).some((v) =>
                typeof v === 'string' ? v.trim() === '' : v.length === 0,
              )
            }
            onClick={createScript}
          >
            생성
          </Button>

          <Button onClick={props.onClose}>취소</Button>
        </>
      }
    />
  );
};
