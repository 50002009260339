import { AxiosError } from 'axios';
import { parseISO } from 'date-fns';
import JSEncrypt from 'jsencrypt';
import _ from 'lodash-es';

import { repositoryClient } from '.';
import type { DateTimeResponse } from './types';

interface SpringErrorResponse {
  error: string;
  path: string;
  status: number;
  message: string;
  timestamp: string;
}

interface ApiErrorResponse {
  response_message?: string;
  message?: string;
  detail?: { code: number; message: string }[];
}

export const repositoryUtils = {
  encryptRawPassword: async (rawPassword: string) => {
    const {
      data: {
        data: { code: otp },
      },
    } = await repositoryClient.post<{ data: { code: string } }>('/otp', null);
    const jsEncrypt = new JSEncrypt();
    jsEncrypt.setPublicKey(import.meta.env.VITE_RSA_PUBLIC_KEY);
    const encrypted = jsEncrypt.encrypt(
      JSON.stringify({ otp, password: rawPassword }),
    );
    if (encrypted === false) {
      throw new Error('Failed to encrypt password');
    }
    return encrypted;
  },

  getErrorMessage: (e: unknown) => {
    if (e instanceof AxiosError && e.response) {
      const body = e.response.data as SpringErrorResponse | ApiErrorResponse;
      if ('error' in body) {
        return body.message || `(${body.status}) ${body.error}`;
      }
      if (body.response_message) {
        return body.response_message;
      }
      if (body.message) {
        return body.message;
      }
      if (body.detail) {
        // 같은 코드의 에러 메시지가 여러개일 경우 중복 제거
        return _.chain(body.detail)
          .uniqBy('code')
          .map(({ code, message }) => `${code}: ${message}`)
          .join('\n')
          .value();
      }
      // body를 ArrayBuffer로 전달받을 경우
      if (body instanceof ArrayBuffer) {
        const decoder = new TextDecoder('utf-8');
        const errorMessage = decoder.decode(body);
        return JSON.parse(errorMessage)
          .detail.map(
            ({ code, message }: { code: number; message: string }) =>
              `${code}: ${message}`,
          )
          .join('\n');
      }
    }
    if (e instanceof Error && e.message) {
      return e.message;
    }
    if (typeof e === 'string' && e) {
      return e;
    }
    return `알 수 없는 에러가 발생했습니다. ${e ? `(${e})` : ''}`;
  },

  parseDate: (date: DateTimeResponse) => {
    if (date === null) {
      return null;
    }
    let parsedDateTime: Date | undefined;
    // ISO string
    if (typeof date === 'string') {
      parsedDateTime = parseISO(date);
    }
    // timestamp
    if (typeof date === 'number') {
      parsedDateTime = new Date(date);
    }
    if (date instanceof Array) {
      const [year, month, day, hour, minute, second, millisecond] = date.map(
        (v) => v.toString(),
      ) as (string | undefined)[];
      if (!year || !month || !day) {
        throw new Error(`Invalid date format: [${date.join(', ')}]`);
      }
      const yearString = year.padStart(4, '0');
      const monthString = month.padStart(2, '0');
      const dayString = day.padStart(2, '0');
      const hourString = hour && hour.padStart(2, '0');
      const minuteString = minute && `:${minute.padStart(2, '0')}`;
      const secondString = second && `:${second.padStart(2, '0')}`;
      const millisecondString =
        millisecond && `.${millisecond.padStart(3, '0')}`;
      const datePart = [yearString, monthString, dayString].join('-');
      const timePart = [
        hourString,
        minuteString,
        secondString,
        millisecondString,
      ].join('');
      parsedDateTime = parseISO([datePart, timePart].join('T'));
    }
    if (!parsedDateTime || Number.isNaN(parsedDateTime.getTime())) {
      throw new Error(`Invalid date format: ${date}`);
    }
    return parsedDateTime;
  },
} as const;
