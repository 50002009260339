import { utils } from '@admin/utils';
import { css } from '@emotion/react';
import Color from 'color';

import { ErrorText } from './ErrorText';

type ModalContainerProps = {
  onClose: () => void;
  title: string;
  errorText: string;
  fields: React.ReactNode;
  actions: React.ReactNode;
};

export const ModalContainer: React.FC<ModalContainerProps> = ({
  onClose,
  title,
  errorText,
  fields,
  actions,
}) => {
  return (
    <div
      css={css`
        z-index: ${utils.style['z-index'].modal};
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: ${Color(utils.style.color.secondary)
          .alpha(0.7)
          .hexa()};
        display: flex;
        align-items: center;
        justify-content: center;
      `}
      onClick={onClose}
    >
      <div
        css={css`
          min-height: 350px;
          min-width: 516px;
          border-radius: 10px;

          box-sizing: border-box;
          background-color: ${utils.style.color.white};

          font-size: 14px;

          overflow: hidden;

          display: flex;
          flex-direction: column;
        `}
        onClick={(e) => e.stopPropagation()}
      >
        {/* Header */}
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 16px 0;
            background-color: ${Color(utils.style.color.grey500)
              .alpha(0.2)
              .hexa()};
            border-bottom: 1px solid ${utils.style.color.grey300};
          `}
        >
          <div
            css={css`
              font-size: 24px;
              line-height: 29px;
              font-weight: 600;
            `}
          >
            {title}
          </div>
        </div>
        {/* Fields */}
        <div
          css={css`
            display: flex;
            flex-direction: column;
            flex: 1 0;
            padding: 20px 40px;
            gap: 20px;
          `}
        >
          {fields}
        </div>
        {/* Actions */}
        <div
          css={css`
            display: flex;
            border-top: 1px solid ${utils.style.color.grey300};
            padding-top: 20px;
            padding-bottom: 20px;
            padding-right: 36px;
            padding-left: 20px;
          `}
        >
          <ErrorText errorText={errorText} />
          <div
            css={css`
              flex: 1 0;
            `}
          />
          <div
            css={css`
              display: flex;
              align-items: end;
              gap: 20px;
            `}
          >
            {actions}
          </div>
        </div>
      </div>
    </div>
  );
};
