import { repositoryClient } from '@admin/repository';
import type { Voice } from '@admin/repository/types';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { AccessLevelForm } from './AccessLevelForm';
import { EmotionManagementTable } from './EmotionManagementTable';
import { PreviewSampleForm } from './PreviewSampleForm';
import { PttsSettingsForm } from './PttsSettingsForm';
import { TagForm } from './TagForm';
import { ThumbnailForm } from './ThumbnailForm';
import { TrainingForm } from './TrainingForm';
import { VoiceInfoForm } from './VoiceInfoForm';

export const VoiceEditPage = () => {
  const { name } = useParams();

  const { data: voice } = useQuery({
    queryKey: ['/voice/all'] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ data: Voice[] }>(path, { signal }),
    select: ({ data: { data } }) => data.find((v) => v.name === name),
  });

  invariant(voice, 'Invalid voice data.');

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 24px;
      `}
    >
      <VoiceInfoForm />

      <ThumbnailForm />

      <PreviewSampleForm />

      <TagForm />

      <TrainingForm />

      <EmotionManagementTable voice={voice} />

      {voice.voiceType === 'PTTS' && <PttsSettingsForm />}

      <AccessLevelForm />

      {/* {voice.voiceType === 'SPECIAL' && <PrepaidDeductionTable />} */}

      {/* {voice.voiceType === 'SPECIAL' && <DeferrerdSettlementTable />} */}
    </div>
  );
};
