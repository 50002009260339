import { utils } from '@admin/utils';
import styled from '@emotion/styled';

export const StyledSelect = styled.select`
  height: 40px;
  padding: 0 12px;
  box-sizing: border-box;
  border: 1px solid ${utils.style.color.grey300};
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${utils.style.color.black};
  background-color: ${utils.style.color.white};

  &:disabled {
    background-color: ${utils.style.color.grey300};
  }
`;
