import styled from '@emotion/styled';

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  button {
    width: 200px;
  }
  select {
    width: 50%;
  }
`;
