import { Button } from '@admin/components/Button';
import { repositoryClient } from '@admin/repository';
import { utils } from '@admin/utils';
import { invariant } from '@admin/utils/invariant';
import { Components } from '@ai-voice-studio/shared';
import { css } from '@emotion/react';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

export const TermsPreview = (props: { fileName: string }) => {
  const { termsHistoryId } = useParams();

  const terms = useQuery({
    queryKey: ['/terms/termsHistory/detail', { termsHistoryId }] as const,
    queryFn: ({ queryKey: [path, { termsHistoryId }], signal }) =>
      repositoryClient.get<ArrayBuffer>(`${path}/${termsHistoryId}`, {
        responseType: 'arraybuffer',
        signal,
      }),
    select: ({ data }) => data,
    staleTime: Infinity,
  });

  invariant(terms.data, 'Invalid terms data.');

  const download = () => {
    const blob = new Blob([terms.data], { type: 'text/html' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = props.fileName;
    a.click();
    URL.revokeObjectURL(url);
  };

  return (
    <>
      <div
        css={css`
          width: 100%;
          height: 500px;
          border: 1px solid ${utils.style.color.grey100};
          display: flex;
          overflow: auto;
        `}
      >
        <Components.HTMLRenderer data={terms.data} />
      </div>
      <Button onClick={download}>다운로드</Button>
    </>
  );
};
