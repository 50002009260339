import { Button } from '@admin/components/Button';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { PromptDialog } from '@admin/components/Dialog/PromptDialog';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledTable } from '@admin/components/Styled/Table';
import { repositoryClient } from '@admin/repository';
import type { Repository } from '@admin/repository/types';
import { repositoryUtils } from '@admin/repository/utils';
import { excelFileAtom } from '@admin/routes/voucher/edit/VoucherMemberList/context/atom';
import { utils } from '@admin/utils';
import { css } from '@emotion/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useRecoilState } from 'recoil';

export const VoucherApplyButton = ({
  voucherDetail,
}: {
  voucherDetail: Repository['voucher'];
}) => {
  const [voucherMemberExcelFile] = useRecoilState(excelFileAtom);

  const { openModal } = useModal();

  const queryClient = useQueryClient();

  /* 바우처 멤버 쿠폰 적용하기 */
  const { mutate: updateVoucherMember, isLoading: isUpdating } = useMutation({
    onError: (e) => {
      if (e instanceof AxiosError && e.response?.status === 409) {
        return openModal(
          <PromptDialog
            title="확인"
            message={`이미 이벤트 쿠폰을 사용중인 유저가 존재합니다.\n계속해서 진행하시겠습니까?`}
            onConfirm={() => updateVoucherMember(true)}
          >
            <div
              css={css`
                width: 100%;
                height: 120px;
                overflow-y: scroll;
              `}
            >
              <StyledTable>
                <thead>
                  <tr>
                    <th>이벤트 코드</th>
                    <th>이메일</th>
                  </tr>
                </thead>
                <tbody>
                  {e.response.data.data.map(
                    (data: { code: string; email: string }) => {
                      return (
                        <tr key={data.email}>
                          <td>{data.code}</td>
                          <td>{data.email}</td>
                        </tr>
                      );
                    },
                  )}
                </tbody>
              </StyledTable>
            </div>
          </PromptDialog>,
        );
      }
      const message = repositoryUtils.getErrorMessage(e);
      openModal(<AlertDialog title="에러" message={message} />);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`/admin/voucher-history/${voucherDetail.code}`],
      });
      openModal(
        <AlertDialog title="알림" message="이벤트 쿠폰이 수정되었습니다." />,
      );
    },
    mutationFn: async (forceApply: boolean) => {
      const formData = new FormData();
      formData.append('code', voucherDetail.code);
      formData.append('file', voucherMemberExcelFile);
      formData.append('forceApply', forceApply.toString());
      return repositoryClient.post('/admin/voucher-history/bulk', formData);
    },
  });

  /* 유저 목록 적용(쿠폰 적용하기 버튼) */
  const voucherMemberApply = () => {
    // 이미 활성화 된 이벤트 쿠폰인 경우
    if (voucherDetail.status === 'ACTIVE') {
      openModal(
        <PromptDialog
          title="확인"
          message={`해당 유저들에게 이벤트 쿠폰이 즉시 적용됩니다.\n진행하시겠습니까?`}
          onConfirm={() => updateVoucherMember(false)}
        />,
      );
    } else if (voucherDetail.status === 'INACTIVE') {
      // 아직 활성화 되지 않은 이벤트 쿠폰인 경우
      openModal(
        <AlertDialog
          title="알림"
          message={`이벤트 쿠폰은 활성화 후 해당 유저들에게 적용됩니다.\n이벤트 쿠폰을 활성화 해주세요.`}
        />,
      );
    } else {
      openModal(
        <AlertDialog title="알림" message="만료된 이벤트 쿠폰입니다." />,
      );
    }
  };

  return (
    <Button
      disabled={isUpdating}
      onClick={() => {
        if (!voucherMemberExcelFile.name) {
          return openModal(
            <AlertDialog title="알림" message="Excel 파일을 업로드해주세요." />,
          );
        }
        voucherMemberApply();
      }}
      baseColor={utils.style.color.subBlack}
    >
      쿠폰 적용하기
    </Button>
  );
};
