import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { StyledH1 } from '@admin/components/Styled/H1';
import { css } from '@emotion/react';
import { useNavigate } from 'react-router-dom';

import { EmailListTable } from './EmailListTable';

export const EmailListPage = () => {
  const navigate = useNavigate();

  return (
    <ContentBox
      css={css`
        height: 100%;
        gap: 24px;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 16px;
        `}
      >
        <StyledH1>메일</StyledH1>
        <Button onClick={() => navigate('./create')}>메일 작성</Button>
      </div>

      <EmailListTable />
    </ContentBox>
  );
};
