import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { Search } from '@admin/components/Search';
import { StyledH1 } from '@admin/components/Styled/H1';
import { StyledTable } from '@admin/components/Styled/Table';
import { repositoryClient } from '@admin/repository';
import type { CategoryTag } from '@admin/repository/types';
import { utils } from '@admin/utils';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { CreateTagModal } from './_widget/CreateTagModal';

export const TagListPage = () => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const [showCreateTagModal, setShowCreateTagModal] = useState(false);

  const query = useQuery({
    queryKey: ['/tag', { searchKeyword: searchParams.get('q') }] as const,
    queryFn: ({ queryKey: [path, { searchKeyword }], signal }) =>
      repositoryClient.get<{ data: CategoryTag[] }>(path, {
        params: searchKeyword && { searchKeyword },
        signal,
      }),
    select: ({ data: { data } }) => data,
  });

  invariant(query.data, 'Invalid query data.');

  return (
    <>
      <ContentBox
        css={css`
          gap: 24px;
          height: 100%;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: space-between;
          `}
        >
          <StyledH1>태그</StyledH1>

          <Button onClick={() => setShowCreateTagModal(true)}>태그 추가</Button>
        </div>

        <Search
          defaultValue={searchParams.get('q') ?? ''}
          onSubmit={(searchString) =>
            setSearchParams((prev) => {
              const params = new URLSearchParams(prev);
              if (searchString) {
                params.set('q', searchString);
              } else {
                params.delete('q');
              }
              return params;
            })
          }
        />

        <div
          css={css`
            height: 100%;
            overflow: auto;
          `}
        >
          <StyledTable>
            <thead>
              <tr>
                <th>ID</th>
                <th>표시 이름</th>
                <th>내부 이름</th>
                <th>카테고리</th>
              </tr>
            </thead>
            <tbody>
              {query.data.map(({ categoryLabel, tags }) =>
                tags.map((tag) => (
                  <tr
                    key={tag.id}
                    css={css`
                      &:hover {
                        background: ${utils.style.color.grey100};
                      }
                      cursor: pointer;
                    `}
                    onClick={() => {
                      navigate(`./edit/${tag.id}`);
                    }}
                  >
                    <td>{tag.id}</td>
                    <td>{tag.tagLabel}</td>
                    <td>{tag.tagName}</td>
                    <td>{categoryLabel}</td>
                  </tr>
                )),
              )}
            </tbody>
          </StyledTable>
        </div>
      </ContentBox>

      {showCreateTagModal && (
        <CreateTagModal onClose={() => setShowCreateTagModal(false)} />
      )}
    </>
  );
};
