import { Button } from '@admin/components/Button';
import type {
  ServiceCodeStatisticsType,
  ServiceCodeVoiceSettlementType,
} from '@admin/routes/service-code/statistics/context/type';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import * as XLSX from 'xlsx';

interface Props {
  readonly since: Date;
  readonly till: Date;
  readonly serviceCodeStatistics: ServiceCodeStatisticsType;
  readonly serviceCodeVoiceSettlement: ServiceCodeVoiceSettlementType[];
}

export const ExportButton = (_props: Props) => {
  const { serviceCode } = useParams();
  const initialServiceCodeStatistics = {
    allTextCount: 0,
    allBillingAmount: 0,
    normalBillingAmount: 0,
    specialBillingAmount: 0,
    customVoiceCount: 0,
    customVoiceBillingAmount: 0,
  };
  const exportStatistics = async () => {
    if (
      _props.serviceCodeStatistics === initialServiceCodeStatistics ||
      _props.serviceCodeVoiceSettlement.length === 0
    ) {
      return;
    }
    const wb = XLSX.utils.book_new();
    const serviceCodeStatisticsSheet = [
      [
        '전체 사용 글자 수',
        '일반 보이스 청구 금액',
        '특별 보이스 청구 금액',
        '마이 AI 보이스 청구 금액',
        '총 청구 금액',
        '마이 AI 보이스 학습 수',
      ],
      [
        _props.serviceCodeStatistics.allTextCount,
        _props.serviceCodeStatistics.normalBillingAmount,
        _props.serviceCodeStatistics.specialBillingAmount,
        _props.serviceCodeStatistics.customVoiceBillingAmount,
        _props.serviceCodeStatistics.allBillingAmount,
        _props.serviceCodeStatistics.customVoiceCount,
      ],
    ];
    const wsServiceCodeStatistics = XLSX.utils.aoa_to_sheet(
      serviceCodeStatisticsSheet,
    );
    XLSX.utils.book_append_sheet(
      wb,
      wsServiceCodeStatistics,
      '서비스 코드 통계',
    );
    const specialVoiceUsageSheet = [
      ['보이스 이름', '실제 사용 글자 수', '청구 금액'],
      ..._props.serviceCodeVoiceSettlement.map((item) => {
        return [item.voiceDisplayName, item.textCount, item.billingAmount];
      }),
    ];
    const wsSpecialVoiceUsage = XLSX.utils.aoa_to_sheet(specialVoiceUsageSheet);
    XLSX.utils.book_append_sheet(wb, wsSpecialVoiceUsage, '특별 보이스 사용량');
    XLSX.writeFile(
      wb,
      `serviceCode(${serviceCode})_(${format(
        _props.since,
        'yyyy.MM.dd',
      )} - ${format(_props.till, 'yyyy.MM.dd')}).xlsx`,
    );
  };

  return (
    <Button
      disabled={
        _props.serviceCodeStatistics === initialServiceCodeStatistics ||
        _props.serviceCodeVoiceSettlement.length === 0
      }
      onClick={exportStatistics}
    >
      내보내기
    </Button>
  );
};
