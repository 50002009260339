import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { InputDateRange } from '@admin/components/InputDateRange';
import { StyledH1 } from '@admin/components/Styled/H1';
import { StyledSelect } from '@admin/components/Styled/Select';
import { StyledTable } from '@admin/components/Styled/Table';
import { repositoryClient } from '@admin/repository';
import type { ServiceCodeMetaData, Statistics } from '@admin/repository/types';
import { utils } from '@admin/utils';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import _ from 'lodash-es';
import { useState } from 'react';
import * as XLSX from 'xlsx';

import { DetailModal } from './DetailModal';

export const StatisticsPage = () => {
  const [statisticsDateRange, setStatisticsDateRange] = useState(() => {
    const [start, end] = [new Date(), new Date()];
    start.setDate(end.getDate() - 7);
    return { start, end };
  });

  const [selectedStatistics, selectStatistics] = useState<Statistics[number]>();

  const serviceCodeQuery = useQuery({
    queryKey: ['/crm/service-code'] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ data: ServiceCodeMetaData[] }>(path, { signal }),
    select: ({ data: { data } }) => data,
  });

  invariant(serviceCodeQuery.data, 'Invalid service code query data.');

  const [selectedServiceCode, selectServiceCode] = useState(
    serviceCodeQuery.data[0]?.serviceCodeName ?? '',
  );

  const [searchParams, setSearchParams] = useState(() => {
    const [start, end] = [new Date(), new Date()];
    start.setDate(end.getDate() - 7);
    return {
      start,
      end,
      serviceCode: serviceCodeQuery.data[0]?.serviceCodeName ?? '',
    };
  });

  const statisticsQuery = useQuery({
    queryKey: [
      '/statistics/entire',
      {
        since: format(searchParams.start, 'yyyy-MM-dd'),
        till: format(searchParams.end, 'yyyy-MM-dd'),
        serviceCode: searchParams.serviceCode,
      },
    ] as const,
    queryFn: ({ queryKey: [path, payload], signal }) =>
      repositoryClient.post<{ data: Statistics }>(path, payload, { signal }),
    select: ({ data: { data } }) => _.chain(data).cloneDeep().reverse().value(),
  });

  invariant(statisticsQuery.data, 'Invalid statistics query data.');

  const onClickExportBtn = () => {
    type DetailTableElem = { 날짜: string; 정보: string };
    const infoTypeList = ['이름', '자 수', '오디오 콘텐츠'] as const;
    const successRankTable: DetailTableElem[] = [];
    const failureRankTable: DetailTableElem[] = [];
    for (const { date, entireServiceStatisticsDto } of statisticsQuery.data) {
      const dateString = date.join('-');
      for (let row = 0; row < infoTypeList.length; row++) {
        const infoType = infoTypeList[
          row % infoTypeList.length
        ] as (typeof infoTypeList)[number];
        const successRankRow = { 날짜: dateString, 정보: infoType };
        const failureRankRow = { 날짜: dateString, 정보: infoType };

        switch (row) {
          // voiceName
          case 0:
            entireServiceStatisticsDto.voiceUsageStatisticsListDto.voiceUsageStatisticsDtoList.forEach(
              (voice, index) => {
                Object.assign(successRankRow, {
                  [index + 1]: voice.voiceDisplayName,
                });
              },
            );
            entireServiceStatisticsDto.failedVoiceUsageStatisticsListDto.voiceUsageStatisticsDtoList.forEach(
              (voice, index) => {
                Object.assign(failureRankRow, {
                  [index + 1]: voice.voiceDisplayName,
                });
              },
            );
            break;
          // characterCount
          case 1:
            entireServiceStatisticsDto.voiceUsageStatisticsListDto.voiceUsageStatisticsDtoList.forEach(
              (voice, index) => {
                Object.assign(successRankRow, {
                  [index + 1]: voice.characterCount,
                });
              },
            );
            entireServiceStatisticsDto.failedVoiceUsageStatisticsListDto.voiceUsageStatisticsDtoList.forEach(
              (voice, index) => {
                Object.assign(failureRankRow, {
                  [index + 1]: voice.characterCount,
                });
              },
            );
            break;
          // countAudioContents
          case 2:
            entireServiceStatisticsDto.voiceUsageStatisticsListDto.voiceUsageStatisticsDtoList.forEach(
              (voice, index) => {
                Object.assign(successRankRow, {
                  [index + 1]: voice.countAudioContents,
                });
              },
            );
            entireServiceStatisticsDto.failedVoiceUsageStatisticsListDto.voiceUsageStatisticsDtoList.forEach(
              (voice, index) => {
                Object.assign(failureRankRow, {
                  [index + 1]: voice.countAudioContents,
                });
              },
            );
            break;
          default:
            break;
        }
        successRankTable.push(successRankRow);
        failureRankTable.push(failureRankRow);
      }
    }

    const dateFormatter = new Intl.DateTimeFormat('ko-KR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    const formattedStartDate = dateFormatter.format(searchParams.start);
    const formattedEndDate = dateFormatter.format(searchParams.end);
    const fileName = `${formattedStartDate} - ${formattedEndDate}.xlsx`;
    const wb = XLSX.utils.table_to_book(
      document.getElementById('statisticsTable'),
      { sheet: 'total' },
    );
    const wsSuccess = XLSX.utils.json_to_sheet(successRankTable, {
      header: ['날짜', '정보'],
    });
    const wsFailure = XLSX.utils.json_to_sheet(failureRankTable, {
      header: ['날짜', '정보'],
    });
    XLSX.utils.book_append_sheet(wb, wsSuccess, 'Success');
    XLSX.utils.book_append_sheet(wb, wsFailure, 'Failure');
    XLSX.writeFile(wb, fileName);
  };

  const onClickSearch = () => {
    setSearchParams({
      ...statisticsDateRange,
      serviceCode: selectedServiceCode,
    });
  };

  return (
    <ContentBox
      css={css`
        gap: 24px;
        height: 100%;
      `}
    >
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 16px;
        `}
      >
        <StyledH1>통계</StyledH1>
        <Button onClick={onClickExportBtn}>내보내기</Button>
      </div>

      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 16px;
        `}
      >
        <InputDateRange
          onChange={setStatisticsDateRange}
          value={statisticsDateRange}
        />

        <StyledSelect
          value={selectedServiceCode}
          onChange={({ currentTarget: { value: serviceCode } }) =>
            selectServiceCode(serviceCode)
          }
        >
          {serviceCodeQuery.data.map(({ serviceCodeName }) => (
            <option key={serviceCodeName} value={serviceCodeName}>
              {serviceCodeName}
            </option>
          ))}
        </StyledSelect>

        <Button onClick={onClickSearch}>조회</Button>
      </div>

      <div
        css={css`
          width: 100%;
          height: 100%;
          overflow: auto;
          position: relative;
          & > table {
            width: max-content;
            position: absolute;
          }
        `}
      >
        <StyledTable id="statisticsTable">
          <thead
            css={css`
              z-index: ${utils.style['z-index'].cover};
            `}
          >
            <tr>
              <th
                css={css`
                  position: sticky;
                  top: 0;
                  left: 0;
                `}
              >
                날짜
              </th>
              <th>가입자</th>
              <th>제작된 오디오 콘텐츠</th>
              <th>제작에 실패한 오디오 콘텐츠</th>
              <th>합성 보이스 자 수</th>
              <th>감정 더빙 자 수</th>
              <th>문장 수</th>
              <th>실패한 합성 보이스 자 수</th>
              <th>실패한 감정 더빙 자 수</th>
              <th>실패한 문장 수</th>
              <th>준비된 마이 AI 보이스</th>
              <th>학습중인 마이 AI 보이스</th>
              <th>완료된 마이 AI 보이스</th>
              <th>실패한 마이 AI 보이스</th>
            </tr>
          </thead>

          <tbody>
            {statisticsQuery.data.map((statistics) => {
              const { date, entireServiceStatisticsDto } = statistics;
              const dateString = date.join('-');
              return (
                <tr
                  key={dateString}
                  css={css`
                    &:hover {
                      background: ${utils.style.color.grey100};
                    }
                    &:hover > #date {
                      background: ${utils.style.color.grey100};
                    }
                    cursor: pointer;
                  `}
                  onClick={() => selectStatistics(statistics)}
                >
                  <td
                    id="date"
                    css={css`
                      background: ${utils.style.color.white};
                      position: sticky;
                      left: 0;
                    `}
                  >
                    {dateString}
                  </td>
                  <td>{entireServiceStatisticsDto.usersCount}</td>
                  <td>
                    {entireServiceStatisticsDto.audioContentsHistoryCount}
                  </td>
                  <td>
                    {entireServiceStatisticsDto.failedAudioContentsHistoryCount}
                  </td>
                  <td>
                    {
                      entireServiceStatisticsDto.sentenceTypeCountDto
                        .voiceSynthesisCount
                    }
                  </td>
                  <td>
                    {
                      entireServiceStatisticsDto.sentenceTypeCountDto
                        .voiceDubbingCount
                    }
                  </td>
                  <td>
                    {
                      entireServiceStatisticsDto.sentenceTypeCountDto
                        .sentenceCount
                    }
                  </td>
                  <td>
                    {
                      entireServiceStatisticsDto.failedSentenceTypeCountDto
                        .voiceSynthesisCount
                    }
                  </td>
                  <td>
                    {
                      entireServiceStatisticsDto.failedSentenceTypeCountDto
                        .voiceDubbingCount
                    }
                  </td>
                  <td>
                    {
                      entireServiceStatisticsDto.failedSentenceTypeCountDto
                        .sentenceCount
                    }
                  </td>

                  <td>{entireServiceStatisticsDto.readyCustomVoiceCount}</td>
                  <td>{entireServiceStatisticsDto.trainingCustomVoiceCount}</td>
                  <td>
                    {entireServiceStatisticsDto.completedCustomVoicesCount}
                  </td>
                  <td>{entireServiceStatisticsDto.failedCustomVoiceCount}</td>
                </tr>
              );
            })}
          </tbody>
        </StyledTable>
      </div>

      {selectedStatistics && (
        <DetailModal
          statistics={selectedStatistics}
          onClose={() => selectStatistics(undefined)}
        />
      )}
    </ContentBox>
  );
};
