import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { ErrorText } from '@admin/components/ErrorText';
import { StyledForm } from '@admin/components/Styled/Form';
import { StyledH1 } from '@admin/components/Styled/H1';
import { repositoryClient } from '@admin/repository';
import { repositoryUtils } from '@admin/repository/utils';
import { utils } from '@admin/utils';
import { invariant } from '@admin/utils/invariant';
import { routeName } from '@admin/utils/routeName';
import { css } from '@emotion/react';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useLayoutEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const LoginPage = () => {
  const navigate = useNavigate();

  const formRef = useRef<HTMLFormElement>(null);

  const [emailValueKey, passwordValueKey, otpValueKey] = [
    'email',
    'password',
    'otp',
  ];

  const [errorText, setErrorText] = useState('');

  const [otpRequired, setOtpRequired] = useState(false);

  useLayoutEffect(() => {
    const { current: form } = formRef;
    if (form) {
      (form.elements.namedItem(emailValueKey) as HTMLInputElement).select();
    }
  }, [emailValueKey]);

  useLayoutEffect(() => {
    const { current: form } = formRef;
    if (form && otpRequired) {
      (form.elements.namedItem(otpValueKey) as HTMLInputElement).select();
    }
  }, [otpRequired, otpValueKey]);

  const { mutate: login, isLoading: isSubmitting } = useMutation({
    onError: (e) => {
      if (!(e instanceof AxiosError)) {
        setErrorText(repositoryUtils.getErrorMessage(e));
        return;
      }
      const { status } = e.response ?? e;
      if (status === 440) {
        const emailInput = document.getElementsByName('email')[0];
        const passwordInput = document.getElementsByName('password')[0];
        const otpInput = document.getElementsByName('otp')[0];
        (emailInput as HTMLInputElement).value = '';
        (passwordInput as HTMLInputElement).value = '';
        (otpInput as HTMLInputElement).value = '';
      }
      if (status !== 471) {
        setErrorText(repositoryUtils.getErrorMessage(e));
        return;
      }
      setOtpRequired(true);
    },
    onMutate: () => {
      setErrorText('');
    },
    onSuccess: ({
      access_token,
      refresh_token,
      is_need_to_change_password,
    }) => {
      utils.token.set({
        accessToken: access_token,
        refreshToken: refresh_token,
      });
      // 페이지 이동을 micro task로 넘김
      // 패스워드 변경 페이지는 authorized일 때만 진입할 수 있는데
      // 즉시 navigate하면 렌더링 되기 전이라 ProtectedLayout에서 로그인 상태로 인식되지 않음
      setTimeout(
        () =>
          navigate(
            is_need_to_change_password ? `/${routeName.passwordExpired}` : '/',
          ),
        0,
      );
    },
    mutationFn: async () => {
      const { current: form } = formRef;
      invariant(form, 'form is not mounted.');
      const formData = new FormData(form);
      const email = formData.get(emailValueKey) as string;
      const password = formData.get(passwordValueKey) as string;
      const otpCode = (formData.get(otpValueKey) ?? '') as string;
      const passwordEncrypted = await repositoryUtils.encryptRawPassword(
        password,
      );
      const payload = { email, password: passwordEncrypted };
      if (otpCode) {
        Object.assign(payload, { otpCode });
      }
      const { data: body } = await repositoryClient.post<{
        data: {
          access_token: string;
          refresh_token: string;
          is_need_to_change_password: boolean;
        };
      }>('/admin/token/authenticate', payload);
      return body.data;
    },
  });

  return (
    <div
      css={css`
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${utils.style.color.background};
      `}
    >
      <ContentBox
        css={css`
          min-width: 400px;
          gap: 24px;
        `}
      >
        <StyledH1>로그인</StyledH1>

        <StyledForm
          ref={formRef}
          onSubmit={(e) => {
            e.preventDefault();
            login();
          }}
        >
          <label>
            이메일
            <input
              required
              readOnly={otpRequired}
              name={emailValueKey}
              autoComplete="username"
            />
          </label>

          <label>
            패스워드
            <input
              required
              readOnly={otpRequired}
              name={passwordValueKey}
              type="password"
              autoComplete="current-password"
            />
          </label>

          <label
            css={css`
              display: ${otpRequired ? 'flex' : 'none'}!important;
            `}
          >
            OTP
            <input required={otpRequired} name={otpValueKey} />
          </label>

          <div
            css={css`
              display: flex;
              gap: 16px;
            `}
          >
            <Button form disabled={isSubmitting}>
              로그인
            </Button>
            {errorText.length > 0 && <ErrorText errorText={errorText} />}
          </div>
        </StyledForm>
      </ContentBox>
    </div>
  );
};
