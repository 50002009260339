import { utils } from '@admin/utils';
import styled from '@emotion/styled';

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;

  label {
    display: flex;
    flex-direction: column;
    gap: 8px;

    ${utils.style.font['title-m']};
    color: ${utils.style.color.black};

    & > * {
      font-weight: initial;
      font-size: initial;
      line-height: initial;
      color: initial;
    }

    &[for='file'] {
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
      color: ${utils.style.color.white};
      box-sizing: border-box;
    }
  }

  // file input을 제외한 모든 input에 스타일 적용
  input:not([type='file']) {
    display: flex;
    align-items: center;

    height: 40px;
    padding: 0 16px;
    box-sizing: border-box;
    border: 1px solid ${utils.style.color.grey300};
    border-radius: 8px;

    ${utils.style.font['text-m']};

    color: ${utils.style.color.black};
    background-color: ${utils.style.color.white};

    &:read-only {
      background-color: ${utils.style.color.grey300};
    }
  }

  input[type='checkbox'] {
    height: 40px;
    width: 40px;
  }

  input[type='radio'] {
    height: 24px;
    width: 24px;
  }

  select {
    height: 40px;
    padding: 0 12px;
    box-sizing: border-box;
    border: 1px solid ${utils.style.color.grey300};
    border-radius: 8px;

    ${utils.style.font['text-m']};

    color: ${utils.style.color.black};
    background-color: ${utils.style.color.white};

    &:disabled {
      background-color: ${utils.style.color.grey300};
    }
  }

  textarea {
    height: 300px;
    padding: 16px;
    box-sizing: border-box;
    border: 1px solid ${utils.style.color.grey300};
    border-radius: 8px;

    color: ${utils.style.color.black};
    background-color: ${utils.style.color.white};

    ${utils.style.font['text-m']};

    resize: none;

    &:read-only {
      background-color: ${utils.style.color.grey300};
    }
  }
`;
