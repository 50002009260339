import { Button } from '@admin/components/Button';
import { useModal } from '@admin/components/Modal/hooks';
import { utils } from '@admin/utils';
import { css } from '@emotion/react';
import type { ReactNode } from 'react';

import { Dialog } from '..';

export const PromptDialog = (props: {
  title: string;
  message: string;
  onConfirm: () => void;
  onCancel?: () => void;
  cancelLabel?: string;
  confirmLabel?: string;
  children?: ReactNode;
}) => {
  const { closeModal } = useModal();

  return (
    <Dialog title={props.title}>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          padding: 24px 30px;
          width: 400px;
          min-height: 200px;
          max-height: 300px;
          gap: 24px;
        `}
      >
        <div
          css={css`
            flex: 1;
            display: flex;
            overflow: auto;
          `}
        >
          <p
            css={css`
              margin: auto;
              max-width: 100%;
              user-select: text;
              white-space: pre-wrap;

              text-align: center;

              ${utils.style.font['text-l']};
            `}
          >
            {props.message}
          </p>
        </div>
        {props.children}
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 16px;
          `}
        >
          <Button
            onClick={() => {
              closeModal();
              props.onConfirm();
            }}
          >
            {props.confirmLabel ?? '확인'}
          </Button>

          <Button
            onClick={() => {
              closeModal();
              props.onCancel?.();
            }}
            textColor={utils.style.color.grey500}
            baseColor={utils.style.color.grey200}
          >
            {props.cancelLabel ?? '취소'}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
