import { repositoryClient } from '@admin/repository';
import type { EventPageDetail } from '@admin/repository/types';
import {
  eventPageAtom,
  initialEventButtonList,
} from '@admin/routes/eventPage/edit/context/atom';
import type { EventButtonList } from '@admin/routes/eventPage/edit/context/type';
import { invariant } from '@admin/utils/invariant';
import { useQuery } from '@tanstack/react-query';
import type { ReactElement } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';

interface Props {
  readonly children: ReactElement;
}

export const EventPageContextInitializer = (props: Props) => {
  const { eventPageId } = useParams();

  const [eventPageState, setEventPageState] = useRecoilState(eventPageAtom);

  /* 이벤트 페이지 버튼 속성 가져오기 */
  const { data: eventPageDetailQueryData, status: eventPageDetailQueryStatus } =
    useQuery({
      queryKey: [`/admin/eventPage/${eventPageId}`] as const,
      queryFn: ({ queryKey: [path], signal }) =>
        repositoryClient.get<{ data: EventPageDetail }>(path, { signal }),
      select: ({ data: { data } }) => data,
    });
  invariant(eventPageDetailQueryData, 'Invalid event-page detail data.');

  /* 이벤트 페이지 이미지 가져오기 */
  const { data: eventPageImageQueryData, status: eventPageImageQueryStatus } =
    useQuery({
      queryKey: [`/admin/eventPage/${eventPageId}/images`] as const,
      queryFn: ({ queryKey: [path], signal }) =>
        repositoryClient.get<{
          data: {
            pcImage: string;
            mobileImage: string;
            pcExtension: string;
            mobileExtension: string;
            isPCBasicImage: boolean;
            isMobileBasicImage: boolean;
          };
        }>(path, { signal }),
      select: ({ data: { data } }) => data,
    });
  invariant(eventPageImageQueryData, 'Invalid event-page image data.');

  useEffect(() => {
    if (
      eventPageDetailQueryStatus !== 'success' ||
      eventPageImageQueryStatus !== 'success'
    ) {
      return;
    }
    const { startDate, endDate, pcButtons, mobileButtons } =
      eventPageDetailQueryData;
    const blob = new Blob();
    setEventPageState({
      since: new Date(startDate.join('-')),
      till: new Date(endDate.join('-')),
      pcImageFile: new File([blob], ''),
      mobileImageFile: new File([blob], ''),
      pcEventButtonList: (JSON.parse(pcButtons) as EventButtonList[]) ?? [
        initialEventButtonList,
      ],
      mobileEventButtonList: (JSON.parse(
        mobileButtons,
      ) as EventButtonList[]) ?? [initialEventButtonList],
      pcImageUrl: `data:image/${eventPageImageQueryData.pcExtension};base64, ${eventPageImageQueryData.pcImage}`,
      mobileImageUrl: `data:image/${eventPageImageQueryData.mobileExtension};base64, ${eventPageImageQueryData.mobileImage}`,
      focusedButtonIndex: 0,
      isPCBasicImage: eventPageImageQueryData.isPCBasicImage,
      isMobileBasicImage: eventPageImageQueryData.isMobileBasicImage,
      isActive: eventPageDetailQueryData.isActive,
    });
  }, [
    eventPageDetailQueryData,
    eventPageDetailQueryStatus,
    eventPageDetailQueryData.isActive,
    eventPageImageQueryData.isMobileBasicImage,
    eventPageImageQueryData.isPCBasicImage,
    eventPageImageQueryData.mobileExtension,
    eventPageImageQueryData.mobileImage,
    eventPageImageQueryData.pcExtension,
    eventPageImageQueryData.pcImage,
    eventPageImageQueryStatus,
    setEventPageState,
  ]);

  return eventPageState ? props.children : null;
};
