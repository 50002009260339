import { Button } from '@admin/components/Button';
import { Dialog } from '@admin/components/Dialog';
import { useModal } from '@admin/components/Modal/hooks';
import { utils } from '@admin/utils';
import { css } from '@emotion/react';
import { useState } from 'react';

export const ChangeRoleReasonDialog = (props: {
  onConfirm: (reason: string) => void;
}) => {
  const { closeModal } = useModal();

  const [reason, setReason] = useState<string>('');

  return (
    <Dialog title="권한 변경">
      <div
        css={css`
          min-width: 500px;
          display: flex;
          flex-direction: column;
          gap: 16px;
          padding: 40px;
        `}
      >
        <p>권한 변경 사유를 입력해 주세요.</p>

        <textarea
          value={reason}
          onChange={({ currentTarget: { value } }) => setReason(value)}
        />

        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 8px;
          `}
        >
          <Button disabled={!reason} onClick={() => props.onConfirm(reason)}>
            확인
          </Button>

          <Button
            onClick={closeModal}
            textColor={utils.style.color.grey500}
            baseColor={utils.style.color.grey200}
          >
            취소
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
