import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { UnmaskingDialog } from '@admin/components/Dialog/UnmaskingDialog';
import { CustomSuspense } from '@admin/components/MainLayout/CustomSuspense';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledH1 } from '@admin/components/Styled/H1';
import { css } from '@emotion/react';
import { useNavigate } from 'react-router-dom';

import { InquiryFilter } from './Filter';
import { InquiryListTable } from './InquiryListTable';

export const InquiryListPage = () => {
  const { openModal } = useModal();

  const navigate = useNavigate();

  return (
    <ContentBox
      css={css`
        height: 100%;
        gap: 24px;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <StyledH1>고객 문의</StyledH1>

        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 16px;
          `}
        >
          <Button onClick={() => openModal(<UnmaskingDialog refresh />)}>
            마스킹 해제
          </Button>

          <Button onClick={() => navigate('./statistics')}>문의 통계</Button>
        </div>
      </div>

      <InquiryFilter />

      <CustomSuspense>
        <InquiryListTable />
      </CustomSuspense>
    </ContentBox>
  );
};
