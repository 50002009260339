import { Button } from '@admin/components/Button';
import { Checkbox } from '@admin/components/Checkbox';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { PromptDialog } from '@admin/components/Dialog/PromptDialog';
import { useModal } from '@admin/components/Modal/hooks';
import { repositoryClient } from '@admin/repository';
import { queryClient } from '@admin/repository/query';
import type { Emotion, Voice } from '@admin/repository/types';
import { repositoryUtils } from '@admin/repository/utils';
import { selectFileButtonStyle } from '@admin/routes/voice/edit/EmotionManagementTable/style';
import { useFileBrowser } from '@admin/utils/hooks';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import _ from 'lodash-es';
import type { SetStateAction } from 'react';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilCallback, useRecoilState } from 'recoil';

import type { EmotionNameType } from '..';
import { voiceTrainingFormAtoms } from '../atom';
import { parseScript, validateScript } from '../utils';

interface Props {
  voiceProps?: Voice;
  currentEmotion: EmotionNameType;
}

export const SelectFileButtons = (props: Props) => {
  const { name } = useParams();

  const { openModal } = useModal();

  const fileBrowser = useFileBrowser();

  const [scriptData, setScriptData] = useRecoilState(
    voiceTrainingFormAtoms['script-data'],
  );

  const [audioFileData, setAudioFileData] = useState<SetStateAction<File>>();

  const loadScriptData = useRecoilCallback(
    ({ reset }) =>
      async () => {
        const newScriptFile = await fileBrowser
          .pick({ accept: 'text/plain' })
          .then((selection) => selection?.item(0));
        if (!newScriptFile) {
          return;
        }
        if (!newScriptFile.name.endsWith('.txt')) {
          openModal(
            <AlertDialog
              message="txt 형식의 파일을 선택해 주세요."
              title="에러"
            />,
          );
          return;
        }

        const parsedLines = parseScript(await newScriptFile.text());

        if (parsedLines.length === 0) {
          openModal(
            <AlertDialog
              message="스크립트 형식이 올바르지 않습니다."
              title="에러"
            />,
          );
          return;
        }

        const causes = validateScript(parsedLines);
        if (causes.length > 0) {
          openModal(<AlertDialog message={causes.join('\n')} title="에러" />);
          return;
        }
        setScriptData({ file: newScriptFile, lines: parsedLines });
        for (const line of parsedLines) {
          reset(voiceTrainingFormAtoms['audio-data'](line));
        }
      },
    [fileBrowser, openModal, setScriptData],
  );

  const setAudioFiles = useRecoilCallback(
    ({ set }) =>
      async () => {
        const newAudioFiles = await fileBrowser
          .pick({ accept: 'audio/wav', multiple: true })
          .then((selection) => (selection ? new Array(...selection) : []));

        if (!newAudioFiles) {
          return;
        }

        type AudioData = { name: string; extension: string; duration: number };

        const fileNamePattern = /^(.+)\.(\w+)$/;

        const audioDataList = await Promise.all(
          newAudioFiles.map((file) => {
            const [, name, extension] = fileNamePattern.exec(file.name) ?? [];
            invariant(name && extension, `Invalid file name: ${file.name}`);
            return new Promise<AudioData>((resolve) => {
              const audio = new Audio(URL.createObjectURL(file));
              audio.oncanplay = () => {
                const { duration, src } = audio;
                resolve({ name, extension, duration });
                URL.revokeObjectURL(src);
              };
            });
          }),
        );

        const invalidExtensionData = audioDataList.filter(
          ({ extension }) => extension !== 'wav',
        );
        const invalidFileNameData = audioDataList.filter(
          ({ name }) => !_.find(scriptData.lines, { filename: name }),
        );
        const invalidDurationData = audioDataList.filter(
          ({ duration }) => duration > 12,
        );

        if (invalidExtensionData.length > 0) {
          const invalidFileNames = invalidExtensionData
            .map(({ name, extension }) => `${name}.${extension}`)
            .join('\n');
          openModal(
            <AlertDialog
              message={`다음 파일은 적용되지 않았습니다: wav 형식만 지원합니다.\n${invalidFileNames}`}
              title="에러"
            />,
          );
        } else if (invalidFileNameData.length > 0) {
          const invalidFileNames = invalidFileNameData
            .map(({ name, extension }) => `${name}.${extension}`)
            .join('\n');
          openModal(
            <AlertDialog
              message={`다음 파일은 적용되지 않았습니다: 스크립트에 존재하지 않는 파일입니다.\n${invalidFileNames}`}
              title="에러"
            />,
          );
        } else if (invalidDurationData.length > 0) {
          const invalidFileNames = invalidDurationData
            .map(({ name, extension }) => `${name}.${extension}`)
            .join('\n');
          openModal(
            <AlertDialog
              message={`다음 파일은 적용되지 않았습니다: 12초 이내의 음성 파일만 선택할 수 있습니다.\n${invalidFileNames}`}
              title="에러"
            />,
          );
        }

        const invalidFileNameSet = new Set(
          _.concat(
            invalidDurationData,
            invalidExtensionData,
            invalidFileNameData,
          ).map(({ name, extension }) => `${name}.${extension}`),
        );

        const validAudioFiles = newAudioFiles.filter(
          ({ name }) => !invalidFileNameSet.has(name),
        );

        for (const audioFile of validAudioFiles) {
          const [, name] = fileNamePattern.exec(audioFile.name) ?? [];
          const targetLine = _.find(scriptData.lines, { filename: name });
          if (targetLine) {
            set(voiceTrainingFormAtoms['audio-data'](targetLine), (prev) => ({
              ...prev,
              file: audioFile,
            }));
            setAudioFileData(audioFile);
          }
        }
      },
    [fileBrowser, openModal, scriptData.lines],
  );

  const { data: voice } = useQuery({
    queryKey: [`/voice/${name}`] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ data: Voice }>(path, { signal }),
    select: ({ data: { data } }) => data,
  });

  invariant(voice ?? props.voiceProps, 'Invalid voice data.');

  const trainingDisabled = useMemo(
    () =>
      voice?.emotions.find(({ name }) => name === props.currentEmotion)
        ?.trainingStatus === 'TRAINING',
    [props.currentEmotion, voice?.emotions],
  );

  const retrainingDisabled = useMemo(
    () =>
      voice?.emotions.find(({ name }) => name === props.currentEmotion)
        ?.trainingStatus === 'COMPLETE',
    [props.currentEmotion, voice?.emotions],
  );

  const renderCompleteCheckbox = (
    condition: File | null | SetStateAction<File> | undefined,
  ) => {
    if (props.voiceProps) {
      return condition ? (
        <Checkbox
          id="complete-check01-true"
          checked
          onChange={() => {}}
          label=""
        />
      ) : (
        <Checkbox
          id="complete-check01-false"
          checked={false}
          onChange={() => {}}
          label=""
        />
      );
    }
    return null;
  };

  const { mutate: resetTrainingState } = useMutation({
    mutationFn: async (emotionName: string) => {
      await repositoryClient.patch(
        `/voice/${name}/reset?emotion=${emotionName}`,
      );
    },
    onError: (e) => {
      openModal(
        <AlertDialog
          title="에러"
          message={repositoryUtils.getErrorMessage(e)}
        />,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [`/voice/${name}`] });
    },
  });

  return (
    <div
      css={
        props.voiceProps
          ? selectFileButtonStyle
          : css`
              display: flex;
              align-items: center;
              gap: 16px;
            `
      }
    >
      <div
        css={css`
          display: flex;
          justify-content: space-between;
        `}
      >
        <Button disabled={trainingDisabled} onClick={loadScriptData}>
          스크립트 파일 선택
        </Button>
        {renderCompleteCheckbox(scriptData.file)}
      </div>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
        `}
      >
        <Button
          disabled={
            props.voiceProps
              ? retrainingDisabled
              : trainingDisabled || !scriptData.file
          }
          onClick={setAudioFiles}
        >
          {props.voiceProps ? '음성 파일 선택' : '음성 파일 일괄 선택'}
        </Button>

        {renderCompleteCheckbox(audioFileData)}
      </div>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
        `}
      >
        <Button
          disabled={
            voice?.emotions.filter(
              (item: Emotion) => item.name === props.currentEmotion,
            )[0]?.trainingStatus === 'TRAINING'
          }
          onClick={() => {
            openModal(
              <PromptDialog
                title="확인"
                message="초기화를 진행하면 재학습이 완료될 때까지 사용할 수 없으며 되돌릴 수 없습니다. 해당 감정 보이스를 초기화하시겠습니까?"
                onConfirm={() => resetTrainingState(props.currentEmotion)}
              />,
            );
          }}
        >
          학습 초기화
        </Button>
      </div>

      <p>
        <strong>TXT, WAV 파일만 선택할 수 있습니다.</strong>
      </p>
    </div>
  );
};
