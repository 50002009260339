import { Button } from '@admin/components/Button';
import { StyledInput } from '@admin/components/Styled/Input';
import { css } from '@emotion/react';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

/** 활성화 여부 라디오 버튼 */
const activationRadioButtonArray = [
  { id: 'isActive-all', value: '', name: '전체' },
  { id: 'isActive-true', value: 'true', name: '활성' },
  { id: 'isActive-false', value: 'false', name: '비활성' },
];

export const SearchContainer = () => {
  const [, setSearchParams] = useSearchParams();
  // const [endDateState, setEndDateState] = useRecoilState(endDateAtom);

  /** 활성화 여부 라디오 버튼 클릭 시 검색 */
  const searchActivation = (value: string) => {
    const params = new URLSearchParams();
    let setValue = '';
    if (value === 'true') {
      setValue = 'true';
    }
    if (value === 'false') {
      setValue = 'false';
    }
    params.set('isActive', setValue);
    setSearchParams(params, { replace: true });
  };

  const defaults = useMemo(() => {
    const currentSearchParams = new URLSearchParams(window.location.search);
    const query = currentSearchParams.get('query') ?? '';
    const isActive = currentSearchParams.get('isActive') ?? '';
    return { query, isActive };
  }, []);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        const params = new URLSearchParams();
        params.set('query', formData.get('query') as string);
        params.set('isActive', formData.get('isActive') as string);
        setSearchParams(params, { replace: true });
      }}
      css={css`
        display: flex;
        align-items: center;
        gap: 16px;
        user-select: none;
        input {
          margin-right: 4px;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 16px;
        `}
      >
        {activationRadioButtonArray.map((button) => {
          return (
            <label key={button.id} htmlFor={button.id}>
              <input
                defaultChecked={defaults.isActive === button.value}
                type="radio"
                id={button.id}
                name="isActive"
                value={button.value}
                onChange={(e) => searchActivation(e.target.value)}
              />
              {button.name}
            </label>
          );
        })}
        <StyledInput
          type="search"
          name="query"
          defaultValue={defaults.query}
          css={css`
            width: 300px;
          `}
        />
        <Button form>검색</Button>
        {/* <div
          css={css`
            width: max-content;
            display: flex;
            align-items: center;
          `}
        >
          <p
            css={css`
              width: 90px;
              margin: 0 12px;
            `}
          >
            종료 날짜
          </p>
          <ReactDatePicker
            required
            dateFormat="yyyy년 M월 d일"
            selected={endDateState}
            onChange={(endDate: Date) => {
              setEndDateState(endDate);
            }}
            locale="ko-KR"
            customInput={createElement(DatePickerButton)}
            renderCustomHeader={DatePickerHeader}
          />
        </div> */}
      </div>
    </form>
  );
};
