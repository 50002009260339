import { utils } from '@admin/utils';
import { css } from '@emotion/react';
import type { ReactNode } from 'react';

export const Dialog = (props: { title: string; children: ReactNode }) => {
  return (
    <div
      css={css`
        border-radius: 8px;
        background: ${utils.style.color.white};
        color: ${utils.style.color.black};
        display: flex;
        flex-direction: column;
        overflow: hidden;
      `}
    >
      <p
        css={css`
          padding: 0 30px;
          display: flex;
          align-items: center;
          height: 72px;
          border-bottom: 1px solid ${utils.style.color.grey200};

          ${utils.style.font['title-m']};
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        `}
      >
        {props.title}
      </p>
      {props.children}
    </div>
  );
};
