import { utils } from '@admin/utils';
import styled from '@emotion/styled';

const CELL_HEIGHT = '60px';

export const StyledTable = styled.table`
  width: 100%;
  height: 100%;
  position: relative;
  & thead {
    height: ${CELL_HEIGHT};
    position: sticky;
    top: 0;
    z-index: ${utils.style['z-index'].cover};
    &::before {
      content: '';
      position: absolute;
      top: 0;
      width: 100%;
      height: 1px;
      background-color: ${utils.style.color.grey300};
      z-index: ${utils.style['z-index'].cover};
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: ${utils.style.color.grey300};
      z-index: ${utils.style['z-index'].cover};
    }
  }
  & tbody {
    height: calc(100% - ${CELL_HEIGHT});

    // 행이 테이블 높이를 전부 채우지 못할 경우 가상 요소가 남은 부분을 채워 테이블 행 높이를 일정하게 유지
    &:not(:empty)::after {
      content: '';
    }

    // 테이블에 데이터가 없을 경우 표시
    &:empty::after {
      width: 100%;
      height: calc(100% - ${CELL_HEIGHT});
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      color: ${utils.style.color.grey500};
      ${utils.style.font['text-l']};
      content: '표시할 데이터가 없습니다.';
    }
  }
  & th {
    height: ${CELL_HEIGHT};
    padding: 10px;
    background-color: ${utils.style.color.white};
    color: ${utils.style.color.grey500};
    text-align: center;
    ${utils.style.font['text-l']};
    white-space: nowrap;
  }
  & td {
    height: ${CELL_HEIGHT};
    padding: 10px;
    border-bottom: 1px solid ${utils.style.color.grey300};
    color: ${utils.style.color.black};
    text-align: center;
    ${utils.style.font['text-l']};
  }
`;
