import type { Tag } from '@admin/repository/types';
import { css } from '@emotion/react';
import _ from 'lodash-es';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { voiceTagFormAtoms, voiceTagFormSelectors } from '../../../atom';

export const TagItem = (props: { tag: Tag }) => {
  const isSelected = useRecoilValue(
    voiceTagFormSelectors['is-selected'](props.tag),
  );

  const setSelectedTags = useSetRecoilState(voiceTagFormAtoms['selected-tags']);

  return (
    <label
      css={css`
        width: max-content;
        display: flex;
        align-items: center;
        gap: 4px;
      `}
    >
      <input
        type="checkbox"
        checked={isSelected}
        onChange={() =>
          setSelectedTags((prev) => _.xorBy(prev, [props.tag], 'id'))
        }
      />
      <span>{props.tag.tagLabel}</span>
    </label>
  );
};
