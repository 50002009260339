import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { StyledH1 } from '@admin/components/Styled/H1';
import type { Voice } from '@admin/repository/types';
import { css } from '@emotion/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { DownloadVoiceList } from './DownloadVoiceList';
import { Filter } from './Filter';
import { VoiceTable } from './VoiceTable';

export const VoiceListPage = () => {
  const navigate = useNavigate();

  const [data, setData] = useState<Voice[]>([]);

  const handleData = (data: Voice[]) => {
    setData(data);
  };

  return (
    <ContentBox
      css={css`
        height: 100%;
        gap: 24px;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <StyledH1>보이스</StyledH1>

        <div
          css={css`
            display: flex;
            gap: 16px;
          `}
        >
          <Button onClick={() => navigate('./create')}>보이스 생성</Button>
          <DownloadVoiceList data={data} />
        </div>
      </div>

      <Filter />

      <VoiceTable handleData={handleData} />
    </ContentBox>
  );
};
