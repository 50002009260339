import '@admin/components/DatePicker/datePickerStyle.css';

import { DatePickerButton } from '@admin/components/DatePicker/DatePickerButton';
import { DatePickerHeader } from '@admin/components/DatePicker/DatePickerHeader';
import { utils } from '@admin/utils';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
// eslint-disable-next-line import/no-duplicates
import { isAfter, isBefore, max, min } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import ko from 'date-fns/esm/locale/ko';
import { createElement } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';

registerLocale('ko-KR', ko);

const UnderLineButton = styled.button`
  padding: 0;
  margin-left: 12px;
  border: none;
  background: none;
  text-decoration: underline;
  cursor: pointer;
  flex-shrink: 0;
  color: ${utils.style.color.black};
  &:disabled {
    color: ${utils.style.color.grey500};
    cursor: not-allowed;
  }
`;

interface DateRange {
  readonly end: Date;
  readonly start: Date;
}

interface Props {
  readonly value: DateRange;
  readonly disabled?: boolean;
  readonly onChange?: (value: DateRange) => void;
  /** 당월, 2주, 1달, 3달 단축 버튼 표시 여부 */
  readonly enableShortcuts?: boolean;
}

export const InputDateRange = (props: Props) => {
  return (
    <div
      css={css`
        width: max-content;
        display: flex;
        align-items: center;
      `}
    >
      {/* start */}
      <ReactDatePicker
        required
        disabled={props.disabled}
        dateFormat="yyyy년 M월 d일"
        selected={props.value.start}
        onChange={(start) => {
          if (start) {
            props.onChange?.({
              ...props.value,
              start: min([start, props.value.end]),
            });
          }
        }}
        locale="ko-KR"
        // 타입 오류를 해결하기 위해 jsx 태그 대신 createElement 사용
        customInput={createElement(DatePickerButton)}
        renderCustomHeader={DatePickerHeader}
        filterDate={(date) => isBefore(date, props.value.end)}
      />

      <p
        css={css`
          margin: 0 12px;
        `}
      >
        -
      </p>

      {/* end */}
      <ReactDatePicker
        required
        disabled={props.disabled}
        dateFormat="yyyy년 M월 d일"
        selected={props.value.end}
        onChange={(end) => {
          if (end) {
            props.onChange?.({
              ...props.value,
              end: max([end, props.value.start]),
            });
          }
        }}
        locale="ko-KR"
        customInput={createElement(DatePickerButton)}
        renderCustomHeader={DatePickerHeader}
        filterDate={(date) => isAfter(date, props.value.start)}
      />

      {props.enableShortcuts && (
        <>
          <UnderLineButton
            disabled={props.disabled}
            onClick={() => {
              const [start, end] = [new Date(), new Date()];
              start.setDate(1);
              props.onChange?.({ start, end });
            }}
          >
            당월
          </UnderLineButton>
          <UnderLineButton
            disabled={props.disabled}
            onClick={() => {
              const [start, end] = [new Date(), new Date()];
              start.setDate(end.getDate() - 14);
              props.onChange?.({ start, end });
            }}
          >
            2주
          </UnderLineButton>
          <UnderLineButton
            disabled={props.disabled}
            onClick={() => {
              const [start, end] = [new Date(), new Date()];
              start.setMonth(end.getMonth() - 1);
              props.onChange?.({ start, end });
            }}
          >
            1개월
          </UnderLineButton>
          <UnderLineButton
            disabled={props.disabled}
            onClick={() => {
              const [start, end] = [new Date(), new Date()];
              start.setMonth(end.getMonth() - 3);
              props.onChange?.({ start, end });
            }}
          >
            3개월
          </UnderLineButton>
        </>
      )}
    </div>
  );
};
