import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { PromptDialog } from '@admin/components/Dialog/PromptDialog';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledH1 } from '@admin/components/Styled/H1';
import { StyledTable } from '@admin/components/Styled/Table';
import { repositoryClient } from '@admin/repository';
import { queryClient } from '@admin/repository/query';
import type { Emotion, Voice } from '@admin/repository/types';
import { repositoryUtils } from '@admin/repository/utils';
import { CreateVoiceEmotionDialog } from '@admin/routes/voice/edit/EmotionManagementTable/CreateVoiceEmotionDialog';
import type { EmotionNameType } from '@admin/routes/voice/edit/TrainingForm';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import type { PreviewStatusType } from './VoiceListItem';
import { VoiceListItem } from './VoiceListItem';

const emotionList = ['N', 'H', 'A', 'S', 'C'];

interface VoiceEmotionsType {
  name: string;
  decibel: number;
  isEnabled: boolean;
}

export const EmotionManagementTable = ({ voice }: { voice: Voice }) => {
  const { openModal } = useModal();

  /* 보이스 상세 데이터 조회 */
  const { data: voiceDetail, isFetching } = useQuery({
    queryKey: [`/voice/${voice.name}`] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ data: Voice }>(path, { signal }),
    select: ({ data: { data } }) => data,
  });
  invariant(voiceDetail, 'Invalid voice data.');

  /* 사용할 수 있는 감정 목록 조회 */
  const { data: usableEmotionList, isLoading: isLoadingEmotions } = useQuery({
    queryKey: [`/voice/${voice.voiceId}/emotions/voiceType`] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ data: EmotionNameType[] }>(path, { signal }),
    select: ({ data: { data } }) => data,
  });
  invariant(usableEmotionList, 'Invalid usable emotion list data.');

  const [previewStatus, setPreviewStatus] = useState({
    isPlaying: false,
    isLoading: false,
    currentPlaying: '',
    audio: new Audio(),
  });
  const [voiceContents, setVoiceContents] = useState<null | Emotion[]>(null);

  useEffect(() => {
    if (isFetching === false) {
      setVoiceContents(
        [...voiceDetail.emotions].sort(
          (a: Emotion, b: Emotion) =>
            emotionList.indexOf(a.name) - emotionList.indexOf(b.name),
        ),
      );
    }
  }, [isFetching, voiceDetail.emotions]);

  const listenPreview = useMutation({
    onError: (e: unknown) => {
      openModal(
        <PromptDialog
          title="에러"
          message={repositoryUtils.getErrorMessage(e)}
          onConfirm={() => {
            setPreviewStatus((prev) => ({
              ...prev,
              isLoading: false,
              isPlaying: false,
            }));
          }}
          onCancel={() => {
            setPreviewStatus((prev) => ({
              ...prev,
              isLoading: false,
              isPlaying: false,
            }));
          }}
        />,
      );
    },
    mutationFn: async ({
      emotionName,
      decibelValue,
    }: {
      emotionName: string;
      decibelValue: number;
    }) => {
      setPreviewStatus((prev) => ({ ...prev, currentPlaying: emotionName }));
      const priviewFormData = {
        voiceName: voice.name,
        text: 'testing a volume control preview.',
        emotion: emotionName,
        language: 'en',
        gender: voice.tags.find((obj) => obj.tagName === 'male')
          ? 'MALE'
          : 'FEMALE',
        pitch: 1.0,
        speed: 1.0,
        fileFormat: 'WAV',
        sampleRate: 24000,
        decibel: parseFloat((decibelValue ?? 0).toFixed(1)),
      };
      setPreviewStatus((prev) => ({ ...prev, isLoading: true }));
      const { data } = await repositoryClient.post(
        `/voice-synthesis/set-volume`,
        priviewFormData,
        { responseType: 'arraybuffer' },
      );
      setPreviewStatus((prev) => ({ ...prev, isLoading: false }));
      if (data) {
        setPreviewStatus((prev) => ({ ...prev, isPlaying: true }));
      }
      const blob = new Blob([data], { type: 'audio/wav' });
      const audioURL = URL.createObjectURL(blob);
      queryClient.setQueryData(['audio', emotionName], audioURL);
      const audio = new Audio();
      audio.src = audioURL;
      setPreviewStatus((prev) => ({ ...prev, audio }));
      await audio.play();
      setTimeout(() => {
        audio.pause();
        setPreviewStatus((prev) => ({ ...prev, isPlaying: false }));
      }, 3500);
    },
  });

  const updateVolumeAndActivation = useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`/voice/${voice.voiceId}/emotions`],
      });
    },
    mutationFn: async () => {
      const formData = {
        serviceCode: '',
        voiceEmotions: [] as VoiceEmotionsType[],
      };
      const voiceEmotionsArray = (voiceContents as VoiceEmotionsType[]).map(
        (v) => ({
          name: v.name,
          decibel: v.decibel,
          isEnabled: v.isEnabled,
        }),
      );
      formData.serviceCode = '001WEB001';
      formData.voiceEmotions = voiceEmotionsArray;
      await repositoryClient.post(`/voice/${voice.voiceId}/emotions`, formData);
    },
  });

  const setChangesOnVoiceContents = (
    name: string,
    valueName: string,
    value: number | boolean,
  ) => {
    const transformedData: {
      [key: string]: {
        decibel: number;
        isEnabled: boolean;
        trainingStatus: string;
      };
    } = {};
    Object.values({ ...voiceContents }).forEach((item) => {
      const { name, ...rest } = item as Emotion;
      transformedData[name] = rest;
    });
    if (valueName === 'decibel') {
      transformedData[name] = {
        ...transformedData[name],
        decibel: value,
      } as Emotion;
    } else if (valueName === 'isEnabled') {
      transformedData[name] = {
        ...transformedData[name],
        isEnabled: value,
      } as Emotion;
    }
    const resultData: Emotion[] = [];
    Object.entries(transformedData).forEach(([name, rest]) => {
      resultData.push({ name, ...rest } as Emotion);
    });
    setVoiceContents([...resultData]);
  };

  const onClickPause = () => {
    setPreviewStatus((prev: PreviewStatusType) => ({
      ...prev,
      isPlaying: false,
    }));
    previewStatus.audio.pause();
  };

  return (
    <ContentBox
      css={css`
        gap: 20px;
      `}
    >
      <StyledH1>감정 별 보이스</StyledH1>

      <div
        css={css`
          overflow: hidden;
        `}
      >
        <StyledTable>
          <thead>
            <tr>
              <th>감정</th>
              <th>볼륨 설정</th>
              <th>미리듣기</th>
              <th>다운로드</th>
              <th>활성화</th>
            </tr>
          </thead>
          <tbody
            css={css`
              & > tr > td > div {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 8px;
              }
            `}
          >
            {voiceContents !== null &&
              (voiceContents as Emotion[]).map((emotion: Emotion) => (
                <VoiceListItem
                  key={emotion.name}
                  emotion={emotion}
                  listenPreview={listenPreview.mutate}
                  previewStatus={previewStatus}
                  onClickPause={onClickPause}
                  setChangesOnVoiceContents={setChangesOnVoiceContents}
                />
              ))}
          </tbody>
        </StyledTable>
      </div>
      <div
        css={css`
          width: 200px;
          display: flex;
          justify-content: space-between;
        `}
      >
        <Button
          disabled={voiceDetail.emotions.length === emotionList.length}
          onClick={() => {
            if (!isLoadingEmotions && !isFetching) {
              openModal(
                <CreateVoiceEmotionDialog
                  usableEmotionList={usableEmotionList}
                  emotionList={emotionList}
                  voice={voice}
                />,
              );
            }
          }}
        >
          추가
        </Button>
        <Button
          onClick={() =>
            openModal(
              <PromptDialog
                title={
                  (voiceContents as VoiceEmotionsType[]).every(
                    (v) => v.decibel <= 10,
                  )
                    ? '알림'
                    : '경고'
                }
                message={
                  (voiceContents as VoiceEmotionsType[]).every(
                    (v) => v.decibel <= 10,
                  )
                    ? '모든 감정의 볼륨과 활성화 여부를 저장하시겠습니까?'
                    : '볼륨이 10을 초과하는 감정 보이스가 있어 음질의 손실이 일어날 수 있습니다. 그래도 저장하시겠습니까?'
                }
                onConfirm={() => {
                  updateVolumeAndActivation.mutate();
                }}
              />,
            )
          }
        >
          저장
        </Button>
      </div>
    </ContentBox>
  );
};
