import { Button } from '@admin/components/Button';
import { ErrorText } from '@admin/components/ErrorText';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledForm } from '@admin/components/Styled/Form';
import { repositoryClient } from '@admin/repository';
import { repositoryUtils } from '@admin/repository/utils';
import { utils } from '@admin/utils';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useLayoutEffect, useRef, useState } from 'react';

import { Dialog } from '..';

const reasons = {
  inquiry: '문의하기 처리',
  'api-user-management': 'API 유저 관리',
  'export-user': '유저 내보내기 사용',
  test: '테스트',
  input: '직접 입력',
} as const;

export const UnmaskingDialog = ({ refresh }: { refresh?: boolean }) => {
  const { closeModal } = useModal();

  const formRef = useRef<HTMLFormElement>(null);

  const [currentSelectValue, setCurrentSelectionValue] = useState<string>('');
  const [errorText, setErrorText] = useState('');

  const queryClient = useQueryClient();

  const { mutate: unmask, isLoading: isSubmitting } = useMutation({
    onError: (e) => {
      setErrorText(repositoryUtils.getErrorMessage(e));
    },
    onMutate: () => {
      setErrorText('');
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['/member/all'],
        exact: false,
      });
      if (refresh) window.location.reload();
      closeModal();
    },
    mutationFn: () => {
      const { current: form } = formRef;
      invariant(form, 'form is not mounted');
      const formData = new FormData(form);
      const reasonKey = formData.get('reason-key');
      const reasonInput = formData.get('reason-input');
      const reason =
        reasonKey === 'input'
          ? reasonInput
          : reasons[reasonKey as keyof typeof reasons];
      return repositoryClient.post('/admin/permission/masking', { reason });
    },
  });

  useLayoutEffect(() => {
    const { current: form } = formRef;
    if (form === null) {
      return;
    }
    (form.elements.namedItem('reason-key') as HTMLInputElement).focus();
  }, []);

  return (
    <Dialog title="마스킹 해제">
      <StyledForm
        ref={formRef}
        onSubmit={(e) => {
          e.preventDefault();
          unmask();
        }}
        css={css`
          display: flex;
          flex-direction: column;
          padding: 24px 30px;
          width: 400px;
          gap: 24px;
        `}
      >
        <label>
          사유
          <select
            value={currentSelectValue}
            name="reason-key"
            onChange={(e) => {
              setCurrentSelectionValue(e.target.value);
            }}
            required
          >
            <option hidden value="">
              --- 사유를 선택해 주세요 ---
            </option>
            {Object.entries(reasons).map(([k, v]) => (
              <option key={k} value={k}>
                {v}
              </option>
            ))}
          </select>
          <input
            required={currentSelectValue === 'input'}
            readOnly={currentSelectValue !== 'input'}
            type="text"
            name="reason-input"
          />
        </label>

        <ErrorText errorText={errorText} />

        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 16px;
          `}
        >
          <Button form disabled={isSubmitting}>
            해제
          </Button>

          <Button
            disabled={isSubmitting}
            onClick={closeModal}
            textColor={utils.style.color.grey500}
            baseColor={utils.style.color.grey200}
          >
            취소
          </Button>
        </div>
      </StyledForm>
    </Dialog>
  );
};
