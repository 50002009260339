import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { PromptDialog } from '@admin/components/Dialog/PromptDialog';
import { InputDate } from '@admin/components/InputDate';
import { InputFile } from '@admin/components/InputFile';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledForm } from '@admin/components/Styled/Form';
import { StyledH1 } from '@admin/components/Styled/H1';
import { repositoryClient } from '@admin/repository';
import { DISPLAY_NAMES_TERMS } from '@admin/repository/constant';
import type { Terms } from '@admin/repository/types';
import { repositoryUtils } from '@admin/repository/utils';
import { invariant } from '@admin/utils/invariant';
import utilTime from '@admin/utils/utilTime';
import { css } from '@emotion/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const TermsUploadPage = () => {
  const { openModal } = useModal();

  const { termsId } = useParams();

  const navigate = useNavigate();

  const formRef = useRef<HTMLFormElement>(null);

  const today = useRef(new Date());

  const selectedTermsFile = useRef<File | undefined>();

  const [publicationDate, setPublicationDate] = useState(
    utilTime.format(today.current.getTime(), true),
  );
  const [effectiveDate, setEffectiveDate] = useState(
    utilTime.format(today.current.getTime(), true),
  );

  const { data: terms } = useQuery({
    queryKey: ['/terms'] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ data: Terms[] }>(path, { signal }),
    select: ({ data: { data } }) =>
      data.find((v) => v.termsId === Number(termsId)),
  });

  invariant(terms, 'Invalid terms data.');

  const { mutate: uploadTerms, isLoading: isUploading } = useMutation({
    onError: (e) => {
      openModal(
        <AlertDialog
          title="에러"
          message={repositoryUtils.getErrorMessage(e)}
        />,
      );
    },
    onSuccess: () => {
      openModal(
        <AlertDialog
          title="알림"
          message={`${
            terms ? DISPLAY_NAMES_TERMS[terms.termsName] : '알 수 없음'
          }이(가) 성공적으로 업로드되었습니다.`}
        />,
      );
      navigate(`../history/${termsId}`, { replace: true });
    },
    mutationFn: () => {
      const { current: form } = formRef;
      invariant(form, 'form is not mounted.');
      invariant(selectedTermsFile.current, '선택된 파일이 없습니다.');
      const formData = new FormData(form);
      const uploadTermsRequest = {
        termsId: terms.termsId,
        publicationDate,
        effectiveDate,
        revisionHistory: formData.get('revisionHistory') as string,
      };
      const uploadFormData = new FormData();
      uploadFormData.append('termsFile', selectedTermsFile.current);
      uploadFormData.append(
        'uploadTermsRequest',
        new Blob([JSON.stringify(uploadTermsRequest)], {
          type: 'application/json',
        }),
      );
      return repositoryClient.post(`/admin-terms`, uploadFormData);
    },
  });

  return (
    <ContentBox
      css={css`
        gap: 24px;
      `}
    >
      <StyledH1>약관 및 정책 업로드</StyledH1>

      <StyledForm
        ref={formRef}
        onSubmit={(e) => {
          e.preventDefault();
          if (selectedTermsFile.current === undefined) {
            openModal(
              <AlertDialog title="알림" message="파일을 선택해주세요." />,
            );
            return;
          }
          openModal(
            <PromptDialog
              title="확인"
              message="약관 및 정책을 업로드 하시겠습니까?"
              onConfirm={uploadTerms}
            />,
          );
        }}
      >
        <label>
          구분
          <input readOnly defaultValue={DISPLAY_NAMES_TERMS[terms.termsName]} />
        </label>

        <label htmlFor="termsFile">
          약관 파일 선택
          <InputFile
            accept=".html"
            onChange={({ file }) => {
              if (file === undefined) {
                selectedTermsFile.current = undefined;
                return;
              }
              selectedTermsFile.current = file;
            }}
          />
        </label>

        <label htmlFor="publicationDate">
          공고 일자
          <div
            css={css`
              width: min-content;
            `}
          >
            <InputDate value={publicationDate} onChange={setPublicationDate} />
          </div>
        </label>

        <label htmlFor="effectiveDate">
          시행 일자
          <div
            css={css`
              width: min-content;
            `}
          >
            <InputDate value={effectiveDate} onChange={setEffectiveDate} />
          </div>
        </label>

        <label>
          개정 내역
          <input required name="revisionHistory" />
        </label>

        <Button form disabled={isUploading}>
          업로드
        </Button>
      </StyledForm>
    </ContentBox>
  );
};
