import { utils } from '@admin/utils';
import { css } from '@emotion/react';

export const InputText = (
  props: {
    name?: string;
    required?: boolean;
    value?: string;
    type?: string;
  } & (
    | { readonly: true }
    | { readonly?: false; onChange?: (value: string) => void }
  ),
) => {
  return (
    <input
      name={props.name}
      required={props.required}
      readOnly={props.readonly}
      type={props.type || 'text'}
      css={css`
        height: 40px;
        padding-left: 16px;
        padding-right: 16px;
        border: 1px solid ${utils.style.color.grey300};
        box-sizing: border-box;
        border-radius: 10px;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: ${utils.style.color.black};
        background-color: ${props.readonly && utils.style.color.grey300};
      `}
      onClick={(e) => e.currentTarget.select()}
      value={props.value}
      onChange={(e) =>
        props.readonly || !props.onChange
          ? undefined
          : props.onChange(e.target.value)
      }
    />
  );
};
