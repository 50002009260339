import { Button } from '@admin/components/Button';
import { Dialog } from '@admin/components/Dialog';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { Divider } from '@admin/components/Divider';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledH1 } from '@admin/components/Styled/H1';
import { StyledTable } from '@admin/components/Styled/Table';
import { repositoryClient } from '@admin/repository';
import { DISPLAY_NAMES_PLAN } from '@admin/repository/constant';
import { queryClient } from '@admin/repository/query';
import type { Voice } from '@admin/repository/types';
import { repositoryUtils } from '@admin/repository/utils';
import { utils } from '@admin/utils';
import { css } from '@emotion/react';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';

import { Filter } from '../../Filter';

const defaultPlanNameState = Object.keys({
  None: 'None',
  ...DISPLAY_NAMES_PLAN,
}).reduce(
  (acc, cur) => ({ ...acc, [`plan-name-${cur.toLowerCase()}`]: true }),
  {},
);

export const CustomVoiceEditDialog = ({
  voiceList,
  activatedVoiceIdList,
  serviceCode,
}: {
  voiceList: Voice[];
  activatedVoiceIdList: number[];
  serviceCode: string | undefined;
}) => {
  const { closeModal, openModal } = useModal();
  const [unusedSelectValue, setUnusedSelectValue] =
    useState<keyof Voice>('displayName');
  const [usedSelectValue, setUsedSelectValue] =
    useState<keyof Voice>('displayName');
  const [unusedSearchValue, setUnusedSearchValue] = useState<string>('');
  const [usedSearchValue, setUsedSearchValue] = useState<string>('');
  const [unusedIsEnable, setUnusedIsEnable] = useState<
    'all' | 'true' | 'false'
  >('all');
  const [usedIsEnable, setUsedIsEnable] = useState<'all' | 'true' | 'false'>(
    'all',
  );
  const [unusedPlanName, setUnusedPlanName] = useState(defaultPlanNameState);
  const [usedPlanName, setUsedPlanName] = useState(defaultPlanNameState);
  const [tempActivatedVoiceIdList, setTempActiveVoiceIdList] = useState<
    number[]
  >([...activatedVoiceIdList]);
  const [isChanged, setIsChanged] = useState<boolean>(false);

  const queryKey = [
    `/voice-permission/group?serviceCode=${serviceCode}`,
  ] as const;

  const { mutate: postChange } = useMutation({
    onError: (e) => {
      openModal(
        <AlertDialog
          title="에러"
          message={repositoryUtils.getErrorMessage(e)}
        />,
      );
    },
    onSuccess: () => {
      setIsChanged(false);
      queryClient.invalidateQueries(queryKey);
    },
    mutationFn: () => {
      const data = { serviceCode, voiceIds: [...tempActivatedVoiceIdList] };
      return repositoryClient.put('/voice-permission/group/custom', data, {
        headers: { 'Content-Type': 'application/json' },
      });
    },
  });

  const switchStatus = (voiceId: number) => {
    setIsChanged(true);
    setTempActiveVoiceIdList((prev: number[]) =>
      prev.includes(voiceId)
        ? [...prev].filter((val: number) => val !== voiceId)
        : [...prev, voiceId],
    );
  };

  const switchAllStatus = (intent: boolean) => {
    setIsChanged(true);
    setTempActiveVoiceIdList(
      intent ? [...voiceList].map((val: Voice) => val.voiceId) : [],
    );
  };

  return (
    <Dialog title="마이 AI 보이스 편집">
      <div
        css={css`
          display: flex;
          flex-direction: column;
          width: calc(100vw - 60px);
          height: calc(100vh - 140px);
          padding: 24px;
          gap: 24px;
        `}
      >
        <div
          css={css`
            display: flex;
            height: calc(100% - 88px);
            gap: 24px;
            overflow-x: auto;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              flex: 1;
              gap: 24px;
            `}
          >
            <div
              css={css`
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 24px;
              `}
            >
              <StyledH1>사용 안함</StyledH1>
              <Filter
                used={false}
                isSmallInput
                handleSelectValue={(voiceType) =>
                  setUnusedSelectValue(voiceType)
                }
                handleSearchValue={(value) => setUnusedSearchValue(value)}
                handleIsEnable={(enable) => setUnusedIsEnable(enable)}
                handlePlanName={(e) =>
                  setUnusedPlanName((prev) => ({
                    ...prev,
                    [e.target.name]: e.target.checked,
                  }))
                }
              />
            </div>
            <div
              css={css`
                height: 100%;
                overflow: auto;
              `}
            >
              <StyledTable>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>내부 이름</th>
                    <th>표시 이름</th>
                    <th>제작자</th>
                    <th>사용</th>
                  </tr>
                </thead>
                <tbody>
                  {voiceList
                    .filter(
                      (item: Voice) =>
                        (item[unusedSelectValue] as string)
                          .toLowerCase()
                          .includes(unusedSearchValue) &&
                        (Object.entries(unusedPlanName).some(
                          ([k, v]) =>
                            v && k.includes(item.planName.toLowerCase()),
                        ) ||
                          Object.values(unusedPlanName).every((v) => !v)) &&
                        (unusedIsEnable === 'all' ||
                          item.isEnabled === (unusedIsEnable === 'true')) &&
                        !tempActivatedVoiceIdList.includes(item.voiceId),
                    )
                    .map((item: Voice) => (
                      <tr key={item.voiceId}>
                        <td>{item.voiceId}</td>
                        <td>{item.name}</td>
                        <td>{item.displayName}</td>
                        <td>{item.creatorEmail}</td>
                        <td>
                          <div
                            css={css`
                              display: flex;
                              justify-content: center;
                            `}
                          >
                            <Button
                              baseColor={utils.style.color.subBlack}
                              onClick={() => {
                                switchStatus(item.voiceId);
                              }}
                            >
                              사용
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </StyledTable>
            </div>
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              flex: 1;
              gap: 24px;
            `}
          >
            <div
              css={css`
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 24px;
              `}
            >
              <StyledH1>사용 함</StyledH1>
              <Filter
                used
                isSmallInput
                handleSelectValue={(voiceType) => setUsedSelectValue(voiceType)}
                handleSearchValue={(value) => setUsedSearchValue(value)}
                handleIsEnable={(enable) => setUsedIsEnable(enable)}
                handlePlanName={(e) =>
                  setUsedPlanName((prev) => ({
                    ...prev,
                    [e.target.name]: e.target.checked,
                  }))
                }
              />
            </div>
            <div
              css={css`
                height: 100%;
                overflow: auto;
              `}
            >
              <StyledTable>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>내부 이름</th>
                    <th>표시 이름</th>
                    <th>제작자</th>
                    <th>사용 해제</th>
                  </tr>
                </thead>
                <tbody>
                  {voiceList
                    .filter(
                      (item: Voice) =>
                        (item[usedSelectValue] as string)
                          .toLowerCase()
                          .includes(usedSearchValue) &&
                        (Object.entries(usedPlanName).some(
                          ([k, v]) =>
                            v && k.includes(item.planName.toLowerCase()),
                        ) ||
                          Object.values(usedPlanName).every((v) => !v)) &&
                        (usedIsEnable === 'all' ||
                          item.isEnabled === (usedIsEnable === 'true')) &&
                        tempActivatedVoiceIdList.includes(item.voiceId),
                    )
                    .map((item: Voice) => (
                      <tr key={item.voiceId}>
                        <td>{item.voiceId}</td>
                        <td>{item.name}</td>
                        <td>{item.displayName}</td>
                        <td>{item.creatorEmail}</td>
                        <td>
                          <div
                            css={css`
                              display: flex;
                              justify-content: center;
                            `}
                          >
                            <Button
                              baseColor={utils.style.color.subBlack}
                              onClick={() => {
                                switchStatus(item.voiceId);
                              }}
                            >
                              사용 해제
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </StyledTable>
            </div>
          </div>
        </div>
        <Divider />
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            gap: 16px;
          `}
        >
          <div
            css={css`
              display: flex;
              gap: 16px;
            `}
          >
            <Button
              baseColor={utils.style.color.subBlack}
              onClick={() => {
                switchAllStatus(true);
              }}
            >
              표시된 모든 목소리 사용
            </Button>
            <Button
              baseColor={utils.style.color.subBlack}
              onClick={() => {
                switchAllStatus(false);
              }}
            >
              표시된 모든 목소리 사용 해제
            </Button>
          </div>
          <div
            css={css`
              display: flex;
              gap: 16px;
            `}
          >
            <Button
              disabled={!isChanged}
              onClick={() => {
                postChange();
              }}
            >
              적용
            </Button>
            <Button
              onClick={() => {
                if (isChanged) {
                  postChange();
                }
                closeModal();
              }}
            >
              확인
            </Button>
            <Button
              textColor={utils.style.color.grey500}
              baseColor={utils.style.color.grey300}
              onClick={closeModal}
            >
              취소
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
