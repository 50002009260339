import { StyledSelect } from '@admin/components/Styled/Select';
import { repositoryClient } from '@admin/repository';
import type { Emotion, Voice } from '@admin/repository/types';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import type { EmotionNameType } from '..';

const emotionMatchObject = {
  N: '중립',
  H: '즐거움',
  A: '화남',
  S: '슬픔',
  C: '침착함',
} as const;

const emotionTrainingStatus = {
  READY: '대기 중',
  TRAINING: '학습 중',
  COMPLETE: '완료',
  FAILED: '실패',
} as const;

export const SelectEmotion = ({
  currentEmotion,
  onChangeCurrentEmotion,
}: {
  currentEmotion: 'N' | 'A' | 'C' | 'H' | 'S';
  onChangeCurrentEmotion: (emotionName: EmotionNameType) => void;
}) => {
  const { name } = useParams();

  const { data: voice } = useQuery({
    queryKey: [`/voice/${name}`] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ data: Voice }>(path, { signal }),
    select: ({ data: { data } }) => data,
  });

  invariant(voice, 'Invalid voice data.');

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: 16px;
      `}
    >
      <StyledSelect
        value={currentEmotion}
        onChange={(e) =>
          onChangeCurrentEmotion(e.target.value as EmotionNameType)
        }
      >
        {[...voice.emotions]
          .sort(
            (a: Emotion, b: Emotion) =>
              Object.keys(emotionMatchObject).indexOf(a.name) -
              Object.keys(emotionMatchObject).indexOf(b.name),
          )
          .map((emotion) => (
            <option key={emotion.name} value={emotion.name}>
              {`${
                emotionMatchObject[
                  emotion.name as keyof typeof emotionMatchObject
                ]
              }(${emotionTrainingStatus[emotion.trainingStatus]})`}
            </option>
          ))}
      </StyledSelect>
    </div>
  );
};
