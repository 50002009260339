import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { ErrorText } from '@admin/components/ErrorText';
import { InputText } from '@admin/components/InputText';
import { StyledH1 } from '@admin/components/Styled/H1';
import { StyledLabel } from '@admin/components/Styled/Label';
import { StyledSelect } from '@admin/components/Styled/Select';
import { TextEditor } from '@admin/components/textEditor/TextEditor';
import { repositoryClient } from '@admin/repository';
import type { Notice } from '@admin/repository/types';
import { repositoryUtils } from '@admin/repository/utils';
import { utils } from '@admin/utils';
import { css } from '@emotion/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const initialContent = JSON.stringify([
  { type: 'paragraph', children: [{ text: '' }] },
]);

export const NoticeCreatePage = () => {
  const navigate = useNavigate();

  const [formValue, setFormValue] = useState<
    Pick<Notice, 'category' | 'contents' | 'title'>
  >({ category: '', title: '', contents: '' });

  const [errorText, setErrorText] = useState('');

  const queryClient = useQueryClient();

  const { mutate: createNotice, isLoading: isCreating } = useMutation({
    onError: (e) => {
      setErrorText(repositoryUtils.getErrorMessage(e));
    },
    onMutate: () => {
      setErrorText('');
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['/notice/private'] });
      navigate('..');
    },
    mutationFn: () => repositoryClient.post('/notice', formValue),
  });

  return (
    <ContentBox
      css={css`
        gap: 24px;
      `}
    >
      <StyledH1>공지사항 작성</StyledH1>
      <StyledLabel>
        카테고리
        <StyledSelect
          defaultValue=""
          onChange={({ target: { value: category } }) =>
            setFormValue((prev) => ({ ...prev, category }))
          }
        >
          <option value="" disabled hidden>
            선택 필요
          </option>
          <option value="service">서비스 안내</option>
          <option value="system">시스템 안내</option>
          <option value="update">업데이트</option>
          <option value="etc">기타</option>
        </StyledSelect>
      </StyledLabel>

      <StyledLabel>
        제목
        <InputText
          value={formValue.title}
          onChange={(newValue) =>
            setFormValue((prev) => ({ ...prev, title: newValue }))
          }
        />
      </StyledLabel>

      {/* htmlFor를 지정하지 않으면 StyledLabel이 텍스트 에디터 내부의 file input과 연결되어
        StyledLabel 내부 영역 전체가 file input을 작동시킴 */}
      <StyledLabel htmlFor="content">
        내용
        <TextEditor
          initialContent={initialContent}
          onChange={(newValue) =>
            setFormValue((prev) => ({ ...prev, contents: newValue }))
          }
        />
      </StyledLabel>

      <div
        css={css`
          display: flex;
          gap: 16px;
        `}
      >
        <Button
          disabled={
            isCreating || Object.values(formValue).some((v) => v.trim() === '')
          }
          onClick={createNotice}
        >
          작성
        </Button>

        <Button
          textColor={utils.style.color.grey500}
          baseColor={utils.style.color.grey200}
          onClick={() => {
            navigate('..');
          }}
        >
          취소
        </Button>

        <ErrorText errorText={errorText} />
      </div>
    </ContentBox>
  );
};
