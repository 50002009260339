import { Button } from '@admin/components/Button';
import { StyledTable } from '@admin/components/Styled/Table';
import { repositoryClient } from '@admin/repository';
import type { Inquiry } from '@admin/repository/types';
import { repositoryUtils } from '@admin/repository/utils';
import { inquiryConstants } from '@admin/routes/inquiry/constants';
import { utils } from '@admin/utils';
import utilTime from '@admin/utils/utilTime';
import { css } from '@emotion/react';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash-es';
import { useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const InquiryListTable = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const dateRange = useMemo(() => {
    const since = searchParams.get('since');
    const till = searchParams.get('till');
    return since && till ? { since, till } : undefined;
  }, [searchParams]);

  const query = useQuery({
    queryKey: ['/inquiry/admin', dateRange] as const,
    queryFn: ({ queryKey: [path, params], signal }) =>
      repositoryClient.get<{ data: ReadonlyArray<Inquiry> }>(path, {
        params,
        signal,
      }),
    select: ({ data: { data } }) => data,
    enabled: !!dateRange,
  });

  const filteredItems = useMemo(
    () =>
      _.chain(query.data)
        .filter(({ status }) => {
          const answerStatus = searchParams.get('status');
          return !answerStatus || status === answerStatus;
        })
        .filter(({ inquiryCategory }) => {
          const category = searchParams.get('category');
          return !category || inquiryCategory === category;
        })
        .filter(({ email, name, title }) => {
          const search = searchParams.get('q');
          const searchColumn = searchParams.get('c');
          return (
            !search ||
            !searchColumn ||
            (searchColumn === 'email' && email?.includes(search)) ||
            (searchColumn === 'name' && name?.includes(search)) ||
            (searchColumn === 'title' && title?.includes(search))
          );
        })
        .sortBy(({ inquiryId }) => inquiryId)
        .reverse()
        .value(),
    [query.data, searchParams],
  );

  return (
    <div
      css={css`
        height: 100%;
        overflow: auto;
      `}
    >
      <StyledTable>
        <thead>
          <tr>
            <th>고객명</th>
            <th>이메일</th>
            <th>등록 일시</th>
            <th>카테고리</th>
            <th>제목</th>
            <th>답변 일시</th>
            <th>관리</th>
          </tr>
        </thead>
        <tbody
          css={css`
            & td {
              white-space: nowrap;
            }
          `}
        >
          {filteredItems.map((e) => {
            const inquiredAt = repositoryUtils.parseDate(e.inquiredAt);
            const answeredAt = repositoryUtils.parseDate(e.answeredAt);
            return (
              <tr key={e.inquiryId}>
                <td
                  css={css`
                    white-space: normal !important;
                  `}
                >
                  {e.name}
                </td>
                <td>{e.email}</td>
                <td>{inquiredAt ? utilTime.format(inquiredAt, false) : '-'}</td>
                <td>{inquiryConstants.categories[e.inquiryCategory]}</td>
                <td
                  css={css`
                    white-space: normal !important;
                  `}
                >
                  {e.title}
                </td>
                <td>{answeredAt ? utilTime.format(answeredAt, false) : '-'}</td>
                <td>
                  <div
                    css={css`
                      display: flex;
                      justify-content: center;
                    `}
                  >
                    <Button
                      baseColor={utils.style.color.subBlack}
                      onClick={() => navigate(`./edit/${e.inquiryId}`)}
                    >
                      관리
                    </Button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </StyledTable>
    </div>
  );
};
