import { Button } from '@admin/components/Button';
import { InputSelect } from '@admin/components/InputSelect';
import { InputText } from '@admin/components/InputText';
import { ModalContainer } from '@admin/components/ModalContainer';
import { StyledLabel } from '@admin/components/Styled/Label';
import { repositoryClient } from '@admin/repository';
import type { Category, CategoryTag, Tag } from '@admin/repository/types';
import { repositoryUtils } from '@admin/repository/utils';
import { utils } from '@admin/utils';
import { invariant } from '@admin/utils/invariant';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

export const CreateTagModal = (props: { onClose: VoidFunction }) => {
  const [formValue, setFormValue] = useState<
    Pick<Tag, 'categoryName' | 'tagLabel' | 'tagName'>
  >({ categoryName: '', tagName: '', tagLabel: '' });

  const [errorText, setErrorText] = useState('');

  const voiceTagQuery = useQuery({
    queryKey: ['/tag'] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ data: CategoryTag[] }>(path, { signal }),
    select: ({ data: { data } }) => data,
  });

  const voiceTagCategoryQuery = useQuery({
    queryKey: ['/category'] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ data: Category[] }>(path, { signal }),
    select: ({ data: { data } }) => data,
  });

  invariant(voiceTagQuery.data, 'Invalid tag data.');

  invariant(voiceTagCategoryQuery.data, 'Invalid tag category data.');

  const queryClient = useQueryClient();

  const { mutate: createTag, isLoading: isCreating } = useMutation({
    onError: (e) => {
      setErrorText(repositoryUtils.getErrorMessage(e));
    },
    onMutate: () => {
      setErrorText('');
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['/tag'] });
      queryClient.invalidateQueries({ queryKey: ['/category'] });
      props.onClose();
    },
    mutationFn: () => repositoryClient.post('/tag', formValue),
  });

  return (
    <ModalContainer
      onClose={props.onClose}
      title="태그 추가"
      errorText={errorText}
      fields={
        <>
          <StyledLabel>
            카테고리
            <InputSelect
              defaultValue=""
              onChange={(categoryName) =>
                setFormValue((prev) => ({ ...prev, categoryName }))
              }
            >
              <option value="" disabled hidden>
                선택 필요
              </option>
              {voiceTagCategoryQuery.data.map(
                ({ id, categoryLabel, categoryName }) => (
                  <option key={id} value={categoryName}>
                    {categoryLabel}
                  </option>
                ),
              )}
            </InputSelect>
          </StyledLabel>

          <StyledLabel>
            내부 이름
            <InputText
              value={formValue.tagName}
              onChange={(tagName) =>
                setFormValue((prev) => ({ ...prev, tagName }))
              }
            />
          </StyledLabel>

          <StyledLabel>
            표시 이름
            <InputText
              value={formValue.tagLabel}
              onChange={(tagLabel) =>
                setFormValue((prev) => ({ ...prev, tagLabel }))
              }
            />
          </StyledLabel>
        </>
      }
      actions={
        <>
          <Button
            disabled={
              isCreating ||
              Object.values(formValue).some((v) => v.trim() === '')
            }
            onClick={createTag}
          >
            생성
          </Button>

          <Button
            onClick={props.onClose}
            textColor={utils.style.color.grey500}
            baseColor={utils.style.color.grey200}
          >
            취소
          </Button>
        </>
      }
    />
  );
};
