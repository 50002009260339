import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { ErrorText } from '@admin/components/ErrorText';
import { LoadingBox } from '@admin/components/MainLayout/CustomSuspense/LoadingBox';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledH1 } from '@admin/components/Styled/H1';
import { StyledInput } from '@admin/components/Styled/Input';
import { StyledLabel } from '@admin/components/Styled/Label';
import { repositoryClient } from '@admin/repository';
import { repositoryUtils } from '@admin/repository/utils';
import { UnmaskingNotificationDialog } from '@admin/routes/email/view/UnmaskingNotificationDialog';
import { utils } from '@admin/utils';
import { invariant } from '@admin/utils/invariant';
import { Components } from '@ai-voice-studio/shared';
import { css } from '@emotion/react';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';

import { emailConstants } from '../constants';

export const EmailViewPage = () => {
  const { reservationMailId } = useParams();

  const { openModal } = useModal();

  /** 예약 메일 조회 */
  const { data: emailQuery } = useQuery({
    queryKey: ['/admin-mail/reservation', reservationMailId] as const,
    queryFn: ({ queryKey: [path, reservationMailId], signal }) =>
      repositoryClient.get<{
        data: {
          reservationMailId: number;
          toEmailList: string[];
          title: string;
          date: number[];
          time: number[];
          status: keyof typeof emailConstants.status;
          fileName: string;
          hasFile: boolean;
          htmlFilePath: string;
        };
      }>(`${path}/${reservationMailId}`, { signal }),
    select: ({ data: { data } }) => data,
  });

  const {
    data: htmlQuery,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: [
      `/admin-mail/reservation/${emailQuery?.reservationMailId}/preview`,
      emailQuery?.reservationMailId,
    ] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<ArrayBuffer>(`${path}`, {
        responseType: 'arraybuffer',
        signal,
      }),
    select: ({ data }) => data,
    // staleTime: Infinity,
  });

  if (isError)
    return <ErrorText errorText={repositoryUtils.getErrorMessage(error)} />;
  if (isLoading) return <LoadingBox />;

  invariant(htmlQuery, 'Invalid html query data.');
  invariant(emailQuery, 'Invalid mail query data.');

  const reservationDateTime = repositoryUtils.parseDate([
    ...emailQuery.date,
    ...emailQuery.time,
  ]);

  return (
    <ContentBox
      css={css`
        gap: 24px;
      `}
    >
      <StyledH1>예약 메일 조회</StyledH1>

      <StyledLabel>
        예약 발송 시간
        <StyledInput
          readOnly
          type="datetime-local"
          defaultValue={
            reservationDateTime
              ? format(reservationDateTime, "yyyy-MM-dd'T'HH:mm")
              : undefined
          }
        />
      </StyledLabel>

      <StyledLabel>
        상태
        <StyledInput
          readOnly
          defaultValue={emailConstants.status[emailQuery.status]}
        />
      </StyledLabel>

      <StyledLabel>
        수신자
        <div
          css={css`
            max-height: 200px;
            padding: 8px 16px;
            border-radius: 8px;
            ${utils.style.font['text-m']};
            color: ${utils.style.color.black};
            background-color: ${utils.style.color.grey300};
            white-space: pre-wrap;
            overflow-y: auto;
          `}
        >
          {emailQuery.toEmailList.join('\n')}
        </div>
      </StyledLabel>

      <StyledLabel>
        제목
        <StyledInput readOnly defaultValue={emailQuery.title} />
      </StyledLabel>

      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 8px;
        `}
      >
        <StyledLabel>
          첨부 파일 이름
          <StyledInput readOnly defaultValue={emailQuery.fileName} />
        </StyledLabel>
      </div>

      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 8px;
        `}
      >
        <StyledLabel>메일 내용</StyledLabel>

        <Components.HTMLRenderer data={htmlQuery} />
      </div>
      <Button
        disabled={false}
        onClick={() => {
          if (!emailQuery) return;
          openModal(<UnmaskingNotificationDialog emailQuery={emailQuery} />);
        }}
      >
        {emailQuery.status === 'COMPLETE' ? '재작성' : '수정하기'}
      </Button>
    </ContentBox>
  );
};
