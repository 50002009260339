import { Button } from '@admin/components/Button';
import { Dialog } from '@admin/components/Dialog';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledForm } from '@admin/components/Styled/Form';
import { repositoryClient } from '@admin/repository';
import { repositoryUtils } from '@admin/repository/utils';
import { utils } from '@admin/utils';
import { invariant } from '@admin/utils/invariant';
import { routeName } from '@admin/utils/routeName';
import { css } from '@emotion/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useLayoutEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

export const CreateFormDialog = () => {
  const navigate = useNavigate();

  const { closeModal, openModal } = useModal();

  const formRef = useRef<HTMLFormElement>(null);

  const fieldKeys = {
    name: 'name',
    code: 'code',
    paymentMethod: 'payment-method',
  } as const;

  const queryClient = useQueryClient();

  const { mutate: create, isLoading: isCreating } = useMutation({
    onError: (e) => {
      const message = repositoryUtils.getErrorMessage(e);
      if (
        e instanceof AxiosError &&
        (e.response?.status === 400 || e.response?.status === 409)
      ) {
        const codeInputElement = formRef.current?.elements.namedItem(
          fieldKeys.code,
        ) as HTMLInputElement;
        codeInputElement.setCustomValidity(message);
        codeInputElement.reportValidity();
        return;
      }
      openModal(<AlertDialog title="에러" message={message} />);
    },
    onSuccess: ({ data: { data } }) => {
      queryClient.invalidateQueries(['/crm/service-code']);
      openModal(
        <AlertDialog
          title="알림"
          message={`${data.serviceCode}(${data.displayName}) 서비스 코드가 생성되었습니다.`}
        />,
      );
      navigate(`/${routeName.serviceCode}/edit/${data.serviceCode}`);
    },
    mutationFn: async () => {
      const { current: form } = formRef;
      invariant(form, 'form is not mounted.');
      const formData = new FormData(form);
      const payload = {
        displayName: formData.get(fieldKeys.name) as string,
        serviceCode: formData.get(fieldKeys.code) as string,
        paymentMethod: formData.get(fieldKeys.paymentMethod) as string,
      };
      await repositoryClient.post('/crm/service-code/check', payload);
      return repositoryClient.post<{
        data: { serviceCode: string; displayName: string };
      }>('/crm/service-code', payload);
    },
  });

  useLayoutEffect(() => {
    const { current: form } = formRef;
    if (form === null) {
      return;
    }
    (form.elements.namedItem(fieldKeys.name) as HTMLInputElement).focus();
  }, [fieldKeys.name]);

  return (
    <Dialog title="서비스 코드 생성">
      <StyledForm
        ref={formRef}
        onSubmit={(e) => {
          e.preventDefault();
          create();
        }}
        css={css`
          width: 400px;
          padding: 24px;
        `}
      >
        <label>
          이름
          <input required name={fieldKeys.name} placeholder="그룹명" />
        </label>

        <label
          css={css`
            flex: 1;
          `}
        >
          코드
          <input
            required
            name={fieldKeys.code}
            placeholder="예) 001API001 또는 001WEB001 형식"
            onChange={
              ({ currentTarget }) => currentTarget.setCustomValidity('') // reset custom validity
            }
          />
        </label>

        <label>
          결제 방식
          <select required name={fieldKeys.paymentMethod} defaultValue="">
            <option hidden value="">
              선택
            </option>
            <option value="PRE">선불</option>
            <option value="DEFERRED">후불</option>
          </select>
        </label>

        <div
          css={css`
            width: 100%;
            display: flex;
            justify-content: flex-end;
            gap: 16px;
          `}
        >
          <Button form disabled={isCreating}>
            생성
          </Button>

          <Button
            onClick={closeModal}
            textColor={utils.style.color.grey500}
            baseColor={utils.style.color.grey200}
          >
            취소
          </Button>
        </div>
      </StyledForm>
    </Dialog>
  );
};
