import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { StyledH1 } from '@admin/components/Styled/H1';
import { repositoryClient } from '@admin/repository';
import { queryClient } from '@admin/repository/query';
import style from '@admin/utils/style';
import { css } from '@emotion/react';
import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';

import { PronunciationMapsTable } from './PronunciationMapsTable';

const fileInput = document.createElement('input');
fileInput.type = 'file';
fileInput.accept = '.csv';

export const PronunciationMapsPage = () => {
  const { mutate: exportCSVFile } = useMutation({
    mutationFn: () =>
      repositoryClient.get<Blob>(`/pronunciation-maps/global/download/csv`, {
        responseType: 'blob',
      }),
    onSuccess: (file) => {
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(file.data);
      downloadLink.download = 'pronunciation-maps.csv';
      downloadLink.click();
      URL.revokeObjectURL(downloadLink.href);
    },
  });

  const { mutate: importCSVFile } = useMutation({
    mutationFn: (file: FormData) =>
      repositoryClient.put(`/pronunciation-maps/global`, file, {
        headers: { 'Content-Type': 'multipart/form-data' },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`/pronunciation-maps/global`],
      });
    },
  });

  useEffect(() => {
    const callback = () => {
      const body = new FormData();
      const [file] = fileInput.files ?? [];
      if (file?.name.endsWith('.csv')) {
        body.set('pronunciationMaps', file);
        file.text().then(() => importCSVFile(body));
      }
      fileInput.value = '';
    };
    fileInput.addEventListener('change', callback);
    return () => {
      fileInput.removeEventListener('change', callback);
    };
  }, [fileInput]);

  return (
    <ContentBox
      css={css`
        gap: 24px;
        height: auto;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: row;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            gap: 16px;
            ${style.font['text-s']}

            user-select: none;

            & > div {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 8px;
            }
          `}
        >
          <div>
            <StyledH1>발음 교정 관리</StyledH1>
          </div>
          <div>
            <StyledH1
              css={css`
                ${style.font['text-s']}
                margin-right: 16px;
                text-align: right;
              `}
            >
              가져오기 실행 시, 현재 적용중인 목록이 삭제되고
              <br />
              가져오는 파일의 목록이 적용됩니다.
              <br />
              (가져오기 전, 내보내기를 권장합니다. / 가져오는 양이 많을 경우
              적용되기까지 약 1분의 시간이 소요됩니다.)
            </StyledH1>
            <Button onClick={() => fileInput.click()}>
              가져오기(.csv 확장자)
            </Button>
            <Button onClick={() => exportCSVFile()}>내보내기</Button>
          </div>
        </div>
      </div>
      <PronunciationMapsTable />
    </ContentBox>
  );
};
