import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledForm } from '@admin/components/Styled/Form';
import { StyledH1 } from '@admin/components/Styled/H1';
import { repositoryClient } from '@admin/repository';
import { DISPLAY_NAMES_ROLE } from '@admin/repository/constant';
import type { Member } from '@admin/repository/types';
import { repositoryUtils } from '@admin/repository/utils';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import type { UpdateMemberPayload } from '../type';
import { ChangeRoleReasonDialog } from './ChangeRoleReasonDialog';

export const ChangeMemberRoleForm = () => {
  type Payload = UpdateMemberPayload['role'];

  const [searchParams] = useSearchParams();

  const serviceCode = searchParams.get('service-code');

  const { email: userEmail } = useParams();

  const { openModal } = useModal();

  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: ['/member/single', { serviceCode, userEmail }] as const,
    queryFn: ({ queryKey: [path, { serviceCode, userEmail }], signal }) =>
      repositoryClient.get<{ data: Member }>(`${path}/${userEmail}`, {
        params: { serviceCode },
        signal,
      }),
    select: ({ data: { data } }) => data,
  });

  invariant(query.data, 'Invalid query data.');

  const [selectedRole, selectRole] = useState<Payload>(query.data.role);

  const { mutate: save, isLoading: isSaving } = useMutation({
    onError: (e) => {
      openModal(
        <AlertDialog
          title="에러"
          message={repositoryUtils.getErrorMessage(e)}
        />,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['/member/single', { serviceCode, userEmail }],
      });
      openModal(
        <AlertDialog
          title="알림"
          message="권한이 성공적으로 변경되었습니다."
        />,
      );
    },
    mutationFn: (reason: string) => {
      if (!serviceCode) {
        throw new Error('invalid service code');
      }
      if (!userEmail) {
        throw new Error('invalid email');
      }
      if (!selectedRole) {
        throw new Error('invalid role value');
      }
      if (!reason) {
        throw new Error('reason is empty');
      }
      return Promise.all([
        repositoryClient.post('/admin/permission/role', {
          changedRole: selectedRole,
          reason,
          userEmail, // 권한을 부여할 유저 이메일
          userServiceCode: serviceCode, // 권한을 부여할 유저 서비스 코드
        }),
        repositoryClient.patch(
          `/member/${userEmail}`,
          {
            role: selectedRole,
            password: '', // 이거 안넣으면 에러남
          },
          { params: { serviceCode } },
        ),
      ]);
    },
  });

  return (
    <ContentBox
      css={css`
        gap: 1rem;
      `}
    >
      <StyledH1>권한</StyledH1>
      <StyledForm>
        <select
          required
          value={selectedRole}
          onChange={({ currentTarget: { value } }) =>
            selectRole(value as Payload)
          }
        >
          {Object.entries(DISPLAY_NAMES_ROLE).map(([key, displayName]) => (
            <option key={key} value={key}>
              {displayName}
            </option>
          ))}
        </select>

        <Button
          disabled={!userEmail || isSaving || selectedRole === query.data.role}
          onClick={() => openModal(<ChangeRoleReasonDialog onConfirm={save} />)}
        >
          저장
        </Button>
      </StyledForm>
    </ContentBox>
  );
};
