import { utils } from '@admin/utils';
import { css } from '@emotion/react';
import { Outlet } from 'react-router-dom';

import { CustomErrorBoundary } from './CustomErrorBoundary';
import { CustomSuspense } from './CustomSuspense';
import { Sidebar } from './Sidebar';

export const MainLayout = () => {
  const sidebarWidth = 220;

  return (
    <>
      <Sidebar width={sidebarWidth} />
      <main
        css={css`
          margin-left: ${sidebarWidth}px;
          width: calc(100vw - ${sidebarWidth}px);
          height: 100vh;
          background: ${utils.style.color.background};
          display: flex;
        `}
      >
        <div
          css={css`
            width: 100%;
            padding: 24px;
            overflow: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
          `}
        >
          <CustomErrorBoundary>
            <CustomSuspense>
              <Outlet />
            </CustomSuspense>
          </CustomErrorBoundary>
        </div>
      </main>
    </>
  );
};
