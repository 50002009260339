import { Button } from '@admin/components/Button';
import { StyledTable } from '@admin/components/Styled/Table';
import { repositoryClient } from '@admin/repository';
import type { Repository } from '@admin/repository/types';
import { utils } from '@admin/utils';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash-es';
import { useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const StatusMap = {
  ACTIVE: '활성',
  INACTIVE: '비활성',
  EXPIRED: '만료',
} as const;

export const VoucherListTable = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  // const [endDateState] = useRecoilState(endDateAtom);

  const { data: voucherList } = useQuery({
    queryKey: ['/admin/voucher'] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ data: Repository['voucher'][] }>(path, { signal }),
    select: ({ data: { data } }) => data,
  });

  invariant(voucherList, 'Invalid voucher-list data.');

  /* 검색창 입력값에 의해 필터 데이터 생성 */
  const filteredVoucherList = useMemo(() => {
    const query = searchParams.get('query');
    const isActive = searchParams.get('isActive');
    return (
      _.chain(voucherList)
        .filter((value) => {
          if (!query) {
            return true;
          }

          return value.code.toLowerCase().includes(query.toLowerCase());
        })
        .filter((elem) => {
          if (isActive === 'true') {
            return elem.status === 'ACTIVE';
          }
          if (isActive === 'false') {
            return elem.status === 'INACTIVE';
          }
          return true; // ACTIVE, INACTIVE, EXPIRED 모두 포함
        })
        // .filter((elem) => {
        //   // const sinceDate = new Date(elem.since.join('-'));
        //   const tillDate = new Date(elem.till.join('-'));

        //   return tillDate <= endDateState;
        // })
        .value()
    );
  }, [searchParams, voucherList]);

  return (
    <div
      css={css`
        height: 100%;
        overflow: auto;
      `}
    >
      <StyledTable>
        <thead>
          <tr>
            <th>표시 이름</th>
            <th>코드</th>
            <th>시작 날짜</th>
            <th>종료 날짜</th>
            <th>상태</th>
            <th>관리</th>
          </tr>
        </thead>
        <tbody>
          {filteredVoucherList.map(
            ({
              displayName,
              code,
              since,
              till,
              status,
            }: Repository['voucher']) => (
              <tr key={code}>
                <td>{displayName}</td>
                <td>{code}</td>
                <td>{since.join('-')}</td>
                <td>{till.join('-')}</td>
                <td>{StatusMap[status]}</td>
                <td>
                  <div
                    css={css`
                      display: flex;
                      justify-content: center;
                    `}
                  >
                    <Button
                      onClick={() => navigate(`./edit/${code}`)}
                      baseColor={utils.style.color.subBlack}
                    >
                      관리
                    </Button>
                  </div>
                </td>
              </tr>
            ),
          )}
        </tbody>
      </StyledTable>
    </div>
  );
};
