import { css } from '@emotion/react';

import { ActiveUserView } from './ActiveUserView';

export const ActiveUserStatisticsPage = () => {
  return (
    <div
      css={css`
        display: flex;
        min-width: fit-content;
        height: 100%;
        gap: 24px;
      `}
    >
      <ActiveUserView type="dau" />

      <ActiveUserView type="mau" />
    </div>
  );
};
