import { repositoryClient } from '@admin/repository';
import { DISPLAY_NAMES_PLAN } from '@admin/repository/constant';
import type { Plan } from '@admin/repository/types';
import { utils } from '@admin/utils';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useQuery } from '@tanstack/react-query';

export const UsersPerPlan = () => {
  const query = useQuery({
    queryKey: ['/admin/statistics'] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ data: Record<Plan['name'], number> }>(path, {
        signal,
      }),
    select: ({ data: { data } }) => data,
  });

  invariant(query.data, 'Invalid query data.');

  const data = [
    {
      planName: 'Free',
      count: query.data.Free,
    },
    {
      planName: 'Basic',
      count: query.data.Basic,
    },
    {
      planName: 'Pro',
      count: query.data.Pro,
    },
    {
      planName: 'ProMax',
      count: query.data.ProMax,
    },
    {
      planName: 'Deferred',
      count: query.data.Deferred,
    },
  ] as const;

  return (
    <section
      css={css`
        align-self: center;
        width: max-content;
      `}
    >
      <fieldset>
        <legend>
          <strong
            css={css`
              text-align: center;
              color: ${utils.style.color.black};
            `}
          >
            요금제 별 사용자 수
          </strong>
        </legend>

        <ul
          css={css`
            margin: 0;
            padding: 0;
          `}
        >
          {data.map(({ planName, count }) => (
            <li
              key={planName}
              css={css`
                display: flex;
                justify-content: space-between;
              `}
            >
              <span>{DISPLAY_NAMES_PLAN[planName]}</span>
              <span>{count}</span>
            </li>
          ))}
        </ul>
      </fieldset>
    </section>
  );
};
