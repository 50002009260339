import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { useModal } from '@admin/components/Modal/hooks';
import {
  eventPageAtom,
  mouseClickAtom,
  selectedTypeAtom,
} from '@admin/routes/eventPage/edit/context/atom';
import type { EventButtonList } from '@admin/routes/eventPage/edit/context/type';
import { utils } from '@admin/utils';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useCallback, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';

export const PreviewEventPage = () => {
  const { openModal } = useModal();
  const initialClicks = {
    first: { x: 0, y: 0 },
    second: { x: 0, y: 0 },
    currentWidth: 0,
    currentHeight: 0,
  };
  const [clicks, setClicks] = useState(initialClicks);
  const imageRef = useRef<HTMLImageElement>(null);
  const [eventPageState, setEventPageState] = useRecoilState(eventPageAtom);
  const [selectedTypeState] = useRecoilState(selectedTypeAtom);
  const [mouseClickState] = useRecoilState(mouseClickAtom);
  const { focusedButtonIndex } = eventPageState;

  invariant(eventPageState, 'invalid eventPage.');

  const eventButtonList =
    selectedTypeState === 'PC' ? 'pcEventButtonList' : 'mobileEventButtonList';

  const buttonStyle = useMemo(() => {
    if (!eventPageState[eventButtonList]) return;
    return eventPageState[eventButtonList].map((item) => {
      return {
        external: true,
        href: item.url,
        style: css`
          top: ${Math.abs(item.top)}%;
          left: ${Math.abs(item.left)}%;
          width: ${Math.abs(item.width)}%;
          height: ${Math.abs(item.height)}%;
          background: red;
          opacity: 0.5;
        `,
      };
    });
  }, [eventButtonList, eventPageState]);

  const imageOptions = useMemo(() => {
    return {
      imageData:
        selectedTypeState === 'PC'
          ? eventPageState.pcImageUrl
          : eventPageState.mobileImageUrl,
      alt: 'event',
      links: buttonStyle ?? [{ external: true, href: '', style: '' }],
    };
  }, [
    buttonStyle,
    eventPageState.mobileImageUrl,
    eventPageState.pcImageUrl,
    selectedTypeState,
  ]);

  const changeEventButtonStyle = useCallback(
    (currentWidth: number, currentHeight: number) => {
      const { first, second } = clicks;
      if (first.x !== 0 && second.x !== 0) {
        const width = parseFloat(
          ((Math.abs(second.x - first.x) / currentWidth) * 100).toFixed(2),
        );
        const height = parseFloat(
          ((Math.abs(second.y - first.y) / currentHeight) * 100).toFixed(2),
        );
        const top = parseFloat(
          (
            ((first.y > second.y ? second.y : first.y) / currentHeight) *
            100
          ).toFixed(2),
        );
        const left = parseFloat(
          (
            ((first.x > second.x ? second.x : first.x) / currentWidth) *
            100
          ).toFixed(2),
        );
        const url = imageOptions?.links[focusedButtonIndex]?.href || '';
        setEventPageState((prevEventPageState) => {
          if (!prevEventPageState) return prevEventPageState;
          const updatedPcEventButtonStyle = [
            ...eventPageState[eventButtonList],
          ];
          updatedPcEventButtonStyle[focusedButtonIndex] = {
            ...updatedPcEventButtonStyle[focusedButtonIndex],
            url,
            width,
            height,
            top,
            left,
          } as EventButtonList;
          const updatedEventPage = {
            ...prevEventPageState,
            [eventButtonList]: updatedPcEventButtonStyle,
          };
          return updatedEventPage;
        });
      }
    },
    [
      clicks,
      eventButtonList,
      eventPageState,
      focusedButtonIndex,
      imageOptions?.links,
      setEventPageState,
    ],
  );

  const takeImageSize = (
    currentWidth: number,
    currentHeight: number,
    offsetX: number,
    offsetY: number,
  ) => {
    const { current: image } = imageRef;
    if (!image) return;

    if (clicks.first.x === 0) {
      setClicks((prev) => ({
        ...prev,
        first: { x: offsetX, y: offsetY },
        currentWidth,
        currentHeight,
      }));
    }
    if (clicks.first.x !== 0 && clicks.second.x === 0) {
      setClicks((prev) => ({
        ...prev,
        second: { x: offsetX, y: offsetY },
      }));
    }
    if (clicks.first.x !== 0 && clicks.second.x !== 0) {
      setClicks(initialClicks);
      changeEventButtonStyle(currentWidth, currentHeight);
    }
  };
  return (
    <div
      css={css`
        overflow: auto;
        max-width: 50%;
        background-color: ${utils.style.color.white};
        display: flex;
        flex-direction: column;
      `}
    >
      <div
        css={css`
          position: relative;
          max-width: ${selectedTypeState === 'PC' ? '1280px' : ''};
          width: ${selectedTypeState === 'Mobile' ? '90%' : ''};
          display: flex;
          justify-content: center;
          align-items: center;
          .coordinates {
            width: 10px;
            height: 10px;
            background: red;
            position: absolute;
          }
        `}
      >
        {/* 좌측상단 좌표 표시 */}
        <div
          className="coordinates"
          css={css`
            display: ${mouseClickState[focusedButtonIndex] && clicks.first.x
              ? 'flex'
              : 'none'};
            top: ${(clicks.first.y / clicks.currentHeight) * 100}%;
            left: ${(clicks.first.x / clicks.currentWidth) * 100}%;
          `}
        />

        {/* 우측하단 좌표 표시 */}
        <div
          className="coordinates"
          css={css`
            display: ${mouseClickState[focusedButtonIndex] &&
            clicks.first.x &&
            clicks.second.x
              ? 'flex'
              : 'none'};
            top: ${((clicks.second.y - 10) / clicks.currentHeight) * 100}%;
            left: ${((clicks.second.x - 10) / clicks.currentWidth) * 100}%;
          `}
        />

        {/* 선택 영역 표시 (line) */}
        <div
          css={css`
            display: ${mouseClickState[focusedButtonIndex] &&
            clicks.first.x &&
            clicks.second.x
              ? 'flex'
              : 'none'};
            width: ${(Math.abs(
              clicks.first.x > clicks.second.x
                ? clicks.first.x - clicks.second.x
                : clicks.second.x - clicks.first.x,
            ) /
              clicks.currentWidth) *
            100}%;
            height: ${(Math.abs(
              clicks.first.y > clicks.second.y
                ? clicks.first.y - clicks.second.y
                : clicks.second.y - clicks.first.y,
            ) /
              clicks.currentHeight) *
            100}%;
            border: 1px solid red;
            position: absolute;
            top: ${((clicks.first.y > clicks.second.y
              ? clicks.second.y
              : clicks.first.y) /
              clicks.currentHeight) *
            100}%;
            left: ${((clicks.first.x > clicks.second.x
              ? clicks.second.x
              : clicks.first.x) /
              clicks.currentWidth) *
            100}%;
          `}
        />

        {/* 위치 확정 안내 문구 */}

        <div
          css={css`
            display: ${mouseClickState[focusedButtonIndex] &&
            clicks.first.x &&
            clicks.second.x
              ? 'flex'
              : 'none'};
            padding: 4px;
            margin-top: 20px;
            color: ${utils.style.color.black};
            background: white;
            border-radius: 10px;
            position: absolute;
            top: ${((clicks.first.y > clicks.second.y
              ? clicks.first.y
              : clicks.second.y) /
              clicks.currentHeight) *
            100}%;
            left: ${((clicks.first.x > clicks.second.x
              ? clicks.second.x
              : clicks.first.x) /
              clicks.currentWidth) *
            100}%;
          `}
        >
          한번 더 클릭해서 버튼 영역을 확정하세요.
        </div>

        <div key={imageOptions.imageData}>
          <img
            role="none"
            css={css`
              vertical-align: top; // 4.38px 제거
              object-fit: contain;
              width: 100%;
              height: 100%;
              cursor: crosshair;
            `}
            key={imageOptions.imageData}
            src={imageOptions.imageData}
            alt={imageOptions.alt}
            ref={imageRef}
            onClick={(e) => {
              if (eventPageState[eventButtonList].length < 1) {
                openModal(
                  <AlertDialog
                    title="알림"
                    message="버튼 개수를 1개 이상 지정해주십시오."
                  />,
                );
              }

              if (!mouseClickState[focusedButtonIndex]) {
                return openModal(
                  <AlertDialog
                    title="알림"
                    message="마우스로 설정하기를 클릭해주십시오."
                  />,
                );
              }
              takeImageSize(
                e.currentTarget.width,
                e.currentTarget.height,
                e.nativeEvent.offsetX,
                e.nativeEvent.offsetY,
              );
            }}
          />
          {imageOptions.links.map(({ href, style }, index) => (
            <Link
              key={`${index - 0}`}
              to={href}
              target="_blank"
              rel="noreferrer"
              css={css`
                ${style};
                position: absolute;
                cursor: pointer;
                text-decoration: none;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 600;
                color: white;
                background: ${focusedButtonIndex === index &&
                mouseClickState[focusedButtonIndex]
                  ? 'yellow'
                  : 'red'};
              `}
            >
              {/* 버튼 영역안에 인덱스 표시 */}
              {eventPageState[eventButtonList].length > 0 &&
              eventPageState[eventButtonList][focusedButtonIndex]?.width
                ? index + 1
                : ''}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};
