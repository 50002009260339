import { Button } from '@admin/components/Button';
import { Dialog } from '@admin/components/Dialog';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { ErrorText } from '@admin/components/ErrorText';
import { InputDateRange } from '@admin/components/InputDateRange';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledForm } from '@admin/components/Styled/Form';
import { StyledInput } from '@admin/components/Styled/Input';
import { StyledLabel } from '@admin/components/Styled/Label';
import { repositoryClient } from '@admin/repository';
import { repositoryUtils } from '@admin/repository/utils';
import { utils } from '@admin/utils';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useRef, useState } from 'react';

export const CreateCodeDialog = (props: {
  title: string;
  message: string;
  queryKey: string;
  onConfirm?: () => void;
  onCancel?: () => void;
}) => {
  const { openModal, closeModal } = useModal();

  const queryClient = useQueryClient();

  const formRef = useRef<HTMLFormElement>(null);

  const [dateRange, setDateRange] = useState(() => {
    const [start, end] = [new Date(), new Date()];
    return { start, end };
  });
  /* 날짜 [yyyy,mm,dd] 로 변환 */
  const formattedDate = (date: Date) => {
    return format(date, 'yyyy-M-d')
      .split('-')
      .map((elem) => parseInt(elem, 10));
  };
  /* 코드 생성 */
  const { mutate: createEventCode, isLoading: isCreating } = useMutation({
    onError: (e) => {
      const message = repositoryUtils.getErrorMessage(e);
      openModal(<AlertDialog title="에러" message={message} />);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [props.queryKey] });
      openModal(<AlertDialog title="알림" message={props.message} />);
    },
    mutationFn: () => {
      const { current: form } = formRef;
      invariant(form, 'form is not mounted.');
      const formData = new FormData(form);
      const payload = {
        code: formData.get('code') as string,
      };
      const eventPagePayload = {
        code: formData.get('code') as string,
        startDate: formattedDate(dateRange.start),
        endDate: formattedDate(dateRange.end),
      };
      const sendPayload =
        props.queryKey === '/admin/eventPage' ? eventPagePayload : payload;
      return repositoryClient.post(props.queryKey, sendPayload);
    },
  });

  return (
    <Dialog title={props.title}>
      <StyledForm
        ref={formRef}
        onSubmit={(e) => {
          e.preventDefault();
          createEventCode();
          props.onConfirm?.();
        }}
        css={css`
          display: flex;
          flex-direction: column;
          width: 480px;
          padding: 24px 30px;
          gap: 24px;
        `}
      >
        <StyledLabel>
          코드
          <StyledInput type="text" required name="code" />
        </StyledLabel>
        {props.queryKey === '/admin/eventPage' ? (
          <div>
            <p
              css={css`
                font-weight: 600;
              `}
            >
              유효 기간
            </p>
            <InputDateRange onChange={setDateRange} value={dateRange} />
          </div>
        ) : null}
        {props.queryKey === '/admin/voucher' ? (
          <div>
            <ErrorText errorText="알파벳, 숫자, _ 만 사용 가능합니다." />
            <ErrorText errorText="이벤트 코드는 20자 이하여야합니다." />
          </div>
        ) : null}
        <div
          css={css`
            display: flex;
            justify-content: flex-end;
            gap: 16px;
          `}
        >
          <Button form disabled={isCreating}>
            생성
          </Button>

          <Button
            onClick={() => {
              closeModal();
              props.onCancel?.();
            }}
            textColor={utils.style.color.grey500}
            baseColor={utils.style.color.grey200}
          >
            취소
          </Button>
        </div>
      </StyledForm>
    </Dialog>
  );
};
