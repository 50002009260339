import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { PromptDialog } from '@admin/components/Dialog/PromptDialog';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledForm } from '@admin/components/Styled/Form';
import { StyledH1 } from '@admin/components/Styled/H1';
import { repositoryClient } from '@admin/repository';
import { DISPLAY_NAMES_VOICE_TYPE } from '@admin/repository/constant';
import type { Voice } from '@admin/repository/types';
import { repositoryUtils } from '@admin/repository/utils';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type ChangeEvent, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import type { CreateVoicePayload } from '../edit/type';

const fieldKeys = {
  displayName: 'display-name',
  voiceType: 'voice-type',
  voiceName: 'voice-name',
} as const;

const nameType = {
  auto: '자동',
  custom: '관리자 지정',
};

export const CreateVoicePage = () => {
  const navigate = useNavigate();

  const { openModal } = useModal();

  const formRef = useRef<HTMLFormElement>(null);

  const [createName, setCreateName] = useState<{
    type: keyof typeof nameType;
    name: string;
  }>({
    type: 'auto',
    name: '',
  });

  const queryClient = useQueryClient();

  const handleCreateNameType = (e: ChangeEvent<HTMLSelectElement>) => {
    setCreateName((prev) => ({
      type: e.target.value as 'custom' | 'auto',
      name: e.target.value === 'auto' ? '' : prev.name,
    }));
  };
  const handleCreateName = (e: ChangeEvent<HTMLInputElement>) => {
    setCreateName((prev) => ({
      ...prev,
      name: e.target.value,
    }));
  };

  const { mutate: create, isLoading: isCreating } = useMutation({
    onError: (e) => {
      openModal(
        <AlertDialog
          title="에러"
          message={repositoryUtils.getErrorMessage(e)}
        />,
      );
    },
    onSuccess: ({
      data: {
        data: { displayName, name },
      },
    }) => {
      queryClient.removeQueries(['/voice/all']);
      openModal(
        <AlertDialog
          title="알림"
          message={`${displayName} 보이스가 성공적으로 생성되었습니다.`}
        />,
      );
      navigate(`../edit/${name}`);
    },
    mutationFn: () => {
      const { current: form } = formRef;
      invariant(form, 'form is not mounted.');
      const formData = new FormData(form);
      const displayName = formData.get(fieldKeys.displayName) as string;
      const voiceType = formData.get(fieldKeys.voiceType) as Voice['voiceType'];
      const voiceName = formData.get(fieldKeys.voiceName) as string;
      const payload: CreateVoicePayload = {
        displayName,
        gender: 'MALE',
        language: 'ko',
        permission: 'SELF',
        voiceType,
        name: createName.type === 'auto' ? undefined : voiceName,
      };
      return repositoryClient.post<{
        data: Pick<Voice, 'name' | 'displayName'>;
      }>('/voice', payload);
    },
  });

  return (
    <ContentBox
      css={css`
        gap: 20px;
      `}
    >
      <StyledH1>보이스 생성</StyledH1>

      <StyledForm
        ref={formRef}
        onSubmit={(e) => {
          e.preventDefault();
          if (createName.type === 'auto') {
            create();
            return;
          }

          openModal(
            <PromptDialog
              title="경고"
              message={`내부 이름을 지정하면 바꿀 수 없습니다.\n입력한 내부이름으로 보이스를 생성하시겠습니까?`}
              onConfirm={create}
            />,
          );
        }}
      >
        <label>
          유형
          <select required name={fieldKeys.voiceType}>
            {Object.entries(DISPLAY_NAMES_VOICE_TYPE).map(([k, v]) => {
              // 특별, PTTS만 가능
              if (k === 'CUSTOM' || k === 'COMMON') {
                return null;
              }
              return (
                <option key={k} value={k}>
                  {v}
                </option>
              );
            })}
          </select>
        </label>

        <label>
          표시 이름
          <input required name={fieldKeys.displayName} />
        </label>

        <label>
          내부이름 생성
          <select
            required
            value={createName.type}
            onChange={handleCreateNameType}
          >
            {Object.entries(nameType).map(([k, v]) => (
              <option key={k} value={k}>
                {v}
              </option>
            ))}
          </select>
        </label>

        <label>
          내부이름 지정
          <input
            required
            disabled={createName.type === 'auto'}
            name={fieldKeys.voiceName}
            value={createName.name}
            onChange={handleCreateName}
          />
        </label>

        <Button form disabled={isCreating}>
          생성
        </Button>
      </StyledForm>
    </ContentBox>
  );
};
