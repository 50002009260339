import { Button } from '@admin/components/Button';
import { repositoryClient } from '@admin/repository';
import { downloadExcelFile } from '@admin/routes/voucher/edit/VoucherMemberList/context/utils';
import { invariant } from '@admin/utils/invariant';
import { useQuery } from '@tanstack/react-query';

export const DownloadTemplate = () => {
  /* Excel 샘플 템플릿 파일 서버에서 받아오기 */
  const { data: voucherMemberListSampleFile } = useQuery({
    queryKey: [`/admin/voucher-history/download/sample`] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<Blob>(path, { signal, responseType: 'blob' }),
    select: ({ data }) => data,
  });

  invariant(
    voucherMemberListSampleFile,
    'Invalid voucher member list Sample file.',
  );
  return (
    <Button onClick={() => downloadExcelFile(voucherMemberListSampleFile)}>
      템플릿 내려받기
    </Button>
  );
};
