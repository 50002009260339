import { ReactComponent as CloseIcon } from '@admin/assets/icon/close.svg';
import { utils } from '@admin/utils';
import { css } from '@emotion/react';

export const Tags = (props: { label: string; onRemove: () => void }) => {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: 4px;

        height: 28px;

        padding: 6px 8px;

        ${utils.style.font['text-s']};

        color: ${utils.style.color.primary};
        background-color: ${utils.style.color.highlight2};

        border-radius: 4px;
      `}
    >
      {props.label}
      <CloseIcon
        css={css`
          cursor: pointer;
        `}
        fill={utils.style.color.grey400}
        width="16"
        height="16"
        onClick={props.onRemove}
      />
    </div>
  );
};
