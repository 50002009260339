import { StyledSelect } from '@admin/components/Styled/Select';
import { utils } from '@admin/utils';
import { css } from '@emotion/react';
import { range } from 'lodash-es';

export const DatePickerHeader = (props: {
  monthDate: Date;
  changeYear: (year: number) => void;
  changeMonth: (month: number) => void;
  decreaseMonth: () => void;
  increaseMonth: () => void;
}) => {
  const years = range(2022, 2041, 1);
  const months = range(0, 12, 1);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 8px;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <button
          type="button"
          className="react-datepicker__navigation react-datepicker__navigation--previous"
          onClick={props.decreaseMonth}
          css={css`
            padding-top: 6px;
          `}
        >
          <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">
            {'<'}
          </span>
        </button>
        <span
          className="react-datepicker__current-month"
          css={css`
            width: 100%;
            ${utils.style.font['text-l']};
          `}
        >
          {props.monthDate.toLocaleString('ko-KR', {
            month: 'long',
            year: 'numeric',
          })}
        </span>
        <button
          type="button"
          className="react-datepicker__navigation react-datepicker__navigation--next"
          onClick={props.increaseMonth}
          css={css`
            padding-top: 6px;
          `}
        >
          <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">
            {'>'}
          </span>
        </button>
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 4px;
        `}
      >
        바로가기
        <StyledSelect
          value=""
          onChange={({ target: { value } }) => props.changeYear(Number(value))}
          css={css`
            height: 24px !important;
          `}
        >
          <option hidden value="">
            연도
          </option>
          {years.map((year) => (
            <option key={year} value={year}>
              {year}년
            </option>
          ))}
        </StyledSelect>
        <StyledSelect
          value=""
          onChange={({ target: { value } }) => props.changeMonth(Number(value))}
          css={css`
            height: 24px !important;
          `}
        >
          <option hidden value="">
            월
          </option>
          {months.map((month) => (
            <option key={month} value={month}>
              {month + 1}월
            </option>
          ))}
        </StyledSelect>
      </div>
    </div>
  );
};
