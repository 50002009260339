import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { PromptDialog } from '@admin/components/Dialog/PromptDialog';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledH1 } from '@admin/components/Styled/H1';
import { StyledSelect } from '@admin/components/Styled/Select';
import { repositoryClient } from '@admin/repository';
import { DISPLAY_NAMES_PLAN } from '@admin/repository/constant';
import type { Plan, Voice } from '@admin/repository/types';
import { repositoryUtils } from '@admin/repository/utils';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

export const AccessLevelForm = () => {
  const { name } = useParams();

  const { openModal } = useModal();

  const { data: voice } = useQuery({
    queryKey: ['/voice/all'] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ data: Voice[] }>(path, { signal }),
    select: ({ data: { data } }) => data.find((v) => v.name === name),
  });

  invariant(voice, 'Invalid voice data.');

  const { data: plans } = useQuery({
    queryKey: ['/plan'] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ data: Plan[] }>(path, { signal }),
    select: ({ data: { data } }) => data,
  });

  invariant(plans, 'Invalid plan data.');

  const { data: accessLevel } = useQuery({
    enabled: !!voice,
    queryKey: [
      `/voice-permission/group/${voice.voiceId}/access-level`,
    ] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      // DB에 Voice에 대한 VoicePermission 정보가 없으면 None
      // None이면 모든 유저가 사용 불가
      repositoryClient.get<{ data: { planName: Plan['name'] | 'None' } }>(
        path,
        { signal },
      ),
    select: ({ data: { data } }) => data,
  });

  invariant(accessLevel, 'Invalid access level data.');

  const [selectedPlanName, setSelectedPlanName] = useState<
    Plan['name'] | 'None'
  >(accessLevel.planName);

  const queryClient = useQueryClient();

  const { mutate: update, isLoading: isUpdating } = useMutation({
    onError: (e) => {
      openModal(
        <AlertDialog
          title="에러"
          message={repositoryUtils.getErrorMessage(e)}
        />,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries([
        `/voice-permission/group/${voice.voiceId}/access-level`,
      ]);
      openModal(
        <AlertDialog
          title="알림"
          message="최소 필요 요금제가 성공적으로 업데이트되었습니다."
        />,
      );
    },
    mutationFn: () =>
      repositoryClient.put(
        `/voice-permission/group/${voice.voiceId}/access-level?planName=${selectedPlanName}`,
        {
          headers: { 'Content-Type': 'application/json' },
        },
      ),
  });

  return (
    <ContentBox
      css={css`
        gap: 20px;
      `}
    >
      <StyledH1>최소 필요 요금제</StyledH1>

      <StyledSelect
        value={selectedPlanName}
        onChange={(e) => {
          setSelectedPlanName(e.target.value as Plan['name']);
        }}
      >
        <option hidden value="None">
          None
        </option>
        {plans.map((plan) => (
          <option key={plan.uid} value={plan.name}>
            {DISPLAY_NAMES_PLAN[plan.name]}
          </option>
        ))}
      </StyledSelect>

      <Button
        disabled={isUpdating || accessLevel.planName === selectedPlanName}
        onClick={() =>
          openModal(
            <PromptDialog
              title="알림"
              message="최소 필요 요금제를 저장 하시겠습니까?"
              onConfirm={update}
            />,
          )
        }
      >
        저장
      </Button>
    </ContentBox>
  );
};
