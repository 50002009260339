import { StyledTable } from '@admin/components/Styled/Table';
import { repositoryClient } from '@admin/repository';
import {
  DISPLAY_NAMES_VOICE,
  DISPLAY_NAMES_VOICE_TYPE,
} from '@admin/repository/constant';
import type { Voice } from '@admin/repository/types';
import { utils } from '@admin/utils';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash-es';
import { useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const columnKeys = {
  'voice-type': 'voiceType',
  'voice-id': 'voiceId',
  name: 'name',
  'display-name': 'displayName',
  'is-enabled': 'isEnabled',
  'is-deleted': 'isDeleted',
} as const satisfies Record<string, keyof Voice>;

export const VoiceTable = ({
  handleData,
}: {
  handleData(data: Voice[]): void;
}) => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const { data: voices } = useQuery({
    queryKey: ['/voice/all'] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ data: Voice[] }>(path, { signal }),
    select: ({ data: { data } }) => data,
  });

  invariant(voices, 'Invalid voice data');

  const filteredVoices = useMemo(() => {
    const query = searchParams.get('query');
    const column = searchParams.get('column');
    const voiceTypes = searchParams.get('voice-type');
    const planNames = searchParams.get('plan-name');
    const isEnabled = searchParams.get('is-enabled');
    return _.chain(voices)
      .filter((v) => {
        if (!column || !query) {
          return true;
        }
        switch (column) {
          case 'displayName':
          case 'name':
          case 'creatorEmail':
            return v[column].toLowerCase().includes(query.toLowerCase());
          case 'voiceId':
            return Number(query) === v[column];
          default:
            return false;
        }
      })
      .filter(
        voiceTypes
          ? ({ voiceType }) =>
              voiceTypes.split(',').includes(voiceType.toLowerCase())
          : undefined,
      )
      .filter(
        planNames
          ? ({ planName }) =>
              planNames.split(',').includes(planName.toLowerCase())
          : undefined,
      )
      .filter(isEnabled ? { isEnabled: isEnabled === 'true' } : undefined)
      .value();
  }, [searchParams, voices]);

  useEffect(() => {
    handleData(filteredVoices);
  }, [filteredVoices, handleData]);

  return (
    <div
      css={css`
        height: 100%;
        overflow: auto;
      `}
    >
      <StyledTable>
        <thead>
          <tr>
            {Object.entries(columnKeys).map(([k, v]) => (
              <th key={k}>{DISPLAY_NAMES_VOICE[v]}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          {filteredVoices.map((voice) => (
            <tr
              key={voice.voiceId}
              onClick={() => navigate(`./edit/${voice.name}?${voice.voiceId}`)}
              css={css`
                cursor: pointer;
                &:hover {
                  background: ${utils.style.color.grey100};
                }
              `}
            >
              {Object.entries(columnKeys).map(([k, v]) => {
                switch (v) {
                  case 'voiceType':
                    return (
                      <td
                        key={k}
                        css={css`
                          white-space: nowrap;
                        `}
                      >
                        {DISPLAY_NAMES_VOICE_TYPE[voice[v]]}
                      </td>
                    );
                  case 'isEnabled':
                    return <td key={k}>{voice[v] ? '활성' : '-'}</td>;
                  case 'isDeleted':
                    return <td key={k}>{voice[v] ? '삭제' : '-'}</td>;
                  default:
                    return <td key={k}>{voice[v]}</td>;
                }
              })}
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </div>
  );
};
