import { Button } from '@admin/components/Button';
import { PromptDialog } from '@admin/components/Dialog/PromptDialog';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledInput, StyledNumberInput } from '@admin/components/Styled/Input';
import { InputContainer } from '@admin/components/Styled/Input/InputContainer';
import {
  eventPageAtom,
  initialEventButtonList,
  mouseClickAtom,
  selectedTypeAtom,
} from '@admin/routes/eventPage/edit/context/atom';
import type {
  EventButtonList,
  EventPage,
} from '@admin/routes/eventPage/edit/context/type';
import { utils } from '@admin/utils';
import { css } from '@emotion/react';
import { useCallback, useMemo } from 'react';
import { useRecoilState } from 'recoil';

/** ['width', 'height', 'top', 'left'] */
const NumberInputArray = ['width', 'height', 'top', 'left'];

export const ButtonList = () => {
  const [eventPageState, setEventPageState] = useRecoilState(eventPageAtom);
  const [mouseClickState, setMouseClickState] = useRecoilState(mouseClickAtom);
  const [selectedTypeState] = useRecoilState(selectedTypeAtom);
  const { openModal } = useModal();

  const buttonList =
    selectedTypeState === 'PC' ? 'pcEventButtonList' : 'mobileEventButtonList';

  /** pc, mobile 버튼 개수 ui에 렌더링 */
  const showButtonCount = useMemo(() => {
    if (selectedTypeState === 'PC') {
      return eventPageState.pcEventButtonList.length ?? 0;
    }
    if (selectedTypeState === 'Mobile') {
      return eventPageState.mobileEventButtonList.length ?? 0;
    }
    return 0;
  }, [
    eventPageState.mobileEventButtonList.length,
    eventPageState.pcEventButtonList.length,
    selectedTypeState,
  ]);

  /** input값 변경하면 상태값 update하여 미리보기 */
  const updateInputState = useCallback(
    (label: keyof EventButtonList, value: string, buttonIndex: number) => {
      setEventPageState((prevEventPageState) => {
        const updatedEventButtonList = [...prevEventPageState[buttonList]];
        updatedEventButtonList[buttonIndex] = {
          ...updatedEventButtonList[buttonIndex],
          [label]: value,
        } as EventButtonList;
        const updatedEventPage = {
          ...prevEventPageState,
          [buttonList]: updatedEventButtonList,
          focusedButtonIndex: buttonIndex,
        };
        return updatedEventPage;
      });
    },
    [buttonList, setEventPageState],
  );

  const updateEventPageStateWithServerData = (buttonIndex: number) => {
    setEventPageState((prevEventPageState) => {
      const updatedEventButtonList = [...prevEventPageState[buttonList]];
      updatedEventButtonList[buttonIndex] = initialEventButtonList;
      const updatedEventPage = {
        ...prevEventPageState,
        [buttonList]: updatedEventButtonList,
      };
      return updatedEventPage;
    });

    setMouseClickState((prevArray) => {
      const updatedArray = [...prevArray];
      updatedArray[buttonIndex] = false;
      return updatedArray;
    });
  };

  const updateFocusedIndexAndMouseClickState = (buttonIndex: number) => {
    setEventPageState((prevEventPageState) => {
      const updatedEventPage = {
        ...prevEventPageState,
        focusedButtonIndex: buttonIndex,
      };
      return updatedEventPage;
    });
    setMouseClickState((prevArray) => {
      const updatedArray = [...prevArray];
      updatedArray[buttonIndex] = true;
      return updatedArray;
    });
  };

  return (
    <div>
      <div>
        <p
          css={css`
            border-bottom: 1px solid ${utils.style.color.grey300};
          `}
        >{`${selectedTypeState === 'PC' ? 'PC' : 'Mobile'} 버튼 속성`}</p>

        <InputContainer
          css={css`
            width: 295px;
            margin-top: 10px;
          `}
        >
          <label htmlFor="buttonCount">
            개수
            <StyledNumberInput
              id="buttonCount"
              name="buttonCount"
              type="number"
              min={0}
              step={1}
              value={showButtonCount}
              onChange={(event) => {
                const currentButtonCount = parseInt(event.target.value, 10);
                const updatedButtonList = [...eventPageState[buttonList]];
                const deleteButtonIndex =
                  currentButtonCount < eventPageState[buttonList].length
                    ? eventPageState[buttonList].length - currentButtonCount
                    : 0;
                if (deleteButtonIndex > 0) {
                  updatedButtonList.forEach((_, index) => {
                    if (index <= deleteButtonIndex - 1) {
                      updatedButtonList.pop();
                    }
                  });
                } else {
                  updatedButtonList.push(initialEventButtonList);
                }

                setEventPageState((prev: EventPage) => ({
                  ...prev,
                  [buttonList]: updatedButtonList,
                }));
              }}
            />
          </label>
        </InputContainer>
      </div>
      <div
        css={css`
          width: 100%;
          height: 20vh;
          overflow-y: scroll;
        `}
      >
        {Array(showButtonCount)
          .fill(1)
          .map((_, buttonIndex: number) => {
            const isClicked =
              eventPageState.focusedButtonIndex === buttonIndex &&
              mouseClickState[buttonIndex];
            return (
              <div key={`button-${buttonIndex + 1}`}>
                {`${buttonIndex + 1}번째 버튼`}
                <InputContainer>
                  {NumberInputArray.map((label) => {
                    return (
                      <label
                        key={label}
                        css={css`
                          margin-right: 20px;
                          margin-bottom: 20px;
                        `}
                      >
                        {label}
                        <div
                          css={css`
                            display: flex;
                            align-items: center;
                          `}
                        >
                          <StyledNumberInput
                            css={css`
                              width: 100px;
                              margin-right: 5px;
                            `}
                            type="number"
                            min={0.0}
                            step={0.01}
                            name={`${label}-${buttonIndex + 1}`}
                            value={
                              eventPageState[buttonList][buttonIndex]?.[
                                label
                              ] ?? 0.0
                            }
                            onChange={(e) => {
                              updateInputState(
                                label,
                                e.target.value,
                                buttonIndex,
                              );
                            }}
                          />
                          %
                        </div>
                      </label>
                    );
                  })}
                  <Button
                    onClick={() => {
                      /* 실행 취소 */
                      if (isClicked) {
                        openModal(
                          <PromptDialog
                            title="확인"
                            message={`${
                              buttonIndex + 1
                            }번째 버튼의 영역 설정을 취소하시겠습니까?\n취소할 경우 저장된 값으로 초기화 됩니다.`}
                            onConfirm={() => {
                              // server에서 받은 값으로 eventPage를 업데이트해주기
                              updateEventPageStateWithServerData(buttonIndex);
                            }}
                          />,
                        );
                      } else {
                        /* 마우스로 설정하기 */
                        // focusedIndex, mouseClickState 업데이트
                        updateFocusedIndexAndMouseClickState(buttonIndex);
                      }
                    }}
                    baseColor={
                      isClicked
                        ? utils.style.color.highlight1
                        : utils.style.color.primary
                    }
                    css={css`
                      margin-top: 30px;
                    `}
                  >
                    {isClicked ? '실행 취소' : '마우스로 설정하기'}
                  </Button>
                </InputContainer>

                <InputContainer
                  css={css`
                    margin-bottom: 30px;
                  `}
                >
                  <label htmlFor="url">
                    URL
                    <StyledInput
                      id="url"
                      name={`url-${buttonIndex + 1}`}
                      type="text"
                      placeholder="https://aivoicestudio.ai/"
                      css={css`
                        width: 295px;
                      `}
                      value={eventPageState[buttonList][buttonIndex]?.url ?? ''}
                      onChange={(e) => {
                        updateInputState('url', e.target.value, buttonIndex);
                      }}
                    />
                  </label>
                </InputContainer>
              </div>
            );
          })}
      </div>
    </div>
  );
};
