import { Button } from '@admin/components/Button';
import { Dialog } from '@admin/components/Dialog';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledInput } from '@admin/components/Styled/Input';
import style from '@admin/utils/style';
import { css } from '@emotion/react';
import type { ChangeEvent } from 'react';
import { useState } from 'react';

import type { PronunciationMapsType } from '../types';

export const UpdatePronunciationMapsDialog = (props: {
  title: string;
  text: string;
  pronunciation: string;
  onConfirm: (changeData: PronunciationMapsType) => void;
  onCancel?: () => void;
}) => {
  const { closeModal } = useModal();
  const [pronunciationValue, setPronunciationValue] = useState<string>(
    props.pronunciation,
  );

  return (
    <Dialog title={props.title}>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          padding: 24px 30px;
          width: 400px;
          min-height: 200px;
          max-height: 300px;
          gap: 24px;
        `}
      >
        <div
          css={css`
            flex: 1;
            display: flex;
            overflow: auto;
          `}
        >
          <div
            css={css`
              margin: auto;
              width: 100%;
              max-width: 100%;
              user-select: text;
              white-space: pre-wrap;

              text-align: center;

              ${style.font['text-m']};

              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 16px;

              div {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 16px;
                height: 40px;
                width: 100%;
                word-break: keep-all;
              }
            `}
          >
            <div>
              단어
              <div
                css={css`
                  margin-left: 16px;
                  ${style.font['text-m']};
                `}
              >
                {props.text}
              </div>
            </div>
            <div>
              발음
              <StyledInput
                css={css`
                  width: 100%;
                `}
                name="pronunciation"
                defaultValue={props.pronunciation}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setPronunciationValue(e.target.value)
                }
              />
            </div>
          </div>
        </div>

        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 16px;
          `}
        >
          <Button
            onClick={() => {
              closeModal();
              props.onConfirm({
                text: props.text,
                pronunciation: pronunciationValue,
              });
            }}
          >
            수정
          </Button>

          <Button
            onClick={() => {
              closeModal();
              props.onCancel?.();
            }}
            textColor={style.color.grey500}
            baseColor={style.color.grey200}
          >
            취소
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
