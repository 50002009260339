import { repositoryUtils } from '@admin/repository/utils';
import { css } from '@emotion/react';
import type { FallbackProps } from 'react-error-boundary';

export const ErrorBox = (props: FallbackProps) => {
  return (
    <div
      css={css`
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 8px;
        padding: 8px 16px;
      `}
    >
      <strong>오류가 발생했습니다</strong>

      <button
        type="button"
        onClick={() => props.resetErrorBoundary()}
        css={css`
          padding: 8px 16px;
        `}
      >
        재시도
      </button>

      <pre
        css={css`
          margin: 0;
          max-height: 200px;
          overflow: auto;
          padding: 0px 16px;
          font-size: 14px;
          white-space: pre-wrap;
          word-break: break-all;
        `}
      >
        {repositoryUtils.getErrorMessage(props.error)}
      </pre>
    </div>
  );
};
