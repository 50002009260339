import { ContentBox } from '@admin/components/ContentBox';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledH1 } from '@admin/components/Styled/H1';
import { StyledInput } from '@admin/components/Styled/Input';
import { StyledLabel } from '@admin/components/Styled/Label';
import { repositoryClient } from '@admin/repository';
import type { emailConstants } from '@admin/routes/email/constants';
import { MailImportExportButton } from '@admin/routes/email/create/Button/MailImportExportButton';
import { SaveEmailButton } from '@admin/routes/email/create/Button/SaveEmailButton';
import { ScheduleEmailButton } from '@admin/routes/email/create/Button/ScheduleEmailButton';
import { SendEmailButton } from '@admin/routes/email/create/Button/SendEmailButton';
import { TestEmailButton } from '@admin/routes/email/create/Button/TestEmailButton';
import { emailToListAtom } from '@admin/routes/email/create/context/atom';
import { FileAttachment } from '@admin/routes/email/create/Section/FileAttachment';
import { ReceivedGroup } from '@admin/routes/email/create/Section/ReceivedGroup';
import { Recipients } from '@admin/routes/email/create/Section/Recipients';
import { css } from '@emotion/react';
import { useQuery } from '@tanstack/react-query';
import CryptoJS from 'crypto-js';
import { useCallback, useEffect, useRef, useState } from 'react';
import type { Design, EditorRef } from 'react-email-editor';
import EmailEditor from 'react-email-editor';
import { useSearchParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';

export const EmailCreatePage = () => {
  const [emailToListState, setEmailToListState] =
    useRecoilState(emailToListAtom);

  const [emailTitle, setEmailTitle] = useState('');

  const [emailToActive, setEmailToActive] = useState(false);
  const [emailToDormant, setEmailToDormant] = useState(false);

  const fileRef = useRef<File | undefined>(undefined);

  const mailEditorRef = useRef<EditorRef>(null);

  const [editorLoaded, setEditorLoaded] = useState(false);

  const [searchParams] = useSearchParams();

  const reservationMailId = searchParams.get('id');

  const { openModal } = useModal();

  const updateFileRef = (ref: React.MutableRefObject<File | undefined>) => {
    fileRef.current = ref.current;
  };

  const updateEmailToActive = (value: boolean) => {
    setEmailToActive(value);
  };

  const updateEmailToDormant = (value: boolean) => {
    setEmailToDormant(value);
  };

  const loadDesign = useCallback(
    (desgin: Design) => {
      try {
        mailEditorRef.current?.loadDesign(desgin);
      } catch (error) {
        openModal(
          <AlertDialog
            title="에러"
            message="메일 불러오기 중 에러가 발생하였습니다."
          />,
        );
      }
    },
    [mailEditorRef, openModal],
  );

  /** 예약 메일 조회 */
  const { data: emailQuery } = useQuery({
    queryKey: ['/admin-mail/reservation', reservationMailId] as const,
    queryFn: ({ queryKey: [path, reservationMailId], signal }) =>
      repositoryClient.get<{
        data: {
          reservationMailId: number;
          toEmailList: string[];
          title: string;
          date: number[];
          time: number[];
          status: keyof typeof emailConstants.status;
          fileName: string;
          hasFile: boolean;
          renderingInfo: string;
        };
      }>(`${path}/${reservationMailId}`, { signal }),
    select: ({ data: { data } }) => data,
    enabled: !!reservationMailId,
  });

  const getAllEmailAddressList = useCallback(async () => {
    const allEmailAddressList = emailToListState.slice();

    if (emailToActive) {
      const {
        data: { data },
      } = await repositoryClient.get<{ data: string[] }>(
        '/member/admin/member-groups',
        {
          params: { memberGroup: 'ACTIVE_USER' },
        },
      );
      allEmailAddressList.push(...data);
    }

    if (emailToDormant) {
      const {
        data: { data },
      } = await repositoryClient.get<{ data: string[] }>(
        '/member/admin/member-groups',
        {
          params: { memberGroup: 'DORMANT_USER' },
        },
      );
      allEmailAddressList.push(...data);
    }

    return Array.from(new Set(allEmailAddressList));
  }, [emailToActive, emailToDormant, emailToListState]);

  useEffect(() => {
    if (editorLoaded) {
      mailEditorRef.current?.loadBlank({
        backgroundColor: '#ffffff',
        contentWidth: '900px',
      });
    }
  }, [editorLoaded]);

  useEffect(() => {
    if (emailQuery) {
      setEmailTitle(emailQuery.title);
      setEmailToListState(emailQuery.toEmailList);
    }
    if (
      editorLoaded &&
      emailQuery &&
      emailQuery.renderingInfo.length &&
      emailQuery.renderingInfo.length > 0
    ) {
      const decodedRenderingInfo = CryptoJS.enc.Base64.parse(
        emailQuery.renderingInfo,
      );
      const jsonRenderingInfo =
        CryptoJS.enc.Utf8.stringify(decodedRenderingInfo);
      loadDesign(JSON.parse(jsonRenderingInfo));
    }
  }, [editorLoaded, emailQuery, loadDesign, setEmailToListState]);

  const propsObject = {
    emailTitle,
    fileRef,
    mailEditorRef,
    editorLoaded,
  };

  return (
    <ContentBox
      css={css`
        gap: 24px;
      `}
    >
      <StyledH1>메일 작성</StyledH1>

      {/* 받는 그룹 */}
      <ReceivedGroup
        updateEmailToActive={updateEmailToActive}
        updateEmailToDormant={updateEmailToDormant}
      />

      {/* 받는 사람 */}
      <Recipients />

      <StyledLabel>
        제목
        <StyledInput
          required
          value={emailTitle}
          onChange={({ currentTarget: { value } }) => setEmailTitle(value)}
        />
      </StyledLabel>

      {/* 파일 첨부 */}
      <FileAttachment emailQuery={emailQuery} updateFileRef={updateFileRef} />

      {/* 메일 파일 불러오기 / 메일 파일로 저장하기    */}
      <MailImportExportButton
        editorLoaded={editorLoaded}
        mailEditorRef={mailEditorRef}
        loadDesign={loadDesign}
      />

      {/* https://www.npmjs.com/package/react-email-editor */}
      <EmailEditor
        ref={mailEditorRef}
        locale="ko-KR"
        style={{ height: '600px' }}
        onReady={() => setEditorLoaded(true)}
        options={{
          features: {
            ai: false,
            audit: false,
            imageEditor: false,
            stockImages: false,
          },
        }}
      />

      <div
        css={css`
          display: flex;
          gap: 16px;
        `}
      >
        {/* 수정하기 / 임시저장 */}
        <SaveEmailButton
          getAllEmailAddressList={getAllEmailAddressList}
          propsObject={propsObject}
          emailQuery={emailQuery}
        />
        {/* 테스트 발송 */}
        <TestEmailButton propsObject={propsObject} />
        {/* 예약 발송 */}
        <ScheduleEmailButton
          getAllEmailAddressList={getAllEmailAddressList}
          propsObject={propsObject}
        />
        {/* 메일 발송 */}
        <SendEmailButton
          getAllEmailAddressList={getAllEmailAddressList}
          propsObject={propsObject}
        />
      </div>
    </ContentBox>
  );
};
