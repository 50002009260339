import { Button } from '@admin/components/Button';
import { Dialog } from '@admin/components/Dialog';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { PromptDialog } from '@admin/components/Dialog/PromptDialog';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledForm } from '@admin/components/Styled/Form';
import { StyledInput, StyledNumberInput } from '@admin/components/Styled/Input';
import { StyledLabel } from '@admin/components/Styled/Label';
import { repositoryClient } from '@admin/repository';
import type { Voice, VoiceContract } from '@admin/repository/types';
import { repositoryUtils } from '@admin/repository/utils';
import { utils } from '@admin/utils';
import { invariant } from '@admin/utils/invariant';
import style from '@admin/utils/style';
import { css } from '@emotion/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useRef } from 'react';

interface Props {
  readonly currentLineValue: VoiceContract;
}
export const UpdateVoiceContractModal = (props: Props) => {
  const formRef = useRef<HTMLFormElement>(null);
  const queryClient = useQueryClient();
  const { openModal, closeModal } = useModal();

  /** voice 전체 조회 */
  const { data: voices } = useQuery({
    queryKey: ['/voice/all'] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ data: Voice[] }>(path, { signal }),
    select: ({ data: { data } }) => data,
  });
  invariant(voices, 'Invalid voice data');

  /* 보이스 계약 수정 */
  const { mutate: updateContract, isLoading: isUpdating } = useMutation({
    onError: (e) => {
      const message = repositoryUtils.getErrorMessage(e);
      openModal(<AlertDialog title="에러" message={message} />);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['/voice-contract'] });
      openModal(
        <AlertDialog title="알림" message="보이스 계약이 수정되었습니다." />,
      );
    },
    mutationFn: () => {
      const { current: form } = formRef;
      invariant(form, 'form is not mounted.');
      const formData = new FormData(form);
      const payload = {
        voiceId: props.currentLineValue.voiceId,
        serviceCode: props.currentLineValue.serviceCode,
        planName: props.currentLineValue.planName,
        pricePerCharacters: parseFloat(
          formData.get('pricePerCharacters') as string,
        ),
        settlementRatio:
          parseFloat(formData.get('settlementRatio') as string) / 100 ||
          props.currentLineValue.settlementRatio,
        dubbingTimes: parseFloat(formData.get('dubbingTimes') as string),
      };
      return repositoryClient.put('/voice-contract', payload);
    },
  });

  /* 보이스 계약 삭제 */
  const { mutate: deleteContract } = useMutation({
    onError: (e) => {
      const message = repositoryUtils.getErrorMessage(e);
      openModal(<AlertDialog title="에러" message={message} />);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['/voice-contract'] });
      openModal(
        <AlertDialog title="알림" message="보이스 계약이 삭제되었습니다." />,
      );
    },
    mutationFn: (contractId: number) =>
      repositoryClient.delete(`/voice-contract/${contractId}`),
  });

  return (
    <Dialog title="보이스 계약 수정">
      <StyledForm
        ref={formRef}
        onSubmit={(e) => {
          e.preventDefault();
          updateContract();
        }}
        css={css`
          width: 400px;
          padding: 24px;
        `}
      >
        <StyledLabel>
          보이스 ID
          <StyledInput
            disabled
            defaultValue={props.currentLineValue.voiceId.toString()}
          />
        </StyledLabel>
        <StyledLabel>
          서비스 코드
          <StyledInput
            disabled
            defaultValue={props.currentLineValue.serviceCode}
          />
        </StyledLabel>
        <StyledLabel>
          요금제
          <StyledInput
            disabled
            defaultValue={props.currentLineValue.planName}
          />
        </StyledLabel>
        <StyledLabel>
          자당 가격(차감자수)
          <StyledNumberInput
            name="pricePerCharacters"
            type="number"
            min={0.0}
            step="0.1"
            defaultValue={props.currentLineValue.pricePerCharacters}
          />
        </StyledLabel>

        <div>
          <StyledLabel>
            특별 보이스 정산 비율 (%)
            <StyledNumberInput
              disabled={props.currentLineValue.serviceCode === '001WEB001'}
              name="settlementRatio"
              type="number"
              min={0}
              max={100}
              step="1"
              defaultValue={props.currentLineValue.settlementRatio * 100}
            />
          </StyledLabel>
          <p
            css={css`
              ${style.font['text-s']}
              color:${style.color.highlight1};
            `}
          >
            {props.currentLineValue.serviceCode === '001WEB001'
              ? '* 서비스 코드 001WEB001은 정산 비율이 100% 로 고정됩니다.'
              : ''}
            {props.currentLineValue.serviceCode !== '001WEB001'
              ? '* 정산 비율은 100% 를 초과할 수 없습니다.'
              : ''}
          </p>
        </div>
        <StyledLabel>
          음성 변조 배수
          <StyledNumberInput
            name="dubbingTimes"
            type="number"
            min={0.0}
            step="0.1"
            defaultValue={props.currentLineValue.dubbingTimes}
          />
        </StyledLabel>
        <div
          css={css`
            display: flex;
            justify-content: space-between;
          `}
        >
          <Button
            baseColor={utils.style.color.highlight1}
            onClick={() => {
              // deleteContract(props.currentLineValue.contractId);
              openModal(
                <PromptDialog
                  title="안내"
                  message="정말로 삭제하시겠습니까?"
                  onConfirm={() =>
                    deleteContract(props.currentLineValue.contractId)
                  }
                />,
              );
            }}
          >
            삭제
          </Button>
          <div
            css={css`
              display: flex;
              width: 180px;
              justify-content: space-between;
            `}
          >
            <Button form disabled={isUpdating}>
              저장
            </Button>
            <Button
              onClick={closeModal}
              textColor={utils.style.color.grey500}
              baseColor={utils.style.color.grey200}
            >
              취소
            </Button>
          </div>
        </div>
      </StyledForm>
    </Dialog>
  );
};
