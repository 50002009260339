import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { PromptDialog } from '@admin/components/Dialog/PromptDialog';
import { InputDateRange } from '@admin/components/InputDateRange';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledForm } from '@admin/components/Styled/Form';
import { StyledH1 } from '@admin/components/Styled/H1';
import { repositoryClient } from '@admin/repository';
import { DISPLAY_NAMES_PLAN } from '@admin/repository/constant';
import type { Member } from '@admin/repository/types';
import { repositoryUtils } from '@admin/repository/utils';
import { utils } from '@admin/utils';
import { invariant } from '@admin/utils/invariant';
import utilTime from '@admin/utils/utilTime';
import { css } from '@emotion/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash-es';
import { useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import type { UpdateSubscriptionPayload } from '../type';

export const SubscriptionForm = () => {
  const { email: userEmail } = useParams();

  const [searchParams] = useSearchParams();

  const serviceCode = searchParams.get('service-code');

  const { openModal } = useModal();

  const query = useQuery({
    queryKey: ['/member/single', { serviceCode, userEmail }] as const,
    queryFn: ({ queryKey: [path, { serviceCode, userEmail }], signal }) =>
      repositoryClient.get<{ data: Member }>(`${path}/${userEmail}`, {
        params: { serviceCode },
        signal,
      }),
    select: ({ data: { data } }) => {
      const { currentSubscription } = data;
      if (!currentSubscription.uid) {
        throw new Error('invalid subscription uid');
      }
      return {
        ...currentSubscription,
        since: utilTime.format(Date.parse(currentSubscription.since), true),
        till: utilTime.format(Date.parse(currentSubscription.till), true),
      };
    },
  });

  invariant(query.data, 'Invalid query data.');

  const [payload, _setPayload] = useState<Readonly<UpdateSubscriptionPayload>>({
    addedCharactersCount: query.data.addedCharactersCount,
    enableCustomVoiceCount: query.data.enableCustomVoiceCount,
    nextPlanName: query.data.nextPlan.name,
    planName: query.data.plan.name,
    since: query.data.since,
    till: query.data.till,
    usedCharactersCount: query.data.usedCharactersCount,
  });

  const setPayload = <K extends keyof UpdateSubscriptionPayload>(
    key: K,
    value: UpdateSubscriptionPayload[K],
  ) => _setPayload((prev) => prev && { ...prev, [key]: value });

  const changedFields = useMemo<Partial<UpdateSubscriptionPayload>>(() => {
    const { data, status } = query;
    if (!payload || status !== 'success') {
      return {};
    }
    return {
      ...(payload.addedCharactersCount !== data.addedCharactersCount && {
        addedCharactersCount: payload.addedCharactersCount,
      }),
      ...(payload.enableCustomVoiceCount !== data.enableCustomVoiceCount && {
        enableCustomVoiceCount: payload.enableCustomVoiceCount,
      }),
      ...(payload.nextPlanName !== data.nextPlan.name && {
        nextPlanName: payload.nextPlanName,
      }),
      ...(payload.planName !== data.plan.name && {
        planName: payload.planName,
      }),
      ...(payload.since !== data.since && { since: payload.since }),
      ...(payload.till !== data.till && { till: payload.till }),
      ...(payload.usedCharactersCount !== data.usedCharactersCount && {
        usedCharactersCount: payload.usedCharactersCount,
      }),
    };
  }, [payload, query]);

  const queryClient = useQueryClient();

  const { mutate: save, isLoading: isSaving } = useMutation({
    onError: (e) => {
      openModal(
        <AlertDialog
          title="에러"
          message={repositoryUtils.getErrorMessage(e)}
        />,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['/member/single', { serviceCode, userEmail }],
      });
      openModal(
        <AlertDialog
          title="알림"
          message="구독 정보가 성공적으로 변경되었습니다."
        />,
      );
    },
    mutationFn: () => {
      if (!serviceCode) {
        throw new Error('invalid service code');
      }
      if (!userEmail) {
        throw new Error('invalid email');
      }
      if (!payload) {
        throw new Error('invalid payload');
      }
      if (!query.data?.uid) {
        throw new Error('invalid subscription uid');
      }
      const currentDate = new Date();
      const sinceDate = new Date(payload.since);
      const tillDate = new Date(payload.till);
      sinceDate.setHours(0, 0, 0, 0);
      tillDate.setHours(0, 0, 0, 0);
      if (currentDate < sinceDate) {
        throw new Error('시작 날짜는 현재 날짜보다 이전이어야 합니다.');
      }
      return repositoryClient.patch(
        `/subscriptions/${query.data.uid}`,
        changedFields,
        { params: { serviceCode } },
      );
    },
  });

  return (
    <ContentBox
      css={css`
        gap: 1rem;
      `}
    >
      <StyledH1>구독 정보</StyledH1>
      <StyledForm
        css={css`
          display: flex;
          flex-direction: column;
          gap: 20px;
        `}
        onSubmit={(e) => {
          e.preventDefault();
          openModal(
            <PromptDialog
              title="구독 정보 변경"
              message="변경 사항을 저장하시겠습니까?"
              onConfirm={save}
            />,
          );
        }}
      >
        <label htmlFor="subscription_date">
          구독 기간
          <InputDateRange
            value={{
              start: new Date(payload.since),
              end: new Date(payload.till),
            }}
            onChange={({ start, end }) => {
              setPayload('since', utilTime.format(start.getTime(), true));
              setPayload('till', utilTime.format(end.getTime(), true));
            }}
          />
        </label>

        <label>
          현재 요금제
          <select
            disabled={query.data.plan.name === 'Deferred'} // 후불 요금제
            value={payload.planName}
            onChange={({ currentTarget: { value } }) =>
              setPayload('planName', value)
            }
          >
            {query.data.plan.name === 'Deferred' ? (
              <option>후불 요금제</option>
            ) : (
              Object.entries(DISPLAY_NAMES_PLAN).map(([key, displayName]) => (
                <option key={key} value={key}>
                  {displayName}
                </option>
              ))
            )}
          </select>
        </label>

        {/* TODO: disable 시키는 조건 확인 필요 */}
        <label>
          다음 예약된 요금제
          <select
            disabled={query.data.nextPlan.name === 'Deferred'} // 후불 요금제
            value={payload.nextPlanName}
            onChange={({ currentTarget: { value } }) =>
              setPayload('nextPlanName', value)
            }
          >
            {query.data.nextPlan.name === 'Deferred' ? (
              <option>후불 요금제</option>
            ) : (
              Object.entries(DISPLAY_NAMES_PLAN).map(([key, displayName]) => (
                <option key={key} value={key}>
                  {displayName}
                </option>
              ))
            )}
          </select>
          <p
            css={css`
              color: ${utils.style.color.grey500};
            `}
          >
            갱신일:{' '}
            {(() => {
              const tillDate = new Date(query.data.till);
              const nextDay = new Date(
                tillDate.setDate(tillDate.getDate() + 1),
              );
              return nextDay.toLocaleDateString('ko-KR', { dateStyle: 'full' });
            })()}
          </p>
        </label>

        <label>
          최대 글자 수
          <input
            disabled
            type="number"
            min={0}
            step={1}
            defaultValue={query.data.maxCharactersCount}
          />
        </label>

        <label>
          사용 가능 글자 수
          <input
            disabled
            type="number"
            min={0}
            step={1}
            defaultValue={query.data.enableCharactersCount}
          />
        </label>

        <label>
          추가 글자 수
          <input
            type="number"
            min={0}
            step={1}
            value={payload.addedCharactersCount}
            onChange={({ currentTarget: { value } }) =>
              setPayload('addedCharactersCount', Number(value))
            }
          />
        </label>

        <label>
          사용한 글자 수
          <input
            type="number"
            min={0}
            step={1}
            value={payload.usedCharactersCount}
            onChange={({ currentTarget: { value } }) =>
              setPayload('usedCharactersCount', Number(value))
            }
          />
        </label>

        <label>
          남은 마이 AI 보이스
          <input
            type="number"
            min={0}
            step={1}
            value={payload.enableCustomVoiceCount}
            onChange={({ currentTarget: { value } }) =>
              setPayload('enableCustomVoiceCount', Number(value))
            }
          />
        </label>

        <Button form disabled={isSaving || _.isEmpty(changedFields)}>
          저장
        </Button>
      </StyledForm>
    </ContentBox>
  );
};
