import { ContentBox } from '@admin/components/ContentBox';
import { StyledForm } from '@admin/components/Styled/Form';
import { StyledH1 } from '@admin/components/Styled/H1';
import { repositoryClient } from '@admin/repository';
import type { Member } from '@admin/repository/types';
import { invariant } from '@admin/utils/invariant';
import utilTime from '@admin/utils/utilTime';
import { css } from '@emotion/react';
import { useQuery } from '@tanstack/react-query';
import { useParams, useSearchParams } from 'react-router-dom';

export const MemberInfo = () => {
  const [searchParams] = useSearchParams();

  const serviceCode = searchParams.get('service-code');

  const { email: userEmail } = useParams();

  const query = useQuery({
    queryKey: ['/member/single', { serviceCode, userEmail }] as const,
    queryFn: ({ queryKey: [path, { serviceCode, userEmail }], signal }) =>
      repositoryClient.get<{ data: Member }>(`${path}/${userEmail}`, {
        params: { serviceCode },
        signal,
      }),
    select: ({ data: { data } }) => ({
      creatorEmail: data.creatorEmail,
      email: data.email,
      passwordExpiredAt: utilTime.toLocalString(data.passwordExpiredAt),
      provider: data.provider || '-',
      registerDate: utilTime.toLocalString(data.registerDate),
      serviceCode: data.serviceCode,
      updaterEmail: data.updaterEmail,
    }),
  });

  invariant(query.data, 'Invalid query data.');

  return (
    <ContentBox
      css={css`
        gap: 1rem;
      `}
    >
      <StyledH1>유저 정보</StyledH1>
      <StyledForm>
        <label>
          이메일
          <input disabled defaultValue={query.data.email} />
        </label>

        <label>
          소셜 인증 플랫폼
          <input disabled defaultValue={query.data.provider} />
        </label>

        <label>
          가입일시
          <input
            disabled
            type="datetime-local"
            defaultValue={query.data.registerDate}
          />
        </label>

        <label>
          패스워드 만료 날짜
          <input
            disabled
            type="datetime-local"
            defaultValue={query.data.passwordExpiredAt}
          />
        </label>

        <label>
          생성자
          <input disabled defaultValue={query.data.creatorEmail} />
        </label>

        <label>
          갱신자
          <input disabled defaultValue={query.data.updaterEmail} />
        </label>
      </StyledForm>
    </ContentBox>
  );
};
