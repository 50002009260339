import { Button } from '@admin/components/Button';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { LoadingDialog } from '@admin/components/Dialog/LoadingDialog';
import { useModal } from '@admin/components/Modal/hooks';
import { repositoryClient } from '@admin/repository';
import { repositoryUtils } from '@admin/repository/utils';
import { TestSendDialog } from '@admin/routes/email/create/Dialog/TestSendDialog';
import { buildFormData } from '@admin/routes/email/create/utils';
import { utils } from '@admin/utils';
import { useMutation } from '@tanstack/react-query';
import type { Design, EditorRef } from 'react-email-editor';

interface Props {
  propsObject: {
    emailTitle: string;
    fileRef: React.MutableRefObject<File | undefined>;
    mailEditorRef: React.RefObject<EditorRef>;
    editorLoaded: boolean;
  };
}

export const TestEmailButton = (property: Props) => {
  const { openModal, closeModal } = useModal();
  const props = property.propsObject;
  const testEmail = useMutation({
    mutationFn: async ({
      htmlString,
      design,
      testAddress,
    }: {
      htmlString: string;
      design: Design;
      testAddress: string;
    }) => {
      const { formData } = buildFormData(
        htmlString,
        design,
        [testAddress],
        props.emailTitle,
        props.fileRef,
      );
      return repositoryClient.post('/admin-mail', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    },
    onMutate: () => {
      openModal(
        <LoadingDialog title="알림" message="테스트 메일 전송 중입니다." />,
      );
    },
    onError: (e) => {
      closeModal();
      openModal(
        <AlertDialog
          title="에러"
          message={repositoryUtils.getErrorMessage(e)}
        />,
      );
    },
    onSuccess: () => {
      closeModal();
      openModal(
        <AlertDialog
          title="알림"
          message="테스트 메일 전송에 성공하였습니다."
        />,
      );
    },
  });

  return (
    <Button
      disabled={!props.editorLoaded}
      onClick={() => {
        if (props.emailTitle.length === 0) {
          openModal(
            <AlertDialog title="에러" message="제목을 입력해주세요." />,
          );
          return;
        }
        openModal(
          <TestSendDialog
            onConfirm={(testAddress) => {
              if (props.mailEditorRef.current === null) {
                throw new Error('메일 에디터가 로드되지 않았습니다.');
              }
              props.mailEditorRef.current.exportHtml(
                ({ html: htmlString, design }) => {
                  testEmail.mutate({
                    htmlString,
                    design,
                    testAddress,
                  });
                },
                {
                  cleanup: true,
                  minify: true,
                },
              );
            }}
          />,
        );
      }}
      baseColor={utils.style.color.subBlack}
    >
      테스트 발송
    </Button>
  );
};
