import { ReactComponent as CloseIcon } from '@admin/assets/icon/close.svg';
import type { Tag } from '@admin/repository/types';
import { voiceTagFormAtoms } from '@admin/routes/voice/edit/TagForm/atom';
import { utils } from '@admin/utils';
import { css } from '@emotion/react';
import _ from 'lodash-es';
import { useSetRecoilState } from 'recoil';

export const TagLabel = (props: { tag: Tag }) => {
  const setSelectedTags = useSetRecoilState(voiceTagFormAtoms['selected-tags']);

  return (
    <div
      css={css`
        flex-shrink: 0;

        background: ${utils.style.color.primary};
        border-radius: 4px;

        display: flex;
        align-items: center;
        padding: 4px;
      `}
    >
      <CloseIcon
        css={css`
          cursor: pointer;
        `}
        fill="#ffffff"
        onClick={() =>
          setSelectedTags((prev) => _.differenceBy(prev, [props.tag], 'id'))
        }
      />

      <span
        css={css`
          margin: 0 4px;
          color: ${utils.style.color.white};
        `}
      >
        {props.tag.tagLabel}
      </span>
    </div>
  );
};
