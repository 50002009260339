import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { StyledH1 } from '@admin/components/Styled/H1';
import { StyledTable } from '@admin/components/Styled/Table';
import { repositoryClient } from '@admin/repository';
import { DISPLAY_NAMES_TERMS } from '@admin/repository/constant';
import type { Terms } from '@admin/repository/types';
import { utils } from '@admin/utils';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

export const TermsListPage = () => {
  const navigate = useNavigate();

  const { data: termsList } = useQuery({
    queryKey: ['/terms'] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ data: Terms[] }>(path, { signal }),
    select: ({ data: { data } }) => data,
  });

  invariant(termsList, 'Invalid terms data.');

  return (
    <ContentBox
      css={css`
        gap: 20px;
        height: 100%;
      `}
    >
      <StyledH1>약관 및 정책</StyledH1>

      <div
        css={css`
          height: 100%;
          overflow: auto;
        `}
      >
        <StyledTable>
          <thead>
            <tr>
              <th>구분</th>
              <th>영문 구분</th>
              <th>파일 이름</th>
              <th>업로드 / 히스토리</th>
            </tr>
          </thead>
          <tbody>
            {termsList.map(({ termsId, termsName, fileName }) => (
              <tr key={termsId}>
                <td>{DISPLAY_NAMES_TERMS[termsName]}</td>
                <td>{termsName}</td>
                <td>{fileName}</td>
                <td>
                  <div
                    css={css`
                      display: flex;
                      justify-content: center;
                      gap: 10px;
                    `}
                  >
                    <Button
                      baseColor={utils.style.color.subBlack}
                      onClick={() => navigate(`./upload/${termsId}`)}
                    >
                      업로드
                    </Button>
                    <Button
                      baseColor={utils.style.color.subBlack}
                      onClick={() => navigate(`./history/${termsId}`)}
                    >
                      히스토리
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </StyledTable>
      </div>
    </ContentBox>
  );
};
