import { utils } from '@admin/utils';
import { css } from '@emotion/react';

import { StyledForm } from './Styled/Form';

export const Search = (props: {
  className?: string;
  defaultValue?: string;
  placeholder?: string;
  onSubmit: (searchString: string) => void;
}) => {
  const handleSubmit = (formData: FormData) => {
    const searchString = formData.get('search');
    if (searchString === null) {
      return;
    }
    props.onSubmit(searchString as string);
  };

  return (
    <StyledForm
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(new FormData(e.currentTarget));
      }}
    >
      <input
        name="search"
        className={props.className}
        css={css`
          width: 300px;
          ::placeholder {
            color: ${utils.style.color.black};
          }

          &:focus {
            ::placeholder {
              color: transparent;
            }
          }
        `}
        type="search"
        defaultValue={props.defaultValue}
        placeholder={props.placeholder ?? '검색'}
      />
    </StyledForm>
  );
};
