import { InputDateRange } from '@admin/components/InputDateRange';
import { Search } from '@admin/components/Search';
import { StyledSelect } from '@admin/components/Styled/Select';
import { VerticalDivider } from '@admin/components/VerticalDivider';
import { utils } from '@admin/utils';
import { css } from '@emotion/react';
import { format, subDays } from 'date-fns';
import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { inquiryConstants } from '../../constants';

const { categories, status } = inquiryConstants;

const searchColumns = {
  title: '제목', // 기본값
  name: '고객명',
  email: '이메일',
} as const;

export const InquiryFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const dateRange = useMemo(() => {
    const since = searchParams.get('since');
    const till = searchParams.get('till');
    return {
      start: since ? new Date(since) : subDays(new Date(), 7),
      end: till ? new Date(till) : new Date(),
    };
  }, [searchParams]);

  useEffect(() => {
    setSearchParams((prev) => {
      const params = new URLSearchParams(prev);
      if (!params.get('since')) {
        params.set('since', format(dateRange.start, 'yyyy-MM-dd'));
      }
      if (!params.get('till')) {
        params.set('till', format(dateRange.end, 'yyyy-MM-dd'));
      }
      if (!params.get('c')) {
        params.set('c', 'title');
      }
      return params;
    });
  }, [dateRange.end, dateRange.start, setSearchParams]);

  return (
    <>
      <InputDateRange
        enableShortcuts
        value={dateRange}
        onChange={({ end, start }) =>
          setSearchParams((prev) => {
            const params = new URLSearchParams(prev);
            params.set('since', format(start, 'yyyy-MM-dd'));
            params.set('till', format(end, 'yyyy-MM-dd'));
            return params;
          })
        }
      />

      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 16px;
        `}
      >
        <div
          css={css`
            ${utils.style.font['text-s']};
            flex-shrink: 0;
            user-select: none;
            display: flex;
            flex-direction: column;
            gap: 4px;
          `}
        >
          답변 상태
          <span
            css={css`
              display: flex;
              gap: 4px;
              & > label {
                display: flex;
                align-items: center;
                gap: 4px;
              }
            `}
          >
            <label>
              <input
                type="radio"
                name="status"
                checked={!searchParams.get('status')}
                onChange={({ currentTarget: { checked } }) =>
                  checked &&
                  setSearchParams((prev) => {
                    const params = new URLSearchParams(prev);
                    params.delete('status');
                    return params;
                  })
                }
              />
              전체
            </label>

            {Object.entries(status).map(([k, v]) => (
              <label key={k}>
                <input
                  type="radio"
                  name="status"
                  checked={k === searchParams.get('status')}
                  onChange={({ currentTarget: { checked } }) =>
                    checked &&
                    setSearchParams((prev) => {
                      const params = new URLSearchParams(prev);
                      params.set('status', k);
                      return params;
                    })
                  }
                />
                {v}
              </label>
            ))}
          </span>
        </div>

        <StyledSelect
          value={searchParams.get('category') ?? ''}
          onChange={({ target: { value } }) =>
            setSearchParams((prev) => {
              const params = new URLSearchParams(prev);
              if (value) {
                params.set('category', value);
              } else {
                params.delete('category');
              }
              return params;
            })
          }
        >
          <option value="">전체</option>
          {Object.entries(categories).map(([k, v]) => (
            <option key={k} value={k}>
              {v}
            </option>
          ))}
        </StyledSelect>

        <VerticalDivider />

        <div
          css={css`
            ${utils.style.font['text-s']};
            user-select: none;
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            gap: 4px;
          `}
        >
          검색 타입
          <span
            css={css`
              display: flex;
              gap: 4px;
            `}
          >
            {Object.entries(searchColumns).map(([k, v]) => (
              <label
                key={k}
                css={css`
                  display: flex;
                  align-items: center;
                  gap: 4px;
                `}
              >
                <input
                  type="radio"
                  name="searchColumn"
                  checked={k === searchParams.get('c')}
                  onChange={({ currentTarget: { checked } }) =>
                    checked &&
                    setSearchParams((prev) => {
                      const params = new URLSearchParams(prev);
                      params.set('c', k);
                      return params;
                    })
                  }
                />
                {v}
              </label>
            ))}
          </span>
        </div>

        <Search
          defaultValue={searchParams.get('q') ?? ''}
          onSubmit={(search) =>
            setSearchParams((prev) => {
              const params = new URLSearchParams(prev);
              params.set('q', search);
              return params;
            })
          }
        />
      </div>
    </>
  );
};
