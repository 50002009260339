import { repositoryClient } from '@admin/repository';
import Cookies from 'js-cookie';
import _ from 'lodash-es';

import type { Token } from './types';

const accessTokenKey = 'AAT_VOS';

const refreshTokenKey = 'ART_VOS';

const attributes: Cookies.CookieAttributes = {
  domain: import.meta.env.VITE_COOKIE_DOMAIN,
} as const;

let reissuePromise: Promise<void> | undefined;

const onTokensChangedCallbacks = new Set<(tokens: Token) => void>();

let currentTokens: Token = {
  accessToken: Cookies.get(accessTokenKey),
  refreshToken: Cookies.get(refreshTokenKey),
};

const update = () => {
  const tokens: Token = {
    accessToken: Cookies.get(accessTokenKey),
    refreshToken: Cookies.get(refreshTokenKey),
  };
  if (!_.isEqual(currentTokens, tokens)) {
    currentTokens = tokens;
    for (const callback of onTokensChangedCallbacks) {
      callback(tokens);
    }
  }
};

const tryReissue = () => {
  const { accessToken, refreshToken } = currentTokens;
  if (!accessToken || !refreshToken) {
    return undefined;
  }
  try {
    return repositoryClient
      .post<{
        data: {
          access_token: string;
          extensions: { expirationTime: number };
          is_need_to_change_password: boolean;
          refresh_token: string;
        };
      }>('/token/reissue', { refresh_token: refreshToken })
      .then(({ data: { data } }) => data);
  } catch {
    return undefined;
  }
};

export default {
  get(): Token {
    update();
    return currentTokens;
  },

  set(tokens: Required<Token>): void {
    Cookies.set(accessTokenKey, tokens.accessToken, attributes);
    Cookies.set(refreshTokenKey, tokens.refreshToken, attributes);
    update();
  },

  clear(): void {
    Cookies.remove(accessTokenKey, attributes);
    Cookies.remove(refreshTokenKey, attributes);
    update();
  },

  subscribe(callback: (tokens: Token) => void): () => void {
    onTokensChangedCallbacks.add(callback);
    return () => {
      onTokensChangedCallbacks.delete(callback);
    };
  },

  reissue(): Promise<void> {
    if (reissuePromise) {
      return reissuePromise;
    }
    reissuePromise = (async () => {
      try {
        // 재발급 시도
        const reissueResult = await tryReissue();
        if (reissueResult) {
          const { access_token, refresh_token } = reissueResult;
          this.set({ accessToken: access_token, refreshToken: refresh_token });
          return;
        }
        this.clear(); // 토큰 만료 처리
        throw new Error('토큰 재발급 실패');
      } finally {
        reissuePromise = undefined;
      }
    })();
    return reissuePromise;
  },
} as const;
