import { StyledH1 } from '@admin/components/Styled/H1';
import { StyledTable } from '@admin/components/Styled/Table';
import type { ServiceCodeStatisticsType } from '@admin/routes/service-code/statistics/context/type';
import { css } from '@emotion/react';

export const ServiceCodeStatistics = ({
  serviceCodeStatistics,
}: {
  serviceCodeStatistics: ServiceCodeStatisticsType;
}) => {
  return (
    <>
      <StyledH1>통계</StyledH1>

      <div
        css={css`
          height: 120px;
          overflow: auto;
        `}
      >
        <StyledTable>
          <thead>
            <tr>
              <th>전체 사용 글자 수</th>
              <th>일반 보이스 청구 금액</th>
              <th>특별 보이스 청구 금액</th>
              <th>마이 AI 보이스 청구 금액</th>
              <th>총 청구 금액</th>
              <th>마이 AI 보이스 학습 수</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{serviceCodeStatistics.allTextCount.toLocaleString()}자</td>
              <td>
                {serviceCodeStatistics.normalBillingAmount.toLocaleString()}원
              </td>
              <td>
                {serviceCodeStatistics.specialBillingAmount.toLocaleString()}원
              </td>
              <td>
                {serviceCodeStatistics.customVoiceBillingAmount.toLocaleString()}
                원
              </td>
              <td>
                {serviceCodeStatistics.allBillingAmount.toLocaleString()}원
              </td>
              <td>
                {serviceCodeStatistics.customVoiceCount.toLocaleString()}개
              </td>
            </tr>
          </tbody>
        </StyledTable>
      </div>
    </>
  );
};
