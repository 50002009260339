import { Button } from '@admin/components/Button';
import { Dialog } from '@admin/components/Dialog';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledForm } from '@admin/components/Styled/Form';
import { utils } from '@admin/utils';
import { css } from '@emotion/react';
import { format } from 'date-fns';

export const ScheduleSendDialog = (props: {
  onConfirm: (datetime: Date) => void;
}) => {
  const { closeModal } = useModal();

  return (
    <Dialog title="예약 발송 시간 지정">
      <StyledForm
        onSubmit={(e) => {
          e.preventDefault();
          const formData = new FormData(e.currentTarget);
          props.onConfirm(new Date(formData.get('datetime') as string));
          closeModal();
        }}
        css={css`
          width: 400px;
          padding: 24px;
        `}
      >
        <input
          required
          name="datetime"
          type="datetime-local"
          min={format(Date.now(), "yyyy-MM-dd'T'HH:mm")}
        />

        <div
          css={css`
            width: 100%;
            display: flex;
            justify-content: flex-end;
            gap: 16px;
          `}
        >
          <Button form>예약</Button>

          <Button
            onClick={closeModal}
            textColor={utils.style.color.grey500}
            baseColor={utils.style.color.grey200}
          >
            취소
          </Button>
        </div>
      </StyledForm>
    </Dialog>
  );
};
