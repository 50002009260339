import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledForm } from '@admin/components/Styled/Form';
import { StyledH1 } from '@admin/components/Styled/H1';
import { repositoryClient } from '@admin/repository';
import { DISPLAY_NAMES_ROLE } from '@admin/repository/constant';
import type { Member, ServiceCodeMetaData } from '@admin/repository/types';
import { repositoryUtils } from '@admin/repository/utils';
import { invariant } from '@admin/utils/invariant';
import { routeName } from '@admin/utils/routeName';
import { css } from '@emotion/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useLayoutEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const UserCreatePage = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const { openModal } = useModal();

  const formRef = useRef<HTMLFormElement>(null);

  const [
    emailFieldKey,
    passwordFieldKey,
    passwordConfirmFieldKey,
    nameFieldKey,
    phoneFieldKey,
    serviceCodeFieldKey,
    roleFieldKey,
  ] = [
    'email',
    'password',
    'password-confirm',
    'name',
    'phone',
    'service-code',
    'role',
  ];

  useLayoutEffect(() => {
    const { current: form } = formRef;
    if (!form) {
      return;
    }
    const { firstElementChild } = form;
    if (firstElementChild instanceof HTMLLabelElement) {
      firstElementChild.focus();
    }
  }, []);

  const { data: serviceCodeList } = useQuery({
    queryKey: ['/crm/service-code'] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ data: ServiceCodeMetaData[] }>(path, {
        signal,
      }),
    select: ({ data: { data } }) => data,
  });

  invariant(serviceCodeList, 'Invalid service code data list.');

  const queryClient = useQueryClient();

  const { mutate: create, isLoading: isCreating } = useMutation({
    onError: (e) => {
      // 포커스 해제
      for (const el of formRef.current?.elements ?? []) {
        if (el instanceof HTMLInputElement) {
          el.blur();
        }
      }
      openModal(
        <AlertDialog
          title="에러"
          message={repositoryUtils.getErrorMessage(e)}
        />,
      );
    },
    onSuccess: ({ data: { data } }) => {
      queryClient.invalidateQueries({ queryKey: ['/member/all'] });
      formRef.current?.reset();
      openModal(
        <AlertDialog
          title="알림"
          message={`${data.email} 유저가 성공적으로 생성되었습니다.`}
        />,
      );
      navigate(
        `/${routeName.user}/edit/${data.email}?service-code=${data.serviceCode}`,
      );
    },
    mutationFn: async () => {
      const { current: form } = formRef;
      invariant(form, 'form is not mounted.');
      const formData = new FormData(form);
      const payload = {
        email: formData.get(emailFieldKey) as string,
        serviceCode: formData.get(serviceCodeFieldKey) as string,
        role: formData.get(roleFieldKey) as string,
        name: formData.get(nameFieldKey) as string,
        password: formData.get(passwordFieldKey) as string,
        phone: formData.get(phoneFieldKey) as string,
      };
      const passwordConfirm = formData.get(passwordConfirmFieldKey) as string;
      const isServiceCodeValid = serviceCodeList.some(
        ({ serviceCodeName }) => serviceCodeName === payload.serviceCode,
      );
      invariant(
        payload.password === passwordConfirm,
        '패스워드 확인이 일치하지 않습니다.',
      );
      invariant(
        isServiceCodeValid,
        `유효하지 않은 서비스 코드입니다: ${payload.serviceCode}`,
      );
      return repositoryClient.post<{
        data: Pick<Member, 'email' | 'serviceCode'>;
      }>('/member/add', {
        ...payload,
        password: await repositoryUtils.encryptRawPassword(payload.password),
      });
    },
  });

  return (
    <ContentBox
      css={css`
        gap: 24px;
      `}
    >
      <StyledH1>유저 생성</StyledH1>

      <StyledForm
        ref={formRef}
        onSubmit={(e) => {
          e.preventDefault();
          create();
        }}
      >
        <label>
          이메일
          <input required autoComplete="username" name={emailFieldKey} />
        </label>

        <label htmlFor={passwordFieldKey}>
          패스워드
          <input
            required
            type="password"
            autoComplete="new-password"
            name={passwordFieldKey}
          />
        </label>

        <label>
          패스워드 확인
          <input
            required
            type="password"
            autoComplete="new-password"
            name={passwordConfirmFieldKey}
          />
        </label>

        <label>
          이름
          <input required name={nameFieldKey} />
        </label>

        <label>
          전화번호
          <input required name={phoneFieldKey} />
        </label>

        <label>
          서비스 코드
          <select
            required
            name={serviceCodeFieldKey}
            defaultValue={searchParams.get('service-code') ?? ''}
          >
            <option hidden value="">
              선택
            </option>
            {serviceCodeList.map(({ serviceCodeName }) => (
              <option key={serviceCodeName} value={serviceCodeName}>
                {serviceCodeName}
              </option>
            ))}
          </select>
        </label>

        <label>
          권한
          <select required name={roleFieldKey}>
            {Object.entries(DISPLAY_NAMES_ROLE).map(([key, displayName]) => (
              <option key={key} value={key}>
                {displayName}
              </option>
            ))}
          </select>
        </label>

        <Button
          form
          disabled={isCreating}
          css={css`
            align-self: flex-start;
          `}
        >
          생성
        </Button>
      </StyledForm>
    </ContentBox>
  );
};
