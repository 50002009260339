import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { PromptDialog } from '@admin/components/Dialog/PromptDialog';
import { Divider } from '@admin/components/Divider';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledLabel } from '@admin/components/Styled/Label';
import { repositoryClient } from '@admin/repository';
import { repositoryUtils } from '@admin/repository/utils';
import { utils } from '@admin/utils';
import { useFileBrowser } from '@admin/utils/hooks';
import { invariant } from '@admin/utils/invariant';
import { Components } from '@ai-voice-studio/shared';
import { css } from '@emotion/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { mailTemplateList } from './MailTemplatePage';

export const MailTemplatePreviewPage = () => {
  const { mailTemplateName } = useParams();

  const mailTemplateData = useMemo(
    () =>
      mailTemplateList.find(
        (mailTemplate) => mailTemplate.name === mailTemplateName,
      ),
    [mailTemplateName],
  );

  invariant(
    mailTemplateData,
    `Invalid mail template name: ${mailTemplateName}`,
  );

  const fileBrowser = useFileBrowser();

  const { openModal } = useModal();

  const [selectedFile, selectFile] = useState<File | undefined>();

  const queryClient = useQueryClient();

  const mailTemplateQuery = useQuery({
    queryKey: [
      '/file/static/mail-template',
      { mailTemplateType: mailTemplateData.name },
    ] as const,
    queryFn: async ({ queryKey: [path, params], signal }) =>
      repositoryClient.get<ArrayBuffer>(path, {
        params,
        responseType: 'arraybuffer',
        signal,
      }),
    select: ({ data }) => data,
    staleTime: Infinity,
  });

  invariant(mailTemplateQuery.data, 'Invalid query data.');

  const { mutate: uploadTemplate, isLoading: isUploading } = useMutation({
    onError: (e) => {
      openModal(
        <AlertDialog
          title="에러"
          message={repositoryUtils.getErrorMessage(e)}
        />,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['/file/static/mail-template'],
      });
      selectFile(undefined);
      openModal(
        <AlertDialog
          title="알림"
          message={`${mailTemplateData.label} 메일 템플릿이 성공적으로 업데이트되었습니다.`}
        />,
      );
    },
    mutationFn: () => {
      invariant(selectedFile, '파일 없으면 버튼 비활성화되어야 함.');
      const formData = new FormData();
      formData.append('uploadFile', selectedFile);
      formData.append('mailTemplateType', mailTemplateData.name);
      return repositoryClient.post('/file/static/mail-template', formData);
    },
  });

  return (
    <ContentBox
      css={css`
        gap: 20px;
      `}
    >
      <div
        css={css`
          font-weight: bold;
          font-size: 30px;
        `}
      >
        {mailTemplateData.label} 메일 템플릿
      </div>

      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
        `}
      >
        <Button
          onClick={() => {
            const url = URL.createObjectURL(
              new Blob([mailTemplateQuery.data], { type: 'text/html' }),
            );
            const a = document.createElement('a');
            a.href = url;
            a.download = `${mailTemplateData.name}.html`;
            a.click();
            URL.revokeObjectURL(url);
          }}
        >
          다운로드
        </Button>

        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 20px;
          `}
        >
          <p>{selectedFile ? selectedFile.name : '선택된 파일이 없습니다.'}</p>

          <Button
            onClick={async () => {
              const htmlFile = await fileBrowser
                .pick({ accept: 'text/html' })
                .then((selection) => selection?.item(0));
              if (htmlFile) {
                selectFile(htmlFile);
              }
            }}
          >
            파일 선택
          </Button>

          <Button
            disabled={!selectedFile || isUploading}
            onClick={() =>
              openModal(
                <PromptDialog
                  title={`${mailTemplateData.label} 메일 템플릿 업로드`}
                  message="업로드 시 현재 메일 템플릿이 덮어씌워집니다."
                  onConfirm={uploadTemplate}
                  confirmLabel="업로드"
                />,
              )
            }
          >
            업로드
          </Button>
        </div>
      </div>

      <div
        css={css`
          margin-top: 12px;
        `}
      >
        <Components.HTMLRenderer
          data={mailTemplateQuery.data}
          onLoaded={(contentDocument) => {
            const img = contentDocument.body.querySelector<HTMLImageElement>(
              'img[src="cid:ktLogo"]',
            );
            if (!img) {
              throw new Error('로고 이미지를 찾을 수 없습니다.');
            }
            const { accessToken } = utils.token.get();
            const logoUrl = new URL(
              `${import.meta.env.VITE_API_BASE_URL}/file/static/voices`,
            );
            logoUrl.searchParams.set('Authorization', `Bearer ${accessToken}`);
            if (
              mailTemplateData.name === 'MAIL_AUTH' ||
              mailTemplateData.name === 'MAIL_CHANGE_PW' ||
              mailTemplateData.name === 'MAIL_CUSTOM_VOICE'
            ) {
              logoUrl.searchParams.set('staticType', 'LOGO');
            } else if (mailTemplateData.name === 'MAIL_SUCCESS') {
              logoUrl.searchParams.set('staticType', 'SIGN_UP_LOGO');
            } else {
              throw new Error(
                `알 수 없는 메일 템플릿: ${mailTemplateData.name}`,
              );
            }
            img.src = logoUrl.href;
          }}
        />
      </div>

      <Divider />

      <StyledLabel>
        {mailTemplateData.label} 메일 템플릿 작성시 필요한 구문들
        {mailTemplateData.name === 'MAIL_SUCCESS' && (
          <div>
            {`img 태그의 src 필드에 cid:ktLogo를 넣어서 가입 완료 이미지를 표시할 수 있습니다. ex) <img src="cid:ktLogo" />`}
            <br />
            <br />
            {`일반 텍스트 영역에 [\${name}]을 적으면 사용자 이름으로 대체됩니다. ex) <p>[\${name}]</p>`}
            <br />
            <br />
            {`a 태그의 href 필드에 [\${link}]를 넣어서 이동할 링크로 만들 수 있습니다. ex) <a href="[\${link}]">이동하기</a>`}
          </div>
        )}
        {(mailTemplateData.name === 'MAIL_AUTH' ||
          mailTemplateData.name === 'MAIL_CHANGE_PW') && (
          <div>
            {`img 태그의 src 필드에 cid:ktLogo를 넣어서 로고를 표시할 수 있습니다. ex) <img src="cid:ktLogo" />`}
            <br />
            <br />
            {`일반 텍스트 영역에 [\${name}]을 적으면 사용자 이름으로 대체됩니다. ex) <p>[\${name}]</p>`}
            <br />
            <br />
            {`a 태그의 href 필드에 [\${link}]를 넣어서 이동할 링크로 만들 수 있습니다. ex) <a href="[\${link}]">이동하기</a>`}
          </div>
        )}
        {mailTemplateData.name === 'MAIL_CUSTOM_VOICE' && (
          <div>
            {mailTemplateData.label} 메일 템플릿 작성시 필요한 구문들
            {`img 태그의 src 필드에 cid:ktLogo를 넣어서 로고를 표시할 수 있습니다. ex) <img src="cid:ktLogo" />`}
            <br />
            <br />
            {`일반 텍스트 영역에 [\${name}]을 적으면 보이스 이름으로 대체됩니다. ex) <p>[\${name}]</p>`}
            <br />
            <br />
            {`a 태그의 href 필드에 [\${link}]를 넣어서 이동할 링크로 만들 수 있습니다. ex) <a href="[\${link}]">이동하기</a>`}
          </div>
        )}
        {mailTemplateData.name === 'MAIL_INQUIRY' && (
          <div>
            {`카테고리: [\${category}]`}
            <br />
            <br />
            {`사용자 이메일: [\${email}]`}
            <br />
            <br />
            {`사용자 이름: [\${name}]`}
            <br />
            <br />
            {`사용자 전화번호: [\${phoneNumber}]`}
            <br />
            <br />
            {`문의 제목: [\${title}]`}
            <br />
            <br />
            {`문의 내용: [\${text}]`}
          </div>
        )}
      </StyledLabel>
    </ContentBox>
  );
};
