/* eslint-disable import/no-duplicates */
import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { DatePickerButton } from '@admin/components/DatePicker/DatePickerButton';
import { StyledH1 } from '@admin/components/Styled/H1';
import { css } from '@emotion/react';
import { isBefore } from 'date-fns';
import ko from 'date-fns/esm/locale/ko';
import dayjs from 'dayjs';
import { createElement, useState } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';

import { downloadExcel } from './downloadStat';

registerLocale('ko-KR', ko);

export const MonthlyStatisticsPage = () => {
  const [date, setDate] = useState(new Date());

  return (
    <ContentBox
      css={css`
        height: 100%;
        gap: 24px;
      `}
    >
      <div
        css={css`
          display: flex;
          gap: 32px;
          flex-direction: column;
          justify-content: space-between;
        `}
      >
        <StyledH1>월별 보이스 통계</StyledH1>

        <div
          css={css`
            display: flex;
            gap: 16px;
            flex-direction: column;
          `}
        >
          <StyledH1>통계 일자 선택</StyledH1>

          <ReactDatePicker
            required
            dateFormat="yyyy년 M월"
            showMonthYearPicker
            selected={date}
            onChange={(date) => {
              if (date) setDate(date);
            }}
            locale="ko-KR"
            customInput={createElement(DatePickerButton)}
            filterDate={(date) => isBefore(date, new Date())}
          />

          <StyledH1>통계 출력</StyledH1>

          <div
            css={css`
              display: flex;
              gap: 8px;
              flex-direction: column;
            `}
          >
            <p>전체 보이스 (마이 AI 보이스 제외)</p>
            <Button
              onClick={() =>
                downloadExcel('all', dayjs(date).format('YYYY-MM'))
              }
            >
              Excel로 내보내기
            </Button>
            <p>마이 AI 보이스 통계</p>
            <Button
              onClick={() =>
                downloadExcel('custom', dayjs(date).format('YYYY-MM'))
              }
            >
              Excel로 내보내기
            </Button>
          </div>
        </div>
      </div>
    </ContentBox>
  );
};
