import { Button } from '@admin/components/Button';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { useModal } from '@admin/components/Modal/hooks';
import { utils } from '@admin/utils';
import { useFileBrowser } from '@admin/utils/hooks';
import { css } from '@emotion/react';
import { useRecoilState } from 'recoil';

import { voiceTrainingFormAtoms } from '../../atom';
import type { Line } from '../../utils';

export const LineData = (props: { order: number; line: Line }) => {
  const fileBrowser = useFileBrowser();

  const { openModal } = useModal();

  const [audioData, setAudioData] = useRecoilState(
    voiceTrainingFormAtoms['audio-data'](props.line),
  );

  return (
    <li
      css={css`
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        gap: 12px;
        padding: 16px;
      `}
    >
      <aside
        css={css`
          width: 40px;
          height: 100%;
          border-right: 1px solid ${utils.style.color.grey300};
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        <p>{`${props.order + 1}`}</p>
      </aside>

      <div
        css={css`
          display: flex;
          flex-direction: column;
          flex: 1 0;
        `}
      >
        <p
          css={css`
            margin: 0;
          `}
        >
          {props.line.text}
        </p>

        <p
          css={css`
            margin: 0;
            margin-top: 4px;
            font-size: 12px;
            color: ${utils.style.color.subBlack};
          `}
        >
          언어: {props.line.language}
        </p>

        <p
          css={css`
            margin: 0;
            font-size: 12px;
            font-weight: 600;
            color: ${audioData.file ? '#70AE6E' : utils.style.color.highlight1};
          `}
        >
          {audioData.file
            ? `연결된 음성 파일: ${audioData.file.name}`
            : `연결된 음성 파일이 없습니다: ${props.line.filename}.wav 파일을 선택해주세요.`}
        </p>
      </div>

      <Button
        onClick={async () => {
          const newAudioFile = await fileBrowser
            .pick({ accept: 'audio/wav' })
            .then((selection) => selection?.item(0));
          if (!newAudioFile) {
            return;
          }

          if (!newAudioFile.name.endsWith('.wav')) {
            openModal(
              <AlertDialog
                message="음성 파일은 wav 형식만 지원합니다."
                title="에러"
              />,
            );
            return;
          }

          if (newAudioFile.name !== `${props.line.filename}.wav`) {
            openModal(
              <AlertDialog
                message="파일 이름이 일치하지 않습니다."
                title="에러"
              />,
            );
            return;
          }

          const duration = await new Promise<number>((resolve) => {
            const audio = new Audio(URL.createObjectURL(newAudioFile));
            audio.oncanplay = () => {
              resolve(audio.duration);
              URL.revokeObjectURL(audio.src);
            };
          });

          if (duration > 12) {
            openModal(
              <AlertDialog
                message="12초 이내의 음성 파일만 선택할 수 있습니다."
                title="에러"
              />,
            );
            return;
          }

          setAudioData({ file: newAudioFile });
        }}
      >
        음성 파일 선택
      </Button>
    </li>
  );
};
