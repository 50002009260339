import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledForm } from '@admin/components/Styled/Form';
import { StyledH1 } from '@admin/components/Styled/H1';
import { repositoryClient } from '@admin/repository';
import { repositoryUtils } from '@admin/repository/utils';
import { css } from '@emotion/react';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

export const ResetPasswordForm = () => {
  const [searchParams] = useSearchParams();

  const serviceCode = searchParams.get('service-code');

  const { email: userEmail } = useParams();

  const { openModal } = useModal();

  const [newPassword, setNewPassword] = useState('');

  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');

  const { mutate: resetPassword } = useMutation({
    onError: (e) => {
      openModal(
        <AlertDialog
          title="에러"
          message={repositoryUtils.getErrorMessage(e)}
        />,
      );
    },
    onSuccess: () => {
      openModal(
        <AlertDialog
          title="알림"
          message="패스워드가 성공적으로 재설정되었습니다."
        />,
      );
    },
    onSettled: () => {
      setNewPassword('');
      setNewPasswordConfirm('');
    },
    mutationFn: async () => {
      if (!serviceCode) {
        throw new Error('invalid service code');
      }
      if (!userEmail) {
        throw new Error('invalid email');
      }
      return repositoryClient.patch(
        `/member/${userEmail}`,
        { password: await repositoryUtils.encryptRawPassword(newPassword) },
        { params: { serviceCode } },
      );
    },
  });

  return (
    <ContentBox
      css={css`
        gap: 1rem;
      `}
    >
      <StyledH1>패스워드 재설정</StyledH1>
      <StyledForm
        onSubmit={(e) => {
          e.preventDefault();
          resetPassword();
        }}
      >
        <div
          css={css`
            display: grid;
            grid-template-columns: max-content 1fr;
            gap: 8px;
          `}
        >
          <label htmlFor="new-password">새 패스워드</label>
          <input
            id="new-password"
            type="password"
            autoComplete="off"
            value={newPassword}
            onChange={({ currentTarget: { value } }) => setNewPassword(value)}
          />

          <label htmlFor="new-password-confirm">새 패스워드 확인</label>
          <input
            id="new-password-confirm"
            type="password"
            autoComplete="off"
            value={newPasswordConfirm}
            onChange={({ currentTarget: { value } }) =>
              setNewPasswordConfirm(value)
            }
          />
        </div>

        <Button
          form
          disabled={
            !newPassword ||
            !newPasswordConfirm ||
            newPassword !== newPasswordConfirm
          }
        >
          재설정
        </Button>
      </StyledForm>
    </ContentBox>
  );
};
