import { utils } from '@admin/utils';
import { css } from '@emotion/react';

export const InputTextarea = (
  props: {
    name?: string;
    required?: boolean;
    value?: string;
  } & (
    | { readonly: true }
    | { readonly?: false; onChange?: (value: string) => void }
  ),
) => {
  return (
    <textarea
      name={props.name}
      required={props.required}
      readOnly={props.readonly}
      css={css`
        height: 200px;
        padding: 16px;
        border: 1px solid ${utils.style.color.grey300};
        box-sizing: border-box;
        border-radius: 10px;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: ${utils.style.color.black};
        background-color: ${props.readonly && utils.style.color.grey300};
        resize: none;
      `}
      value={props.value}
      onChange={(e) =>
        props.readonly || !props.onChange
          ? undefined
          : props.onChange(e.target.value)
      }
    />
  );
};
