import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledH1 } from '@admin/components/Styled/H1';
import { StyledTable } from '@admin/components/Styled/Table';
import { repositoryClient } from '@admin/repository';
import type {
  ServiceCodeMetaData,
  Voice,
  VoiceContract,
} from '@admin/repository/types';
import { CreateVoiceContractModal } from '@admin/routes/voice/contract/CreateVoiceContractModal';
import { SearchFilter } from '@admin/routes/voice/contract/SearchFilter';
import { UpdateVoiceContractModal } from '@admin/routes/voice/contract/UpdateVoiceContractModal';
import { utils } from '@admin/utils';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash-es';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export const VoiceContractPage = () => {
  const { openModal } = useModal();
  const [searchParams] = useSearchParams();
  /* 보이스 계약 가져오기 */
  const { data: voiceContracts } = useQuery({
    queryKey: ['/voice-contract'] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ data: VoiceContract[] }>(path, {
        signal,
      }),
    select: ({ data: { data } }) => data.sort((a, b) => a.voiceId - b.voiceId),
  });
  invariant(voiceContracts, 'Invalid voice-contract data.');

  /* 보이스 목록 가져오기 */
  const { data: voices, isLoading: isVoicesLoading } = useQuery({
    queryKey: ['/voice/all'] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ data: Voice[] }>(path, { signal }),
    select: ({ data: { data } }) => data,
  });
  invariant(voices, 'Invalid voice data');

  /* 서비스 코드 목록 가져오기 */
  const { data: serviceCodeList, isLoading: isServiceCodeListLoading } =
    useQuery({
      queryKey: ['/crm/service-code'] as const,
      queryFn: ({ queryKey: [path], signal }) =>
        repositoryClient.get<{ data: ServiceCodeMetaData[] }>(path, {
          signal,
        }),
      select: ({ data: { data } }) => {
        const includedServiceCodes = voiceContracts.map(
          (item) => item.serviceCode,
        );
        return data.filter((v) =>
          includedServiceCodes.includes(v.serviceCodeName),
        );
      },
    });

  invariant(serviceCodeList, 'Invalid service code data list.');
  /* 서비스 코드에 Default 항목 추가 */
  serviceCodeList.push({
    groupName: 'Default',
    memberCount: 0,
    serviceCodeName: 'Default',
  });

  /* 검색창 입력값에 의해 필터 데이터 생성 */
  const filteredVoiceContractArray = useMemo(() => {
    const query = searchParams.get('query');
    const column = searchParams.get('column');
    return _.chain(voiceContracts)
      .filter((value) => {
        if (!column || !query) {
          return true;
        }
        switch (column) {
          case 'voiceId':
            return Number(query) === value[column];
          case 'serviceCode':
            return query.toLowerCase() === value[column].toLowerCase();
          case 'planName':
            return query.toLowerCase() === value[column].toLowerCase();
          default:
            return false;
        }
      })
      .value();
  }, [voiceContracts, searchParams]);

  const renderPlanName = (planName: VoiceContract['planName']) => {
    switch (planName) {
      case 'Deferred':
        return '후불 요금제';
      case 'Default':
        return 'Default';
      default:
        return '선불 요금제';
    }
  };

  return (
    <div>
      <ContentBox
        css={css`
          height: 90vh;
          margin-bottom: 24px;
          position: relative;
        `}
      >
        <div
          css={css`
            background-color: white;
            display: flex;
            flex-direction: column;
            padding-top: 8px;
            z-index: 1;
            position: relative;
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 20px;
            `}
          >
            <StyledH1>계약</StyledH1>
          </div>

          <SearchFilter />
          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: flex-end;
              padding: 10px;
            `}
          >
            <div
              css={css`
                display: flex;
                align-items: center;
                justify-content: space-between;
              `}
            >
              <Button
                onClick={() => {
                  openModal(
                    <CreateVoiceContractModal
                      queryData={{
                        voices,
                        isVoicesLoading,
                        voiceContracts,
                        serviceCodeList,
                        isServiceCodeListLoading,
                      }}
                    />,
                  );
                }}
              >
                추가
              </Button>
            </div>
          </div>
          <div
            css={css`
              height: 60px;
              display: grid;
              grid-template-columns: repeat(8, 1fr);
              &::before {
                content: '';
                position: absolute;
                top: 160px;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: ${utils.style.color.grey300};
              }
              &::after {
                content: '';
                position: absolute;
                top: 210px;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: ${utils.style.color.grey300};
              }
              & p {
                padding: 10px;
                height: 60px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: ${utils.style.color.white};
                color: ${utils.style.color.grey500};
                text-align: center;
                ${utils.style.font['text-l']};
                white-space: nowrap;
              }
            `}
          >
            <p>구분</p>
            <p>서비스 코드</p>
            <p>보이스 ID</p>
            <p>요금제</p>
            <p>자당 가격 (원)</p>
            <p>특별 보이스 정산 비율 (%)</p>
            <p>음성 변조 배수</p>
            <p>차감자수</p>
          </div>
        </div>
        <div
          css={css`
            overflow: auto;
          `}
        >
          <StyledTable>
            <tbody>
              {filteredVoiceContractArray.map((elem) => (
                <tr
                  key={`${elem.serviceCode}-${elem.voiceId}-${elem.planName}`}
                  css={css`
                    display: grid;
                    grid-template-columns: repeat(8, 1fr);
                    cursor: pointer;
                    &:hover {
                      background: ${utils.style.color.grey100};
                    }
                    & td {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    }
                  `}
                  onClick={() => {
                    openModal(
                      <UpdateVoiceContractModal currentLineValue={elem} />,
                    );
                  }}
                >
                  <td>{renderPlanName(elem.planName)}</td>
                  <td>{elem.serviceCode}</td>
                  <td>{elem.voiceId}</td>
                  <td>{elem.planName}</td>
                  <td>{elem.pricePerCharacters}</td>
                  <td>{elem.settlementRatio * 100} </td>
                  <td>{elem.dubbingTimes}</td>
                  <td>{elem.pricePerCharacters}자</td>
                </tr>
              ))}
            </tbody>
          </StyledTable>
        </div>
      </ContentBox>
    </div>
  );
};
