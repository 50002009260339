import { Button } from '@admin/components/Button';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { PromptDialog } from '@admin/components/Dialog/PromptDialog';
import { useModal } from '@admin/components/Modal/hooks';
// import { endDateAtom } from '@admin/components/SearchContainer/context/atom';
import { StyledTable } from '@admin/components/Styled/Table';
import { repositoryClient } from '@admin/repository';
import { repositoryUtils } from '@admin/repository/utils';
import type { EventPageList } from '@admin/routes/eventPage/list/context/type';
import { utils } from '@admin/utils';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash-es';
import { useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
// import { useRecoilState } from 'recoil';

export const EventPageListTable = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { openModal } = useModal();
  const [searchParams] = useSearchParams();
  // const [endDateState] = useRecoilState(endDateAtom);

  /** 이벤트 페이지 데이터 가져오기 */
  const { data: eventPageList } = useQuery({
    queryKey: ['/admin/eventPage'] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ data: EventPageList[] }>(path, { signal }),
    select: ({ data: { data } }) => data,
  });

  invariant(eventPageList, 'Invalid event-page-list data.');

  /** 검색창 입력값에 의해 필터된 데이터 목록 */
  const filteredEventPageList = useMemo(() => {
    const query = searchParams.get('query');
    const isActive = searchParams.get('isActive');
    return (
      _.chain(eventPageList)
        .filter((value) => {
          if (!query) {
            return true;
          }
          return value.code.toLowerCase().includes(query.toLowerCase());
        })
        .filter((elem) => {
          if (isActive === 'true') {
            return elem.isActive === true;
          }
          if (isActive === 'false') {
            return elem.isActive === false;
          }
          return true; // ACTIVE, INACTIVE, EXPIRED 모두 포함
        })
        // .filter((elem) => {
        //   const sinceDate = new Date(elem.since.join('-'));
        //   const tillDate = new Date(elem.endDate.join('-'));
        //   return tillDate <= endDateState;
        // })
        .value()
    );
  }, [eventPageList, searchParams]);

  /** 이벤트 페이지 삭제 */
  const { mutate: deleteEventPage, isLoading: isDeleting } = useMutation({
    onError: (e) => {
      const message = repositoryUtils.getErrorMessage(e);
      openModal(<AlertDialog title="에러" message={message} />);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['/admin/eventPage'],
      });
      openModal(
        <AlertDialog title="알림" message="이벤트 페이지가 삭제되었습니다." />,
      );
    },
    mutationFn: (eventPageId: number) => {
      return repositoryClient.delete(`/admin/eventPage/${eventPageId}`);
    },
  });

  return (
    <div
      css={css`
        height: 100%;
        overflow: auto;
      `}
    >
      <StyledTable>
        <thead>
          <tr>
            <th>코드</th>
            <th>시작 날짜</th>
            <th>종료 날짜</th>
            <th>상태</th>
            <th>관리</th>
          </tr>
        </thead>
        <tbody>
          {filteredEventPageList.map(
            ({
              eventPageId,
              code,
              startDate,
              endDate,
              isActive,
            }: EventPageList) => (
              <tr key={code}>
                <td>{code}</td>
                <td>{startDate.join('-')}</td>
                <td>{endDate.join('-')}</td>
                <td>{isActive ? '활성' : '비활성'}</td>
                <td>
                  <div
                    css={css`
                      width: 100%;
                      display: flex;
                      justify-content: center;
                      gap: 24px;
                    `}
                  >
                    <Button
                      onClick={() => {
                        navigate(`./edit/${eventPageId}`);
                      }}
                      baseColor={utils.style.color.subBlack}
                    >
                      관리
                    </Button>
                    <Button
                      disabled={isDeleting}
                      onClick={() =>
                        openModal(
                          <PromptDialog
                            title="삭제"
                            message="이벤트를 삭제하시겠습니까?"
                            onConfirm={() => deleteEventPage(eventPageId)}
                          />,
                        )
                      }
                      baseColor={utils.style.color.highlight1}
                    >
                      삭제
                    </Button>
                  </div>
                </td>
              </tr>
            ),
          )}
        </tbody>
      </StyledTable>
    </div>
  );
};
