import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { Search } from '@admin/components/Search';
import { StyledH1 } from '@admin/components/Styled/H1';
import { StyledSelect } from '@admin/components/Styled/Select';
import { VerticalDivider } from '@admin/components/VerticalDivider';
import { utils } from '@admin/utils';
import { css } from '@emotion/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { faqCategories } from '../constant';
import { FAQListTable } from './FAQListTable';

export const FAQListPage = () => {
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState<string>('');
  const [categoryValue, setCategoryValue] = useState<string>('ALL');
  const [searchTarget, setSearchTarget] = useState<string>('title');

  return (
    <ContentBox
      css={css`
        height: 100%;
        gap: 24px;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <StyledH1>FAQ</StyledH1>

        <Button onClick={() => navigate('./create')}>FAQ 등록</Button>
      </div>

      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 16px;
        `}
      >
        <StyledSelect
          onChange={(e) => {
            setCategoryValue(e.target.value);
          }}
        >
          <option value="ALL">전체 카테고리</option>
          {Object.entries(faqCategories).map(([category, displayCategory]) => (
            <option key={category} value={category}>
              {displayCategory}
            </option>
          ))}
        </StyledSelect>

        <VerticalDivider />

        <div
          css={css`
            display: flex;
            flex-direction: column;
            ${utils.style.font['text-s']};

            user-select: none;

            & > label {
              display: flex;
              align-items: center;
              gap: 4px;
            }
          `}
        >
          <label>
            <input
              type="radio"
              name="searchColumn"
              defaultChecked
              value="title"
              onChange={(e) => setSearchTarget(e.target.value)}
            />
            제목
          </label>
          <label>
            <input
              type="radio"
              name="searchColumn"
              value="contents"
              onChange={(e) => setSearchTarget(e.target.value)}
            />
            내용
          </label>
        </div>

        <Search
          onSubmit={(value) => {
            setSearchValue(value);
          }}
          placeholder="검색"
        />
      </div>

      <FAQListTable
        searchValue={searchValue}
        categoryValue={categoryValue}
        searchTarget={searchTarget}
      />
    </ContentBox>
  );
};
