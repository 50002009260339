import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { CreateCodeDialog } from '@admin/components/Dialog/CreateCodeDialog';
import { useModal } from '@admin/components/Modal/hooks';
import { SearchContainer } from '@admin/components/SearchContainer';
import { StyledH1 } from '@admin/components/Styled/H1';
import { EventPageListTable } from '@admin/routes/eventPage/list/EventPageListTable';
import { css } from '@emotion/react';

export const EventPageList = () => {
  const { openModal } = useModal();
  return (
    <ContentBox
      css={css`
        gap: 24px;
        height: 100%;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 16px;
        `}
      >
        <StyledH1>이벤트 페이지 관리</StyledH1>

        <Button
          onClick={() =>
            openModal(
              <CreateCodeDialog
                title="새 이벤트 페이지 생성"
                message="이벤트 페이지가 추가되었습니다."
                queryKey="/admin/eventPage"
              />,
            )
          }
        >
          이벤트 페이지 관리 코드 생성
        </Button>
      </div>

      {/* 이벤트 페이지 검색 */}
      <SearchContainer />

      {/* 이벤트 페이지 목록 */}
      <EventPageListTable />
    </ContentBox>
  );
};
