import '@admin/etc/sanitize.css';

import { AuthorizationContextProvider } from '@admin/components/AuthorizationContextProvider';
import { Modal } from '@admin/components/Modal';
import { initializeMSW } from '@admin/mocks';
import { queryClient } from '@admin/repository/query';
import { css, Global } from '@emotion/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import { App } from './App';

console.log(import.meta.env.VITE_BUILD_TIME);

initializeMSW().then(() =>
  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
      <Global
        styles={css`
          * {
            font-family: Pretendard;
          }
        `}
      />
      <QueryClientProvider client={queryClient}>
        <RecoilRoot>
          <AuthorizationContextProvider>
            <BrowserRouter>
              <App />
              <Modal />
            </BrowserRouter>
            <ReactQueryDevtools />
          </AuthorizationContextProvider>
        </RecoilRoot>
      </QueryClientProvider>
    </React.StrictMode>,
  ),
);
