import { Button } from '@admin/components/Button';
import { StyledTable } from '@admin/components/Styled/Table';
import { repositoryClient } from '@admin/repository';
import type { ServiceCodeMetaData } from '@admin/repository/types';
import { utils } from '@admin/utils';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const ListTable = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const searchKeyword = searchParams.get('q') ?? '';

  const searchColumn = searchParams.get('c') ?? '';

  const query = useQuery({
    queryKey: ['/crm/service-code'] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ data: ServiceCodeMetaData[] }>(path, { signal }),
    select: ({ data: { data } }) => data,
  });

  invariant(query.data, 'Invalid query data.');

  const filteredServiceCodeList = useMemo(() => {
    if (query.status !== 'success') {
      return [];
    }
    if (!searchKeyword) {
      return query.data;
    }
    const lowerCasedSearchKeyword = searchKeyword.toLowerCase();
    return query.data.filter(({ groupName, serviceCodeName }) =>
      searchColumn === 'code'
        ? serviceCodeName.toLowerCase().includes(lowerCasedSearchKeyword)
        : groupName.toLowerCase().includes(lowerCasedSearchKeyword),
    );
  }, [query.data, query.status, searchColumn, searchKeyword]);

  return (
    <div
      css={css`
        height: 100%;
        overflow: auto;
      `}
    >
      <StyledTable>
        <thead>
          <tr>
            <th>이름</th>
            <th>서비스 코드</th>
            <th>사용자 수</th>
            <th>통계 / 관리</th>
          </tr>
        </thead>

        <tbody>
          {filteredServiceCodeList.map(
            ({ groupName, memberCount, serviceCodeName }) => (
              <tr key={serviceCodeName}>
                <td>{groupName}</td>
                <td>{serviceCodeName}</td>
                <td>{memberCount}</td>
                <td
                  css={css`
                    width: 300px;
                  `}
                >
                  <div
                    css={css`
                      display: flex;
                      justify-content: center;
                      gap: 16px;
                    `}
                  >
                    <Button
                      onClick={() =>
                        navigate(`./statistics/${serviceCodeName}`)
                      }
                      baseColor={utils.style.color.subBlack}
                    >
                      정산 조회
                    </Button>
                    <Button
                      onClick={() => navigate(`./edit/${serviceCodeName}`)}
                      baseColor={utils.style.color.subBlack}
                    >
                      관리
                    </Button>
                  </div>
                </td>
              </tr>
            ),
          )}
        </tbody>
      </StyledTable>
    </div>
  );
};
