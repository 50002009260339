import { utils } from '@admin/utils';
import styled from '@emotion/styled';

export const StyledInput = styled.input`
  display: flex;
  align-items: center;

  height: 40px;
  padding: 0 16px;
  box-sizing: border-box;
  border: 1px solid ${utils.style.color.grey300};
  border-radius: 8px;

  ${utils.style.font['text-m']};

  color: ${utils.style.color.black};
  background-color: ${utils.style.color.white};

  &:read-only {
    background-color: ${utils.style.color.grey300};
  }

  &[type='checkbox'] {
    height: 40px;
    width: 40px;
  }

  &[type='radio'] {
    height: 24px;
    width: 24px;
  }
`;

export const StyledNumberInput = styled(StyledInput)`
  width: 70px;
  padding: 0;
  text-align: left;
  vertical-align: middle;
`;
