import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { InputDateRange } from '@admin/components/InputDateRange';
import { StyledH1 } from '@admin/components/Styled/H1';
import { StyledTable } from '@admin/components/Styled/Table';
import { repositoryClient } from '@admin/repository';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useQuery } from '@tanstack/react-query';
import { format, subDays } from 'date-fns';
import _ from 'lodash-es';
import { useMemo, useRef, useState } from 'react';
import * as XLSX from 'xlsx';

export const InquiryStatisticsPage = () => {
  const tableRef = useRef<HTMLTableElement>(null);

  const now = new Date();

  const [dateRange, setDateRange] = useState(() => {
    const [start, end] = [subDays(now, 7), now];
    return { start, end };
  });

  const query = useQuery({
    queryKey: [
      '/inquiry/admin/statistics',
      {
        since: format(dateRange.start, 'yyyy-MM-dd'),
        till: format(dateRange.end, 'yyyy-MM-dd'),
      },
    ] as const,
    queryFn: async ({ queryKey: [path, { since, till }], signal }) =>
      repositoryClient.get<{
        data: {
          inquiryDate: number[];
          allCount: number;
          serviceCount: number;
          accountCount: number;
          paymentCount: number;
          complainCount: number;
          otherCount: number;
          affiliateCount: number;
        }[];
      }>(path, {
        params: {
          since,
          till,
        },
        signal,
      }),
    select: ({ data: { data } }) => _.chain(data).cloneDeep().reverse().value(),
    enabled: dateRange.start <= dateRange.end && dateRange.end <= now,
  });

  invariant(query.data, 'Invalid query data.');

  const inquiryStatisticsTotal = useMemo(
    () =>
      query.data.reduce(
        (acc, cur) => {
          return {
            allCount: acc.allCount + cur.allCount,
            serviceCount: acc.serviceCount + cur.serviceCount,
            accountCount: acc.accountCount + cur.accountCount,
            paymentCount: acc.paymentCount + cur.paymentCount,
            complainCount: acc.complainCount + cur.complainCount,
            otherCount: acc.otherCount + cur.otherCount,
            affiliateCount: acc.affiliateCount + cur.affiliateCount,
          };
        },
        {
          allCount: 0,
          serviceCount: 0,
          accountCount: 0,
          paymentCount: 0,
          complainCount: 0,
          otherCount: 0,
          affiliateCount: 0,
        },
      ),
    [query.data],
  );

  const exportInquiryStatistics = () => {
    const { current: table } = tableRef;
    if (!table) {
      return;
    }
    const formattedStartDate = format(dateRange.start, 'yyyy. MM. dd');
    const formattedEndDate = format(dateRange.end, 'yyyy. MM. dd');
    const fileName = `고객 문의 통계 (${formattedStartDate} - ${formattedEndDate}).xlsx`;
    const wb = XLSX.utils.table_to_book(table, {
      sheet: '고객 문의 통계 통계',
    });
    XLSX.writeFile(wb, fileName);
  };

  return (
    <ContentBox
      css={css`
        height: 100%;
        gap: 24px;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 16px;
        `}
      >
        <StyledH1>고객 문의 통계</StyledH1>

        <Button onClick={exportInquiryStatistics}>내보내기</Button>
      </div>

      <InputDateRange
        enableShortcuts
        onChange={setDateRange}
        value={dateRange}
      />

      <div
        css={css`
          height: 100%;
          overflow: auto;
        `}
      >
        {(() => {
          if (dateRange.start > dateRange.end) {
            return (
              <div
                css={css`
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                `}
              >
                검색 시작 날짜는 끝 날짜보다 늦을 수 없습니다.
              </div>
            );
          }

          if (dateRange.end > now) {
            return (
              <div
                css={css`
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                `}
              >
                검색 끝 날짜는 현재 날짜보다 늦을 수 없습니다.
              </div>
            );
          }

          return (
            <StyledTable ref={tableRef}>
              <thead>
                <tr>
                  <th>날짜</th>
                  <th>전체</th>
                  <th>서비스</th>
                  <th>계정</th>
                  <th>결제</th>
                  <th>불편사항</th>
                  <th>기타</th>
                  <th>제휴</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>합계</td>
                  <td>{inquiryStatisticsTotal.allCount}</td>
                  <td>{inquiryStatisticsTotal.serviceCount}</td>
                  <td>{inquiryStatisticsTotal.accountCount}</td>
                  <td>{inquiryStatisticsTotal.paymentCount}</td>
                  <td>{inquiryStatisticsTotal.complainCount}</td>
                  <td>{inquiryStatisticsTotal.otherCount}</td>
                  <td>{inquiryStatisticsTotal.affiliateCount}</td>
                </tr>
                {query.data.map((inquiryStatistics) => (
                  <tr key={inquiryStatistics.inquiryDate.join('-')}>
                    <td>{inquiryStatistics.inquiryDate.join('-')}</td>
                    <td>{inquiryStatistics.allCount}</td>
                    <td>{inquiryStatistics.serviceCount}</td>
                    <td>{inquiryStatistics.accountCount}</td>
                    <td>{inquiryStatistics.paymentCount}</td>
                    <td>{inquiryStatistics.complainCount}</td>
                    <td>{inquiryStatistics.otherCount}</td>
                    <td>{inquiryStatistics.affiliateCount}</td>
                  </tr>
                ))}
              </tbody>
            </StyledTable>
          );
        })()}
      </div>
    </ContentBox>
  );
};
