import { repositoryClient } from '@admin/repository';
import type { Voice } from '@admin/repository/types';
import { invariant } from '@admin/utils/invariant';
import { useQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { CommonVoiceTable } from './CommonVoiceTable';
import { CustomVoiceTable } from './CustomVoiceTable';
import { PTTSVoiceTable } from './PTTSVoiceTable';
import { SpecialVoiceTable } from './SpecialVoiceTable';
import { VoiceTableTab } from './VoiceTableTab';

export const VoiceManagementTables = () => {
  const [tab, setTab] = useState<Voice['voiceType']>('COMMON');
  const { serviceCode } = useParams();

  const queryKey = [
    `/voice-permission/group?serviceCode=${serviceCode}`,
  ] as const;

  const { data: voiceList } = useQuery({
    queryKey: ['/voice/all'] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ data: Voice[] }>(path, { signal }),
    select: ({ data: { data } }) => data,
  });

  const { data: activatedVoiceIdList } = useQuery({
    queryKey,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ data: number[] }>(path, { signal }),
    select: ({ data: { data } }) => data,
  });

  invariant(
    voiceList && activatedVoiceIdList,
    'Invalid voice & activated voice ID list',
  );

  const commonVoiceList = useMemo(
    () => voiceList.filter(({ voiceType }) => voiceType === 'COMMON'),
    [voiceList],
  );

  const specialVoiceList = useMemo(
    () => voiceList.filter(({ voiceType }) => voiceType === 'SPECIAL'),
    [voiceList],
  );

  const customVoiceList = useMemo(
    () => voiceList.filter(({ voiceType }) => voiceType === 'CUSTOM'),
    [voiceList],
  );

  const pttsVoiceList = useMemo(
    () => voiceList.filter(({ voiceType }) => voiceType === 'PTTS'),
    [voiceList],
  );

  const commonActivatedVoiceIdList = useMemo(
    () =>
      commonVoiceList
        .filter((item: Voice) => activatedVoiceIdList.includes(item.voiceId))
        .map((item: Voice) => item.voiceId),
    [commonVoiceList, activatedVoiceIdList],
  );

  const specialActivatedVoiceIdList = useMemo(
    () =>
      specialVoiceList
        .filter((item: Voice) => activatedVoiceIdList.includes(item.voiceId))
        .map((item: Voice) => item.voiceId),
    [specialVoiceList, activatedVoiceIdList],
  );
  const customActivatedVoiceIdList = useMemo(
    () =>
      customVoiceList
        .filter((item: Voice) => activatedVoiceIdList.includes(item.voiceId))
        .map((item: Voice) => item.voiceId),
    [customVoiceList, activatedVoiceIdList],
  );
  const pttsActivatedVoiceIdList = useMemo(
    () =>
      pttsVoiceList
        .filter((item: Voice) => activatedVoiceIdList.includes(item.voiceId))
        .map((item: Voice) => item.voiceId),
    [pttsVoiceList, activatedVoiceIdList],
  );

  return (
    <>
      <VoiceTableTab currentTab={tab} onClickTab={setTab} />
      {tab === 'COMMON' && (
        <CommonVoiceTable
          voiceList={commonVoiceList}
          activatedVoiceIdList={commonActivatedVoiceIdList}
          serviceCode={serviceCode}
        />
      )}
      {tab === 'SPECIAL' && (
        <SpecialVoiceTable
          voiceList={specialVoiceList}
          activatedVoiceIdList={specialActivatedVoiceIdList}
          serviceCode={serviceCode}
        />
      )}
      {tab === 'CUSTOM' && (
        <CustomVoiceTable
          voiceList={customVoiceList}
          activatedVoiceIdList={customActivatedVoiceIdList}
          serviceCode={serviceCode}
        />
      )}
      {tab === 'PTTS' && (
        <PTTSVoiceTable
          voiceList={pttsVoiceList}
          activatedVoiceIdList={pttsActivatedVoiceIdList}
          serviceCode={serviceCode}
        />
      )}
    </>
  );
};
