import { utils } from '@admin/utils';
import { css } from '@emotion/react';
import type { To } from 'react-router-dom';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';

const SidebarNavLink = (props: { to: To; title: string }) => {
  const resolvedPath = useResolvedPath(props.to);
  const match = useMatch({ path: resolvedPath.pathname, end: false });

  const height = 32;
  const active = match !== null;

  return (
    <Link
      to={props.to}
      css={css`
        width: 100%;
        height: ${height}px;
        text-decoration-line: none;
        position: relative;
        display: flex;
        align-items: center;
      `}
    >
      {/* 메뉴 이름 */}
      <span
        css={css`
          font-size: 20px;
          font-weight: bold;
          color: ${active
            ? utils.style.color.primary
            : utils.style.color.secondary};
        `}
      >
        {props.title}
      </span>
    </Link>
  );
};

export { SidebarNavLink };
