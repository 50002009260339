import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import type { ReactNode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useLocation } from 'react-router-dom';

import { ErrorBox } from './ErrorBox';

interface Props {
  children: ReactNode;
}

export const CustomErrorBoundary = (props: Props) => {
  const { reset } = useQueryErrorResetBoundary();
  const { pathname } = useLocation();
  return (
    <ErrorBoundary
      key={pathname} // 에러가 난 페이지에서 벗어났을 때 에러 바운더리 state가 리셋되도록 함
      onReset={reset}
      FallbackComponent={ErrorBox}
    >
      {props.children}
    </ErrorBoundary>
  );
};
