import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { InputDateRange } from '@admin/components/InputDateRange';
import { StyledH1 } from '@admin/components/Styled/H1';
import { StyledTable } from '@admin/components/Styled/Table';
import { repositoryClient } from '@admin/repository';
import type { DateTimeResponse } from '@admin/repository/types';
import { repositoryUtils } from '@admin/repository/utils';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import _ from 'lodash-es';
import { useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import * as XLSX from 'xlsx';

export const VoiceUsageTable = () => {
  const { email: userEmail } = useParams();

  const [searchParams] = useSearchParams();

  const serviceCode = searchParams.get('service-code');

  const tableRef = useRef<HTMLTableElement>(null);

  const [dateRange, setDateRange] = useState(() => {
    const [start, end] = [new Date(), new Date()];
    start.setDate(end.getDate() - 2);
    return { start, end };
  });

  const exportToXlsx = () => {
    const { current: table } = tableRef;
    if (!table) {
      return;
    }
    const formattedStartDate = format(dateRange.start, 'yyyy. MM. dd');
    const formattedEndDate = format(dateRange.end, 'yyyy. MM. dd');
    const fileName = `보이스 사용 통계 (${formattedStartDate} - ${formattedEndDate}).xlsx`;
    const wb = XLSX.utils.table_to_book(table, { sheet: '보이스 사용 통계' });
    XLSX.writeFile(wb, fileName);
  };

  const query = useQuery({
    queryKey: [
      '/statistics/charactersCount',
      {
        serviceCode,
        userEmail,
        since: format(dateRange.start, 'yyyy-MM-dd'),
        till: format(dateRange.end, 'yyyy-MM-dd'),
      },
    ] as const,
    queryFn: ({ queryKey: [path, payload], signal }) =>
      repositoryClient.post<{
        data: ReadonlyArray<{
          readonly charactersCount: number;
          readonly localDate: DateTimeResponse;
          readonly voiceName: string;
          readonly voiceDisplayName: string;
          readonly voiceUid: string;
        }>;
      }>(path, payload, { signal }),
    select: ({ data: { data } }) =>
      _.chain(data)
        .sortBy(({ localDate }) => repositoryUtils.parseDate(localDate))
        .reverse()
        .value(),
  });

  invariant(query.data, 'Invalid query data.');

  return (
    <ContentBox
      css={css`
        gap: 1rem;
      `}
    >
      <StyledH1>보이스별 사용 통계</StyledH1>

      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 20px;
        `}
      >
        <InputDateRange value={dateRange} onChange={setDateRange} />

        <Button disabled={_.isEmpty(query.data)} onClick={exportToXlsx}>
          내보내기
        </Button>
      </div>

      <div
        css={css`
          min-height: 120px;
          height: 100%;
          max-height: 500px;
          overflow: auto;
        `}
      >
        <StyledTable ref={tableRef}>
          <thead>
            <tr>
              <th>날짜</th>
              <th>보이스 이름</th>
              <th>사용 자 수</th>
            </tr>
          </thead>
          <tbody>
            {query.data.map(
              ({ localDate, voiceDisplayName, charactersCount, voiceUid }) => {
                const parsedDate = repositoryUtils.parseDate(localDate);
                if (parsedDate === null) {
                  throw new Error(`Invalid date format: ${localDate}`);
                }
                return (
                  <tr key={`${parsedDate.toISOString()}${voiceUid}}`}>
                    <td>
                      {parsedDate.toLocaleDateString('ko-KR', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      })}
                    </td>
                    <td>{voiceDisplayName}</td>
                    <td>{charactersCount}</td>
                  </tr>
                );
              },
            )}
          </tbody>
        </StyledTable>
      </div>
    </ContentBox>
  );
};
