import { StyledTable } from '@admin/components/Styled/Table';
import { repositoryClient } from '@admin/repository';
import { DISPLAY_NAMES_VOICE } from '@admin/repository/constant';
import type { Voice } from '@admin/repository/types';
import { utils } from '@admin/utils';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const columnKeys = {
  'voice-id': 'voiceId',
  name: 'name',
  'display-name': 'displayName',
  'is-enabled': 'isEnabled',
} as const satisfies Record<string, keyof Voice>;

export const VoiceTable = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const { data: voices } = useQuery({
    queryKey: ['/voice/all'] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ data: Voice[] }>(path, { signal }),
    select: ({ data: { data } }) => data,
  });

  invariant(voices, 'Invalid voice data');

  const filteredVoices = useMemo(() => {
    const query = searchParams.get('query');
    const column = searchParams.get('column');
    return voices
      .filter((v) => v.voiceType === 'SPECIAL')
      .filter((v) => {
        if (!column || !query) {
          return true;
        }
        switch (column) {
          case 'displayName':
          case 'name':
            return v[column].toLowerCase().includes(query.toLowerCase());
          case 'voiceId':
            return Number(query) === v[column];
          default:
            return false;
        }
      });
  }, [searchParams, voices]);

  return (
    <div
      css={css`
        height: 100%;
        overflow: auto;
      `}
    >
      <StyledTable>
        <thead>
          <tr>
            {Object.entries(columnKeys).map(([k, v]) => (
              <th key={k}>{DISPLAY_NAMES_VOICE[v]}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          {filteredVoices.map((voice) => (
            <tr
              key={voice.voiceId}
              onClick={() => navigate(voice.name)}
              css={css`
                cursor: pointer;
                &:hover {
                  background: ${utils.style.color.grey100};
                }
              `}
            >
              {Object.entries(columnKeys).map(([k, v]) => {
                if (v === 'isEnabled') {
                  return <td key={k}>{voice[v] ? '활성' : '-'}</td>;
                }
                return <td key={k}>{voice[v]}</td>;
              })}
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </div>
  );
};
