import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledForm } from '@admin/components/Styled/Form';
import { StyledH1 } from '@admin/components/Styled/H1';
import { repositoryClient } from '@admin/repository';
import type { Member } from '@admin/repository/types';
import { repositoryUtils } from '@admin/repository/utils';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

export const ChangeServiceCodeForm = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const serviceCode = searchParams.get('service-code');

  const { email: userEmail } = useParams();

  const { openModal } = useModal();

  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: ['/member/single', { serviceCode, userEmail }] as const,
    queryFn: ({ queryKey: [path, { serviceCode, userEmail }], signal }) =>
      repositoryClient.get<{ data: Member }>(`${path}/${userEmail}`, {
        params: { serviceCode },
        signal,
      }),
    select: ({ data: { data } }) => data,
  });

  invariant(query.data, 'Invalid query data.');

  const [selectedServiceCode, selectServiceCode] = useState<string>(
    query.data.serviceCode,
  );

  const { mutate: save, isLoading: isSaving } = useMutation({
    onError: (e) => {
      openModal(
        <AlertDialog
          title="에러"
          message={repositoryUtils.getErrorMessage(e)}
        />,
      );
    },
    onSuccess: () => {
      setSearchParams((searchParams) => {
        searchParams.set('service-code', selectedServiceCode);
        return searchParams;
      });
      queryClient.invalidateQueries({
        queryKey: ['/member/single', { selectedServiceCode, userEmail }],
      });
      openModal(
        <AlertDialog
          title="알림"
          message="서비스 코드가 성공적으로 변경되었습니다."
        />,
      );
    },
    mutationFn: () => {
      if (!serviceCode) {
        throw new Error('invalid service code');
      }
      if (!userEmail) {
        throw new Error('invalid email');
      }
      if (!selectedServiceCode) {
        throw new Error('invalid selected service code value');
      }
      if (
        !query.data.serviceCode.includes('WEB') ||
        !selectedServiceCode.includes('WEB')
      ) {
        throw new Error('WEB 유저만 변경 가능합니다.');
      }
      return Promise.all([
        repositoryClient.patch(`/member/${userEmail}/change-service-code`, {
          oldServiceCode: query.data.serviceCode,
          newServiceCode: selectedServiceCode,
        }),
      ]);
    },
  });

  return (
    <ContentBox
      css={css`
        gap: 1rem;
      `}
    >
      <StyledH1>서비스 코드</StyledH1>
      <StyledForm>
        <select
          required
          value={selectedServiceCode}
          onChange={({ currentTarget: { value } }) =>
            selectServiceCode(value as string)
          }
        >
          {!query.data.serviceCode.includes('WEB') ? (
            <option>{query.data.serviceCode}</option>
          ) : (
            <>
              <option value="001WEB001">001WEB001</option>
              <option value="001WEB002">001WEB002</option>
            </>
          )}
        </select>

        <Button
          disabled={
            !userEmail ||
            isSaving ||
            selectedServiceCode === query.data.serviceCode ||
            !query.data.serviceCode.includes('WEB')
          }
          onClick={save}
        >
          저장
        </Button>
      </StyledForm>
    </ContentBox>
  );
};
