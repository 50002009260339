import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { PromptDialog } from '@admin/components/Dialog/PromptDialog';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledForm } from '@admin/components/Styled/Form';
import { StyledH1 } from '@admin/components/Styled/H1';
import { repositoryClient } from '@admin/repository';
import type { Member } from '@admin/repository/types';
import { repositoryUtils } from '@admin/repository/utils';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

export const DormantForm = () => {
  const { openModal, closeModal } = useModal();

  const [searchParams] = useSearchParams();

  const serviceCode = searchParams.get('service-code');

  const { email: userEmail } = useParams();

  const dormantData = useQuery({
    queryKey: ['/member/single', { serviceCode, userEmail }] as const,
    queryFn: ({ queryKey: [path, { serviceCode, userEmail }], signal }) =>
      repositoryClient.get<{ data: Member }>(`${path}/${userEmail}`, {
        params: { serviceCode },
        signal,
      }),
    select: ({ data: { data } }) => ({
      serviceCode: data.serviceCode,
      lastLoginTime: data.lastLoginTime,
      dormancyDate: data.dormancyDate,
      scheduledDormancyDate: data.scheduledDormancyDate,
      isDormant: data.isDormant,
    }),
  });

  const { mutate: saveDormantData } = useMutation({
    mutationFn: (isDormant) => {
      return repositoryClient.patch(
        `/member/${userEmail}?serviceCode=${dormantData.data?.serviceCode}`,
        { isDormant },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
    },
    onError: (error) => {
      closeModal();
      openModal(
        <AlertDialog
          title="에러"
          message={repositoryUtils.getErrorMessage(error)}
        />,
      );
    },
    onSuccess: () => {
      closeModal();
      openModal(
        <AlertDialog
          title="알림"
          message="변경 사항이 성공적으로 저장되었습니다."
        />,
      );
    },
  });

  invariant(dormantData.data, 'Invalid dormantData data.');

  const displayLastLoginTime = useMemo(() => {
    if (!(dormantData.data.lastLoginTime === null)) {
      return format(
        repositoryUtils.parseDate(dormantData.data.lastLoginTime) as Date,
        'yyyy년 MM월 dd일 HH시 mm분',
      );
    }
    return '한 번도 로그인하지 않은 사용자입니다.';
  }, [dormantData.data]);

  const displayDate = (date: string) => {
    if (date !== null) {
      return format(
        repositoryUtils.parseDate(date) as Date,
        'yyyy년 MM월 dd일',
      );
    }
    return '한 번도 로그인하지 않은 사용자입니다.';
  };

  return (
    <ContentBox
      css={css`
        gap: 1rem;
      `}
    >
      <StyledH1>휴면 정보</StyledH1>
      <StyledForm
        onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
          e.preventDefault();
          openModal(
            <PromptDialog
              title="확인"
              message="휴면 정보를 수정하시겠습니까?"
              onConfirm={() => {
                saveDormantData(
                  (e.target as HTMLFormElement).isDormant.checked,
                );
              }}
            />,
          );
        }}
      >
        <label>
          최근 접속일
          <input value={displayLastLoginTime} disabled />
        </label>

        <label>
          휴면 계정 전환 안내 메일 발송일 (휴면 계정 전환 40일 전)
          <input
            value={displayDate(dormantData.data.scheduledDormancyDate)}
            disabled
          />
        </label>

        <label>
          휴면 계정 전환 메일 발송일
          <input value={displayDate(dormantData.data.dormancyDate)} disabled />
        </label>

        <label>
          휴면 여부
          <input
            defaultChecked={dormantData.data.isDormant}
            type="checkbox"
            name="isDormant"
          />
        </label>

        <Button form>저장</Button>
      </StyledForm>
    </ContentBox>
  );
};
