import chardet from 'chardet';
import { useEffect, useRef, useState } from 'react';

interface Props {
  readonly data: ArrayBuffer;
  readonly onLoaded?: (contentDocument: Document) => void;
}

export const HTMLRenderer = (props: Props) => {
  const [height, setHeight] = useState(0);

  const objectRef = useRef<HTMLObjectElement>(null);

  useEffect(() => {
    const { current: object } = objectRef;
    if (!object) {
      return;
    }
    const pattern = /<meta(.*)charset="?([\w-]+)"?(.*)>/;
    const charset =
      // <meta charset="..."> 또는 <meta content="text/html; charset=..."> 태그에서 charset 추출
      new TextDecoder().decode(props.data).match(pattern)?.[2] ??
      // 실패 시 chardet을 사용하여 인코딩 유추
      chardet
        .analyse(new Uint8Array(props.data))
        .find(({ confidence }) => confidence > 80)?.name;

    if (!charset) {
      throw new Error('Failed to detect charset');
    }

    const htmlText = new TextDecoder(charset, { fatal: true }).decode(
      props.data,
    );
    const doc = new DOMParser().parseFromString(htmlText, 'text/html');
    // sanitize html
    // 스크립트 태그와 외부 리소스 임베딩, 모든 이벤트 핸들러 제거
    const restrictedTags = ['script', 'iframe', 'frame', 'object', 'embed'];
    doc.querySelectorAll('*').forEach((node) => {
      const tag = node.tagName.toLowerCase();
      if (restrictedTags.includes(tag)) {
        node.remove();
      }
      for (let i = 0; i < node.attributes.length; ++i) {
        const attr = node.attributes[i];
        // eslint-disable-next-line no-script-url
        if (attr?.name === 'href' && attr.value.startsWith('javascript:')) {
          node.remove();
        }
        if (attr?.name.startsWith('on')) {
          node.removeAttribute(attr.name);
        }
        if (tag === 'a') {
          node.setAttribute('target', '_blank');
          node.setAttribute('rel', 'noopener noreferrer');
        }
      }
    });
    const html = new XMLSerializer().serializeToString(doc);
    const htmlBlob = new Blob([html], { type: 'text/html;charset=utf-8' });
    if (object.contentDocument?.body.textContent === doc.body.textContent) {
      URL.revokeObjectURL(object.data);
    } else {
      object.data = URL.createObjectURL(htmlBlob);
    }
  }, [props.data]);

  return (
    // eslint-disable-next-line jsx-a11y/alt-text
    <object
      ref={objectRef}
      onLoad={({ currentTarget }) => {
        const { contentDocument, contentWindow } = currentTarget;
        if (!contentDocument || !contentWindow) {
          return;
        }
        const { body, documentElement } = contentDocument;
        body.style.setProperty('height', 'max-content');
        documentElement.style.setProperty('height', 'max-content');
        setHeight(documentElement.getBoundingClientRect().height);
        contentWindow.onresize = () => {
          const rect = contentDocument.documentElement.getBoundingClientRect();
          if (rect) {
            setHeight(rect.height);
          }
        };
        props.onLoaded?.(contentDocument);
      }}
      style={{
        width: '100%',
        height: height ? `${height}px` : '100%',
      }}
    />
  );
};
