import { Button } from '@admin/components/Button';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { useModal } from '@admin/components/Modal/hooks';
import { repositoryClient } from '@admin/repository';
import type { Emotion, Voice } from '@admin/repository/types';
import { repositoryUtils } from '@admin/repository/utils';
import { invariant } from '@admin/utils/invariant';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import type { EmotionNameType } from '..';
import { voiceTrainingFormAtoms, voiceTrainingFormSelectors } from '../atom';

interface Props {
  voiceProps?: Voice;
  currentEmotion: EmotionNameType;
}

export const StartButton = (props: Props) => {
  const { name } = useParams();

  const { openModal } = useModal();

  const trainingData = useRecoilValue(
    voiceTrainingFormSelectors['training-data'],
  );

  const resetScriptData = useResetRecoilState(
    voiceTrainingFormAtoms['script-data'],
  );

  const { data: voice } = useQuery({
    queryKey: [`/voice/${name}`] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ data: Voice }>(path, { signal }),
    select: ({ data: { data } }) => data,
  });

  invariant(voice ?? props.voiceProps, 'Invalid voice data.');

  const queryClient = useQueryClient();

  const { mutate: startIndividualTraining, isLoading: isStarting } =
    useMutation({
      onError: (e) => {
        openModal(
          <AlertDialog
            title="에러"
            message={repositoryUtils.getErrorMessage(e)}
          />,
        );
      },
      onSuccess: () => {
        queryClient.invalidateQueries([`/voice/${name}`]);
        openModal(
          <AlertDialog
            title="알림"
            message={`${name} 보이스 학습이 시작되었습니다.`}
          />,
        );
        resetScriptData();
      },
      mutationFn: async () => {
        const { audioFiles, recordInfo } = trainingData;
        const payload = audioFiles.reduce((form, file) => {
          form.append('audioFiles', file);
          return form;
        }, new FormData());
        payload.append(
          'recordInfo',
          new Blob([recordInfo], { type: 'text/plain' }),
        );
        payload.append('emotion', props.currentEmotion);

        await repositoryClient.patch(
          `/voice/${name}/reset?emotion=${props.currentEmotion}`,
        );
        await repositoryClient.post(`/voice/${name}/upload-files`, payload, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        await repositoryClient.post(
          `/voice/${name}/start-training?emotion=${props.currentEmotion}`,
          null,
        );
      },
    });

  return (
    <Button
      disabled={
        voice?.emotions.filter(
          (item: Emotion) => item.name === props.currentEmotion,
        )[0]?.trainingStatus === 'TRAINING' ||
        isStarting ||
        !trainingData.isReady
      }
      onClick={startIndividualTraining}
    >
      학습 시작
    </Button>
  );
};
