import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { PromptDialog } from '@admin/components/Dialog/PromptDialog';
import { Divider } from '@admin/components/Divider';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledH1 } from '@admin/components/Styled/H1';
import { repositoryClient } from '@admin/repository';
import type { Repository } from '@admin/repository/types';
import { repositoryUtils } from '@admin/repository/utils';
import { VoucherMemberList } from '@admin/routes/voucher/edit/VoucherMemberList';
import { utils } from '@admin/utils';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { VoucherEditForm } from './VoucherEditForm';

export const VoucherEditPage = () => {
  const { code } = useParams();
  const { openModal } = useModal();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  /* 이벤트 쿠폰 상세 조회 */
  const { data: voucherDetail } = useQuery({
    queryKey: [`/admin/voucher/${code}`] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ data: Repository['voucher'] }>(path, { signal }),
    select: ({ data: { data } }) => data,
  });

  invariant(voucherDetail, 'Invalid voucher-list data.');

  /* 이벤트 쿠폰 삭제 */
  const { mutate: deleteEventCoupon, isLoading: isDeleting } = useMutation({
    onError: (e) => {
      const message = repositoryUtils.getErrorMessage(e);
      openModal(<AlertDialog title="에러" message={message} />);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [`/admin/voucher/${code}`] });

      openModal(
        <PromptDialog
          title="알림"
          message="이벤트 쿠폰이 삭제되었습니다."
          onConfirm={() => navigate('/voucher')}
          onCancel={() => navigate('/voucher')}
        />,
      );
      navigate('/voucher');
    },
    mutationFn: () => repositoryClient.delete(`/admin/voucher/${code}`),
  });

  return (
    <ContentBox
      css={css`
        gap: 24px;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <StyledH1>이벤트 쿠폰 관리</StyledH1>

        <Button
          disabled={isDeleting}
          onClick={() => {
            openModal(
              <PromptDialog
                title="삭제"
                message="이벤트 쿠폰을 삭제하시겠습니까?"
                onConfirm={deleteEventCoupon}
              />,
            );
          }}
          baseColor={utils.style.color.highlight1}
        >
          삭제
        </Button>
      </div>

      <VoucherEditForm voucherDetail={voucherDetail} />

      <Divider />

      <VoucherMemberList voucherDetail={voucherDetail} />
    </ContentBox>
  );
};
