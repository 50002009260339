import { utils } from '@admin/utils';
import { css } from '@emotion/react';
import Color from 'color';
import { createPortal } from 'react-dom';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { modalAtom } from './atom';

export const Modal = () => {
  const { child } = useRecoilValue(modalAtom);

  const close = useResetRecoilState(modalAtom);

  if (child === null) {
    return null;
  }

  return createPortal(
    <div
      css={css`
        position: absolute;
        top: 0;
        left: 0;

        width: 100vw;
        height: 100vh;

        background: ${new Color(utils.style.color.secondary).alpha(0.7).hexa()};

        display: flex;
        justify-content: center;
        align-items: center;

        z-index: ${utils.style['z-index'].modal};
      `}
      onClick={() => close()}
    >
      <div onClick={(e) => e.stopPropagation()}>{child}</div>
    </div>,
    document.body,
  );
};
