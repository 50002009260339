import { Button } from '@admin/components/Button';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { PromptDialog } from '@admin/components/Dialog/PromptDialog';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledTable } from '@admin/components/Styled/Table';
import { repositoryClient } from '@admin/repository';
import { repositoryUtils } from '@admin/repository/utils';
import { utils } from '@admin/utils';
import { invariant } from '@admin/utils/invariant';
import utilTime from '@admin/utils/utilTime';
import { css } from '@emotion/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import _ from 'lodash-es';
import { useNavigate } from 'react-router-dom';

import { emailConstants } from '../../constants';

export const EmailListTable = () => {
  const navigate = useNavigate();

  const { openModal } = useModal();

  const query = useQuery({
    queryKey: ['/admin-mail/reservation'] as const,
    queryFn: async ({ queryKey: [path], signal }) =>
      repositoryClient.get<{
        data: {
          reservationMailId: number;
          title: string;
          date: number[];
          time: number[];
          status: keyof typeof emailConstants.status;
        }[];
      }>(path, {
        signal,
      }),
    select: ({ data: { data } }) =>
      _.chain(data).sortBy('reservationMailId').reverse().value(),
  });

  const cancelReservation = useMutation({
    mutationFn: async (reservationMailId: number) =>
      repositoryClient.patch(
        `/admin-mail/reservation/cancel/${reservationMailId}`,
      ),
    onError: (e) => {
      openModal(
        <AlertDialog
          title="에러"
          message={repositoryUtils.getErrorMessage(e)}
        />,
      );
    },
    onSuccess: () => {
      openModal(
        <AlertDialog title="알림" message="예약 메일이 취소되었습니다." />,
      );
      query.refetch();
    },
  });

  const deleteReservation = useMutation({
    mutationFn: async (reservationMailId: number) =>
      repositoryClient.delete(`/admin-mail/reservation/${reservationMailId}`),
    onError: (e) => {
      openModal(
        <AlertDialog
          title="에러"
          message={repositoryUtils.getErrorMessage(e)}
        />,
      );
    },
    onSuccess: () => {
      openModal(<AlertDialog title="알림" message="메일이 삭제되었습니다." />);
      query.refetch();
    },
  });

  invariant(query.data, 'Invalid query data.');

  return (
    <div
      css={css`
        height: 100%;
        overflow: auto;
      `}
    >
      <StyledTable>
        <thead>
          <tr>
            <th>제목</th>
            <th>예약 날짜</th>
            <th>상태</th>
            <th>관리</th>
          </tr>
        </thead>
        <tbody>
          {query.data.map((email) => {
            const reservationDateTime = repositoryUtils.parseDate([
              ...email.date,
              ...email.time,
            ]);

            return (
              <tr key={email.reservationMailId}>
                <td>{email.title}</td>
                <td>
                  {reservationDateTime
                    ? utilTime.format(reservationDateTime, false)
                    : '-'}
                </td>
                <td>{emailConstants.status[email.status]}</td>
                <td>
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      gap: 8px;
                    `}
                  >
                    {/* 예약 대기중에는 비활성화 */}
                    <Button
                      baseColor={utils.style.color.subBlack}
                      onClick={() =>
                        navigate(`./view/${email.reservationMailId}`)
                      }
                    >
                      조회
                    </Button>

                    {/* 예약 취소 또는 완료, 날짜 지남 상태에서 비활성화 */}
                    <Button
                      disabled={
                        email.status !== 'READY' ||
                        (reservationDateTime
                          ? reservationDateTime < new Date()
                          : false)
                      }
                      baseColor={utils.style.color.subBlack}
                      onClick={() =>
                        openModal(
                          <PromptDialog
                            title="예약 취소"
                            message="메일 발송 예약을 취소하시겠습니까?"
                            onConfirm={() =>
                              cancelReservation.mutate(email.reservationMailId)
                            }
                          />,
                        )
                      }
                    >
                      예약 취소
                    </Button>

                    <Button
                      baseColor={utils.style.color.highlight1}
                      onClick={() =>
                        openModal(
                          <PromptDialog
                            title="삭제"
                            message="메일을 삭제하시겠습니까?"
                            onConfirm={() =>
                              deleteReservation.mutate(email.reservationMailId)
                            }
                          />,
                        )
                      }
                    >
                      삭제
                    </Button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </StyledTable>
    </div>
  );
};
