import { useCallback, useMemo, useState } from 'react';

type FileBrowserAttr = Partial<Pick<HTMLInputElement, 'accept' | 'multiple'>>;

export const useFileBrowser = (attr?: FileBrowserAttr) => {
  const [input] = useState(() =>
    Object.assign(document.createElement('input'), {
      type: 'file',
      ...attr,
    }),
  );
  const pick = useCallback(
    async (attr?: FileBrowserAttr) => {
      Object.assign(input, attr);
      return new Promise<FileList | null>((resolve) => {
        input.onchange = () => {
          resolve(input.files);
          setTimeout(() => Object.assign(input, { value: '' }));
        };
        input.click();
      });
    },
    [input],
  );
  return useMemo(() => ({ pick }), [pick]);
};
