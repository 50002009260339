import { utils } from '@admin/utils';
import { css } from '@emotion/react';
import { useRecoilValue } from 'recoil';

import { voiceTrainingFormAtoms } from '../atom';
import { LineData } from './LineData';

export const Script = () => {
  const scriptData = useRecoilValue(voiceTrainingFormAtoms['script-data']);

  return (
    <div
      css={css`
        border: 1px solid ${utils.style.color.grey300};
        border-radius: 4px;
        display: flex;
        flex-direction: column;
      `}
    >
      <p
        css={css`
          border-bottom: 1px solid ${utils.style.color.grey300};
          padding: 4px 8px;
        `}
      >
        {scriptData.file
          ? `스크립트: ${scriptData.file.name}`
          : '선택된 스크립트 없음'}
      </p>

      <ul
        css={css`
          margin: 0;
          height: 400px;
          display: flex;
          flex-direction: column;
          gap: 8px;
          overflow: auto;
          padding: 16px;
        `}
      >
        {scriptData.lines.map((line, i) => (
          <LineData key={line.index} order={i} line={line} />
        ))}
      </ul>
    </div>
  );
};
