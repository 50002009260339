import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledForm } from '@admin/components/Styled/Form';
import { StyledH1 } from '@admin/components/Styled/H1';
import { repositoryClient } from '@admin/repository';
import { DISPLAY_NAMES_VOICE } from '@admin/repository/constant';
import type { Voice } from '@admin/repository/types';
import { repositoryUtils } from '@admin/repository/utils';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';

import type { UpdateVoicePayload } from '../type';

type Payload = Pick<UpdateVoicePayload, 'speakerNo'>;

export const PttsSettingsForm = () => {
  const { name } = useParams();

  const { openModal } = useModal();

  const formRef = useRef<HTMLFormElement>(null);

  const { data: voice } = useQuery({
    queryKey: ['/voice/all'] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ data: Voice[] }>(path, { signal }),
    select: ({ data: { data } }) => data.find((v) => v.name === name),
  });

  invariant(voice, 'Invalid voice data.');

  const { mutate: save, isLoading: isSaving } = useMutation({
    onError: (e) => {
      openModal(
        <AlertDialog
          title="에러"
          message={repositoryUtils.getErrorMessage(e)}
        />,
      );
    },
    onSuccess: () => {
      openModal(
        <AlertDialog
          title="알림"
          message="보이스 정보가 성공적으로 저장되었습니다."
        />,
      );
    },
    mutationFn: () => {
      const { current: form } = formRef;
      invariant(form, 'form is not mounted.');
      const formData = new FormData(form);
      const payload: Payload = {
        speakerNo: Number(formData.get('speaker-no')),
      };
      return repositoryClient.patch(`/voice/${name}`, payload);
    },
  });

  return (
    <ContentBox
      css={css`
        gap: 24px;
      `}
    >
      <StyledH1>PTTS 설정</StyledH1>
      <StyledForm
        ref={formRef}
        onSubmit={(e) => {
          e.preventDefault();
          save();
        }}
      >
        <label>
          {DISPLAY_NAMES_VOICE.speakerNo}
          <input
            required
            name="speaker-no"
            type="number"
            defaultValue={voice.speakerNo}
          />
        </label>
        <Button form disabled={isSaving}>
          저장
        </Button>
      </StyledForm>
    </ContentBox>
  );
};
