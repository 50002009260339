import { Button } from '@admin/components/Button';
import { Dialog } from '@admin/components/Dialog';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledForm } from '@admin/components/Styled/Form';
import { utils } from '@admin/utils';
import { css } from '@emotion/react';

const emailRegex =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

export const AddEmailAddressDialog = (props: {
  onConfirm: (newEmailAddressList: string[]) => void;
}) => {
  const { closeModal } = useModal();

  return (
    <Dialog title="이메일 주소 추가">
      <StyledForm
        onSubmit={(e) => {
          e.preventDefault();
          const textareaElement = e.currentTarget.elements.namedItem(
            'emailAddressTextarea',
          ) as HTMLTextAreaElement;
          const emailAddressList = textareaElement.value
            .split('\n')
            .filter((emailAddress) => emailRegex.test(emailAddress));
          props.onConfirm(emailAddressList);
          closeModal();
        }}
        css={css`
          width: 400px;
          padding: 24px;
        `}
      >
        <p
          css={css`
            ${utils.style.font['text-l']};
          `}
        >
          이메일 주소를 줄바꿈으로 구분하여 입력해주세요.
        </p>

        <textarea name="emailAddressTextarea" />

        <div
          css={css`
            width: 100%;
            display: flex;
            justify-content: flex-end;
            gap: 16px;
          `}
        >
          <Button form>추가</Button>

          <Button
            onClick={closeModal}
            textColor={utils.style.color.grey500}
            baseColor={utils.style.color.grey200}
          >
            취소
          </Button>
        </div>
      </StyledForm>
    </Dialog>
  );
};
