import type { Tag } from '@admin/repository/types';
import _ from 'lodash-es';
import { atom, selectorFamily } from 'recoil';

export const voiceTagFormAtoms = {
  'search-keyword': atom({
    key: 'atom/voice/edit/tag-form/search-keyword',
    default: '',
  }),

  'selected-tags': atom({
    key: 'atom/voice/edit/tag-form/selected-tags',
    default: [] as ReadonlyArray<Tag>,
  }),
} as const;

export const voiceTagFormSelectors = {
  'is-selected': selectorFamily({
    key: 'selector/voice/edit/tag-form/is-selected',
    get:
      (tag: Tag) =>
      ({ get }) =>
        _.some(get(voiceTagFormAtoms['selected-tags']), { id: tag.id }),
  }),
};
