import { CustomSuspense } from '@admin/components/MainLayout/CustomSuspense';
import { VoucherForm } from '@admin/routes/user/edit/VoucherForm';
import { css } from '@emotion/react';

import { ChangeMemberRoleForm } from './ChangeMemberRoleForm';
import { ChangeServiceCodeForm } from './ChangeServiceCodeForm';
import { DeleteMemberButton } from './DeleteMemberButton';
import { DormantForm } from './DormantForm';
import { EventTable } from './EventTable';
import { MemberFlagsForm } from './MemberFlagsForm';
import { MemberInfo } from './MemberInfo';
import { MemberUpdateForm } from './MemberUpdateForm';
import { MyAiVoiceTable } from './MyAiVoiceTable';
import { ResetPasswordForm } from './ResetPasswordForm';
import { Statistics } from './Statistics';
import { SubscriptionForm } from './SubscriptionForm';
import { VoiceAssignmentForm } from './VoiceAssignmentForm';
import { VoiceUsageTable } from './VoiceUsageTable';

export const UserEditorPage = () => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 24px;
      `}
    >
      {/* 유저 정보 */}
      <MemberInfo />
      {/* 가입 정보 */}
      <MemberUpdateForm />
      {/* 플래그 설정 */}
      <MemberFlagsForm />
      {/* 서비스 코드 */}
      <ChangeServiceCodeForm />
      {/* 권한 */}
      <ChangeMemberRoleForm />
      {/* 패스워드 재설정 */}
      <ResetPasswordForm />
      {/* 휴면 정보 */}
      <DormantForm />
      {/* 구독 정보 */}
      <SubscriptionForm />
      {/* MyAIVoice 리스트 */}
      <MyAiVoiceTable />
      {/* 유저 통계 */}
      <Statistics />
      {/* 유저 이벤트 이용권 테이블 */}
      <EventTable />
      {/* 보이스별 사용 통계 */}
      {/* 통계 검색 기간 설정시 이 페이지 전체가 재렌더링 되지 않도록 suspense 설정 */}
      <CustomSuspense>
        <VoiceUsageTable />
      </CustomSuspense>
      {/* 보이스 추가 할당 */}
      <VoiceAssignmentForm />
      {/* 이벤트 쿠폰 */}
      <VoucherForm />
      {/* 유저 삭제 버튼 */}
      <DeleteMemberButton />
    </div>
  );
};
