import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { InputDateRange } from '@admin/components/InputDateRange';
import { StyledH1 } from '@admin/components/Styled/H1';
import { StyledTable } from '@admin/components/Styled/Table';
import { repositoryClient } from '@admin/repository';
import { queryClient } from '@admin/repository/query';
import type { ActiveUserData, DateTimeResponse } from '@admin/repository/types';
import { repositoryUtils } from '@admin/repository/utils';
import { invariant } from '@admin/utils/invariant';
import style from '@admin/utils/style';
import { css } from '@emotion/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { format, subDays } from 'date-fns';
import _ from 'lodash-es';
import { useMemo, useRef, useState } from 'react';
import * as XLSX from 'xlsx';

const DAU_DEFAULT_DATE_RANGE = 7;
const MAU_DEFAULT_DATE_RANGE = 2;

const ActiveUserLabelMap = {
  dau: '일별 사용자 수',
  mau: '월별 사용자 수',
} as const;

export const ActiveUserView = (props: { type: 'dau' | 'mau' }) => {
  const tableRef = useRef<HTMLTableElement>(null);

  const now = new Date();

  const defaultState = () => {
    if (props.type === 'dau') {
      return { start: subDays(now, DAU_DEFAULT_DATE_RANGE), end: now };
    }
    return { start: subDays(now, MAU_DEFAULT_DATE_RANGE), end: now };
  };

  const [dateRange, setDateRange] = useState(defaultState);
  const [searchParams, setSearchParams] = useState(defaultState);

  const [isFetched, setIsFetched] = useState<boolean>(false);
  const [displayMessage, setDisplayMessage] = useState<string>('');

  const exportActiveUser = () => {
    const { current: table } = tableRef;
    if (!table) {
      return;
    }
    const dateFormat = props.type === 'dau' ? 'yyyy. MM. dd' : 'yyyy. MM';
    const formattedStartDate = format(searchParams.start, dateFormat);
    const formattedEndDate = format(searchParams.end, dateFormat);
    const fileName = `${
      ActiveUserLabelMap[props.type]
    } 통계 (${formattedStartDate} - ${formattedEndDate}).xlsx`;
    const wb = XLSX.utils.table_to_book(table, {
      sheet: `${ActiveUserLabelMap[props.type]} 통계`,
    });
    XLSX.writeFile(wb, fileName);
  };

  const query = useQuery({
    queryKey: [
      `/statistics/${props.type}`,
      {
        since: format(searchParams.start, 'yyyy-MM-dd'),
        till: format(searchParams.end, 'yyyy-MM-dd'),
      },
    ] as const,
    queryFn: ({ queryKey: [path, payload], signal }) =>
      repositoryClient.post<{
        data: {
          readonly auStatisticsResponses: ReadonlyArray<ActiveUserData>;
          readonly modifiedAt: DateTimeResponse;
        };
      }>(path, payload, { signal }),
    select: ({ data: { data } }) => ({
      ...data,
      auStatisticsResponses: _.chain(data.auStatisticsResponses)
        .sortBy(({ day }) => repositoryUtils.parseDate(day))
        .reverse()
        .value(),
    }),
    enabled: searchParams.start <= searchParams.end && searchParams.end <= now,
  });

  const { mutate: fetchData, isLoading: fetchLoading } = useMutation({
    mutationFn: () => repositoryClient.patch(`/statistics/${props.type}`),
    onSuccess: () => {
      setIsFetched(true);
      setDisplayMessage('요청 성공! (반영되기 까지 시간이 걸립니다)');
      queryClient.invalidateQueries([
        `/statistics/${props.type}`,
        {
          since: format(searchParams.start, 'yyyy-MM-dd'),
          till: format(searchParams.end, 'yyyy-MM-dd'),
        },
      ]);
    },
    onError: (e) => {
      setDisplayMessage(repositoryUtils.getErrorMessage(e));
    },
  });

  const lastRequest = useMemo(() => {
    if (
      query.data !== undefined &&
      (query.data.modifiedAt as number[])[0] !== 999999999
    ) {
      return format(
        repositoryUtils.parseDate(query.data?.modifiedAt) as Date,
        'yyyy년 MM월 dd일 H시 m분 S초',
      );
    }
    return '기간이 유효하지 않습니다.';
  }, [query]);

  const onClickSearch = () => {
    setSearchParams(dateRange);
  };

  return (
    <ContentBox
      css={css`
        flex: 1;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          flex: 1 0;
          gap: 24px;
          height: 100%;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: space-between;
          `}
        >
          <StyledH1>{ActiveUserLabelMap[props.type]}</StyledH1>
          <Button onClick={exportActiveUser}>내보내기</Button>
        </div>
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
          `}
        >
          <div
            css={css`
              display: flex;
              gap: 16px;
            `}
          >
            <InputDateRange value={dateRange} onChange={setDateRange} />
            <Button onClick={onClickSearch}>조회</Button>
          </div>
          <div
            css={css`
              display: flex;
              align-items: center;
              ${style.font['text-s']}
              gap: 12px;
            `}
          >
            <p>
              마지막 업데이트 - {lastRequest} <br />
              {displayMessage}
            </p>
            <Button
              disabled={isFetched || fetchLoading}
              onClick={() => fetchData()}
            >
              업데이트
            </Button>
          </div>
        </div>

        <div
          css={css`
            height: 100%;
            overflow: auto;
          `}
        >
          {(() => {
            if (dateRange.start > dateRange.end) {
              return (
                <div
                  css={css`
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                  `}
                >
                  검색 시작 날짜는 끝 날짜보다 늦을 수 없습니다.
                </div>
              );
            }

            if (dateRange.end > now) {
              return (
                <div
                  css={css`
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                  `}
                >
                  검색 끝 날짜는 현재 날짜보다 늦을 수 없습니다.
                </div>
              );
            }

            invariant(query.data, 'Invalid query data.');

            return (
              <StyledTable ref={tableRef}>
                <thead>
                  <tr>
                    <th>날짜</th>
                    <th>{ActiveUserLabelMap[props.type]}</th>
                  </tr>
                </thead>
                <tbody>
                  {query.data.auStatisticsResponses.map(({ count, day }) => {
                    const parsedDate = repositoryUtils.parseDate(day);
                    invariant(parsedDate, `Invalid date: ${day}`);
                    const formattedDate = format(parsedDate, 'yyyy-MM-dd');
                    return (
                      <tr key={formattedDate}>
                        <td>{formattedDate}</td>
                        <td>{count}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </StyledTable>
            );
          })()}
        </div>
      </div>
    </ContentBox>
  );
};
