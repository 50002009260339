import { ReactComponent as InfoIcon } from '@admin/assets/icon/info.svg';
import { utils } from '@admin/utils';
import { css } from '@emotion/react';

export const ErrorText: React.FC<{ errorText: string }> = ({ errorText }) => {
  return errorText.length === 0 ? null : (
    <div
      css={css`
        display: flex;
        align-items: center;
      `}
    >
      <InfoIcon width={20} height={20} fill={utils.style.color.highlight1} />
      <div
        css={css`
          margin-left: 6px;
          ${utils.style.font['text-m']};
          color: ${utils.style.color.highlight1};
        `}
      >
        {errorText}
      </div>
    </div>
  );
};
