import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { PromptDialog } from '@admin/components/Dialog/PromptDialog';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledH1 } from '@admin/components/Styled/H1';
import { repositoryClient } from '@admin/repository';
import type { Voice } from '@admin/repository/types';
import { repositoryUtils } from '@admin/repository/utils';
import { utils } from '@admin/utils';
import { useFileBrowser } from '@admin/utils/hooks';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

export const ThumbnailForm = () => {
  const { name } = useParams();

  const { openModal } = useModal();

  const { data: voice } = useQuery({
    queryKey: ['/voice/all'] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ data: Voice[] }>(path, { signal }),
    select: ({ data: { data } }) => data.find((v) => v.name === name),
  });

  invariant(voice, 'Invalid voice data.');

  const fileBrowser = useFileBrowser({
    accept: 'image/png,image/jpeg,image/gif',
    multiple: false,
  });

  const [selectedFile, selectFile] = useState<File | undefined>();

  const selectedFileSrc = useMemo(
    () => selectedFile && URL.createObjectURL(selectedFile),
    [selectedFile],
  );

  useEffect(
    () => () => {
      if (selectedFileSrc) {
        URL.revokeObjectURL(selectedFileSrc);
      }
    },
    [selectedFileSrc],
  );

  const queryKey = [
    '/file/static',
    {
      resourceDomain: 'voices',
      resourceName: 'THUMBNAIL',
      voiceName: voice.name,
    },
  ] as const;

  const query = useQuery({
    queryKey,
    queryFn: async ({
      queryKey: [path, { resourceDomain, resourceName, voiceName }],
      signal,
    }) => {
      const response = await repositoryClient.get<Blob>(
        `${path}/${resourceDomain}`,
        {
          params: { staticType: resourceName, voiceName },
          responseType: 'blob',
          validateStatus: (status) => status < 400 || status === 404,
          signal,
        },
      );
      return {
        res: response,
        src:
          response.status === 404
            ? undefined
            : URL.createObjectURL(response.data),
      };
    },
    staleTime: 5 * 60 * 1000,
    behavior: {
      onFetch: ({ state: { data } }) => {
        if (data?.src) {
          URL.revokeObjectURL(data.src);
        }
      },
    },
  });

  invariant(query.data, 'Invalid query data.');

  const { mutate: apply, isLoading: isApplying } = useMutation({
    onError: (e) => {
      openModal(
        <AlertDialog
          title="에러"
          message={repositoryUtils.getErrorMessage(e)}
        />,
      );
    },
    onSuccess: () => {
      openModal(
        <AlertDialog
          title="알림"
          message="썸네일이 성공적으로 적용되었습니다."
        />,
      );
      query.refetch();
      selectFile(undefined);
    },
    mutationFn: () => {
      const [path, { resourceDomain, resourceName, voiceName }] = queryKey;
      if (!voiceName) {
        throw new Error('invalid name');
      }
      if (!selectedFile) {
        throw new Error('file not selected');
      }
      const payload = new FormData();
      payload.append('uploadFile', selectedFile);
      payload.append(
        'voiceStaticDto',
        new Blob([JSON.stringify({ staticType: resourceName, voiceName })], {
          type: 'application/json',
        }),
      );
      return repositoryClient.post(`${path}/${resourceDomain}`, payload);
    },
  });

  const { mutate: deleteThumbnail, isLoading: isDeleting } = useMutation({
    onError: (e) => {
      openModal(
        <AlertDialog
          title="에러"
          message={repositoryUtils.getErrorMessage(e)}
        />,
      );
    },
    onSuccess: () => {
      openModal(
        <AlertDialog
          title="알림"
          message="썸네일이 성공적으로 삭제되었습니다."
        />,
      );
      query.refetch();
    },
    mutationFn: () => {
      const [path, { resourceDomain, resourceName, voiceName }] = queryKey;
      if (!voiceName) {
        throw new Error('invalid name');
      }
      return repositoryClient.delete(`${path}/${resourceDomain}`, {
        data: { staticType: resourceName, voiceName },
      });
    },
  });

  return (
    <ContentBox
      css={css`
        gap: 20px;
      `}
    >
      <StyledH1>썸네일</StyledH1>

      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 20px;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: flex-end;
            gap: 20px;
          `}
        >
          <img
            css={css`
              width: 200px;
              height: 200px;
              object-fit: contain;
            `}
            src={selectedFileSrc || query.data.src}
            alt="thumbnail"
          />
          <span>{selectedFile && `선택됨: ${selectedFile.name}`}</span>
        </div>

        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 20px;
          `}
        >
          <Button
            disabled={isApplying || isDeleting}
            onClick={async () => {
              if (selectedFile) {
                selectFile(undefined);
                return;
              }
              const file = await fileBrowser
                .pick()
                .then((selection) => selection?.item(0));
              if (!file) {
                return;
              }
              if (file.size > 5 * 1024 * 1024) {
                openModal(
                  <AlertDialog
                    title="알림"
                    message="파일 첨부는 최대 5MB까지 가능합니다."
                  />,
                );
                return;
              }
              selectFile(file);
            }}
            textColor={utils.style.color.black}
            baseColor={utils.style.color.white}
            css={css`
              width: 100px;
              border: 1px solid ${utils.style.color.grey300};
            `}
          >
            {selectedFile ? '선택 취소' : '파일 선택'}
          </Button>

          <strong
            css={css`
              margin-top: 4px;
            `}
          >
            PNG/JPG/GIF 형식, 5MB 미만의 파일만 적용 가능합니다.
          </strong>
        </div>

        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 20px;
          `}
        >
          <Button
            disabled={!selectedFile || isApplying || isDeleting}
            onClick={() =>
              openModal(
                <PromptDialog
                  title="적용 확인"
                  message="선택한 썸네일을 적용하시겠습니까?"
                  onConfirm={apply}
                />,
              )
            }
          >
            적용
          </Button>

          <Button
            disabled={!query.data.src || isApplying || isDeleting}
            onClick={() =>
              openModal(
                <PromptDialog
                  title="삭제"
                  message="썸네일을 삭제하시겠습니까?"
                  onConfirm={deleteThumbnail}
                />,
              )
            }
            baseColor={utils.style.color.highlight1}
          >
            삭제
          </Button>
        </div>
      </div>
    </ContentBox>
  );
};
