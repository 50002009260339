import { InputSelect } from '@admin/components/InputSelect';
import { InputContainer } from '@admin/components/Styled/Input/InputContainer';
import {
  eventPageAtom,
  mouseClickAtom,
  selectedTypeAtom,
} from '@admin/routes/eventPage/edit/context/atom';
import { utils } from '@admin/utils';
import { css } from '@emotion/react';
import { useCallback, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';

export const ImageUpload = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [eventPageState, setEventPageState] = useRecoilState(eventPageAtom);
  const [_, setMouseClickState] = useRecoilState(mouseClickAtom);

  /** 현재 선택한 이벤트의 타입( PC / Mobile ) 을 관리하는 Recoil 상태 값 */
  const [selectedTypeState, setSelectedTypeState] =
    useRecoilState(selectedTypeAtom);

  const [uploadedFileName, setUploadedFileName] = useState({
    pcFileName: eventPageState.pcImageFile.name ?? '',
    mobileFileName: eventPageState.mobileImageFile.name ?? '',
  });

  /** 파일 업로드 후 eventPageState에 존재하는 image 관련 필드 값 업데이트 */
  const updateEventImageState = useCallback(
    (file: File) => {
      const fileUrl = URL.createObjectURL(file);
      if (selectedTypeState === 'PC') {
        URL.revokeObjectURL(eventPageState.pcImageUrl);
        setEventPageState((prev) => ({
          ...prev,
          pcImageFile: file,
          pcImageUrl: fileUrl,
          isPCBasicImage: false,
        }));
      } else if (selectedTypeState === 'Mobile') {
        URL.revokeObjectURL(eventPageState.mobileImageUrl);
        setEventPageState((prev) => ({
          ...prev,
          mobileImageFile: file,
          mobileImageUrl: fileUrl,
          isMobileBasicImage: false,
        }));
      }
    },
    [
      eventPageState.mobileImageUrl,
      eventPageState.pcImageUrl,
      selectedTypeState,
      setEventPageState,
    ],
  );

  return (
    <div>
      <p
        css={css`
          border-bottom: 1px solid ${utils.style.color.grey300};
        `}
      >
        이벤트 이미지 업로드
      </p>
      <InputContainer
        css={css`
          height: 50%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-top: 10px;
        `}
      >
        <InputSelect
          name="imageType"
          onChange={(value) => {
            setSelectedTypeState(value);
            setMouseClickState((prevArray) => {
              let updatedArray = [...prevArray];
              updatedArray = updatedArray.map((_) => false);
              return updatedArray;
            });
          }}
        >
          <option value="PC">PC</option>
          <option value="Mobile">Mobile</option>
        </InputSelect>

        <input
          css={css`
            color: white;
            margin-top: 18px;
          `}
          ref={inputRef}
          type="file"
          name="imageFileInput"
          accept="image/*"
          onChange={() => {
            if (
              !inputRef.current ||
              !inputRef.current.files ||
              !inputRef.current.files[0]
            ) {
              return;
            }
            const file = inputRef.current.files[0];
            const fileName =
              selectedTypeState === 'PC' ? 'pcFileName' : 'mobileFileName';
            setUploadedFileName((prev) => ({
              ...prev,
              [fileName]: file.name,
            }));
            updateEventImageState(file);
          }}
        />
        {selectedTypeState === 'PC'
          ? uploadedFileName.pcFileName || '파일을 선택해주세요.'
          : uploadedFileName.mobileFileName || '파일을 선택해주세요.'}
      </InputContainer>
    </div>
  );
};
