import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { PromptDialog } from '@admin/components/Dialog/PromptDialog';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledForm } from '@admin/components/Styled/Form';
import { StyledH1 } from '@admin/components/Styled/H1';
import { TextEditor } from '@admin/components/textEditor/TextEditor';
import { repositoryClient } from '@admin/repository';
import { queryClient } from '@admin/repository/query';
import type { Notice } from '@admin/repository/types';
import { repositoryUtils } from '@admin/repository/utils';
import { utils } from '@admin/utils';
import { css } from '@emotion/react';
import { useMutation } from '@tanstack/react-query';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { faqCategories } from '../constant';

export const FAQCreatePage = () => {
  const { openModal } = useModal();

  const formRef = useRef<HTMLFormElement>(null);

  const navigate = useNavigate();

  const [formValue, setFormValue] = useState<
    Pick<Notice, 'category' | 'contents' | 'title'>
  >({
    category: '',
    title: '',
    contents: JSON.stringify([{ type: 'paragraph', children: [{ text: '' }] }]),
  });

  const { mutate: createFAQ } = useMutation({
    onError: (e) => {
      openModal(
        <AlertDialog
          title="에러"
          message={repositoryUtils.getErrorMessage(e)}
        />,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['/faq'] });
      navigate('/faq');
    },
    mutationFn: () => {
      // KT 외부망에서 요청할 경우 방화벽에서 body의 내용을 검사하는데
      // 'base64'가 포함되어 있으면 요청을 차단하기 때문에 'base6@4'로 치환하여 요청
      const body = JSON.parse(
        JSON.stringify(formValue).replace(/base64/g, 'base6@4'),
      );
      return repositoryClient.post('/admin-faq', body);
    },
  });

  return (
    <ContentBox
      css={css`
        gap: 24px;
      `}
    >
      <StyledH1>FAQ 등록</StyledH1>

      <StyledForm
        ref={formRef}
        onSubmit={(e) => {
          e.preventDefault();
          openModal(
            <PromptDialog
              title="확인"
              message="FAQ를 등록하시겠습니까?"
              onConfirm={createFAQ}
            />,
          );
        }}
      >
        <label>
          카테고리
          <select
            required
            onChange={(newValue) =>
              setFormValue((prev) => ({
                ...prev,
                category: newValue.target.value,
              }))
            }
          >
            <option hidden value="">
              선택
            </option>
            {Object.entries(faqCategories).map(
              ([category, displayCategory]) => (
                <option key={category} value={category}>
                  {displayCategory}
                </option>
              ),
            )}
          </select>
        </label>

        <label>
          제목
          <input
            required
            placeholder="제목을 입력해 주세요."
            onChange={(newValue) =>
              setFormValue((prev) => ({
                ...prev,
                title: newValue.target.value,
              }))
            }
          />
        </label>

        <label htmlFor="content">
          답변
          <TextEditor
            initialContent={formValue.contents}
            onChange={(newValue) =>
              setFormValue((prev) => ({ ...prev, contents: newValue }))
            }
          />
        </label>

        <div
          css={css`
            display: flex;
            gap: 16px;
          `}
        >
          <Button form>등록</Button>
          <Button
            textColor={utils.style.color.grey500}
            baseColor={utils.style.color.grey200}
            onClick={() => {
              navigate('..');
            }}
          >
            취소
          </Button>
        </div>
      </StyledForm>
    </ContentBox>
  );
};
