import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { useModal } from '@admin/components/Modal/hooks';
import { repositoryClient } from '@admin/repository';
import { repositoryUtils } from '@admin/repository/utils';
import { css } from '@emotion/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export const StatisticsFilterForTestAccounts = () => {
  const modal = useModal();

  const query = useQuery({
    queryKey: ['/member-test/check'] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ data: boolean }>(path, { signal }),
    select: ({ data: { data } }) => data,
  });

  const queryClient = useQueryClient();

  const change = useMutation({
    mutationFn: () => repositoryClient.post('/member-test/check'),
    onError: (e) => {
      modal.openModal(
        <AlertDialog
          title="에러"
          message={repositoryUtils.getErrorMessage(e)}
        />,
      );
    },
    onSuccess: () => {
      // 모든 쿼리 재요청
      queryClient.invalidateQueries();
    },
  });

  return (
    <label
      css={css`
        user-select: none;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        word-break: keep-all;
        background: #eeeeee;
        padding: 8px;
      `}
    >
      테스트용 계정 통계에서 제외
      <input
        type="checkbox"
        disabled={change.status === 'loading'}
        checked={query.data}
        onChange={() => change.mutate()}
      />
    </label>
  );
};
