import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { UnmaskingDialog } from '@admin/components/Dialog/UnmaskingDialog';
import { InputDateRange } from '@admin/components/InputDateRange';
import { CustomSuspense } from '@admin/components/MainLayout/CustomSuspense';
import { useModal } from '@admin/components/Modal/hooks';
import { Search } from '@admin/components/Search';
import { StyledH1 } from '@admin/components/Styled/H1';
import { StyledSelect } from '@admin/components/Styled/Select';
import { DISPLAY_NAMES_MEMBER } from '@admin/repository/constant';
import type { Member } from '@admin/repository/types';
import { utils } from '@admin/utils';
import { css } from '@emotion/react';
import { format } from 'date-fns';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { ExportButton } from './ExportButton';
import { MemberTable } from './MemberTable';

const columns = [
  'email',
  'phone',
  'serviceCode',
  'name',
] as const satisfies readonly (keyof Member)[];

export const UserListPage = () => {
  const { openModal } = useModal();

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams({
    column: 'email',
    query: '',
    page: '0',
  });

  const [dateRange, setDateRange] = useState(() => {
    const [start, end] = [new Date(), new Date()];
    start.setDate(end.getDate() - 7);
    return { start, end };
  });

  return (
    <ContentBox
      css={css`
        gap: 24px;
        height: 100%;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <StyledH1>유저</StyledH1>

        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 16px;
          `}
        >
          <Button onClick={() => openModal(<UnmaskingDialog refresh />)}>
            마스킹 해제
          </Button>

          <Button onClick={() => navigate('./create')}>유저 생성</Button>

          <ExportButton />
        </div>
      </div>

      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 16px;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            ${utils.style.font['text-s']};
            user-select: none;
            & > label {
              display: flex;
              align-items: center;
              gap: 4px;
            }
          `}
        >
          <label>
            <input
              type="radio"
              name="registerDateRange"
              defaultChecked
              onChange={({ currentTarget: { checked } }) =>
                setSearchParams((prev) => {
                  const params = new URLSearchParams(prev);
                  if (checked) {
                    params.delete('since');
                    params.delete('till');
                  }
                  return params;
                })
              }
            />
            전체 가입 기간
          </label>
          <label>
            <input
              type="radio"
              name="registerDateRange"
              onChange={({ currentTarget: { checked } }) =>
                setSearchParams((prev) => {
                  const params = new URLSearchParams(prev);
                  if (checked) {
                    params.set('since', format(dateRange.start, 'yyyy-MM-dd'));
                    params.set('till', format(dateRange.end, 'yyyy-MM-dd'));
                  }
                  return params;
                })
              }
            />
            가입 기간 지정
          </label>
        </div>

        <InputDateRange
          enableShortcuts
          disabled={!searchParams.get('since') || !searchParams.get('till')}
          onChange={({ start, end }) => {
            setDateRange({ start, end });
            setSearchParams((prev) => {
              const params = new URLSearchParams(prev);
              params.set('since', format(start, 'yyyy-MM-dd'));
              params.set('till', format(end, 'yyyy-MM-dd'));
              return params;
            });
          }}
          value={dateRange}
        />
      </div>

      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 16px;
        `}
      >
        <StyledSelect
          value={searchParams.get('column') as string}
          onChange={({ currentTarget: { value } }) =>
            setSearchParams((prev) => {
              const params = new URLSearchParams(prev);
              params.set('column', value);
              return params;
            })
          }
        >
          {columns.map((colum) => (
            <option key={colum} value={colum}>
              {DISPLAY_NAMES_MEMBER[colum]}
            </option>
          ))}
        </StyledSelect>

        <Search
          defaultValue={searchParams.get('query') as string}
          onSubmit={(searchString) =>
            setSearchParams((prev) => {
              const params = new URLSearchParams(prev);
              if (searchString) {
                params.set('query', searchString);
              } else {
                params.delete('query');
              }
              return params;
            })
          }
          css={css`
            width: 300px;
          `}
        />
      </div>

      {/* 검색바 까지 로딩 렌더링으로 인해 재렌더링 되지 않도록 suspense 영역을 한정 */}
      <CustomSuspense>
        <MemberTable />
      </CustomSuspense>
    </ContentBox>
  );
};
