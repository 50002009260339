import { Checkbox } from '@admin/components/Checkbox';
import { StyledLabel } from '@admin/components/Styled/Label';
import { css } from '@emotion/react';
import { useState } from 'react';

export const ReceivedGroup = ({
  updateEmailToActive,
  updateEmailToDormant,
}: {
  updateEmailToActive: (value: boolean) => void;
  updateEmailToDormant: (value: boolean) => void;
}) => {
  const [emailToActive, setEmailToActive] = useState(false);
  const [emailToDormant, setEmailToDormant] = useState(false);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 8px;
      `}
    >
      <StyledLabel>받는 그룹</StyledLabel>

      <Checkbox
        id="toActive"
        checked={emailToActive}
        onChange={({ currentTarget: { checked } }) => {
          setEmailToActive(checked);
          updateEmailToActive(checked);
        }}
        label="활성"
      />

      <Checkbox
        id="toDormant"
        checked={emailToDormant}
        onChange={({ currentTarget: { checked } }) => {
          setEmailToDormant(checked);
          updateEmailToDormant(checked);
        }}
        label="휴면"
      />
    </div>
  );
};
