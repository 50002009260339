import { InputDateRange } from '@admin/components/InputDateRange';
import { eventPageAtom } from '@admin/routes/eventPage/edit/context/atom';
import { utils } from '@admin/utils';
import { css } from '@emotion/react';
import { useRecoilState } from 'recoil';

export const Expirationdate = () => {
  const [eventPageState, setEventPageState] = useRecoilState(eventPageAtom);
  return (
    <label
      css={css`
        margin-bottom: 40px;
      `}
      htmlFor="dateRangeOpt"
    >
      <p
        css={css`
          border-bottom: 1px solid ${utils.style.color.grey300};
        `}
      >
        유효 기간
      </p>
      <InputDateRange
        onChange={({ start, end }) => {
          setEventPageState((prev) => ({
            ...prev,
            since: start,
            till: end,
          }));
        }}
        value={{
          start: eventPageState.since,
          end: eventPageState.till,
        }}
      />
    </label>
  );
};
