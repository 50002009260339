import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { PromptDialog } from '@admin/components/Dialog/PromptDialog';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledForm } from '@admin/components/Styled/Form';
import { StyledH1 } from '@admin/components/Styled/H1';
import { repositoryClient } from '@admin/repository';
import type { Plan } from '@admin/repository/types';
import { repositoryUtils } from '@admin/repository/utils';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash-es';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

export const PlanEditorPage = () => {
  const { uid } = useParams();

  const { openModal } = useModal();

  const query = useQuery({
    queryKey: ['/plan', { uid }] as const,
    queryFn: ({ queryKey: [path, { uid }], signal }) => {
      if (!uid) {
        throw new Error('invalid plan uid');
      }
      return repositoryClient.get<{ data: Plan }>(`${path}/${uid}`, { signal });
    },
    select: ({ data: { data } }) => data,
  });

  invariant(query.data, 'Invalid query data.');

  const [formValue, setFormValue] = useState<
    Pick<
      Plan,
      'bgmRemovable' | 'isEnableVoiceDubbing' | 'isEnableCreateCustomVoice'
    >
  >(() =>
    _.pick(query.data, [
      'bgmRemovable',
      'isEnableVoiceDubbing',
      'isEnableCreateCustomVoice',
    ]),
  );

  const queryClient = useQueryClient();

  const { mutate: save, isLoading: isSaving } = useMutation({
    onError: (e) => {
      openModal(
        <AlertDialog
          title="에러"
          message={repositoryUtils.getErrorMessage(e)}
        />,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['/plan'] });
      openModal(
        <AlertDialog
          title="알림"
          message="요금제 정보가 성공적으로 변경되었습니다."
        />,
      );
    },
    mutationFn: () => repositoryClient.patch(`/plan/${uid}`, formValue),
  });

  return (
    <ContentBox
      css={css`
        gap: 24px;
      `}
    >
      <StyledH1>요금제 정보</StyledH1>

      <StyledForm>
        <label>
          이름
          <input readOnly value={query.data.displayName} />
        </label>

        <label>
          보이스 개수
          <input readOnly value={query.data.voiceFont} />
        </label>

        <div
          css={css`
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 8px;

            & > label {
              width: max-content;
            }
          `}
        >
          <label>
            워터마크 BGM 제거
            <input
              type="checkbox"
              checked={formValue.bgmRemovable}
              onChange={({ currentTarget: { checked } }) => {
                setFormValue({
                  ...formValue,
                  bgmRemovable: checked,
                });
              }}
            />
          </label>

          <label>
            감정 더빙 권한
            <input
              type="checkbox"
              checked={formValue.isEnableVoiceDubbing}
              onChange={({ currentTarget: { checked } }) => {
                setFormValue({
                  ...formValue,
                  isEnableVoiceDubbing: checked,
                });
              }}
            />
          </label>

          <label>
            마이 AI 보이스 권한
            <input
              type="checkbox"
              checked={formValue.isEnableCreateCustomVoice}
              onChange={({ currentTarget: { checked } }) => {
                setFormValue({
                  ...formValue,
                  isEnableCreateCustomVoice: checked,
                });
              }}
            />
          </label>
        </div>

        <Button
          disabled={
            isSaving ||
            (formValue.bgmRemovable === query.data.bgmRemovable &&
              formValue.isEnableVoiceDubbing ===
                query.data.isEnableVoiceDubbing &&
              formValue.isEnableCreateCustomVoice ===
                query.data.isEnableCreateCustomVoice)
          }
          onClick={() =>
            openModal(
              <PromptDialog
                title="요금제 정보 변경"
                message="현재 입력된 정보를 저장하시겠습니까?"
                onConfirm={save}
                confirmLabel="저장"
              />,
            )
          }
        >
          저장
        </Button>
      </StyledForm>
    </ContentBox>
  );
};
