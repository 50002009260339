import { Button } from '@admin/components/Button';
import type { Voice } from '@admin/repository/types';
import { utils } from '@admin/utils';
import { css } from '@emotion/react';

type Tab = Voice['voiceType'];

const tabColor = {
  active: utils.style.color.subBlack,
  hover: utils.style.color.grey500,
  idle: utils.style.color.grey400,
  disabled: utils.style.color.primary,
};

export const VoiceTableTab = (props: {
  currentTab: Tab;
  onClickTab: (tab: Tab) => void;
}) => {
  return (
    <div
      css={css`
        display: flex;
        & > button {
          flex: 1;
          border-radius: 0;
        }
      `}
    >
      <Button
        disabled={props.currentTab === 'COMMON'}
        onClick={() => props.onClickTab('COMMON')}
        customColors={tabColor}
      >
        일반 보이스
      </Button>
      <Button
        disabled={props.currentTab === 'SPECIAL'}
        onClick={() => props.onClickTab('SPECIAL')}
        customColors={tabColor}
      >
        특별 보이스
      </Button>
      <Button
        disabled={props.currentTab === 'CUSTOM'}
        onClick={() => props.onClickTab('CUSTOM')}
        customColors={tabColor}
      >
        마이 AI 보이스
      </Button>
      <Button
        disabled={props.currentTab === 'PTTS'}
        onClick={() => props.onClickTab('PTTS')}
        customColors={tabColor}
      >
        PTTS
      </Button>
    </div>
  );
};
