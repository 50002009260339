import { ContentBox } from '@admin/components/ContentBox';
import { Divider } from '@admin/components/Divider';
import { InputDateRange } from '@admin/components/InputDateRange';
import { StyledH1 } from '@admin/components/Styled/H1';
import { repositoryClient } from '@admin/repository';
import { css } from '@emotion/react';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { ExportButton } from './ExportButton';
import { ServiceCodeStatistics } from './ServiceCodeStatistics';
import { SpecialVoiceUsage } from './SpecialVoiceUsage';

export const ServiceCodeStatisticsPage = () => {
  const { serviceCode } = useParams();

  const [dateRange, setDateRange] = useState(() => {
    const [start, end] = [new Date(), new Date()];
    start.setDate(end.getDate() - 7);
    return { start, end };
  });

  const { data: serviceCodeStatistics, refetch: serviceCodeStatisticsRefetch } =
    useQuery({
      queryKey: [
        '/statistics/settlements/service-codes',
        { serviceCode },
      ] as const,
      queryFn: ({ queryKey: [path, { serviceCode }], signal }) =>
        repositoryClient.post(
          path,
          { serviceCode, since: dateRange.start, till: dateRange.end },
          {
            signal,
            headers: {
              'Content-Type': 'application/json',
              'Service-Code': serviceCode,
            },
          },
        ),
      select: ({ data: { data } }) => data,
    });

  const {
    data: serviceCodeVoiceSettlement,
    refetch: serviceCodeVoiceSettlementRefetch,
  } = useQuery({
    queryKey: [
      '/statistics/settlements/service-codes/voices',
      { serviceCode },
    ] as const,
    queryFn: ({ queryKey: [path, { serviceCode }], signal }) =>
      repositoryClient.post(
        path,
        { serviceCode, since: dateRange.start, till: dateRange.end },
        {
          signal,
          headers: {
            'Content-Type': 'application/json',
            'Service-Code': serviceCode,
          },
        },
      ),
    select: ({ data: { data } }) => data,
  });

  return (
    <ContentBox
      css={css`
        gap: 24px;
      `}
    >
      <div
        css={css`
          display: flex;
          justify-content: space-between;
        `}
      >
        <StyledH1>서비스 코드 - {serviceCode}</StyledH1>
        <ExportButton
          since={dateRange.start}
          till={dateRange.end}
          serviceCodeStatistics={serviceCodeStatistics}
          serviceCodeVoiceSettlement={serviceCodeVoiceSettlement}
        />
      </div>

      <InputDateRange
        enableShortcuts
        onChange={(newDateRange) => {
          setDateRange(newDateRange);
          setTimeout(() => {
            serviceCodeStatisticsRefetch();
            serviceCodeVoiceSettlementRefetch();
          }, 0);
        }}
        value={dateRange}
      />

      <ServiceCodeStatistics serviceCodeStatistics={serviceCodeStatistics} />

      <Divider />

      <SpecialVoiceUsage
        serviceCodeVoiceSettlement={serviceCodeVoiceSettlement}
      />
    </ContentBox>
  );
};
