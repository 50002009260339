import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { CustomSuspense } from '@admin/components/MainLayout/CustomSuspense';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledH1 } from '@admin/components/Styled/H1';
import { repositoryClient } from '@admin/repository';
import { DISPLAY_NAMES_VOICE_TYPE } from '@admin/repository/constant';
import type { Voice } from '@admin/repository/types';
import { utils } from '@admin/utils';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { AssignCustomVoiceDialog } from './CustomVoice/AssignCustomVoiceDialog';
import { AssignedCustomVoiceTable } from './CustomVoice/AssignedCustomVoiceTable';
import { AssignedPTTSVoiceTable } from './PTTSVoice/AssignedPTTSVoiceTable';
import { AssignPTTSVoiceDialog } from './PTTSVoice/AssignPTTSVoiceDialog';
import { AssignedSpecialVoiceTable } from './SpecialVoice/AssignedSpecialVoiceTable';
import { AssignSpecialVoiceDialog } from './SpecialVoice/AssignSpecialVoiceDialog';

type AssignableVoiceType = Exclude<Voice['voiceType'], 'COMMON'>;

const tabs = [
  'CUSTOM',
  'PTTS',
  'SPECIAL',
] as const satisfies ReadonlyArray<AssignableVoiceType>;

export const VoiceAssignmentForm = () => {
  const { email: userEmail } = useParams();

  const [searchParams] = useSearchParams();

  const serviceCode = searchParams.get('service-code');

  invariant(userEmail, '사용자 이메일이 없습니다.');

  invariant(serviceCode, '서비스 코드가 없습니다.');

  const { openModal } = useModal();

  const [currentType, setCurrentType] = useState<AssignableVoiceType>('CUSTOM');

  const { data: voices } = useQuery({
    queryKey: ['/voice/all'] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ data: ReadonlyArray<Voice> }>(path, { signal }),
    select: ({ data: { data } }) => data,
  });

  invariant(voices, 'Invalid voice data.');

  const { data: assignedVoiceIds } = useQuery({
    queryKey: ['/voice-permission/user', { serviceCode, userEmail }] as const,
    queryFn: ({ queryKey: [path, params], signal }) =>
      repositoryClient.get<{ data: ReadonlyArray<Voice['voiceId']> }>(path, {
        params,
        signal,
      }),
    select: ({ data: { data } }) => data,
  });

  invariant(assignedVoiceIds, 'Invalid assignment data.');

  return (
    <ContentBox
      css={css`
        gap: 1rem;
      `}
    >
      <header
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <StyledH1>보이스 할당</StyledH1>
        <Button
          onClick={() => {
            switch (currentType) {
              case 'CUSTOM':
                openModal(
                  <AssignCustomVoiceDialog
                    assignedVoiceIds={assignedVoiceIds}
                    userEmail={userEmail}
                    voices={voices}
                  />,
                );
                break;
              case 'PTTS':
                openModal(
                  <AssignPTTSVoiceDialog
                    assignedVoiceIds={assignedVoiceIds}
                    userEmail={userEmail}
                    voices={voices}
                  />,
                );
                break;
              case 'SPECIAL':
                openModal(
                  <AssignSpecialVoiceDialog
                    assignedVoiceIds={assignedVoiceIds}
                    userEmail={userEmail}
                    voices={voices}
                  />,
                );
                break;
              // no default
            }
          }}
        >
          추가 할당 편집
        </Button>
      </header>

      <div
        css={css`
          display: flex;
        `}
      >
        {tabs.map((tab) => (
          <Button
            key={tab}
            disabled={tab === currentType}
            onClick={() => setCurrentType(tab)}
            customColors={{
              active: utils.style.color.subBlack,
              disabled: utils.style.color.primary,
              hover: utils.style.color.grey500,
              idle: utils.style.color.grey400,
            }}
            css={css`
              flex: 1 0;
              border-radius: 0;
            `}
          >
            {DISPLAY_NAMES_VOICE_TYPE[tab]} 보이스
          </Button>
        ))}
      </div>

      <div
        css={css`
          height: 552px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        `}
      >
        <CustomSuspense>
          {currentType === 'CUSTOM' && (
            <AssignedCustomVoiceTable
              assignedVoiceIds={assignedVoiceIds}
              voices={voices}
            />
          )}
          {currentType === 'PTTS' && (
            <AssignedPTTSVoiceTable
              assignedVoiceIds={assignedVoiceIds}
              voices={voices}
            />
          )}
          {currentType === 'SPECIAL' && (
            <AssignedSpecialVoiceTable
              assignedVoiceIds={assignedVoiceIds}
              voices={voices}
            />
          )}
        </CustomSuspense>
      </div>
    </ContentBox>
  );
};
