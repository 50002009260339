import { utils } from '@admin/utils';
import { css } from '@emotion/react';
import Color from 'color';
import type { ReactNode } from 'react';
import { useMemo } from 'react';

type ColorOption =
  | {
      textColor?: string;
    } & (
      | { baseColor?: string; customColors?: undefined }
      | {
          baseColor?: undefined;
          customColors?: Record<
            'active' | 'disabled' | 'hover' | 'idle',
            string
          >;
        }
    );

type ButtonOptions = {
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
} & ColorOption;

type FormButtonProps = {
  form: true;
  onClick?: undefined;
} & ButtonOptions;

type ButtonProps = {
  form?: undefined;
  onClick: () => void;
} & ButtonOptions;

export const Button = (props: FormButtonProps | ButtonProps) => {
  const backgroundColors = useMemo(() => {
    if (props.baseColor) {
      const baseColor = new Color(props.baseColor);
      return {
        idle: baseColor.hexa(),
        hover: baseColor.darken(0.1).desaturate(0.1).hexa(),
        active: baseColor.darken(0.2).desaturate(0.2).hexa(),
        disabled: utils.style.color.grey400,
      };
    }

    if (props.customColors) {
      return props.customColors;
    }

    const defaultIdleColor = new Color(utils.style.color.primary);
    return {
      idle: defaultIdleColor.hexa(),
      hover: defaultIdleColor.darken(0.1).desaturate(0.1).hexa(),
      active: defaultIdleColor.darken(0.2).desaturate(0.2).hexa(),
      disabled: utils.style.color.grey400,
    };
  }, [props]);

  return (
    <button
      className={props.className}
      disabled={props.disabled}
      type={props.form ? 'submit' : 'button'}
      onClick={props.form ? undefined : () => props.onClick()}
      css={css`
        cursor: pointer;
        &:disabeld {
          cursor: default;
        }

        pointer-events: auto;
        &:disabled {
          pointer-events: none;
        }

        color: ${props.textColor ?? utils.style.color.white};

        background: ${backgroundColors.idle};
        &:disabled {
          background: ${backgroundColors.disabled};
        }
        &:hover {
          background: ${backgroundColors.hover};
        }
        &:active {
          background: ${backgroundColors.active};
        }

        border: none;
        border-radius: 8px;

        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 16px;

        ${utils.style.font['button-m']};

        height: 40px;
        min-width: 80px;
        width: fit-content;
        flex-shrink: 0;
      `}
    >
      {props.children}
    </button>
  );
};
