import { utils } from '@admin/utils';
import type { Token } from '@admin/utils/token/types';
import type { ReactNode } from 'react';
import {
  createContext,
  useContext,
  useMemo,
  useSyncExternalStore,
} from 'react';

const context = createContext<Token | null>(null);

export const useAuthorizationContext = () => {
  const token = useContext(context);
  return useMemo<
    | { status: 'loading' }
    | { status: 'unauthorized' }
    | ({ status: 'authorized' } & Required<Token>)
  >(() => {
    if (token === null) {
      return { status: 'loading' };
    }
    if (!token.accessToken || !token.refreshToken) {
      return { status: 'unauthorized' };
    }
    return {
      status: 'authorized',
      ...(token as Required<Token>),
    };
  }, [token]);
};

export const AuthorizationContextProvider = (props: {
  children: ReactNode;
}) => {
  const token = useSyncExternalStore(utils.token.subscribe, utils.token.get);
  return <context.Provider value={token}>{props.children}</context.Provider>;
};
