import { css } from '@emotion/react';
import React from 'react';

interface BaseProps {
  className: string;
  [key: string]: unknown;
}

interface ButtonProps extends BaseProps {
  active: boolean;
  reversed: boolean;
}

export const Button = React.forwardRef<HTMLSpanElement, ButtonProps>(
  ({ active, reversed, ...props }, ref) => {
    const activeColor = reversed ? 'white' : 'black';
    const inactiveColor = reversed ? '#aaa' : '#ccc';
    return (
      <span
        {...props}
        ref={ref}
        css={css`
          cursor: pointer;
          color: ${active ? activeColor : inactiveColor};
        `}
      />
    );
  },
);

Button.displayName = 'Button';

export const Icon = React.forwardRef<HTMLSpanElement, BaseProps>(
  ({ ...props }, ref) => (
    <span
      {...props}
      ref={ref}
      className="material-icons"
      css={css`
        font-size: 20px;
        vertical-align: text-bottom;
      `}
    />
  ),
);

Icon.displayName = 'Icon';

export const Menu = React.forwardRef<HTMLDivElement, BaseProps>(
  ({ ...props }, ref) => (
    <div
      {...props}
      ref={ref}
      css={css`
        & > * {
          display: inline-block;
        }

        & > * + * {
          margin-left: 15px;
        }
      `}
    />
  ),
);

Menu.displayName = 'Menu';

export const Toolbar = React.forwardRef<HTMLDivElement, BaseProps>(
  ({ ...props }, ref) => (
    <Menu
      {...props}
      ref={ref}
      css={css`
        position: relative;
        padding: 1px 18px 17px;
        margin: 0 -20px;
        border-bottom: 2px solid #eee;
        margin-bottom: 20px;
      `}
    />
  ),
);

Toolbar.displayName = 'Toolbar';
