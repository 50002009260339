import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledForm } from '@admin/components/Styled/Form';
import { StyledH1 } from '@admin/components/Styled/H1';
import { repositoryClient } from '@admin/repository';
import {
  DISPLAY_NAMES_VOICE_TYPE,
  VOICE_DELETED_REASON,
} from '@admin/repository/constant';
import type { Voice } from '@admin/repository/types';
import { repositoryUtils } from '@admin/repository/utils';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import _ from 'lodash-es';
import { useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import type { UpdateVoicePayload } from '../type';

type Payload = Pick<
  UpdateVoicePayload,
  'displayName' | 'isEnabled' | 'isDeleted' | 'voiceType'
>;

export const VoiceInfoForm = () => {
  const { name } = useParams();

  const { openModal } = useModal();

  const { data: voice } = useQuery({
    queryKey: ['/voice/all'] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ data: Voice[] }>(path, { signal }),
    select: ({ data: { data } }) => data.find((v) => v.name === name),
  });

  invariant(voice, 'Invalid voice data.');

  const formRef = useRef<HTMLFormElement>(null);

  const [payload, setPayload] = useState<Payload | undefined>(() =>
    _.pick(voice, ['displayName', 'isEnabled', 'isDeleted', 'voiceType']),
  );

  const changedFields = useMemo<Partial<Payload>>(
    () =>
      payload
        ? _.omitBy(payload, (v, k) => v === voice[k as keyof Payload])
        : {},
    [payload, voice],
  );

  const queryClient = useQueryClient();

  const { mutate: update, isLoading: isUpdating } = useMutation({
    onError: (e) => {
      openModal(
        <AlertDialog
          title="에러"
          message={repositoryUtils.getErrorMessage(e)}
        />,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['/voice/all']);
      openModal(
        <AlertDialog
          title="알림"
          message="보이스 정보가 성공적으로 업데이트되었습니다."
        />,
      );
    },
    mutationFn: async () => {
      await repositoryClient.patch(`/voice/${voice.name}`, changedFields);
      if (voice.voiceType !== payload?.voiceType)
        await repositoryClient.patch(`/voice/${voice.voiceId}/type`, {
          newType: changedFields.voiceType,
        });
    },
  });

  return (
    <ContentBox
      css={css`
        gap: 20px;
      `}
    >
      <StyledH1>정보</StyledH1>

      <StyledForm
        ref={formRef}
        onSubmit={(e) => {
          e.preventDefault();
          update();
        }}
      >
        <label>
          유형
          <select
            required
            disabled={
              voice.voiceType === 'CUSTOM' || voice.voiceType === 'PTTS'
            }
            value={payload?.voiceType}
            onChange={({ currentTarget: { value: voiceType } }) =>
              setPayload(
                (prev) =>
                  prev && {
                    ...prev,
                    voiceType: voiceType as Voice['voiceType'],
                  },
              )
            }
          >
            {Object.entries(DISPLAY_NAMES_VOICE_TYPE).map(([key, value]) => (
              <option
                key={key}
                value={key}
                disabled={key === 'CUSTOM' || key === 'PTTS'}
              >
                {value}
              </option>
            ))}
          </select>
        </label>

        <label>
          내부 이름
          <input disabled defaultValue={voice.name} />
        </label>

        {voice.voiceType === 'CUSTOM' && (
          <>
            <label>
              마이AI보이스 생성자 계정
              <input disabled defaultValue={voice.creatorEmail} />
            </label>

            <label>
              마이AI보이스 녹음 등록일
              <input
                disabled
                defaultValue={
                  dayjs(voice.recordTime as string).isValid()
                    ? dayjs(voice.recordTime as string).format('YYYY.MM.DD')
                    : ''
                }
              />
            </label>

            <label>
              마이AI보이스 생성일
              <input
                disabled
                defaultValue={dayjs(voice.createdAt as string).format(
                  'YYYY.MM.DD',
                )}
              />
            </label>

            <label>
              마이AI보이스 재학습 횟수 정보
              <input disabled defaultValue={voice.trainingCount} />
            </label>
          </>
        )}

        <label>
          표시 이름
          <input
            required
            value={payload?.displayName}
            onChange={({ currentTarget: { value: displayName } }) =>
              setPayload((prev) => prev && { ...prev, displayName })
            }
          />
        </label>

        <label
          css={css`
            width: max-content;
          `}
        >
          활성화
          <input
            type="checkbox"
            checked={payload?.isEnabled}
            onChange={({ currentTarget: { checked: isEnabled } }) =>
              setPayload((prev) => prev && { ...prev, isEnabled })
            }
          />
        </label>

        <label
          css={css`
            width: max-content;
          `}
        >
          삭제여부
          <input
            type="checkbox"
            checked={payload?.isDeleted}
            onChange={({ currentTarget: { checked: isDeleted } }) =>
              setPayload((prev) => prev && { ...prev, isDeleted })
            }
          />
        </label>

        <label>
          삭제일시
          <input
            disabled
            value={
              // eslint-disable-next-line no-nested-ternary
              voice.isDeleted
                ? // eslint-disable-next-line no-nested-ternary
                  payload?.isDeleted
                  ? dayjs(voice.deletedAt as string).isValid()
                    ? dayjs(voice.deletedAt as string).format('YYYY.MM.DD')
                    : '-'
                  : '-'
                : payload?.isDeleted
                ? dayjs().format('YYYY.MM.DD')
                : '-'
            }
          />
        </label>

        <label>
          삭제사유
          <select
            disabled
            value={
              voice.deletedReason ||
              (payload?.isDeleted ? VOICE_DELETED_REASON.ADMIN?.key : 'NONE')
            }
          >
            {Object.entries(VOICE_DELETED_REASON).map(([key, value]) => (
              <option key={key} value={value.key}>
                {value.value}
              </option>
            ))}
          </select>
        </label>

        <Button form disabled={isUpdating || _.isEmpty(changedFields)}>
          저장
        </Button>
      </StyledForm>
    </ContentBox>
  );
};
