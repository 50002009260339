import { cloneDeep } from 'lodash-es';

const pad = (time: number) => {
  return time.toString().padStart(2, '0');
};

const convertMinsToHrsMins = (mins: number) => {
  let h: string | number = Math.floor(Math.abs(mins) / 60);
  let m: string | number = Math.abs(mins % 60);
  h = h < 10 ? `0${h}` : h;
  m = m < 10 ? `0${m}` : m;
  return `${mins >= 0 ? '+' : '-'}${h}${m}`;
};

const timezoneOffsetString = convertMinsToHrsMins(
  -new Date().getTimezoneOffset(),
);

const getISODateTimeString = (dateTime: string) => {
  const isoDateTime = new Date(dateTime);
  return `${isoDateTime.getFullYear()}-${pad(isoDateTime.getMonth() + 1)}-${pad(
    isoDateTime.getDate(),
  )}T${pad(isoDateTime.getHours())}:${pad(isoDateTime.getMinutes())}:${pad(
    isoDateTime.getSeconds(),
  )}`;
};

const utilTime = {
  /** 일은 제외하고 달만 계산하여 두 날짜가 몇 개월 차이나는지 반환함 */
  differenceMonth: (startDate: Date, endDate: Date) => {
    return (
      endDate.getMonth() -
      startDate.getMonth() +
      12 * (endDate.getFullYear() - startDate.getFullYear())
    );
  },
  differenceFormat: (startTimeStamp: number, endTimeStamp: number) => {
    const difference = endTimeStamp - startTimeStamp;
    const suffix = difference >= 0 ? '남음' : '전';

    const dist = Math.abs(difference);
    const minutes = Math.floor(dist / (1000 * 60));
    const hours = Math.floor(dist / (1000 * 60 * 60));
    const days = Math.floor(dist / (1000 * 60 * 60 * 24));

    if (days > 0) {
      return `${days}일 ${suffix}`;
    }

    if (hours > 0) {
      return `${hours}시간 ${suffix}`;
    }

    if (minutes > 0) {
      return `${minutes}분 ${suffix}`;
    }

    return `1분 미만${suffix}`;
  },
  format: (time: number | Date, skipHms: boolean) => {
    const dateTime = new Date(time);

    const ymd = [
      dateTime.getFullYear(),
      pad(dateTime.getMonth() + 1),
      pad(dateTime.getDate()),
    ].join('-');

    if (skipHms) {
      return ymd;
    }

    const hms = [
      pad(dateTime.getHours()),
      pad(dateTime.getMinutes()),
      pad(dateTime.getSeconds()),
    ].join(':');

    return `${ymd} ${hms}`;
  },
  toLocalString: (utcString: string) => {
    return getISODateTimeString(utcString);
  },
  toUTCString: (localString: string) => {
    return getISODateTimeString(localString) + timezoneOffsetString;
  },
  getDateOnly: (dateTime: Date) => {
    const date = cloneDeep(dateTime);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  },
  convertUTCtoKST: (dateTime: Date) => {
    const dateTimeClone = cloneDeep(dateTime);
    dateTimeClone.setHours(dateTimeClone.getHours() - 9);
    return dateTimeClone;
  },
};

export default utilTime;
