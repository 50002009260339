import { Search } from '@admin/components/Search';
import { StyledSelect } from '@admin/components/Styled/Select';
import { VerticalDivider } from '@admin/components/VerticalDivider';
import {
  DISPLAY_NAMES_PLAN,
  DISPLAY_NAMES_VOICE,
} from '@admin/repository/constant';
import type { Voice } from '@admin/repository/types';
import { utils } from '@admin/utils';
import { css } from '@emotion/react';
import _ from 'lodash-es';
import type { ChangeEvent } from 'react';
import { Fragment, useMemo } from 'react';

interface PropType {
  used?: boolean;
  isSmallInput?: boolean;
  handleSelectValue(voiceType: keyof Voice): void;
  handleSearchValue(value: string): void;
  handleIsEnable?(enable: 'all' | 'true' | 'false'): void;
  handlePlanName?(e: ChangeEvent<HTMLInputElement>): void;
}

export const Filter = ({
  used,
  isSmallInput,
  handleIsEnable,
  handlePlanName,
  handleSearchValue,
  handleSelectValue,
}: PropType) => {
  const usedValue = useMemo(() => {
    if (_.isUndefined(used)) return '';
    return used ? 'used-' : 'unused-';
  }, [used]);

  const radioInfo: {
    id: string;
    label: string;
    value: 'all' | 'true' | 'false';
  }[] = useMemo(
    () => [
      {
        id: `${usedValue}is-enable-all`,
        label: '전체',
        value: 'all',
      },
      { id: `${usedValue}is-enabled-true`, label: '활성', value: 'true' },
      { id: `${usedValue}is-enabled-false`, label: '비활성', value: 'false' },
    ],
    [usedValue],
  );

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: 16px;
      `}
    >
      <StyledSelect
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          handleSelectValue(e.target.value as keyof Voice);
        }}
      >
        <option value="displayName">표시 이름</option>
        <option value="name">내부 이름</option>
        <option value="creatorEmail">마이 AI 보이스 생성자</option>
      </StyledSelect>

      <Search
        onSubmit={(str: string) => {
          handleSearchValue(str.toLowerCase());
        }}
        css={
          isSmallInput
            ? css`
                width: 200px;
              `
            : undefined
        }
      />

      <VerticalDivider />

      <section
        css={css`
          display: flex;
          flex-direction: column;
          gap: 4px;
          ${utils.style.font['text-s']};
        `}
      >
        <p>최소 필요 요금제</p>
        <div
          css={css`
            display: flex;
            gap: 4px;
          `}
        >
          {Object.entries({ None: 'None', ...DISPLAY_NAMES_PLAN }).map(
            ([k, v]) => {
              const key = k.toLowerCase();
              return (
                <label
                  key={key}
                  css={css`
                    display: flex;
                    align-items: center;
                    gap: 4px;
                  `}
                >
                  <input
                    type="checkbox"
                    name={`plan-name-${key}`}
                    defaultChecked
                    onChange={(e) => handlePlanName && handlePlanName(e)}
                  />
                  <span>{v}</span>
                </label>
              );
            },
          )}
        </div>
      </section>

      <VerticalDivider />

      <section
        css={css`
          display: flex;
          flex-direction: column;
          gap: 4px;
          ${utils.style.font['text-s']};
        `}
      >
        <p>{DISPLAY_NAMES_VOICE.isEnabled}</p>
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 4px;
          `}
        >
          {radioInfo.map((item) => (
            <Fragment key={item.id}>
              <input
                type="radio"
                id={item.id}
                name={`${usedValue}is-enabled`}
                value={item.value}
                defaultChecked={item.value === 'all'}
                onChange={() => handleIsEnable && handleIsEnable(item.value)}
              />
              <label htmlFor={item.id}>{item.label}</label>
            </Fragment>
          ))}
        </div>
      </section>
    </div>
  );
};
