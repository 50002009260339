import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { StyledH1 } from '@admin/components/Styled/H1';
import { StyledTable } from '@admin/components/Styled/Table';
import { repositoryClient } from '@admin/repository';
import {
  DISPLAY_NAMES_TERMS,
  DISPLAY_NAMES_TERMS_STATUS,
} from '@admin/repository/constant';
import type { Terms, TermsHistory } from '@admin/repository/types';
import { utils } from '@admin/utils';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';

export const TermsHistoryPage = () => {
  const { termsId } = useParams();

  const navigate = useNavigate();

  const termsHistoryQuery = useQuery({
    queryKey: ['/admin-terms/termsHistory', { termsId }] as const,
    queryFn: ({ queryKey: [path, { termsId }], signal }) =>
      repositoryClient.get<{ data: TermsHistory[] }>(`${path}/${termsId}`, {
        signal,
      }),
    select: ({ data: { data } }) => data,
  });

  invariant(termsHistoryQuery.data, 'Invalid terms history data.');

  const { data: termsName } = useQuery({
    queryKey: ['/terms'] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ data: Terms[] }>(path, { signal }),
    select: ({ data: { data } }) =>
      data.find((v) => v.termsId === Number(termsId))?.termsName,
  });

  invariant(termsName, 'Invalid terms data.');

  return (
    <ContentBox
      css={css`
        gap: 24px;
      `}
    >
      <StyledH1>
        약관 및 정책 히스토리 - {termsName && DISPLAY_NAMES_TERMS[termsName]}
      </StyledH1>

      <div
        css={css`
          height: 500px;
          overflow: auto;
        `}
      >
        <StyledTable>
          <thead>
            <tr>
              <th>공고 일자</th>
              <th>시행 일자</th>
              <th>개정 내역</th>
              <th>상태</th>
              <th>활성화</th>
              <th>관리</th>
            </tr>
          </thead>
          <tbody>
            {termsHistoryQuery.data.map((termsHistory) => (
              <tr key={termsHistory.termsHistoryId}>
                <td>{termsHistory.publicationDate.join('-')}</td>
                <td>{termsHistory.effectiveDate.join('-')}</td>
                <td>{termsHistory.revisionHistory}</td>
                <td>{DISPLAY_NAMES_TERMS_STATUS[termsHistory.status]}</td>
                <td>{termsHistory.isEnabled ? '활성' : '-'}</td>
                <td>
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    `}
                  >
                    <Button
                      baseColor={utils.style.color.subBlack}
                      onClick={() =>
                        navigate(`./preview/${termsHistory.termsHistoryId}`)
                      }
                    >
                      관리
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </StyledTable>
      </div>
    </ContentBox>
  );
};
