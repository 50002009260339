import { Button } from '@admin/components/Button';
import { ModalContainer } from '@admin/components/ModalContainer';
import { StyledTable } from '@admin/components/Styled/Table';
import type { Statistics } from '@admin/repository/types';
import { utils } from '@admin/utils';
import { css } from '@emotion/react';
import { useState } from 'react';

interface Props {
  statistics: Statistics[number];
  onClose: VoidFunction;
}

export const DetailModal = (props: Props) => {
  const tabs = ['성공', '실패'] as const;

  const [currentTab, setCurrentTab] = useState<(typeof tabs)[number]>('성공');

  const voiceUsageData =
    currentTab === '성공'
      ? props.statistics.entireServiceStatisticsDto.voiceUsageStatisticsListDto
          .voiceUsageStatisticsDtoList
      : props.statistics.entireServiceStatisticsDto
          .failedVoiceUsageStatisticsListDto.voiceUsageStatisticsDtoList;

  return (
    <ModalContainer
      onClose={props.onClose}
      title={`보이스 통계 ${props.statistics.date.join('-')}`}
      errorText=""
      fields={
        <>
          <div
            css={css`
              width: 100%;
              display: flex;
            `}
          >
            {tabs.map((tab) => {
              const color = currentTab === tab ? 'white' : 'black';
              const background =
                currentTab === tab ? utils.style.color.primary : 'none';
              const borderColor =
                currentTab === tab
                  ? utils.style.color.primary
                  : utils.style.color.grey100;
              return (
                <div
                  key={tab}
                  onClick={() => setCurrentTab(tab)}
                  css={css`
                    width: 50%;
                    height: 30px;
                    color: ${color};
                    background: ${background};
                    border: 1px solid ${borderColor};
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  `}
                >
                  {tab}
                </div>
              );
            })}
          </div>

          {voiceUsageData.length === 0 ? (
            <div
              css={css`
                flex: 1 0;
                font-size: 16px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
              `}
            >
              데이터가 없습니다.
            </div>
          ) : (
            <div
              css={css`
                max-height: 60vh;
                height: 100%;
                overflow: auto;
              `}
            >
              <StyledTable>
                <thead>
                  <tr>
                    <th>순위</th>
                    <th>이름</th>
                    <th>자 수</th>
                    <th>오디오 콘텐츠</th>
                  </tr>
                </thead>
                <tbody>
                  {voiceUsageData.map((data, index) => (
                    <tr key={data.voiceUid}>
                      <td>{index + 1}</td>
                      <td>{data.voiceDisplayName}</td>
                      <td>{data.characterCount}</td>
                      <td>{data.countAudioContents}</td>
                    </tr>
                  ))}
                </tbody>
              </StyledTable>
            </div>
          )}
        </>
      }
      actions={<Button onClick={props.onClose}>확인</Button>}
    />
  );
};
