export const get = () => {
  return localStorage.getItem('lastApiRequest');
};

export const set = (lastApiRequest: string) => {
  return localStorage.setItem('lastApiRequest', lastApiRequest);
};

export const remove = () => {
  return localStorage.removeItem('lastApiRequest');
};
