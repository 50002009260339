import { css } from '@emotion/react';
import { useRecoilValue } from 'recoil';

import { voiceTagFormAtoms } from '../atom';
import { TagLabel } from './TagLabel';

export const SelectedTags = () => {
  const selectedTags = useRecoilValue(voiceTagFormAtoms['selected-tags']);

  if (selectedTags.length === 0) {
    return <p>적용할 태그를 선택해 주세요.</p>;
  }

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 4px;
      `}
    >
      <strong>선택된 태그</strong>

      <div
        css={css`
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
        `}
      >
        {selectedTags.map((tag) => (
          <TagLabel key={tag.id} tag={tag} />
        ))}
      </div>
    </div>
  );
};
