import { utils } from '@admin/utils';
import { css } from '@emotion/react';

export const Divider: React.FC = () => {
  return (
    <div
      css={css`
        border-top: 1px solid ${utils.style.color.grey300};
      `}
    />
  );
};
