import _ from 'lodash-es';
import { atom, atomFamily, selector } from 'recoil';

import type { Line } from './utils';

export const voiceTrainingFormAtoms = {
  'script-data': atom<{ file: File | null; lines: Line[] }>({
    key: 'atom/voice/edit/training-form/script-data',
    default: { file: null, lines: [] },
  }),
  'audio-data': atomFamily<{ file: File | null }, Line>({
    key: 'atom/voice/edit/training-form/audio-data',
    default: { file: null },
  }),
};

export const voiceTrainingFormSelectors = {
  'training-data': selector({
    key: 'selector/voice/edit/training-form/training-data',
    get: ({ get }) => {
      const { lines } = get(voiceTrainingFormAtoms['script-data']);
      const audioFiles = _.chain(lines)
        .map((line) => get(voiceTrainingFormAtoms['audio-data'](line)).file)
        .filter((file): file is File => !!file)
        .value();
      const recordInfo = lines
        .map(
          ({ filename, language, text }) => `${filename}|${language}|${text}`,
        )
        .join('\n');
      const isReady = lines.length > 0 && audioFiles.length === lines.length;
      return { isReady, recordInfo, audioFiles };
    },
  }),
};
