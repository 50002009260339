import { utils } from '@admin/utils';
import { css } from '@emotion/react';

export const LoadingBox = () => {
  return (
    <div
      css={css`
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${utils.style.color.black};
      `}
    >
      <p
        css={css`
          ${utils.style.font['text-l']};
        `}
      >
        로딩중...
      </p>
    </div>
  );
};
