import CryptoJS from 'crypto-js';
import type { Design } from 'react-email-editor';

export const buildFormData = (
  htmlString: string,
  design: Design,
  emailAddressList: string[],
  emailTitle: string,
  fileRef: React.MutableRefObject<File | undefined>,
  isEdited?: boolean,
) => {
  const { current: file } = fileRef;
  // 방화벽 이슈로 인해 base64로 인코딩하여 전송
  const base64HTMLString = CryptoJS.enc.Base64.stringify(
    CryptoJS.enc.Utf8.parse(htmlString),
  );
  const base64DesignString = CryptoJS.enc.Base64.stringify(
    CryptoJS.enc.Utf8.parse(JSON.stringify(design)),
  );
  const payload = {
    renderingInfo: base64DesignString,
    contents: base64HTMLString,
    title: emailTitle,
    to: emailAddressList,
  } as const;
  const formData = new FormData();

  /** 메일 수정하기 */
  if (isEdited) {
    const updatedPayload = {
      ...payload,
      deleteAttachment: false,
    };
    formData.append(
      'updateMailRequest',
      new Blob([JSON.stringify(updatedPayload)], {
        type: 'application/json',
      }),
    );
  } else {
    formData.append(
      'mailRequest',
      new Blob([JSON.stringify(payload)], { type: 'application/json' }),
    );
  }
  if (file) {
    formData.append('attachment', file);
  }
  return { formData, payload };
};

export const validateEmail = async (
  emailTitle: string,
  getAllEmailAddressList: () => Promise<string[]>,
) => {
  const allEmailAddressList = await getAllEmailAddressList();
  if (allEmailAddressList.length === 0) {
    throw new Error('받는 사람이 없습니다.');
  }
  if (emailTitle.length === 0) {
    throw new Error('메일 제목이 없습니다.');
  }
  return allEmailAddressList;
};
