import { Button } from '@admin/components/Button';
import { repositoryClient } from '@admin/repository';
import type { Repository } from '@admin/repository/types';
import { downloadExcelFile } from '@admin/routes/voucher/edit/VoucherMemberList/context/utils';
import { invariant } from '@admin/utils/invariant';
import { useQuery } from '@tanstack/react-query';

export const DownloadMemberList = ({
  voucherDetail,
}: {
  voucherDetail: Repository['voucher'];
}) => {
  /* Excel 파일 서버에서 받아오기 */
  const { data: voucherMemberListFile } = useQuery({
    queryKey: [
      `/admin/voucher-history/download/${voucherDetail.code}`,
    ] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<Blob>(path, { signal, responseType: 'blob' }),
    select: ({ data }) => data,
  });

  invariant(voucherMemberListFile, 'Invalid voucher member list file.');

  return (
    <Button onClick={() => downloadExcelFile(voucherMemberListFile)}>
      유저목록 내려받기
    </Button>
  );
};
