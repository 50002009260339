import { Button } from '@admin/components/Button';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { LoadingDialog } from '@admin/components/Dialog/LoadingDialog';
import { PromptDialog } from '@admin/components/Dialog/PromptDialog';
import { useModal } from '@admin/components/Modal/hooks';
import { repositoryClient } from '@admin/repository';
import { repositoryUtils } from '@admin/repository/utils';
import { buildFormData, validateEmail } from '@admin/routes/email/create/utils';
import { useMutation } from '@tanstack/react-query';
import type { Design, EditorRef } from 'react-email-editor';
import { useNavigate } from 'react-router-dom';

interface Props {
  getAllEmailAddressList: () => Promise<string[]>;
  propsObject: {
    emailTitle: string;
    fileRef: React.MutableRefObject<File | undefined>;
    mailEditorRef: React.RefObject<EditorRef>;
    editorLoaded: boolean;
  };
}

export const SendEmailButton = (property: Props) => {
  const { openModal, closeModal } = useModal();

  const navigate = useNavigate();

  const props = {
    ...property.propsObject,
    getAllEmailAddressList: property.getAllEmailAddressList,
  };

  const sendEmail = useMutation({
    mutationFn: async ({
      htmlString,
      design,
    }: {
      htmlString: string;
      design: Design;
    }) => {
      const { formData } = buildFormData(
        htmlString,
        design,
        await validateEmail(props.emailTitle, props.getAllEmailAddressList),
        props.emailTitle,
        props.fileRef,
      );
      return repositoryClient.post('/admin-mail', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    },
    onMutate: () => {
      openModal(<LoadingDialog title="알림" message="메일 전송 중입니다." />);
    },
    onError: (e) => {
      closeModal();
      openModal(
        <AlertDialog
          title="에러"
          message={repositoryUtils.getErrorMessage(e)}
        />,
      );
    },
    onSuccess: () => {
      closeModal();
      openModal(
        <AlertDialog title="알림" message="메일 전송에 성공하였습니다." />,
      );
      navigate('..');
    },
  });

  return (
    <Button
      disabled={!props.editorLoaded}
      onClick={() => {
        if (props.emailTitle.length === 0) {
          openModal(
            <AlertDialog title="에러" message="제목을 입력해주세요." />,
          );
          return;
        }
        openModal(
          <PromptDialog
            title="알림"
            message="메일을 지금 발송하시겠습니까?"
            onConfirm={() => {
              if (props.mailEditorRef.current === null) {
                throw new Error('메일 에디터가 로드되지 않았습니다.');
              }
              props.mailEditorRef.current.exportHtml(
                ({ html: htmlString, design }) => {
                  sendEmail.mutate({ htmlString, design });
                },
                { cleanup: true, minify: true },
              );
            }}
          />,
        );
      }}
    >
      메일 발송
    </Button>
  );
};
