import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { PromptDialog } from '@admin/components/Dialog/PromptDialog';
import { useModal } from '@admin/components/Modal/hooks';
import type { emailConstants } from '@admin/routes/email/constants';
import { utils } from '@admin/utils';
import { routeName } from '@admin/utils/routeName';
import { css } from '@emotion/react';
import { useNavigate } from 'react-router-dom';

export const UnmaskingNotificationDialog = ({
  emailQuery,
}: {
  emailQuery: {
    reservationMailId: number;
    toEmailList: string[];
    title: string;
    date: number[];
    time: number[];
    status: keyof typeof emailConstants.status;
    fileName: string;
    hasFile: boolean;
    htmlFilePath: string;
  };
}) => {
  const navigate = useNavigate();

  const { openModal } = useModal();

  return (
    <PromptDialog
      title="알림"
      message={
        emailQuery.status === 'COMPLETE'
          ? '메일을 다시 작성하시겠습니까?'
          : '메일을 수정하시겠습니까?'
      }
      onConfirm={() =>
        emailQuery.toEmailList.join('\n').includes('*')
          ? openModal(
              <AlertDialog
                title="알림"
                message="이메일 마스킹 해제 후 시도해주세요."
              />,
            )
          : navigate(
              `/${routeName.email}/create?id=${emailQuery.reservationMailId}`,
            )
      }
    >
      {emailQuery.toEmailList.join('\n').includes('*') ? (
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          `}
        >
          <a
            href={`/${routeName.user}`}
            css={css`
              text-decoration: none;
            `}
          >
            이메일 마스킹 해제하기
          </a>
          <span
            css={css`
              color: ${utils.style.color.highlight1};
              font-size: ${utils.style.font['text-m']};
            `}
          >
            * 마스킹을 해제한 후 진행해주세요.
          </span>
        </div>
      ) : null}
    </PromptDialog>
  );
};
