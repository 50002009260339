import type { ReactNode } from 'react';
import { Suspense } from 'react';

import { LoadingBox } from './LoadingBox';

interface Props {
  children: ReactNode;
}

export const CustomSuspense = (props: Props) => {
  return <Suspense fallback={<LoadingBox />}>{props.children}</Suspense>;
};
