import { Button } from '@admin/components/Button';
import { utils } from '@admin/utils';
import { css } from '@emotion/react';
import { useCallback, useRef, useState } from 'react';

export const InputFile = (props: {
  name?: string;
  accept?: string;
  displayValue?: string;
  id?: string;
  queryFileName?: string;
  onChange: (
    args:
      | {
          file: File;
          reset: () => void;
        }
      | {
          file: undefined;
          reset: undefined;
        },
  ) => void;
}) => {
  const inputFileRef = useRef<HTMLInputElement>(null);

  const [selectedFileName, setSelectedFileName] = useState<string | null>(null);

  const reset = useCallback(() => {
    if (inputFileRef.current) {
      inputFileRef.current.value = '';
    }
    setTimeout(() => {
      setSelectedFileName(null);
    }, 0);
  }, []);

  const handleUpload = useCallback(() => {
    const [file] = inputFileRef.current?.files ?? [];
    if (!file) {
      props.onChange({ file: undefined, reset: undefined });
      reset();
      return;
    }
    props.onChange({ file, reset });
    setSelectedFileName(file.name);
  }, [reset, props]);

  return (
    <>
      <input
        ref={inputFileRef}
        type="file"
        name={props.name}
        accept={props.accept}
        onChange={handleUpload}
        css={css`
          display: none !important;
        `}
        id={props.id}
      />
      <div
        css={css`
          display: ${props.displayValue || 'flex'};
          gap: 24px;
          align-items: center;
        `}
      >
        <Button
          onClick={() => {
            if (inputFileRef.current === null) {
              return;
            }
            if (inputFileRef.current.value.length > 0) {
              props.onChange({ file: undefined, reset: undefined });
              reset();
              return;
            }
            inputFileRef.current.click();
          }}
          textColor={utils.style.color.black}
          baseColor={utils.style.color.white}
          css={css`
            width: 100px;
            border: 1px solid ${utils.style.color.grey300};
          `}
        >
          {props.queryFileName || selectedFileName ? '제거' : '파일 선택'}
        </Button>
        {props.queryFileName
          ? props.queryFileName
          : selectedFileName ?? '파일을 선택해주세요.'}
      </div>
    </>
  );
};
