import '@admin/components/DatePicker/datePickerStyle.css';

import { DatePickerButton } from '@admin/components/DatePicker/DatePickerButton';
import { DatePickerHeader } from '@admin/components/DatePicker/DatePickerHeader';
import utilTime from '@admin/utils/utilTime';
import ko from 'date-fns/locale/ko';
import { createElement } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';

registerLocale('ko-KR', ko);

/** 입출력 날짜 포맷은 yyyy-MM-dd 입니다.
 * @param value 날짜
 * @param onChange 날짜 선택 이벤트
 */
export const InputDate = (props: {
  disabled?: boolean;
  value: string;
  onChange?: (value: string) => void;
}) => {
  return (
    <ReactDatePicker
      required
      disabled={props.disabled}
      dateFormat="yyyy년 M월 d일"
      selected={new Date(props.value)}
      onChange={(date) => {
        if (date === null || props.onChange === undefined) {
          return;
        }
        props.onChange(utilTime.format(date.getTime(), true));
      }}
      locale="ko-KR"
      // 타입 오류를 해결하기 위해 jsx 태그 대신 createElement 사용
      customInput={createElement(DatePickerButton)}
      renderCustomHeader={DatePickerHeader}
    />
  );
};
