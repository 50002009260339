import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { PromptDialog } from '@admin/components/Dialog/PromptDialog';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledForm } from '@admin/components/Styled/Form';
import { StyledH1 } from '@admin/components/Styled/H1';
import { repositoryClient } from '@admin/repository';
import type { Member, Repository } from '@admin/repository/types';
import { repositoryUtils } from '@admin/repository/utils';
import { utils } from '@admin/utils';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useRef } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { RemoveVoucherDialog } from './RemoveVoucherDialog';

export const VoucherForm = () => {
  const [searchParams] = useSearchParams();
  const serviceCode = searchParams.get('service-code');
  const { email: userEmail } = useParams();
  const { openModal } = useModal();
  const formRef = useRef<HTMLFormElement>(null);
  const queryClient = useQueryClient();

  /* 단일 멤버 데이터 조회 */
  const { data: memberData } = useQuery({
    queryKey: ['/member/single', { serviceCode, userEmail }] as const,
    queryFn: ({ queryKey: [path, { serviceCode, userEmail }], signal }) =>
      repositoryClient.get<{ data: Member }>(`${path}/${userEmail}`, {
        params: { serviceCode },
        signal,
      }),
    select: ({ data: { data } }) => ({
      id: data.id,
    }),
  });
  invariant(memberData, 'Invalid memberData.');

  /* 단일 멤버 바우처 데이터 조회 */
  const { data: voucherMember } = useQuery({
    queryKey: [`/admin/voucher-history/member/${memberData.id}`] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{
        data: Repository['voucher'] & Repository['userVoucher'];
      }>(path, {
        signal,
      }),
    select: ({ data: { data } }) => data,
    enabled: !!memberData.id,
  });

  // invariant(voucherMember, 'Invalid voucher-member data.');

  /* 이벤트 쿠폰 상세 조회 */
  const { data: voucherDetail } = useQuery({
    queryKey: [`/admin/voucher/${voucherMember?.code}`] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ data: Repository['voucher'] }>(path, { signal }),
    select: ({ data: { data } }) => data,
    enabled: !!voucherMember?.code,
  });
  // invariant(voucherDetail, 'Invalid voucher-list data.');

  /* 이벤트 쿠폰 등록 */
  const { mutate: updateEventCoupon, isLoading: isUpdating } = useMutation({
    onError: (e) => {
      const message = repositoryUtils.getErrorMessage(e);
      openModal(<AlertDialog title="에러" message={message} />);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`/admin/voucher-history/member/${memberData.id}`],
      });
      openModal(
        <AlertDialog title="알림" message="이벤트 쿠폰이 등록되었습니다" />,
      );
    },
    mutationFn: () => {
      const { current: form } = formRef;
      invariant(form, 'form is not mounted.');
      const formData = new FormData(form);
      const payload = {
        email: userEmail, // 서버 요청 데이터는 masking 처리 되어있으므로 url에서 추출함
        code: formData.get('code'),
      };
      return repositoryClient.post(`/admin/voucher-history`, payload);
    },
  });

  /* 멤버 바우처 조회를 위한 상태값 매핑 */
  const voucherCharactersOptStatusMap = {
    MONTHLY: `유효 기간 내 매 달 ${voucherDetail?.charactersCount ?? 0}자 충전`,
    ONCE: `유효 기간 내 ${voucherDetail?.charactersCount ?? 0}자 사용 가능`,
    UNLIMITED: '유효 기간 내 무제한 사용 가능',
  };
  const voucherDubbingOptStatusMap = {
    PLAN: '요금제 정책 연동',
    ALWAYS: '항상 사용 가능',
  };

  const voucherMyVoiceOptStatusMap = {
    PLAN: '요금제 정책 연동',
    ALWAYS: `${voucherDetail?.myVoiceCount ?? 0} 개 지급`,
  };

  return (
    <ContentBox
      css={css`
        gap: 1rem;
      `}
    >
      <StyledH1>이벤트 쿠폰</StyledH1>
      <StyledForm
        ref={formRef}
        onSubmit={(e) => {
          e.preventDefault();
          openModal(
            <PromptDialog
              title="확인"
              message={`이벤트 쿠폰을 등록하시겠습니까?\n기존에 등록된 쿠폰은 제거됩니다.`}
              onConfirm={updateEventCoupon}
            />,
          );
        }}
      >
        <label>
          코드
          <input
            key={voucherMember?.code ?? 'code'}
            name="code"
            placeholder="코드 입력"
            defaultValue={voucherMember?.code ?? ''}
          />
        </label>

        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 16px;
          `}
        >
          <Button form disabled={isUpdating}>
            등록
          </Button>
          <Button
            baseColor={utils.style.color.highlight1}
            onClick={() =>
              openModal(<RemoveVoucherDialog memberId={memberData.id} />)
            }
          >
            제거
          </Button>
        </div>

        <label>
          유효 기간
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 16px;
              & > input {
                width: 140px;
                text-align: center;
              }
            `}
          >
            <input
              disabled
              value={voucherMember?.since.join('-') ?? '해당 없음'}
            />
            -
            <input
              disabled
              value={voucherMember?.till.join('-') ?? '해당 없음'}
            />
          </div>
        </label>

        <label>
          사용한 글자 수
          <input disabled value={voucherMember?.usedCharactersCount ?? 0} />
        </label>

        <label>
          사용한 마이 AI 보이스 수
          <input disabled value={voucherMember?.usedMyVoiceCount ?? 0} />
        </label>

        <label>
          글자 수 옵션
          <input
            disabled
            value={
              voucherCharactersOptStatusMap[
                voucherMember?.charactersOpt ?? 'UNLIMITED'
              ]
            }
          />
        </label>

        <label>
          감정 더빙 제작 옵션
          <input
            disabled
            value={
              voucherDubbingOptStatusMap[voucherMember?.dubbingOpt ?? 'PLAN']
            }
          />
        </label>

        <label>
          마이 AI 보이스 생성 옵션
          <input
            disabled
            value={
              voucherMyVoiceOptStatusMap[voucherMember?.myVoiceOpt ?? 'PLAN']
            }
          />
        </label>
      </StyledForm>
    </ContentBox>
  );
};
