import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { UnmaskingDialog } from '@admin/components/Dialog/UnmaskingDialog';
import { Divider } from '@admin/components/Divider';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledH1 } from '@admin/components/Styled/H1';
import { utils } from '@admin/utils';
import { routeName } from '@admin/utils/routeName';
import { css } from '@emotion/react';
import Color from 'color';
import { Link, useParams } from 'react-router-dom';

import { InfoForm } from './InfoForm';
import { MemberTable } from './MemberTable';
import { VoiceManagementTables } from './VoiceManagementTables';
import { useState } from 'react';

export const ServiceCodeEditingPage = () => {
  const { serviceCode } = useParams();

  const { openModal } = useModal();

  const [fetchYn, setFetchYn] = useState(false);

  return (
    <ContentBox
      css={css`
        gap: 24px;

        & > section {
          display: flex;
          flex-direction: column;
          gap: 24px;
        }
      `}
    >
      <section>
        <StyledH1>서비스 코드 정보</StyledH1>
        <InfoForm />
      </section>

      <Divider />

      <section>
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: space-between;
          `}
        >
          <StyledH1>유저 목록</StyledH1>

          <div
            css={css`
              display: flex;
              gap: 16px;
            `}
          >
            <Button onClick={() => setFetchYn(true)}>조회</Button>

            <Button onClick={() => openModal(<UnmaskingDialog />)}>
              마스킹 해제
            </Button>

            <Link
              to={`/${routeName.user}/create?service-code=${serviceCode}`}
              css={css`
                height: 40px;
                min-width: 80px;
                width: fit-content;
                flex-shrink: 0;
                border-radius: 8px;
                background: ${utils.style.color.primary};
                &:hover {
                  background: ${new Color(utils.style.color.primary)
                    .darken(0.1)
                    .desaturate(0.1)
                    .hexa()};
                }
                &:active {
                  background: ${new Color(utils.style.color.primary)
                    .darken(0.2)
                    .desaturate(0.2)
                    .hexa()};
                }
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 8px 16px;
                text-decoration: none;
              `}
            >
              <span
                css={css`
                  color: ${utils.style.color.white};
                  ${utils.style.font['button-m']};
                `}
              >
                유저 생성
              </span>
            </Link>
          </div>
        </div>

        <MemberTable fetchYn={fetchYn} />
      </section>

      <Divider />

      <section>
        <StyledH1>보이스 목록</StyledH1>
        <VoiceManagementTables />
      </section>
    </ContentBox>
  );
};
