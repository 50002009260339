import { StyledTable } from '@admin/components/Styled/Table';
import { repositoryClient } from '@admin/repository';
import { DISPLAY_NAMES_PLAN } from '@admin/repository/constant';
import type { ServiceCodeMetaData, Voice } from '@admin/repository/types';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';

interface Props {
  readonly start: Date;
  readonly end: Date;
}

export const StatisticsTable = (props: Props) => {
  const { name } = useParams();

  const voiceDetailQuery = useQuery({
    queryKey: [`/voice/${name}`] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ data: Voice }>(path, { signal }),
    select: ({ data: { data } }) => data,
  });

  invariant(
    voiceDetailQuery.data,
    `voiceDetailQuery status: ${voiceDetailQuery.status}`,
  );

  const serviceCodeQuery = useQuery({
    queryKey: ['/crm/service-code'] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ data: ServiceCodeMetaData[] }>(path, { signal }),
    select: ({ data: { data } }) => data,
  });

  invariant(
    serviceCodeQuery.data,
    `serviceCodeQuery status: ${serviceCodeQuery.status}`,
  );

  const { data: voice } = voiceDetailQuery;

  const serviceCodeList = serviceCodeQuery.data.map(
    (item) => item.serviceCodeName,
  );

  const specialVoiceSettlementQuery = useQuery({
    queryKey: [
      '/statistics/settlements/special-voices',
      {
        voiceUid: voice.uid,
        serviceCodeList,
        since: format(props.start, 'yyyy-MM-dd'),
        till: format(props.end, 'yyyy-MM-dd'),
      },
    ] as const,
    queryFn: ({ queryKey: [path, payload], signal }) =>
      repositoryClient.post<{
        data: ReadonlyArray<{
          readonly planName: string;
          readonly billingAmount: number;
          readonly settlementAmount: number;
          readonly textCount: number;
        }>;
      }>(path, payload, { signal }),
    select: ({ data: { data } }) => data,
  });

  invariant(
    specialVoiceSettlementQuery.data,
    `specialVoiceSettlementQuery status: ${specialVoiceSettlementQuery.status}`,
  );
  return (
    <div
      css={css`
        height: 100%;
        overflow: auto;
      `}
    >
      <StyledTable>
        <thead>
          <tr>
            <th>요금제</th>
            <th>사용 글자 수</th>
            <th>사용 금액</th>
            <th>정산 금액</th>
          </tr>
        </thead>
        <tbody>
          {specialVoiceSettlementQuery.data.map((item) => (
            <tr key={item.planName}>
              <td>
                {Object.keys(DISPLAY_NAMES_PLAN).includes(item.planName)
                  ? DISPLAY_NAMES_PLAN[
                      item.planName as
                        | 'Free'
                        | 'Basic'
                        | 'Pro'
                        | 'ProMax'
                        | 'Deferred'
                    ]
                  : item.planName}
              </td>
              <td>{item.textCount.toLocaleString()}자</td>
              <td>{item.billingAmount.toLocaleString()}원</td>
              <td>{item.settlementAmount.toLocaleString()}원</td>
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </div>
  );
};
