import { utils } from '@admin/utils';
import { css } from '@emotion/react';

export const InputSelect = (
  props: {
    name?: string;
    required?: boolean;
    children: React.ReactNode;
    defaultValue?: string;
    value?: string;
  } & (
    | { readonly: true }
    | { readonly?: false; onChange?: (value: string) => void }
  ),
) => {
  return (
    <select
      name={props.name}
      required={props.required}
      disabled={props.readonly ?? false}
      css={css`
        height: 40px;
        font-size: 14px;
        padding: 0 10px;
        border: 1px solid ${utils.style.color.grey300};
        border-radius: 10px;
      `}
      defaultValue={props.defaultValue}
      value={props.value}
      onChange={(e) =>
        props.readonly || !props.onChange
          ? undefined
          : props.onChange(e.target.value)
      }
    >
      {props.children}
    </select>
  );
};
