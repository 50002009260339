import { css } from '@emotion/react';

interface Props {
  text: string[];
  inputDisabled: boolean;
  inputName: string[];
  value: string;
  defaultCheckedCondition: boolean;
  defaultValue: number;
}
export const RadioButtonNumberInput = (props: Props) => {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: 12px;
        height: 48px;
      `}
    >
      <input
        type="radio"
        disabled={props.inputDisabled}
        name={props.inputName[0]}
        value={props.value}
        defaultChecked={props.defaultCheckedCondition}
      />
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 12px;
        `}
      >
        {props.text[0]}
        <input
          type="number"
          disabled={props.inputDisabled}
          name={props.inputName[1]}
          step={1}
          min={0}
          defaultValue={props.defaultValue}
          css={css`
            width: 120px;
          `}
        />
        {props.text[1]}
      </div>
    </div>
  );
};
