import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledForm } from '@admin/components/Styled/Form';
import { StyledH1 } from '@admin/components/Styled/H1';
import { repositoryClient } from '@admin/repository';
import type { Inquiry, Member } from '@admin/repository/types';
import { repositoryUtils } from '@admin/repository/utils';
import { invariant } from '@admin/utils/invariant';
import utilTime from '@admin/utils/utilTime';
import { css } from '@emotion/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { inquiryConstants } from '../constants';

// 이 페이지에서 업데이트할 수 있는 것은 카테고리와 답변 2개입니다.

export const InquiryEditPage = () => {
  const { openModal } = useModal();

  const { inquiryId } = useParams();

  invariant(inquiryId, 'Invalid inquiry id.');

  const member = useQuery({
    queryKey: ['/member?isPermission=true'] as const, // 마스킹 해제된 데이터로 받음
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ data: Member }>(path, {
        signal,
      }),
    select: ({ data: { data } }) => data,
  });

  invariant(member.data, 'Invalid member query data.');

  const inquiry = useQuery({
    queryKey: ['/inquiry/admin', { inquiryId }] as const,
    queryFn: ({ queryKey: [path, { inquiryId }], signal }) =>
      repositoryClient.get<{ data: Inquiry }>(`${path}/${inquiryId}`, {
        signal,
      }),
    select: ({ data: { data } }) => data,
  });

  invariant(inquiry.data, 'Invalid inquiry query data.');

  const [inquiryCategory, setInquiryCategory] = useState(
    inquiry.data.inquiryCategory,
  );
  const [answerText, setAnswerText] = useState(inquiry.data.answerText ?? '');

  const category = useMutation({
    onError: (e) => {
      openModal(
        <AlertDialog
          title="에러"
          message={repositoryUtils.getErrorMessage(e)}
        />,
      );
    },
    onSuccess: () => {
      inquiry.refetch();
      openModal(
        <AlertDialog title="알림" message="카테고리가 저장되었습니다." />,
      );
    },
    mutationFn: () => {
      return repositoryClient.patch(`inquiry/admin/${inquiryId}`, {
        inquiryCategory,
      });
    },
  });

  const inquiryAnswer = useMutation({
    onError: (e) => {
      openModal(
        <AlertDialog
          title="에러"
          message={repositoryUtils.getErrorMessage(e)}
        />,
      );
    },
    onSuccess: () => {
      inquiry.refetch();
      openModal(<AlertDialog title="알림" message="답변이 저장되었습니다." />);
    },
    mutationFn: () => {
      return repositoryClient.post(`inquiry/admin/${inquiryId}`, {
        /* 불필요한 데이터 서버에 전달하지 않음 */
        // answererName: member.data.name,
        // answererEmail: member.data.email,
        answerText,
      });
    },
  });

  const inquiredAt = repositoryUtils.parseDate(inquiry.data.inquiredAt);

  const answerer =
    inquiry.data.answererName && inquiry.data.answererEmail
      ? `${inquiry.data.answererName} (${inquiry.data.answererEmail})`
      : '-';
  const answeredAt = repositoryUtils.parseDate(inquiry.data.answeredAt);

  return (
    <ContentBox
      css={css`
        gap: 24px;
      `}
    >
      <StyledH1>고객 문의 관리</StyledH1>

      <StyledForm>
        <label>
          카테고리
          <select
            name="inquiryCategory"
            value={inquiryCategory}
            onChange={({ target: { value } }) =>
              setInquiryCategory(value as Inquiry['inquiryCategory'])
            }
          >
            {Object.entries(inquiryConstants.categories).map(([key, value]) => (
              <option key={key} value={key}>
                {value}
              </option>
            ))}
          </select>
        </label>

        <Button
          disabled={
            inquiry.data.inquiryCategory === inquiryCategory ||
            category.isLoading
          }
          onClick={() => category.mutate()}
        >
          카테고리 저장
        </Button>

        <label>
          작성자 이름
          <input readOnly defaultValue={inquiry.data.name} />
        </label>

        <label>
          작성자 이메일
          <input readOnly defaultValue={inquiry.data.email} />
        </label>

        <label>
          작성 일시
          <input
            readOnly
            defaultValue={inquiredAt ? utilTime.format(inquiredAt, false) : '-'}
          />
        </label>

        <label>
          제목
          <input readOnly defaultValue={inquiry.data.title} />
        </label>

        <label>
          내용
          <textarea readOnly defaultValue={inquiry.data.text} />
        </label>

        <label>
          파일 첨부 여부
          <input
            readOnly
            defaultValue={inquiry.data.hasFile ? '예' : '아니오'}
          />
        </label>

        {inquiry.data.hasFile && (
          <label>
            첨부 파일 이름
            <input readOnly defaultValue={inquiry.data.fileName} />
          </label>
        )}

        <label>
          답변
          <textarea
            name="answerText"
            required
            value={answerText}
            onChange={({ target: { value } }) => setAnswerText(value)}
          />
        </label>

        <label>
          답변자
          <input readOnly key={answerer} defaultValue={answerer} />
        </label>

        <label>
          답변 일시
          <input
            readOnly
            key={answeredAt?.getTime()}
            defaultValue={answeredAt ? utilTime.format(answeredAt, false) : '-'}
          />
        </label>

        <div
          css={css`
            display: flex;
            justify-content: flex-start;
          `}
        >
          <Button
            disabled={
              !answerText ||
              inquiry.data.answerText === answerText ||
              inquiryAnswer.isLoading
            }
            onClick={() => inquiryAnswer.mutate()}
          >
            답변 저장
          </Button>
        </div>
      </StyledForm>
    </ContentBox>
  );
};
