import { ReactComponent as CheckIcon } from '@admin/assets/icon/check.svg';
import { utils } from '@admin/utils';
import styled from '@emotion/styled';
import type { ChangeEvent } from 'react';

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CheckBox = styled.label<{ checked: boolean; defaultCursor?: boolean }>`
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  border: 2px solid
    ${({ checked }) =>
      checked ? utils.style.color.primary : utils.style.color.grey300};
  background: ${({ checked }) =>
    checked ? utils.style.color.primary : 'none'};
  border-radius: 4px;
  position: relative;
  cursor: ${({ defaultCursor }) => (defaultCursor ? 'default' : 'pointer')};
  & > svg {
    position: absolute;
  }
`;

const HiddenCheckbox = styled.input`
  margin: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  border: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
`;

const Label = styled.label<{ defaultCursor?: boolean }>`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  padding-left: 8px;
  cursor: ${({ defaultCursor }) => (defaultCursor ? 'default' : 'pointer')};
  color: ${utils.style.color.black};
`;

export const Checkbox: React.FC<{
  id: string;
  checked: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  label: string;
  defaultCursor?: boolean;
}> = ({ id, checked, onChange, label, defaultCursor }) => {
  return (
    <CheckboxContainer>
      <CheckBox checked={checked} htmlFor={id} defaultCursor={defaultCursor}>
        <HiddenCheckbox
          id={id}
          type="checkbox"
          onChange={onChange}
          checked={checked}
        />
        <CheckIcon width={12} height={12} stroke="white" />
      </CheckBox>
      {label ? (
        <Label htmlFor={id} defaultCursor={defaultCursor}>
          {label}
        </Label>
      ) : null}
    </CheckboxContainer>
  );
};
