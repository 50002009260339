import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { Search } from '@admin/components/Search';
import { StyledH1 } from '@admin/components/Styled/H1';
import { StyledTable } from '@admin/components/Styled/Table';
import { repositoryClient } from '@admin/repository';
import type { Script } from '@admin/repository/types';
import { utils } from '@admin/utils';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { CreateScriptModal } from './_widget/CreateScriptModal';

export const ScriptListPage = () => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const [showCreateScriptModal, setShowCreateScriptModal] = useState(false);

  const searchKeyword = useMemo(
    () => searchParams.get('q') || '',
    [searchParams],
  );

  const query = useQuery({
    queryKey: ['/scripts'] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ data: Script[] }>(path, { signal }),
    select: ({ data: { data } }) => data,
  });

  invariant(query.data, 'Invalid query data.');

  const filteredScriptList = useMemo(
    () =>
      (query.data ?? []).filter(({ title }) => title.includes(searchKeyword)),
    [query.data, searchKeyword],
  );

  return (
    <>
      <ContentBox
        css={css`
          height: 100%;
          gap: 24px;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: space-between;
          `}
        >
          <StyledH1>스크립트</StyledH1>

          <Button onClick={() => setShowCreateScriptModal(true)}>
            스크립트 추가
          </Button>
        </div>

        <Search
          defaultValue={searchKeyword}
          onSubmit={(searchString) =>
            setSearchParams((prev) => {
              const params = new URLSearchParams(prev);
              if (searchString) {
                params.set('q', searchString);
              } else {
                params.delete('q');
              }
              return params;
            })
          }
        />

        <div
          css={css`
            height: 100%;
            overflow: auto;
          `}
        >
          <StyledTable>
            <thead>
              <tr>
                <th>UUID</th>
                <th>스크립트 제목</th>
                <th>태그</th>
                <th>활성화</th>
              </tr>
            </thead>

            <tbody>
              {filteredScriptList.map((script) => (
                <tr
                  key={script.uuid}
                  css={css`
                    &:hover {
                      background: ${utils.style.color.grey100};
                    }
                    cursor: pointer;
                  `}
                  onClick={() => {
                    navigate(`./edit/${script.uuid}`);
                  }}
                >
                  <td>{script.uuid}</td>
                  <td>{script.title}</td>
                  <td>{script.tagName}</td>
                  <td>{script.isEnabled ? '활성' : '-'}</td>
                </tr>
              ))}
            </tbody>
          </StyledTable>
        </div>
      </ContentBox>

      {showCreateScriptModal && (
        <CreateScriptModal onClose={() => setShowCreateScriptModal(false)} />
      )}
    </>
  );
};
