import { InputFile } from '@admin/components/InputFile';
import { excelFileAtom } from '@admin/routes/voucher/edit/VoucherMemberList/context/atom';
import { css } from '@emotion/react';
import { useRecoilState } from 'recoil';

export const ExcelFileUpload = () => {
  const [_, setVoucherMemberExcelFile] = useRecoilState(excelFileAtom);
  return (
    <div
      css={css`
        display: flex;
        gap: 24px;
        align-items: center;
      `}
    >
      <p
        css={css`
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
        `}
      >
        파일 업로드 후 일괄 적용
      </p>

      <InputFile
        accept=".xlsx"
        onChange={({ file }) => {
          if (file) setVoucherMemberExcelFile(file);
        }}
      />
    </div>
  );
};
