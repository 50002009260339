import { StyledTable } from '@admin/components/Styled/Table';
import { DISPLAY_NAMES_VOICE } from '@admin/repository/constant';
import type { Voice } from '@admin/repository/types';
import { css } from '@emotion/react';
import _ from 'lodash-es';
import { useMemo, useState } from 'react';

import { SearchBar } from '../../SearchBar';

type ColumnKey = Extract<keyof Voice, 'creatorEmail' | 'displayName' | 'name'>;

const columns = {
  displayName: DISPLAY_NAMES_VOICE.displayName,
  name: DISPLAY_NAMES_VOICE.name,
  creatorEmail: DISPLAY_NAMES_VOICE.creatorEmail, // 생성자 이메일
} as const satisfies Record<ColumnKey, string>;

const searchColumns = _.pick(columns, ['displayName', 'name']);

interface Props {
  readonly assignedVoiceIds: ReadonlyArray<Voice['voiceId']>;
  readonly voices: ReadonlyArray<Voice>;
}

export const AssignedCustomVoiceTable = (props: Props) => {
  const [search, setSearch] = useState({
    column: 'name' as keyof typeof searchColumns,
    value: '',
  });

  const assignedVoiceIdSet = useMemo(
    () =>
      props.assignedVoiceIds.reduce(
        (set, id) => set.add(id),
        new Set<Voice['voiceId']>(),
      ),
    [props.assignedVoiceIds],
  );

  const assignedCustomVoices = _.chain(props.voices)
    .filter({ voiceType: 'CUSTOM' })
    .filter(({ voiceId }) => assignedVoiceIdSet.has(voiceId))
    .value();

  return (
    <>
      <SearchBar
        column={search.column}
        columns={searchColumns}
        value={search.value}
        onChangeColumn={(column) => setSearch((prev) => ({ ...prev, column }))}
        onSubmitSearchValue={(value) =>
          setSearch((prev) => ({ ...prev, value }))
        }
      />
      <div
        css={css`
          height: 500px;
          overflow: auto;
        `}
      >
        <StyledTable>
          <thead>
            <tr>
              {Object.entries(columns).map(([k, v]) => (
                <th key={k}>{v}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {assignedCustomVoices.map((voice) => (
              <tr key={voice.voiceId}>
                <td>{voice.displayName}</td>
                <td>{voice.name}</td>
                <td>{voice.userEmail}</td>
              </tr>
            ))}
          </tbody>
        </StyledTable>
      </div>
    </>
  );
};
