import { Button } from '@admin/components/Button';
import { PromptDialog } from '@admin/components/Dialog/PromptDialog';
import { ErrorText } from '@admin/components/ErrorText';
import { LoadingBox } from '@admin/components/MainLayout/CustomSuspense/LoadingBox';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledTable } from '@admin/components/Styled/Table';
import { repositoryClient } from '@admin/repository';
import { queryClient } from '@admin/repository/query';
import { repositoryUtils } from '@admin/repository/utils';
import { invariant } from '@admin/utils/invariant';
import style from '@admin/utils/style';
import { css } from '@emotion/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { AddPronunciationMapsTable } from '../AddPronunciationMapsTable';
import type { PronunciationMapsType } from '../types';
import { UpdatePronunciationMapsDialog } from '../UpdatePronunciationMapsDialog';

export const PronunciationMapsTable = () => {
  const [errorText, setErrorText] = useState<string>('');
  const { openModal } = useModal();

  // 발음 교정 목록 불러오기
  const {
    data: pronunciationMapData,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: [`/pronunciation-maps/global`] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get(path, {
        signal,
      }),
    select: ({ data: { data } }) => data,
  });

  // 발음 교정 단어 삭제
  const { mutate: pronunciationMapRemove } = useMutation({
    mutationFn: (text: string) =>
      repositoryClient.delete(`/pronunciation-maps/global`, {
        data: { text },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`/pronunciation-maps/global`],
      });
    },
    onError: (e) => {
      const message = repositoryUtils.getErrorMessage(e);
      setErrorText(message);
    },
  });

  // 발음 교정 단어 수정
  const { mutate: pronunciationMapUpdate } = useMutation({
    mutationFn: ({
      text,
      pronunciation,
    }: {
      text: string;
      pronunciation: string;
    }) =>
      repositoryClient.patch(`/pronunciation-maps/global`, {
        text,
        pronunciation,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`/pronunciation-maps/global`],
      });
    },
    onError: (e) => {
      const message = repositoryUtils.getErrorMessage(e);
      setErrorText(message);
    },
  });

  if (isError) {
    setErrorText(repositoryUtils.getErrorMessage(error));
    return <ErrorText errorText={errorText} />;
  }
  if (isLoading) return <LoadingBox />;

  invariant(pronunciationMapData, 'Invalid pronunciation map data.');

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 24px;
      `}
    >
      <StyledTable
        css={css`
          table-layout: fixed;
        `}
      >
        <thead>
          <tr>
            <th>단어</th>
            <th>발음</th>
            <th>편집</th>
          </tr>
        </thead>
        <tbody>
          <AddPronunciationMapsTable setErrorText={setErrorText} />
          {pronunciationMapData &&
            pronunciationMapData.map((item: PronunciationMapsType) => (
              <tr key={`${item.text}`}>
                <td>{item.text}</td>
                <td>{item.pronunciation}</td>
                <td
                  css={css`
                    display: flex;
                    justify-content: center;
                    gap: 8px;
                  `}
                >
                  <Button
                    css={css`
                      display: inline;
                    `}
                    onClick={() =>
                      openModal(
                        <UpdatePronunciationMapsDialog
                          title="발음 교정 단어 수정"
                          text={item.text}
                          pronunciation={item.pronunciation}
                          onConfirm={pronunciationMapUpdate}
                        />,
                      )
                    }
                  >
                    수정
                  </Button>
                  <Button
                    css={css`
                      display: inline;
                      background-color: ${style.color.highlight1};
                      &:hover {
                        background-color: ${style.color.highlight3};
                      }
                    `}
                    onClick={() => {
                      openModal(
                        <PromptDialog
                          title="발음 교정 단어 삭제"
                          message={`[${item.text}] 해당 단어를 삭제하시겠습니까?`}
                          confirmLabel="삭제"
                          onConfirm={() => pronunciationMapRemove(item.text)}
                        />,
                      );
                    }}
                  >
                    삭제
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </StyledTable>
      <div
        css={css`
          width: 100%;
          display: flex;
          justify-content: center;
        `}
      >
        <ErrorText errorText={errorText} />
      </div>
    </div>
  );
};
