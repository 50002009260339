import { utils } from '@admin/utils';
import { css } from '@emotion/react';
import Color from 'color';
import { forwardRef } from 'react';

export const DatePickerButton = forwardRef(
  (
    props: React.HTMLProps<HTMLButtonElement>,
    ref: React.Ref<HTMLButtonElement>,
  ) => {
    return (
      <button
        type="button"
        disabled={props.disabled}
        onClick={props.onClick}
        ref={ref}
        css={css`
          width: 140px;
          height: 40px;
          border: 1px solid ${utils.style.color.grey300};
          border-radius: 8px;
          text-align: center;
          cursor: pointer;
          color: ${utils.style.color.black};
          background-color: ${utils.style.color.white};
          &:hover {
            background-color: ${Color(utils.style.color.white)
              .darken(0.05)
              .hex()};
          }
          &:active {
            background-color: ${Color(utils.style.color.white)
              .darken(0.1)
              .hex()};
          }
          &:disabled {
            color: ${utils.style.color.grey500};
            background-color: ${Color(utils.style.color.white)
              .darken(0.1)
              .hex()};
            cursor: not-allowed;
          }
        `}
      >
        {props.value}
      </button>
    );
  },
);

DatePickerButton.displayName = 'DatePickerInput';
