import { Button } from '@admin/components/Button';
import { Dialog } from '@admin/components/Dialog';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { useModal } from '@admin/components/Modal/hooks';
import { repositoryClient } from '@admin/repository';
import { repositoryUtils } from '@admin/repository/utils';
import { utils } from '@admin/utils';
import { css } from '@emotion/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

interface Props {
  memberId: number;
}

export const RemoveVoucherDialog = (props: Props) => {
  const { openModal, closeModal } = useModal();
  const queryClient = useQueryClient();
  /* 이벤트 쿠폰 삭제 */
  const { mutate: deleteEventCoupon, isLoading: isDeleting } = useMutation({
    onError: (e) => {
      const message = repositoryUtils.getErrorMessage(e);
      openModal(<AlertDialog title="에러" message={message} />);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`/admin/voucher-history/member/${props.memberId}`],
      });
      openModal(
        <AlertDialog title="알림" message="이벤트 쿠폰이 삭제되었습니다" />,
      );
    },
    mutationFn: () =>
      repositoryClient.post(`/admin/voucher-history/member/${props.memberId}`),
  });
  return (
    <Dialog title="확인">
      <div
        css={css`
          display: flex;
          flex-direction: column;
          width: 400px;
          height: 200px;
          padding: 24px 30px;
          gap: 24px;
        `}
      >
        <p
          css={css`
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            ${utils.style.font['text-l']};
          `}
        >
          유저에게서 이벤트 쿠폰을 제거하시겠습니까?
        </p>
        <div
          css={css`
            display: flex;
            justify-content: flex-end;
            gap: 16px;
          `}
        >
          <Button
            disabled={isDeleting}
            onClick={deleteEventCoupon}
            baseColor={utils.style.color.highlight1}
          >
            확인
          </Button>

          <Button
            onClick={closeModal}
            textColor={utils.style.color.grey500}
            baseColor={utils.style.color.grey200}
          >
            취소
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
