import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { InputDateRange } from '@admin/components/InputDateRange';
import { StyledH1 } from '@admin/components/Styled/H1';
import { StyledTable } from '@admin/components/Styled/Table';
import { repositoryClient } from '@admin/repository';
import type { GPUStatistics } from '@admin/repository/types';
import { repositoryUtils } from '@admin/repository/utils';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import _ from 'lodash-es';
import { useRef, useState } from 'react';
import * as XLSX from 'xlsx';

export const GPUStatisticsPage = () => {
  const tableRef = useRef<HTMLTableElement>(null);

  const defaultState = () => {
    const [start, end] = [new Date(), new Date()];
    start.setDate(end.getDate() - 1);
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    start.setMilliseconds(0);
    end.setHours(0);
    end.setMinutes(0);
    end.setSeconds(0);
    end.setMilliseconds(0);
    return { start, end };
  };

  const [dateRange, setDateRange] = useState(defaultState);
  const [searchParams, setSearchParams] = useState(defaultState);

  const gpuStatisticsQuery = useQuery({
    queryKey: [
      '/statistics/gpu',
      {
        since: searchParams.start.getTime(),
        till: searchParams.end.getTime(),
      },
    ] as const,
    queryFn: ({ queryKey: [path, payload], signal }) =>
      repositoryClient.post<{ data: GPUStatistics }>(path, payload, { signal }),
    select: ({ data: { data } }) => _.chain(data).cloneDeep().reverse().value(),
  });

  invariant(gpuStatisticsQuery.data, 'Invalid query data.');

  const exportToXlsx = () => {
    const { current: table } = tableRef;
    if (!table) {
      return;
    }
    const dateFormatter = new Intl.DateTimeFormat('ko-KR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    const formattedStartDate = dateFormatter.format(searchParams.start);
    const formattedEndDate = dateFormatter.format(searchParams.end);
    const wb = XLSX.utils.table_to_book(table, { sheet: 'GPU 가동률' });
    const fileName = `GPU 가동률 (${formattedStartDate} - ${formattedEndDate}).xlsx`;
    XLSX.writeFile(wb, fileName);
  };

  const onClickSearch = () => {
    setSearchParams(dateRange);
  };

  return (
    <ContentBox
      css={css`
        gap: 24px;
        height: 100%;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 16px;
        `}
      >
        <StyledH1>GPU 가동률</StyledH1>

        <Button onClick={exportToXlsx}>내보내기</Button>
      </div>

      <div
        css={css`
          display: flex;
          gap: 16px;
        `}
      >
        <InputDateRange value={dateRange} onChange={setDateRange} />
        <Button onClick={onClickSearch}>조회</Button>
      </div>

      <div
        css={css`
          height: 100%;
          overflow: auto;
        `}
      >
        <StyledTable ref={tableRef}>
          <thead>
            <tr>
              <th>날짜</th>
              <th>GPU 번호</th>
              <th>PID</th>
              <th>가동률(%)</th>
            </tr>
          </thead>
          <tbody>
            {gpuStatisticsQuery.data.map((data) => {
              const parsedDate = repositoryUtils.parseDate(data.localDateTime);
              if (!parsedDate) {
                throw new Error(`Invalid date: ${data.localDateTime}`);
              }
              return (
                <tr key={`${data.localDateTime}-${data.gpuNo}-${data.pid}`}>
                  <td>{format(parsedDate, 'yyyy년 MM월 dd일 HH시')}</td>
                  <td>{data.gpuNo}</td>
                  <td>{data.pid}</td>
                  <td>{data.operateRate}</td>
                </tr>
              );
            })}
          </tbody>
        </StyledTable>
      </div>
    </ContentBox>
  );
};
