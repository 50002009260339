import { Button } from '@admin/components/Button';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledLabel } from '@admin/components/Styled/Label';
import { AddEmailAddressDialog } from '@admin/routes/email/component/AddEmailAddressDialog';
import { Tags } from '@admin/routes/email/component/Tags';
import { TagsBox } from '@admin/routes/email/component/TagsBox';
import { emailToListAtom } from '@admin/routes/email/create/context/atom';
import { css } from '@emotion/react';
import { useRecoilState } from 'recoil';

export const Recipients = () => {
  const [emailToListState, setEmailToListState] =
    useRecoilState(emailToListAtom);

  const { openModal } = useModal();

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 8px;
      `}
    >
      <StyledLabel>받는 사람</StyledLabel>

      <TagsBox>
        {emailToListState.map((emailTo) => (
          <Tags
            key={emailTo}
            label={emailTo}
            onRemove={() => {
              setEmailToListState((prev) =>
                prev.filter((item) => item !== emailTo),
              );
            }}
          />
        ))}
      </TagsBox>

      <Button
        onClick={() =>
          openModal(
            <AddEmailAddressDialog
              onConfirm={(newEmailAddressList) =>
                setEmailToListState((prev) =>
                  Array.from(new Set([...prev, ...newEmailAddressList])),
                )
              }
            />,
          )
        }
      >
        추가
      </Button>
    </div>
  );
};
