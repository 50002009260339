import type { CategoryTag } from '@admin/repository/types';
import { utils } from '@admin/utils';
import { css } from '@emotion/react';

import { TagItem } from './TagItem';

export const TagGroup = (props: { categoryTag: CategoryTag }) => {
  return (
    <fieldset
      css={css`
        margin: 0;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        border: 1px solid ${utils.style.color.grey300};
        border: none;
        padding: 8px;
      `}
    >
      <legend
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <strong
          css={css`
            color: ${utils.style.color.secondary};
          `}
        >
          {props.categoryTag.categoryLabel}
        </strong>
      </legend>

      <ul
        css={css`
          margin: 0 8px;
          display: flex;
          flex-direction: column;
          gap: 4px;
          padding: 0;
        `}
      >
        {props.categoryTag.tags.map((tag) => (
          <TagItem key={tag.id} tag={tag} />
        ))}
      </ul>
    </fieldset>
  );
};
