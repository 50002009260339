import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { useRecoilCallback, useResetRecoilState } from 'recoil';

import { modalAtom } from './atom';

export const useModal = () => {
  const openModal = useRecoilCallback(
    ({ set }) =>
      (dialog: ReactNode) =>
        set(modalAtom, { child: dialog }),
    [],
  );

  const closeModal = useResetRecoilState(modalAtom);

  return useMemo(() => ({ openModal, closeModal }), [closeModal, openModal]);
};
