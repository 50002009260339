import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { PromptDialog } from '@admin/components/Dialog/PromptDialog';
import { Divider } from '@admin/components/Divider';
import { InputDate } from '@admin/components/InputDate';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledForm } from '@admin/components/Styled/Form';
import { StyledH1 } from '@admin/components/Styled/H1';
import { repositoryClient } from '@admin/repository';
import { DISPLAY_NAMES_TERMS } from '@admin/repository/constant';
import type { Terms, TermsHistory } from '@admin/repository/types';
import { repositoryUtils } from '@admin/repository/utils';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash-es';
import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { TermsPreview } from './TermsPreview';

export const TermsEditPage = () => {
  const navigate = useNavigate();

  const { termsId, termsHistoryId } = useParams();

  const { openModal } = useModal();

  const termsHistoryQuery = useQuery({
    queryKey: ['/admin-terms/termsHistory', { termsId }] as const,
    queryFn: ({ queryKey: [path, { termsId }], signal }) =>
      repositoryClient.get<{ data: TermsHistory[] }>(`${path}/${termsId}`, {
        signal,
      }),
    select: ({ data: { data } }) =>
      data.find(
        (termsHistory) =>
          termsHistory.termsHistoryId.toString() === termsHistoryId,
      ),
    onSuccess(data) {
      if (!data) {
        navigate('..', { replace: true });
      }
    },
  });

  const { data: terms } = useQuery({
    queryKey: ['/terms'] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ data: Terms[] }>(path, { signal }),
    select: ({ data: { data } }) =>
      data.find((v) => v.termsId === Number(termsId)),
  });

  invariant(termsHistoryQuery.data, 'Invalid terms history query data.');

  invariant(terms, 'Invalid terms query data.');

  const [termsHistory] = useState(termsHistoryQuery.data);

  const [publicationDate, setPublicationDate] = useState(
    termsHistory.publicationDate.join('-'),
  );

  const [effectiveDate, setEffectiveDate] = useState(
    termsHistory.effectiveDate.join('-'),
  );

  const [revisionHistory, setRevisionHistory] = useState(
    termsHistory.revisionHistory,
  );

  const [isEnabled, setIsEnabled] = useState(termsHistory.isEnabled);

  const changedFields = useMemo(() => {
    if (!termsHistory) {
      return {};
    }
    return {
      ...(termsHistory.publicationDate.join('-') !== publicationDate && {
        publicationDate,
      }),
      ...(termsHistory.effectiveDate.join('-') !== effectiveDate && {
        effectiveDate,
      }),
      ...(termsHistory.revisionHistory !== revisionHistory && {
        revisionHistory,
      }),
      ...(termsHistory.isEnabled !== isEnabled && { isEnabled }),
    };
  }, [
    effectiveDate,
    isEnabled,
    publicationDate,
    revisionHistory,
    termsHistory,
  ]);

  const queryClient = useQueryClient();

  const { mutate: updateTerms, isLoading: isUpdating } = useMutation({
    onError: (e) => {
      openModal(
        <AlertDialog
          title="에러"
          message={repositoryUtils.getErrorMessage(e)}
        />,
      );
    },
    onSuccess: async () => {
      openModal(
        <AlertDialog
          title="알림"
          message="변경 사항이 성공적으로 저장되었습니다."
        />,
      );
      await queryClient.invalidateQueries({
        queryKey: ['/admin-terms/termsHistory', { termsId }],
      });
    },
    mutationFn: () => {
      return repositoryClient.patch(
        `/admin-terms/termsHistory/${termsHistoryId}`,
        changedFields,
      );
    },
  });

  return (
    <ContentBox
      css={css`
        gap: 24px;
      `}
    >
      <StyledH1>약관 및 정책 관리</StyledH1>

      <StyledForm
        onSubmit={(e) => {
          e.preventDefault();
          openModal(
            <PromptDialog
              title="확인"
              message="변경 사항을 저장하시겠습니까?"
              onConfirm={updateTerms}
            />,
          );
        }}
      >
        <label>
          구분
          <input readOnly defaultValue={DISPLAY_NAMES_TERMS[terms.termsName]} />
        </label>

        <label htmlFor="publicationDate">
          공고 일자
          <div
            css={css`
              width: min-content;
            `}
          >
            <InputDate value={publicationDate} onChange={setPublicationDate} />
          </div>
        </label>

        <label htmlFor="effectiveDate">
          시행 일자
          <div
            css={css`
              width: min-content;
            `}
          >
            <InputDate value={effectiveDate} onChange={setEffectiveDate} />
          </div>
        </label>

        <label>
          개정 내역
          <input
            name="revisionHistory"
            value={revisionHistory}
            onChange={({ currentTarget: { value } }) =>
              setRevisionHistory(value)
            }
          />
        </label>

        <label
          css={css`
            width: max-content;
          `}
        >
          활성화
          <input
            name="isEnabled"
            type="checkbox"
            checked={isEnabled}
            onChange={({ currentTarget: { checked } }) => setIsEnabled(checked)}
          />
        </label>

        <Button disabled={isUpdating || _.isEmpty(changedFields)} form>
          저장
        </Button>
      </StyledForm>

      <Divider />

      <StyledH1>미리보기</StyledH1>

      <TermsPreview fileName={terms.fileName} />
    </ContentBox>
  );
};
