import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledH1 } from '@admin/components/Styled/H1';
import { css } from '@emotion/react';

import { CreateFormDialog } from './CreateFormDialog';
import { ListTable } from './ListTable';
import { SearchForm } from './SearchForm';

export const ServiceCodeListPage = () => {
  const { openModal } = useModal();

  return (
    <ContentBox
      css={css`
        height: 100%;
        gap: 24px;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 16px;
        `}
      >
        <StyledH1>서비스 코드</StyledH1>

        <Button onClick={() => openModal(<CreateFormDialog />)}>
          서비스 코드 생성
        </Button>
      </div>

      <SearchForm />

      <ListTable />
    </ContentBox>
  );
};
