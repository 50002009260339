import { Button } from '@admin/components/Button';
import { Dialog } from '@admin/components/Dialog';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { InputSelect } from '@admin/components/InputSelect';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledForm } from '@admin/components/Styled/Form';
import { StyledNumberInput } from '@admin/components/Styled/Input';
import { StyledLabel } from '@admin/components/Styled/Label';
import { repositoryClient } from '@admin/repository';
import type {
  ServiceCodeMetaData,
  Voice,
  VoiceContract,
} from '@admin/repository/types';
import { repositoryUtils } from '@admin/repository/utils';
import { utils } from '@admin/utils';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRef } from 'react';

interface Props {
  readonly queryData: {
    voices: Voice[];
    voiceContracts: VoiceContract[];
    isVoicesLoading: boolean;
    serviceCodeList: ServiceCodeMetaData[];
    isServiceCodeListLoading: boolean;
  };
}

export const CreateVoiceContractModal = (props: Props) => {
  const formRef = useRef<HTMLFormElement>(null);
  const { openModal, closeModal } = useModal();
  const planNames: VoiceContract['planName'][] = [
    'Free',
    'Basic',
    'Pro',
    'ProMax',
    'Deferred',
    'Default',
  ];
  const queryClient = useQueryClient();

  /* 계약 추가하기 */
  const { mutate: createContract, isLoading: isCreating } = useMutation({
    onError: (e) => {
      const message = repositoryUtils.getErrorMessage(e);
      openModal(<AlertDialog title="에러" message={message} />);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['/voice-contract'] });
      openModal(
        <AlertDialog title="알림" message="보이스 계약이 추가되었습니다." />,
      );
    },
    mutationFn: () => {
      const { current: form } = formRef;
      invariant(form, 'form is not mounted.');
      const formData = new FormData(form);
      const payload = {
        voiceId: parseInt(formData.get('voiceId') as string, 10),
        serviceCode: formData.get('serviceCode'),
        planName: formData.get('planName'),
        pricePerCharacters: parseFloat(
          formData.get('pricePerCharacters') as string,
        ),
        settlementRatio: parseFloat(formData.get('settlementRatio') as string),
        dubbingTimes: parseFloat(formData.get('dubbingTimes') as string),
      };
      return repositoryClient.put('/voice-contract', payload);
    },
  });

  return (
    <Dialog title="보이스 계약 추가">
      <StyledForm
        ref={formRef}
        onSubmit={(e) => {
          e.preventDefault();
          createContract();
        }}
        css={css`
          width: 400px;
          padding: 24px;
        `}
      >
        <StyledLabel>
          보이스 ID
          <InputSelect defaultValue="" name="voiceId">
            <option value="" disabled hidden>
              선택 필요
            </option>
            {props.queryData.isVoicesLoading ? (
              <option>데이터를 로드하고 있습니다.</option>
            ) : (
              props.queryData.voices.map(({ voiceId, displayName }) => (
                <option key={voiceId} value={voiceId}>
                  {`${voiceId} (${displayName})`}
                </option>
              ))
            )}
          </InputSelect>
        </StyledLabel>
        <StyledLabel>
          서비스 코드
          <InputSelect defaultValue="" name="serviceCode">
            <option value="" disabled hidden>
              선택 필요
            </option>
            {props.queryData.isServiceCodeListLoading ? (
              <option>데이터를 로드하고 있습니다.</option>
            ) : (
              props.queryData.serviceCodeList.map(({ serviceCodeName }) => (
                <option key={serviceCodeName} value={serviceCodeName}>
                  {serviceCodeName}
                </option>
              ))
            )}
          </InputSelect>
        </StyledLabel>
        <StyledLabel>
          요금제
          <InputSelect defaultValue="" name="planName">
            <option value="" disabled hidden>
              선택 필요
            </option>
            {planNames.length < 1 ? (
              <option value="">서비스 코드를 먼저 선택해주세요.</option>
            ) : (
              planNames.map((plan) => (
                <option key={plan} value={plan}>
                  {plan}
                </option>
              ))
            )}
          </InputSelect>
        </StyledLabel>
        <StyledLabel>
          자당 가격(차감자수)
          <StyledNumberInput
            name="pricePerCharacters"
            type="number"
            min={0.0}
            step="0.1"
            defaultValue={0.0}
          />
        </StyledLabel>

        <StyledLabel>
          정산 비율
          <StyledNumberInput
            name="settlementRatio"
            type="number"
            min={0.0}
            step="0.1"
            defaultValue={0.0}
          />
        </StyledLabel>

        <StyledLabel>
          음성 변조 배수
          <StyledNumberInput
            name="dubbingTimes"
            type="number"
            min={0.0}
            step="0.1"
            defaultValue={0.0}
          />
        </StyledLabel>
        <div
          css={css`
            display: flex;
            justify-content: space-between;
          `}
        >
          <Button form disabled={isCreating}>
            생성
          </Button>

          <Button
            onClick={closeModal}
            textColor={utils.style.color.grey500}
            baseColor={utils.style.color.grey200}
          >
            취소
          </Button>
        </div>
      </StyledForm>
    </Dialog>
  );
};
