import { Button } from '@admin/components/Button';
import { ErrorText } from '@admin/components/ErrorText';
import { eventPageAtom } from '@admin/routes/eventPage/edit/context/atom';
import { css } from '@emotion/react';
import { useRecoilState } from 'recoil';

export const SaveButton = ({ isSaving }: { isSaving: boolean }) => {
  const [eventPageState] = useRecoilState(eventPageAtom);
  return (
    <div
      css={css`
        width: 300px;
        display: flex;
        justify-content: space-between;
      `}
    >
      <Button form disabled={isSaving}>
        저장
      </Button>
      <div>
        {eventPageState.isPCBasicImage ? (
          <ErrorText errorText="PC 이벤트 이미지 업로드 필요" />
        ) : null}
        {eventPageState.isMobileBasicImage ? (
          <ErrorText errorText="Mobile 이벤트 이미지 업로드 필요" />
        ) : null}
      </div>
    </div>
  );
};
