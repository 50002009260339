import { Button } from '@admin/components/Button';
import { StyledInput } from '@admin/components/Styled/Input';
import { StyledSelect } from '@admin/components/Styled/Select';
import { DISPLAY_NAMES_VOICE } from '@admin/repository/constant';
import type { Voice } from '@admin/repository/types';
import { css } from '@emotion/react';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

const filterColumnKeys = {
  'display-name': 'displayName',
  name: 'name',
  'voice-id': 'voiceId',
} as const satisfies Record<string, keyof Voice>;

export const Filter = () => {
  const defaults = useMemo(() => {
    const currentSearchParams = new URLSearchParams(window.location.search);
    const query = currentSearchParams.get('query') ?? '';
    const column = currentSearchParams.get('column') ?? '';
    return { query, column };
  }, []);

  const [, setSearchParams] = useSearchParams();

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        const params = new URLSearchParams();
        params.set('query', formData.get('query') as string);
        params.set(
          'column',
          (formData.get('column') as string) ||
            filterColumnKeys['display-name'],
        );
        setSearchParams(params, { replace: true });
      }}
      css={css`
        display: flex;
        align-items: center;
        gap: 16px;
        user-select: none;
      `}
    >
      <StyledSelect name="column" defaultValue={defaults.column}>
        {Object.entries(filterColumnKeys).map(([k, v]) => (
          <option key={k} value={v}>
            {DISPLAY_NAMES_VOICE[v]}
          </option>
        ))}
      </StyledSelect>

      <StyledInput
        type="search"
        name="query"
        defaultValue={defaults.query}
        css={css`
          width: 300px;
        `}
      />

      <Button form>검색</Button>
    </form>
  );
};
