import type {
  Member,
  Plan,
  Subscription,
  Terms,
  TermsHistory,
  Voice,
  VoiceContract,
} from './types';

export const DISPLAY_NAMES_ROLE: Readonly<Record<Member['role'], string>> = {
  ROLE_ADMIN: '어드민',
  ROLE_SUPER: '슈퍼 유저',
  ROLE_USER: '일반 유저',
};

export const DISPLAY_NAMES_PLAN: Readonly<Record<Plan['name'], string>> = {
  Basic: 'Lite',
  Deferred: 'Deferred',
  Free: 'Free',
  Pro: 'Super',
  ProMax: 'Super Plus',
};

export const DISPLAY_NAMES_MEMBER: Readonly<Record<keyof Member, string>> = {
  birth: '생년월일',
  creatorEmail: '생성자',
  currentSubscription: '요금제',
  email: '이메일',
  id: '고유 ID',
  isBlocked: '차단 여부',
  isCheckUnifiedRegistration: '통합 약관 동의',
  isCheckMarketingTerm: '마케팅 정보 수신 동의',
  isDeleted: '삭제 여부',
  isEnabled: '활성화 여부',
  isReceivedDebugEmail: '디버그 이메일 수신 여부',
  isReceivedErrorEmail: '에러 이메일 수신 여부',
  language: '언어',
  loginFailCount: '로그인 실패 횟수',
  name: '이름',
  passwordExpiredAt: '패스워드 만료일',
  phone: '전화번호',
  provider: '소셜 인증 플랫폼',
  region: '국가',
  registerDate: '가입일',
  role: '권한',
  serviceCode: '서비스 코드',
  uid: '고유 ID',
  updaterEmail: '최근 수정자',
  lastLoginTime: '마지막 로그인 시간',
  dormancyDate: '휴면 계정 메일 발송일',
  scheduledDormancyDate: '휴면 계정 메일 발송 예정일',
  isDormant: '휴면 계정 여부',
};

export const DISPLAY_NAMES_SUBSCRIPTION: Readonly<
  Record<keyof Subscription, string>
> = {
  addedCharactersCount: '추가 글자 수',
  enableCharactersCount: '사용 가능 글자 수',
  enableCustomVoiceCount: '생성 가능 마이 AI 보이스 수',
  maxCharactersCount: '최대 글자 수',
  nextPlan: '다음 요금제',
  plan: '요금제',
  since: '구독 시작일',
  till: '구독 갱신 / 만료일',
  uid: '고유 ID',
  usedCharactersCount: '사용한 글자 수',
};

export const DISPLAY_NAMES_VOICE: Readonly<Record<keyof Voice, string>> = {
  createdAt: '생성일',
  creatorEmail: '생성한 사용자 이메일',
  displayName: '표시 이름',
  emotions: '감정',
  isDeleted: '삭제 여부',
  isEnabled: '활성화 여부',
  modifiedAt: '최근 수정일',
  modifierEmail: '최근 수정자',
  name: '내부 이름',
  permission: '공개 범위',
  speakerNo: 'PTTS 매핑 ID',
  tags: '태그',
  trainingStatus: '학습 상태',
  uid: 'UID',
  userEmail: '사용자 이메일',
  voiceId: '보이스 ID',
  voiceType: '유형',
  planName: '최소 사용 가능 요금제',
  cloudId: 'KT Cloud ID(External ID)',
  recordTime: '마이AI보이스 녹음 등록일',
  trainingCount: '마이AI보이스 재학습 횟수 정보',
  deletedAt: '삭제 일시',
  deletedReason: '삭제 사유',
};

export const DISPLAY_NAMES_VOICE_CONTRACT: Readonly<
  Record<keyof VoiceContract, string>
> = {
  contractId: '계약 ID',
  voiceId: '보이스 ID',
  serviceCode: '서비스 코드',
  planName: '요금제',
  pricePerCharacters: '자당 가격',
  dubbingTimes: '음성 변조 배수',
  settlementRatio: '정산 비율',
};

export const DISPLAY_NAMES_VOICE_TYPE: Readonly<
  Record<Voice['voiceType'], string>
> = {
  COMMON: '일반',
  CUSTOM: '마이 AI',
  SPECIAL: '특별',
  PTTS: 'PTTS',
};

export const DISPLAY_NAMES_TERMS: Readonly<Record<Terms['termsName'], string>> =
  {
    operation_policy: '운영 정책',
    privacy_management: '개인정보 처리 방침',
    privacy_agreement: '개인정보 수집 이용 동의서',
    terms_of_service: '서비스 이용 약관',
    marketing_agreement: '마케팅 정보 수신 동의서',
  };

export const DISPLAY_NAMES_TERMS_STATUS: Readonly<
  Record<TermsHistory['status'], string>
> = {
  READY: '업로드 대기',
  DONE: '업로드 완료',
};

export const VOICE_DELETED_REASON: Readonly<
  Record<string, { key: string; value: string }>
> = {
  NONE: {
    key: 'NONE',
    value: '미삭제',
  },
  ADMIN: {
    key: '관리자가 API를 호출하여 CUSTOM VOICE를 삭제합니다.',
    value: '관리자 삭제',
  },
  ADMIN_WEB: {
    key: '관리자가 사이트에서 API를 호출하여 CUSTOM VOICE를 삭제합니다.',
    value: '관리자 삭제',
  },
  USER: {
    key: '유저가 API를 호출하여 CUSTOM VOICE를 삭제합니다.',
    value: '유저 삭제',
  },
  FREE: {
    key: '요금제가 FREE로 변경되어 보이스를 삭제합니다.',
    value: 'FREE 요금제 변경',
  },
  EVENT: {
    key: '이벤트로 발급한 보이스를 삭제합니다.',
    value: '이벤트 발급 보이스 회수',
  },
};
