import { Button } from '@admin/components/Button';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { PromptDialog } from '@admin/components/Dialog/PromptDialog';
import { useModal } from '@admin/components/Modal/hooks';
import { repositoryClient } from '@admin/repository';
import type { Repository } from '@admin/repository/types';
import { repositoryUtils } from '@admin/repository/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const ActivationButton = ({
  voucherDetail,
}: {
  voucherDetail: Repository['voucher'];
}) => {
  const { openModal } = useModal();
  const queryClient = useQueryClient();

  /* 이벤트 쿠폰 활성화 */
  const { mutate: activateEventCoupon, isLoading: isActivating } = useMutation({
    onError: (e) => {
      const message = repositoryUtils.getErrorMessage(e);
      openModal(<AlertDialog title="에러" message={message} />);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`/admin/voucher/${voucherDetail.code}`],
      });

      openModal(
        <AlertDialog title="알림" message="이벤트 쿠폰이 활성화되었습니다." />,
      );
    },
    mutationFn: () =>
      repositoryClient.post(`/admin/voucher/${voucherDetail.code}`),
  });

  return (
    <Button
      disabled={voucherDetail.status === 'ACTIVE' || isActivating}
      onClick={() => {
        openModal(
          <PromptDialog
            title="알림"
            message={`저장한 쿠폰을 활성화하시겠습니까?\n활성화 이후 쿠폰 적용된 유저가 존재할 경우\n수정할 수 없습니다.`}
            onConfirm={() => {
              activateEventCoupon();
            }}
          />,
        );
      }}
    >
      활성화
    </Button>
  );
};
