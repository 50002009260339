import { Button } from '@admin/components/Button';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { DeleteDialog } from '@admin/components/Dialog/DeleteDialog';
import { useModal } from '@admin/components/Modal/hooks';
import { repositoryClient } from '@admin/repository';
import { repositoryUtils } from '@admin/repository/utils';
import { utils } from '@admin/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

export const DeleteMemberButton = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const serviceCode = searchParams.get('service-code');

  const { email: userEmail } = useParams();

  const { openModal } = useModal();

  const queryClient = useQueryClient();

  const { mutate: deleteUser, isLoading: isDeleting } = useMutation({
    onError: (e) => {
      openModal(
        <AlertDialog
          title="에러"
          message={repositoryUtils.getErrorMessage(e)}
        />,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['/member/all'] });
      queryClient.removeQueries({
        queryKey: ['/member/single', { serviceCode, userEmail }],
      });
      openModal(
        <AlertDialog
          title="알림"
          message={`${userEmail} 유저가 성공적으로 삭제되었습니다.`}
        />,
      );
      navigate('..', { replace: true });
    },
    mutationFn: () => {
      if (!serviceCode) {
        throw new Error('invalid service code');
      }
      if (!userEmail) {
        throw new Error('invalid email');
      }
      return repositoryClient.delete(`/member/${userEmail}`, {
        params: { serviceCode },
      });
    },
  });

  if (!userEmail) {
    return null;
  }

  return (
    <Button
      disabled={isDeleting}
      onClick={() =>
        openModal(
          <DeleteDialog
            type="유저"
            subjectText={userEmail}
            confirmText={userEmail}
            onConfirm={deleteUser}
          />,
        )
      }
      baseColor={utils.style.color.highlight1}
    >
      유저 삭제
    </Button>
  );
};
