import { Button } from '@admin/components/Button';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledTable } from '@admin/components/Styled/Table';
import { DISPLAY_NAMES_PLAN } from '@admin/repository/constant';
import type { Voice } from '@admin/repository/types';
import { css } from '@emotion/react';
import { useState } from 'react';

import { Filter } from '../Filter';
import { CommonVoiceEditDialog } from './EditDialog';

export const CommonVoiceTable = ({
  voiceList,
  activatedVoiceIdList,
  serviceCode,
}: {
  voiceList: Voice[];
  activatedVoiceIdList: number[];
  serviceCode: string | undefined;
}) => {
  const { openModal } = useModal();
  const [selectValue, setSelectValue] = useState<keyof Voice>('displayName');
  const [searchValue, setSearchValue] = useState<string>('');
  const [isEnable, setIsEnable] = useState<'all' | 'true' | 'false'>('all');
  const [planName, setPlanName] = useState(
    Object.keys({ None: 'None', ...DISPLAY_NAMES_PLAN }).reduce(
      (acc, cur) => ({ ...acc, [`plan-name-${cur.toLowerCase()}`]: true }),
      {},
    ),
  );

  return (
    <>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
        `}
      >
        <Filter
          handleSelectValue={(voiceType) => setSelectValue(voiceType)}
          handleSearchValue={(value) => setSearchValue(value)}
          handleIsEnable={(enable) => setIsEnable(enable)}
          handlePlanName={(e) =>
            setPlanName((prev) => ({
              ...prev,
              [e.target.name]: e.target.checked,
            }))
          }
        />

        <Button
          onClick={() => {
            openModal(
              <CommonVoiceEditDialog
                voiceList={voiceList}
                serviceCode={serviceCode}
                activatedVoiceIdList={activatedVoiceIdList}
              />,
            );
          }}
        >
          편집
        </Button>
      </div>
      <div
        css={css`
          height: 500px;
          overflow: auto;
        `}
      >
        <StyledTable>
          <thead>
            <tr>
              <th>ID</th>
              <th>내부 이름</th>
              <th>표시 이름</th>
              <th>최소 필요 요금제</th>
              <th>활성화</th>
            </tr>
          </thead>
          <tbody>
            {voiceList
              .filter(
                (item: Voice) =>
                  (item[selectValue] as string)
                    .toLowerCase()
                    .includes(searchValue) &&
                  (Object.entries(planName).some(
                    ([k, v]) => v && k.includes(item.planName.toLowerCase()),
                  ) ||
                    Object.values(planName).every((v) => !v)) &&
                  (isEnable === 'all' ||
                    item.isEnabled === (isEnable === 'true')) &&
                  activatedVoiceIdList.includes(item.voiceId),
              )
              .map((item: Voice) => {
                return (
                  <tr key={item.voiceId}>
                    <td>{item.voiceId}</td>
                    <td>{item.name}</td>
                    <td>{item.displayName}</td>
                    <td>{item.planName}</td>
                    <td>{item.isEnabled ? '활성' : '비활성'}</td>
                  </tr>
                );
              })}
          </tbody>
        </StyledTable>
      </div>
    </>
  );
};
