import { Button } from '@admin/components/Button';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledInput } from '@admin/components/Styled/Input';
import { queryClient } from '@admin/repository/query';
import type { Emotion } from '@admin/repository/types';
import { utils } from '@admin/utils';
import { css } from '@emotion/react';
import type { UseMutateFunction } from '@tanstack/react-query';

const emotionMatchObject = {
  N: '중립',
  A: '화남',
  C: '침착함',
  H: '즐거움',
  S: '슬픔',
} as const;

export type PreviewStatusType = {
  isPlaying: boolean;
  isLoading: boolean;
  currentPlaying: string;
  audio: HTMLAudioElement;
};

export type ListenPreviewMutationFn = UseMutateFunction<
  void,
  unknown,
  {
    emotionName: string;
    decibelValue: number;
  },
  unknown
>;

export const VoiceListItem = ({
  emotion,
  listenPreview,
  previewStatus,
  onClickPause,
  setChangesOnVoiceContents,
}: {
  emotion: Emotion;
  listenPreview: ListenPreviewMutationFn;
  previewStatus: PreviewStatusType;
  onClickPause: () => void;
  setChangesOnVoiceContents: (
    name: string,
    valueName: string,
    value: number | boolean,
  ) => void;
}) => {
  const { openModal } = useModal();

  const previewButton = () => {
    if (previewStatus.currentPlaying === emotion.name) {
      if (previewStatus.isLoading) return <p>로딩 중...</p>;
      if (previewStatus.isPlaying)
        return (
          <Button
            baseColor={utils.style.color.secondary}
            onClick={() => onClickPause()}
          >
            그만듣기
          </Button>
        );
    }
    return (
      <Button
        disabled={emotion.trainingStatus !== 'COMPLETE'}
        onClick={() =>
          listenPreview({
            emotionName: emotion.name,
            decibelValue: Number(emotion.decibel),
          })
        }
      >
        미리듣기
        {emotion.trainingStatus !== 'COMPLETE'
          ? '(학습 상태를 확인해주세요)'
          : ''}
      </Button>
    );
  };

  const downloadAudio = () => {
    const cacheUrl = queryClient.getQueryData([
      'audio',
      emotion.name,
    ]) as string;
    if (!cacheUrl) {
      openModal(
        <AlertDialog title="에러" message="미리듣기 후 다운로드 해주세요." />,
      );
      return;
    }
    const downloadLink = document.createElement('a');
    downloadLink.href = cacheUrl;
    downloadLink.download = 'tts.wav';
    downloadLink.click();
    URL.revokeObjectURL(cacheUrl);
  };

  return (
    <tr>
      <td>
        {emotionMatchObject[emotion.name as keyof typeof emotionMatchObject]}
      </td>
      <td>
        <div>
          <StyledInput
            type="number"
            disabled={emotion.trainingStatus !== 'COMPLETE'}
            value={emotion.decibel}
            onChange={(e) =>
              setChangesOnVoiceContents(
                emotion.name,
                'decibel',
                Number(e.target.value),
              )
            }
            css={css`
              width: 80px;
              // padding: 0;
            `}
          />
        </div>
      </td>
      <td>
        <div>{previewButton()}</div>
      </td>
      <td>
        <div>
          <Button
            disabled={emotion.trainingStatus !== 'COMPLETE'}
            baseColor={utils.style.color.subBlack}
            onClick={() => downloadAudio()}
          >
            다운로드
          </Button>
        </div>
      </td>
      <td>
        <div>
          <input
            type="checkbox"
            disabled={emotion.trainingStatus !== 'COMPLETE'}
            checked={emotion.isEnabled}
            onChange={(e) =>
              setChangesOnVoiceContents(
                emotion.name,
                'isEnabled',
                e.target.checked,
              )
            }
            css={css`
              width: 24px;
              height: 24px;
            `}
          />
        </div>
      </td>
    </tr>
  );
};
