import { StyledH1 } from '@admin/components/Styled/H1';
import type { Repository } from '@admin/repository/types';
import { DownloadMemberList } from '@admin/routes/voucher/edit/VoucherMemberList/DownloadMemberList';
import { DownloadTemplate } from '@admin/routes/voucher/edit/VoucherMemberList/DownloadTemplate';
import { ExcelFileUpload } from '@admin/routes/voucher/edit/VoucherMemberList/ExcelFileUpload';
import { VoucherApplyButton } from '@admin/routes/voucher/edit/VoucherMemberList/VoucherApplyButton';
import { VoucherMemberTable } from '@admin/routes/voucher/edit/VoucherMemberList/VoucherMemberTable';
import { css } from '@emotion/react';

export const VoucherMemberList = ({
  voucherDetail,
}: {
  voucherDetail: Repository['voucher'];
}) => {
  return (
    <>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
        `}
      >
        <StyledH1>유저 목록</StyledH1>
      </div>

      <div
        css={css`
          display: flex;
          gap: 24px;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 24px;
            justify-content: space-between;
          `}
        >
          {/* 템플릿 내려받기 */}
          <DownloadTemplate />
          {/* 엑셀 파일 업로드 */}
          <ExcelFileUpload />
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 24px;
            align-items: flex-end;
            justify-content: space-between;
          `}
        >
          {/* 쿠폰 적용하기 버튼 */}
          <VoucherApplyButton voucherDetail={voucherDetail} />
          {/* 유저 목록 내려받기 */}
          <DownloadMemberList voucherDetail={voucherDetail} />
        </div>
      </div>

      {/* 쿠폰 적용된 유저 목록 */}
      <VoucherMemberTable voucherDetail={voucherDetail} />
    </>
  );
};
