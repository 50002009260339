import { Button } from '@admin/components/Button';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { PromptDialog } from '@admin/components/Dialog/PromptDialog';
import { useModal } from '@admin/components/Modal/hooks';
import { repositoryClient } from '@admin/repository';
import { repositoryUtils } from '@admin/repository/utils';
import { eventPageAtom } from '@admin/routes/eventPage/edit/context/atom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';

export const ActivationButton = () => {
  const [eventPageState, setEventPageState] = useRecoilState(eventPageAtom);
  const [isActive, setIsActive] = useState(eventPageState.isActive);
  const { eventPageId } = useParams();
  const { openModal } = useModal();
  const queryClient = useQueryClient();

  const { mutate: updateIsAcitve, isLoading } = useMutation({
    onError: (e) => {
      const message = repositoryUtils.getErrorMessage(e);
      openModal(<AlertDialog title="에러" message={message} />);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`/admin/eventPage/${eventPageId}/active`],
      });
      openModal(
        <AlertDialog
          title="성공"
          message={
            eventPageState.isActive
              ? '이벤트 페이지가 활성화 되었습니다.'
              : '이벤트 페이지가 비활성화 되었습니다.'
          }
        />,
      );
    },
    mutationFn: () => {
      setEventPageState((prevEventPage) => ({
        ...prevEventPage,
        isActive,
      }));
      return repositoryClient.post(`/admin/eventPage/${eventPageId}/active`, {
        isActive,
      });
    },
  });

  return (
    <Button
      disabled={isLoading}
      onClick={() => {
        openModal(
          <PromptDialog
            title="확인"
            message={
              eventPageState.isActive
                ? '이벤트 페이지를 비활성화 하시겠습니까?'
                : '이벤트 페이지를 활성화 하시겠습니까?'
            }
            onConfirm={() => {
              setIsActive((prev) => !prev);
              updateIsAcitve();
            }}
          />,
        );
      }}
    >
      {eventPageState.isActive ? '비활성화' : '활성화'}
    </Button>
  );
};
