import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { ErrorText } from '@admin/components/ErrorText';
import { InputSelect } from '@admin/components/InputSelect';
import { InputText } from '@admin/components/InputText';
import { InputTextarea } from '@admin/components/InputTextarea';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledInput } from '@admin/components/Styled/Input';
import { StyledLabel } from '@admin/components/Styled/Label';
import { repositoryClient } from '@admin/repository';
import type { Script, ScriptTag } from '@admin/repository/types';
import { repositoryUtils } from '@admin/repository/utils';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash-es';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

export const ScriptEditorPage = () => {
  const { openModal } = useModal();

  const { uuid } = useParams();

  const [errorText, setErrorText] = useState('');

  const scriptQuery = useQuery({
    queryKey: ['/scripts', { uuid }] as const,
    queryFn: ({ queryKey: [path, { uuid }], signal }) =>
      repositoryClient.get<{ data: Script }>(`${path}/${uuid}`, {
        signal,
      }),
    select: ({ data: { data } }) => data,
  });

  const scriptTagQuery = useQuery({
    queryKey: ['/scripts/tags'] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ data: ScriptTag[] }>(path, { signal }),
    select: ({ data: { data } }) => data,
  });

  invariant(scriptQuery.data, 'Invalid script query data.');

  invariant(scriptTagQuery.data, 'Invalid script tag query data.');

  const [formValue, setFormValue] = useState<
    | Pick<Script, 'isEnabled' | 'source' | 'tagName' | 'title'> &
        Record<'content', string>
  >(() => {
    const content = scriptQuery.data.scriptContentList
      .map((script) => script.content)
      .join('\n');
    return {
      ..._.pick(scriptQuery.data, ['isEnabled', 'source', 'tagName', 'title']),
      content,
    };
  });

  const queryClient = useQueryClient();

  const { mutate: save, isLoading: isSaving } = useMutation({
    onError: (e) => {
      setErrorText(repositoryUtils.getErrorMessage(e));
    },
    onMutate: () => {
      setErrorText('');
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['/scripts'] });
      openModal(
        <AlertDialog title="알림" message="스크립트 정보가 저장되었습니다." />,
      );
    },
    mutationFn: () => {
      const scriptContentDtoList = _.chain(formValue.content.split('\n'))
        .filter((line) => line.length > 0)
        .map((line, index) => ({ orders: index + 1, content: line }))
        .value();
      const payload = _.chain(formValue)
        .cloneDeep()
        .assign({ scriptContentDtoList })
        .omit('content')
        .value();
      return repositoryClient.patch(`/scripts/${uuid}`, payload);
    },
  });

  return (
    <ContentBox
      css={css`
        gap: 20px;
      `}
    >
      <div
        css={css`
          font-weight: bold;
          font-size: 30px;
        `}
      >
        스크립트 정보
      </div>

      <StyledLabel>
        UUID
        <InputText readonly value={scriptQuery.data.uuid} />
      </StyledLabel>

      <StyledLabel>
        제목
        <InputText
          value={formValue?.title}
          onChange={(title) =>
            setFormValue((prev) => prev && { ...prev, title })
          }
        />
      </StyledLabel>

      <StyledLabel>
        내용
        <InputTextarea readonly value={formValue?.content} />
      </StyledLabel>

      <StyledLabel>
        태그
        <InputSelect readonly value={formValue?.tagName}>
          {scriptTagQuery.data.map((tag) => (
            <option key={tag.tagName} value={tag.tagName}>
              {tag.tagLabel}
            </option>
          ))}
        </InputSelect>
      </StyledLabel>

      <StyledLabel>
        출처
        <InputText
          value={formValue?.source}
          onChange={(source) =>
            setFormValue((prev) => prev && { ...prev, source })
          }
        />
      </StyledLabel>

      <StyledLabel>
        활성화
        <StyledInput
          type="checkbox"
          checked={formValue?.isEnabled}
          onChange={({ currentTarget: { checked: isEnabled } }) =>
            setFormValue((prev) => prev && { ...prev, isEnabled })
          }
        />
      </StyledLabel>

      <div
        css={css`
          display: flex;
          gap: 20px;
        `}
      >
        <Button
          disabled={
            isSaving ||
            (!!formValue &&
              Object.values(formValue).some(
                (v) => typeof v === 'string' && v.trim() === '',
              ))
          }
          onClick={save}
        >
          저장
        </Button>

        <ErrorText errorText={errorText} />
      </div>
    </ContentBox>
  );
};

// 스크립트 삭제 기능 주석 처리
// const navigate = useNavigate();
// const [showDeleteScriptModal, setShowDeleteScriptModal] = useState(false);
// {<Button
//   label="삭제"
//   color={utils.style.color.highlight1}
//   onClick={() => {
//     setShowDeleteScriptModal(true);
//   }}
// />}
// {showDeleteScriptModal && (
//     <DeleteScriptModal
//       scriptTitle={scriptSlice.script.data!.title}
//       onConfirm={async () => {
//         await voiceRepository.deleteScript(uuid!);
//         setShowDeleteScriptModal(false);
//         navigate('..', { replace: true });
//       }}
//       onClose={() => {
//         setShowDeleteScriptModal(false);
//       }}
//     />
//   );}
