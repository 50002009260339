import { Button } from '@admin/components/Button';
import { StyledH1 } from '@admin/components/Styled/H1';
import { StyledTable } from '@admin/components/Styled/Table';
import { DISPLAY_NAMES_VOICE } from '@admin/repository/constant';
import type { Voice } from '@admin/repository/types';
import { utils } from '@admin/utils';
import { css } from '@emotion/react';
import _ from 'lodash-es';
import { useState } from 'react';

import { SearchBar } from '../../../SearchBar';

type ColumnKey = Extract<keyof Voice, 'displayName' | 'name'>;

const columns = {
  name: DISPLAY_NAMES_VOICE.name,
  displayName: DISPLAY_NAMES_VOICE.displayName,
} as const satisfies Record<ColumnKey, string>;

const searchColumns = _.pick(columns, ['displayName', 'name']);

interface Props {
  readonly items: ReadonlyArray<Voice['voiceId']>;
  readonly onClickDismiss: (voiceId: Voice['voiceId']) => void;
  readonly voices: ReadonlyArray<Voice>;
}

export const AssignedTable = (props: Props) => {
  const [search, setSearch] = useState({
    column: 'name' as keyof typeof searchColumns,
    value: '',
  });

  const filteredItems = _.chain(props.voices)
    .filter({ voiceType: 'SPECIAL' })
    .filter(({ voiceId }) => props.items.includes(voiceId))
    .filter(({ [search.column]: value }) =>
      value.toLowerCase().includes(search.value.toLowerCase()),
    )
    .value();

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: 24px;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 24px;
        `}
      >
        <StyledH1
          css={css`
            white-space: nowrap;
          `}
        >
          사용 중
        </StyledH1>
        <SearchBar
          columns={searchColumns}
          column={search.column}
          value={search.value}
          onChangeColumn={(column) =>
            setSearch((prev) => ({ ...prev, column }))
          }
          onSubmitSearchValue={(value) =>
            setSearch((prev) => ({ ...prev, value }))
          }
        />
      </div>

      <div
        css={css`
          height: 100%;
          overflow: auto;
        `}
      >
        <StyledTable>
          <thead>
            <tr>
              {Object.entries(columns).map(([key, value]) => (
                <th key={key}>{value}</th>
              ))}
              <th />
            </tr>
          </thead>
          <tbody>
            {filteredItems.map((e) => (
              <tr key={e.voiceId}>
                <td>{e.name}</td>
                <td>{e.displayName}</td>
                <td>
                  <div
                    css={css`
                      display: flex;
                      justify-content: center;
                    `}
                  >
                    <Button
                      baseColor={utils.style.color.subBlack}
                      onClick={() => props.onClickDismiss(e.voiceId)}
                    >
                      해제
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </StyledTable>
      </div>
    </div>
  );
};
