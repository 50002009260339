import { utils } from '@admin/utils';
import { css } from '@emotion/react';
import type { ReactNode } from 'react';

export const ContentBox = (props: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <div
      css={css`
        min-width: fit-content;
        background-color: ${utils.style.color.white};
        border-radius: 20px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        padding: 30px;
      `}
      className={props.className}
    >
      {props.children}
    </div>
  );
};
