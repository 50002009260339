import { ContentBox } from '@admin/components/ContentBox';
import { InputDateRange } from '@admin/components/InputDateRange';
import { CustomSuspense } from '@admin/components/MainLayout/CustomSuspense';
import { StyledH1 } from '@admin/components/Styled/H1';
import { repositoryClient } from '@admin/repository';
import type { Voice } from '@admin/repository/types';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { StatisticsTable } from './StatisticsTable';

export const SpecialVoiceSettlementPage = () => {
  const { name } = useParams();

  const [dateRange, setDateRange] = useState(() => {
    const [start, end] = [new Date(), new Date()];
    start.setDate(end.getDate() - 7);
    return { start, end };
  });

  const voiceDetailQuery = useQuery({
    queryKey: [`/voice/${name}`] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ data: Voice }>(path, { signal }),
    select: ({ data: { data } }) => data,
  });

  invariant(
    voiceDetailQuery.data,
    `voiceDetailQuery status: ${voiceDetailQuery.status}`,
  );

  const { data: voice } = voiceDetailQuery;

  return (
    <ContentBox
      css={css`
        gap: 20px;
        height: 100%;
      `}
    >
      <StyledH1>
        {voice.displayName} ({voice.name})
      </StyledH1>
      <InputDateRange
        enableShortcuts
        onChange={setDateRange}
        value={dateRange}
      />
      <CustomSuspense>
        <StatisticsTable start={dateRange.start} end={dateRange.end} />
      </CustomSuspense>
    </ContentBox>
  );
};
