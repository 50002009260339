import _ from 'lodash-es';

export type Line = {
  index: number;
  filename: string;
  language: string;
  text: string;
};

export const parseScript = (rawScript: string) =>
  _.chain(rawScript)
    .split(/\r\n|\n|\r/)
    .flatMap<Line>((line, index) => {
      const matches = line.match(
        /^(?<filename>.+)\|(?<language>\w+)\|(?<text>.+)$/,
      );
      if (matches === null) {
        return [];
      }

      const groups = matches.groups as
        | Record<'filename' | 'language' | 'text', string>
        | undefined;
      if (groups === undefined) {
        return [];
      }

      groups.filename = groups.filename.normalize('NFC');
      groups.language = _.capitalize(groups.language);
      groups.text = groups.text.normalize('NFC');

      const { filename, text } = groups;
      if (filename.trim() === '' || text.trim() === '') {
        return [];
      }

      return { ...groups, index };
    })
    .value();

export const validateScript = (lines: Line[]): string[] => {
  const causes: string[] = [];
  const fileNameSet = new Set<string>();
  for (const { index, filename } of lines) {
    if (fileNameSet.has(filename)) {
      causes.push(
        `중복된 오디오 파일명: ${filename}.wav (${index + 1}번째 줄)`,
      );
    }
    fileNameSet.add(filename);
  }
  return causes;
};
