import { ContentBox } from '@admin/components/ContentBox';
import { InputDateRange } from '@admin/components/InputDateRange';
import { StyledH1 } from '@admin/components/Styled/H1';
import { StyledTable } from '@admin/components/Styled/Table';
import { repositoryClient } from '@admin/repository';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useEffect, useRef, useState } from 'react';

type HumeloStatisticsType = {
  allBillingAmount: number; // 총 청구 금액
  textCountBillingAmount: number; // 글자 수 청구 금액
  webTextCountBillingAmount: number; // 웹 청구 금액
  apiTextCountBillingAmount: number; // API 글자 수 청구 금액
  customVoiceBillingAmount: number; // 마이 AI 보이스 청구 금액
  customVoiceSettlementAmount: number; // 마이 AI 보이스 정산 금액
  settlementTextCount: number; // 총 정산 글자 수
  webSettlementTextCount: number; // 웹 정산 글자 수
  apiSettlementTextCount: number; // API 정산 글자 수
};

export const HumeloStatisticsPage = () => {
  const tableRef = useRef<HTMLTableElement>(null);

  const [dateRange, setDateRange] = useState(() => {
    const [start, end] = [new Date(), new Date()];
    start.setDate(end.getDate() - 7);
    return { start, end };
  });

  const requestData = {
    since: JSON.parse(format(dateRange.start, '[yyyy, M, d]')),
    till: JSON.parse(format(dateRange.end, '[yyyy, M, d]')),
  };

  const {
    data: humeloStatisticsData,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ['/statistics/settlements/humelo'] as const,
    queryFn: ({ queryKey: [path], signal }) => {
      return repositoryClient.post<{ data: HumeloStatisticsType }>(
        path,
        requestData,
        {
          signal,
        },
      );
    },
    select: ({ data: { data } }) => data,
  });

  useEffect(() => {
    refetch();
  }, [dateRange]);

  if (isError) return <div>Error</div>;
  if (isLoading) return <div>Loading</div>;

  invariant(humeloStatisticsData, 'Invalid humelo statistics data.');

  return (
    <ContentBox
      css={css`
        gap: 24px;
        height: 100%;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 16px;
        `}
      >
        <StyledH1>휴멜로 정산</StyledH1>
      </div>

      <InputDateRange value={dateRange} onChange={setDateRange} />

      <div
        css={css`
          height: 120px;
          overflow: auto;
        `}
      >
        <StyledTable ref={tableRef}>
          <thead>
            <tr>
              <th>총 청구 금액</th>
              <th>글자 수 청구 금액</th>
              <th>웹 청구 금액</th>
              <th>API 글자 수 청구 금액</th>
              <th>마이 AI 보이스 청구 금액</th>
              <th>마이 AI 보이스 정산 금액</th>
              <th>총 정산 글자 수</th>
              <th>웹 정산 글자 수</th>
              <th>API 정산 글자 수</th>
            </tr>
          </thead>
          <tbody>
            <td>{humeloStatisticsData.allBillingAmount}원</td>
            <td>{humeloStatisticsData.textCountBillingAmount}원</td>
            <td>{humeloStatisticsData.webTextCountBillingAmount}원</td>
            <td>{humeloStatisticsData.apiTextCountBillingAmount}원</td>
            <td>{humeloStatisticsData.customVoiceBillingAmount}원</td>
            <td>{humeloStatisticsData.customVoiceSettlementAmount}원</td>
            <td>{humeloStatisticsData.settlementTextCount}</td>
            <td>{humeloStatisticsData.webSettlementTextCount}</td>
            <td>{humeloStatisticsData.apiSettlementTextCount}</td>
          </tbody>
        </StyledTable>
      </div>
    </ContentBox>
  );
};
