import { ContentBox } from '@admin/components/ContentBox';
import { StyledH1 } from '@admin/components/Styled/H1';
import { StyledLabel } from '@admin/components/Styled/Label';
import { repositoryClient } from '@admin/repository';
import { DISPLAY_NAMES_PLAN } from '@admin/repository/constant';
import type { MemberStatistics, Voice } from '@admin/repository/types';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useQuery } from '@tanstack/react-query';
import { useParams, useSearchParams } from 'react-router-dom';

export const Statistics = () => {
  const [searchParams] = useSearchParams();

  const serviceCode = searchParams.get('service-code');

  const { email: userEmail } = useParams();

  const query = useQuery({
    queryKey: ['/statistics', { serviceCode, userEmail }] as const,
    queryFn: ({ queryKey: [path, payload], signal }) =>
      repositoryClient.post<{ data: MemberStatistics }>(path, payload, {
        signal,
      }),
    select: ({ data: { data } }) => data,
  });

  invariant(query.data, 'Invalid query data.');

  const voiceQuery = useQuery({
    queryKey: [`/voice/all/${userEmail}`] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ data: Voice[] }>(path, {
        signal,
      }),
    select: ({ data: { data } }) => data,
  });

  invariant(voiceQuery.data, 'Invalid voice query data.');

  const {
    audioContentsStatisticsDto,
    customVoiceStatisticsDto,
    sentenceTypeStatisticsDto,
    subscriptionUsageStatisticsDto,
    voiceUsageStatisticsListDto: { voiceUsageStatisticsDtoList },
  } = query.data;

  return (
    <ContentBox
      css={css`
        gap: 1rem;
      `}
    >
      <StyledH1>유저 통계</StyledH1>
      <div
        css={css`
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
          gap: 20px;
        `}
      >
        <StyledLabel>
          마이 AI 보이스
          <p>
            사용한 마이 AI 보이스 :{' '}
            {customVoiceStatisticsDto.customVoiceCount.toLocaleString('ko-KR')}
            개
          </p>
          <p>제작한 마이 AI 보이스 : {voiceQuery.data.length}개</p>
        </StyledLabel>
        <StyledLabel>
          플랜
          <p>
            플랜 이름 :{' '}
            {DISPLAY_NAMES_PLAN[subscriptionUsageStatisticsDto.planName]}
          </p>
          <p>
            총 자 수 :{' '}
            {subscriptionUsageStatisticsDto.amountCharactersCount.toLocaleString(
              'ko-KR',
            )}
            자
          </p>
          <p>
            남은 자 수:{' '}
            {subscriptionUsageStatisticsDto.enableCharactersCount.toLocaleString(
              'ko-KR',
            )}
            자
          </p>
          <p>
            사용 자 수:{' '}
            {subscriptionUsageStatisticsDto.usedCharactersCount.toLocaleString(
              'ko-KR',
            )}
            자
          </p>
        </StyledLabel>
        <StyledLabel>
          사용량
          <p>
            전체 사용량 :{' '}
            {Math.round(
              ((sentenceTypeStatisticsDto.allServiceCount ?? 0) /
                (subscriptionUsageStatisticsDto.amountCharactersCount ?? 0)) *
                100 *
                10,
            ) / 10}
            %
          </p>
          <p>
            전체 :{' '}
            {sentenceTypeStatisticsDto.allServiceCount.toLocaleString('ko-KR')}
            자
          </p>
          <p>
            합성 보이스 :{' '}
            {sentenceTypeStatisticsDto.voiceSynthesisCount.toLocaleString(
              'ko-KR',
            )}
            자
          </p>
          <p>
            감정 더빙 :{' '}
            {sentenceTypeStatisticsDto.voiceDubbingCount.toLocaleString(
              'ko-KR',
            )}
            자
          </p>
        </StyledLabel>
        <StyledLabel>
          사용한 보이스
          <div
            css={css`
              max-height: 200px;
              overflow: auto;
            `}
          >
            {voiceUsageStatisticsDtoList.length > 0 ? (
              voiceUsageStatisticsDtoList.map((voice) => (
                <p key={voice.voiceUid}>
                  {voice.voiceDisplayName} -{' '}
                  {voice.characterCount.toLocaleString('ko-KR')} 자 사용 -
                  오디오 콘텐츠{' '}
                  {voice.countAudioContents.toLocaleString('ko-KR')}건 -{' '}
                  {voice.isCustom ? '마이 AI 보이스' : '일반 보이스'}
                </p>
              ))
            ) : (
              <p>사용한 보이스가 없습니다.</p>
            )}
          </div>
        </StyledLabel>
        <StyledLabel>
          오디오 컨텐츠 제작
          <p>
            월별 제작 :{' '}
            {audioContentsStatisticsDto.countMonthly.toLocaleString('ko-KR')}건
          </p>
          <p>
            일별 제작 :{' '}
            {audioContentsStatisticsDto.countDaily.toLocaleString('ko-KR')}건
          </p>
        </StyledLabel>
      </div>
    </ContentBox>
  );
};
