import { css } from '@emotion/react';

interface Props {
  text: string;
  inputDisabled: boolean;
  inputName: string;
  value: string;
  defaultCheckedCondition: boolean;
}

export const RadioButtonInput = (props: Props) => {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: 12px;
        height: 48px;
      `}
    >
      <input
        type="radio"
        disabled={props.inputDisabled}
        name={props.inputName}
        value={props.value}
        defaultChecked={props.defaultCheckedCondition}
      />
      {props.text}
    </div>
  );
};
