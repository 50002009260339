import { Button } from '@admin/components/Button';
import { Dialog } from '@admin/components/Dialog';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledForm } from '@admin/components/Styled/Form';
import { StyledLabel } from '@admin/components/Styled/Label';
import { StyledSelect } from '@admin/components/Styled/Select';
import { repositoryClient } from '@admin/repository';
import type { Emotion, Voice } from '@admin/repository/types';
import { repositoryUtils } from '@admin/repository/utils';
import type { EmotionNameType } from '@admin/routes/voice/edit/TrainingForm';
import { utils } from '@admin/utils';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRef } from 'react';

interface Props {
  usableEmotionList: EmotionNameType[];
  emotionList: string[];
  voice: Voice;
}
type emotionMapType = {
  [key: string]: string;
};
const emotionMap: emotionMapType = {
  N: '중립',
  H: '즐거움',
  A: '화남',
  S: '슬픔',
  C: '침착함',
};

export const CreateVoiceEmotionDialog = (props: Props) => {
  const { openModal, closeModal } = useModal();

  const queryClient = useQueryClient();

  const formRef = useRef<HTMLFormElement>(null);

  /* form 에서 선택한 감정 값 가져오기 */
  const getEmotion = () => {
    const { current: form } = formRef;
    invariant(form, 'form is not mounted.');
    const formData = new FormData(form);
    const emotion = formData.get('emotion') as string;
    return emotion;
  };

  /* form 에서 선택한 status 값 가져오기 */
  const getStatus = () => {
    const { current: form } = formRef;
    invariant(form, 'form is not mounted.');
    const formData = new FormData(form);
    const trainingStatus = formData.get('trainingStatus') as string;
    return trainingStatus;
  };

  /* 보이스 감정 추가 */
  const { mutate: createVoiceEmotion, isLoading: isCreating } = useMutation({
    onError: (e) => {
      const message = repositoryUtils.getErrorMessage(e);
      openModal(<AlertDialog title="에러" message={message} />);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`/voice/${props.voice.voiceId}/emotions/${getEmotion()}`],
      });
      queryClient.invalidateQueries({
        queryKey: [`/voice/${props.voice.name}`],
      });
      closeModal();
    },
    mutationFn: () => {
      return repositoryClient.post(
        `/voice/${
          props.voice.voiceId
        }/emotions/${getEmotion()}?isAlreadyTrained=${getStatus()}`,
      );
    },
  });
  const filteredEmotionArray = [...props.voice.emotions]
    .sort(
      (a: Emotion, b: Emotion) =>
        props.emotionList.indexOf(a.name) - props.emotionList.indexOf(b.name),
    )
    .map((item) => item.name);

  const addableEmotionArray = props.usableEmotionList.filter(
    (emotion) => !filteredEmotionArray.includes(emotion),
  );
  return (
    <Dialog title="보이스 감정 추가">
      <StyledForm
        ref={formRef}
        onSubmit={(e) => {
          e.preventDefault();
          createVoiceEmotion();
        }}
        css={css`
          display: flex;
          flex-direction: column;
          width: 480px;
          padding: 24px 30px;
          gap: 24px;
        `}
      >
        <StyledLabel>
          감정
          <StyledSelect name="emotion" required>
            {addableEmotionArray.length > 0 ? (
              addableEmotionArray.map((emotion) => {
                return (
                  <option key={emotion} value={emotion}>
                    {emotionMap[emotion]}
                  </option>
                );
              })
            ) : (
              <option key="loadingEmotion">
                추가할 수 있는 감정이 없습니다.
              </option>
            )}
          </StyledSelect>
        </StyledLabel>

        <StyledLabel>
          학습 상태
          <StyledSelect name="trainingStatus" required>
            <option key="ready" value="false">
              학습 대기중
            </option>
            <option key="complete" value="true">
              학습 완료
            </option>
          </StyledSelect>
        </StyledLabel>

        <div
          css={css`
            display: flex;
            justify-content: flex-end;
            gap: 16px;
          `}
        >
          <Button
            form
            disabled={
              isCreating || addableEmotionArray.length === 0 // 해당 보이스가 사용 가능한 모든 감정을 추가할 경우 더이상 생성 불가
            }
          >
            생성
          </Button>

          <Button
            onClick={closeModal}
            textColor={utils.style.color.grey500}
            baseColor={utils.style.color.grey200}
          >
            취소
          </Button>
        </div>
      </StyledForm>
    </Dialog>
  );
};
