import { Search } from '@admin/components/Search';
import { StyledSelect } from '@admin/components/Styled/Select';
import { css } from '@emotion/react';

interface Props<M extends Record<string, string>> {
  readonly columns: M;
  readonly column: keyof M;
  readonly value: string;
  readonly onChangeColumn: (column: keyof M) => void;
  readonly onSubmitSearchValue: (value: string) => void;
}

export const SearchBar = <M extends Record<string, string>>(
  props: Props<M>,
) => {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: 16px;
      `}
    >
      <StyledSelect
        onChange={({ currentTarget: { value } }) => props.onChangeColumn(value)}
        value={props.column as string}
      >
        {Object.entries(props.columns).map(([column, display]) => (
          <option key={column} value={column}>
            {display}
          </option>
        ))}
      </StyledSelect>
      <Search
        defaultValue={props.value}
        onSubmit={props.onSubmitSearchValue}
        css={css`
          width: 200px;
        `}
      />
    </div>
  );
};
