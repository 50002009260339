import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { Divider } from '@admin/components/Divider';
import { InputTextarea } from '@admin/components/InputTextarea';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledLabel } from '@admin/components/Styled/Label';
import { repositoryClient } from '@admin/repository';
import { repositoryUtils } from '@admin/repository/utils';
import { utils } from '@admin/utils';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

export const MailTitlePreviewPage = () => {
  const { openModal } = useModal();

  const queryKey = [
    '/file/static',
    { resourceDomain: 'mail-template', resourceName: 'MAIL_TITLE' },
  ] as const;

  const query = useQuery({
    queryKey,
    queryFn: ({ queryKey: [path, { resourceDomain, resourceName }], signal }) =>
      repositoryClient.get<string>(`${path}/${resourceDomain}`, {
        params: { mailTemplateType: resourceName },
        responseType: 'text',
        signal,
      }),
    select: ({ data }) => data,
  });

  invariant(query.data, 'Invalid query data.');

  const [textContent, setTextContent] = useState(query.data);

  const queryClient = useQueryClient();

  const { mutate: save, isLoading: isSaving } = useMutation({
    onError: (e) => {
      openModal(
        <AlertDialog
          title="에러"
          message={repositoryUtils.getErrorMessage(e)}
        />,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      openModal(
        <AlertDialog title="알림" message="메일 제목이 저장되었습니다." />,
      );
    },
    mutationFn: async () => {
      if (!textContent) {
        throw new Error('텍스트를 입력해 주세요.');
      }
      const [path, { resourceDomain, resourceName }] = queryKey;
      const formData = new FormData();
      formData.append(
        'uploadFile',
        new Blob([textContent], { type: 'text/plain' }),
      );
      formData.append('mailTemplateType', resourceName);
      return repositoryClient.post(`${path}/${resourceDomain}`, formData);
    },
  });

  return (
    <ContentBox
      css={css`
        gap: 20px;
      `}
    >
      <div
        css={css`
          font-weight: bold;
          font-size: 30px;
        `}
      >
        메일 제목 리스트
      </div>

      <div
        css={css`
          display: flex;
          justify-content: end;
          align-items: center;
        `}
      >
        <Button disabled={!textContent || isSaving} onClick={save}>
          업로드
        </Button>
      </div>

      <InputTextarea value={textContent} onChange={setTextContent} />

      <Divider />

      <StyledLabel>
        작성 예시
        <div
          css={css`
            ${utils.style.font['text-l']};
          `}
        >
          mail_auth KT AI Voice Studio 인증 메일
          <br />
          mail_change_pw KT AI Voice Studio 패스워드 변경 메일
          <br />
          mail_success KT AI Voice Studio 회원가입 완료
          <br />
          mail_inquiry KT AI Voice Studio 고객 문의 메일
          <br />
          mail_custom_voice KT AI Voice Studio 마이 AI 보이스 생성 완료 메일
        </div>
      </StyledLabel>
    </ContentBox>
  );
};
