import { Button } from '@admin/components/Button';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { InputDateRange } from '@admin/components/InputDateRange';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledForm } from '@admin/components/Styled/Form';
import { repositoryClient } from '@admin/repository';
import type { Repository } from '@admin/repository/types';
import { repositoryUtils } from '@admin/repository/utils';
import { RadioButtonInput } from '@admin/routes/voucher/edit/VoucherEditForm/RadioButtonInput';
import { RadioButtonNumberInput } from '@admin/routes/voucher/edit/VoucherEditForm/RadioButtonNumberInput';
import { ActivationButton } from '@admin/routes/voucher/edit/VoucherMemberList/ActivationButton';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useRef, useState } from 'react';

const voucherStatusMap = {
  ACTIVE: '활성',
  INACTIVE: '비활성',
  EXPIRED: '만료',
};

export const VoucherEditForm = ({
  voucherDetail,
}: {
  voucherDetail: Repository['voucher'];
}) => {
  const { openModal } = useModal();
  const queryClient = useQueryClient();
  const formRef = useRef<HTMLFormElement>(null);
  const [dateRange, setDateRange] = useState(() => {
    const [start, end] = [
      new Date(voucherDetail.since.join('-') ?? ''),
      new Date(voucherDetail.till.join('-') ?? ''),
    ];
    return { start, end };
  });
  /* 날짜 [yyyy,mm,dd] 로 변환 */
  const formattedDate = (date: Date) => {
    return format(date, 'yyyy-M-d')
      .split('-')
      .map((elem) => parseInt(elem, 10));
  };

  /* 바우처 적용된 멤버 조회 */
  const { data: voucherMemberList } = useQuery({
    queryKey: [`/admin/voucher-history/${voucherDetail.code}`] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ data: Repository['voucherMember'][] }>(path, {
        signal,
      }),
    select: ({ data: { data } }) => data,
  });

  invariant(voucherMemberList, 'Invalid voucher member list.');

  /* 이벤트 쿠폰 상세정보 수정 */
  const { mutate: updateEventCoupon, isLoading: isUpdating } = useMutation({
    onError: (e) => {
      const message = repositoryUtils.getErrorMessage(e);
      openModal(<AlertDialog title="에러" message={message} />);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`/admin/voucher/${voucherDetail.code}`],
      });

      openModal(
        <AlertDialog title="알림" message="이벤트 쿠폰이 수정되었습니다." />,
      );
    },
    mutationFn: () => {
      const { current: form } = formRef;
      invariant(form, 'form is not mounted.');
      const formData = new FormData(form);
      const charactersCountValue = () => {
        let result = 0;
        if (formData.get('charactersOpt') === 'MONTHLY') {
          result = parseFloat(formData.get('charactersCountMonthly') as string);
        } else if (formData.get('charactersOpt') === 'ONCE') {
          result = parseFloat(formData.get('charactersCountOnce') as string);
        }
        return result;
      };
      const payload = {
        displayName: formData.get('displayName') as string,
        expirationOpt: formData.get('expirationOpt') as string,
        expirationMonth: parseInt(
          formData.get('expirationMonth') as string,
          10,
        ),
        since: formattedDate(dateRange.start),
        till: formattedDate(dateRange.end),
        charactersCount: charactersCountValue(),
        myVoiceCount: parseInt(formData.get('myVoiceCount') as string, 10),
        charactersOpt: formData.get('charactersOpt') as string,
        dubbingOpt: formData.get('dubbingOpt') as string,
        myVoiceOpt: formData.get('myVoiceOpt') as string,
      };
      return repositoryClient.patch(
        `/admin/voucher/${voucherDetail.code}`,
        payload,
      );
    },
  });

  const inputDisabled =
    voucherDetail.status !== 'INACTIVE' && voucherMemberList.length > 0;

  return (
    <StyledForm
      ref={formRef}
      onSubmit={(e) => {
        e.preventDefault();
        updateEventCoupon();
      }}
    >
      <label>
        표시 이름
        <input
          type="text"
          name="displayName"
          defaultValue={voucherDetail.displayName}
        />
      </label>

      <label>
        코드
        <input disabled type="text" name="code" value={voucherDetail.code} />
      </label>

      <label>
        상태
        <input
          disabled
          type="text"
          name="status"
          value={voucherStatusMap[voucherDetail.status]}
        />
      </label>

      <label htmlFor="charactersOpt">
        글자 수
        <RadioButtonNumberInput
          text={['유효 기간 내 매 달', '자 충전']}
          inputDisabled={inputDisabled}
          inputName={['charactersOpt', 'charactersCountMonthly']}
          value="MONTHLY"
          defaultCheckedCondition={voucherDetail.charactersOpt === 'MONTHLY'}
          defaultValue={voucherDetail.charactersCount}
        />
        <RadioButtonNumberInput
          text={['유효 기간 내', '자 사용 가능']}
          inputDisabled={inputDisabled}
          inputName={['charactersOpt', 'charactersCountOnce']}
          value="ONCE"
          defaultCheckedCondition={voucherDetail.charactersOpt === 'ONCE'}
          defaultValue={voucherDetail.charactersCount}
        />
        <RadioButtonInput
          text="유효 기간 내 무제한 사용 가능"
          inputDisabled={inputDisabled}
          inputName="charactersOpt"
          value="UNLIMITED"
          defaultCheckedCondition={voucherDetail.charactersOpt === 'UNLIMITED'}
        />
      </label>

      <label htmlFor="expirationOpt">
        유효 기간
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 12px;
            height: 48px;
          `}
        >
          <input
            type="radio"
            disabled={inputDisabled}
            name="expirationOpt"
            value="LIMITED"
            defaultChecked
          />
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 12px;
            `}
          >
            <InputDateRange
              disabled={inputDisabled}
              onChange={setDateRange}
              value={dateRange}
            />
          </div>
        </div>
        <RadioButtonNumberInput
          text={['등록일로부터', '개월']}
          inputDisabled={inputDisabled}
          inputName={['expirationOpt', 'expirationMonth']}
          value="UNLIMITED"
          defaultCheckedCondition={voucherDetail.charactersOpt === 'ONCE'}
          defaultValue={voucherDetail.expirationMonth ?? 0}
        />
      </label>

      <label htmlFor="dubbingOpt">
        감정 더빙
        <RadioButtonInput
          text="요금제 정책 연동"
          inputDisabled={inputDisabled}
          inputName="dubbingOpt"
          value="PLAN"
          defaultCheckedCondition={voucherDetail.dubbingOpt === 'PLAN'}
        />
        <RadioButtonInput
          text="항상 사용 가능"
          inputDisabled={inputDisabled}
          inputName="dubbingOpt"
          value="ALWAYS"
          defaultCheckedCondition={voucherDetail.dubbingOpt === 'ALWAYS'}
        />
      </label>

      <label htmlFor="myVoiceOpt">
        마이 AI 보이스 정책
        <RadioButtonInput
          text="요금제 정책 연동"
          inputDisabled={inputDisabled}
          inputName="myVoiceOpt"
          value="PLAN"
          defaultCheckedCondition={voucherDetail.myVoiceOpt === 'PLAN'}
        />
        <RadioButtonInput
          text="요금제정책과 상관없이 마이 AI 보이스 제작 및 오디오 콘텐츠 제작 가능"
          inputDisabled={inputDisabled}
          inputName="myVoiceOpt"
          value="ALWAYS"
          defaultCheckedCondition={voucherDetail.myVoiceOpt === 'ALWAYS'}
        />
      </label>

      <label htmlFor="myVoiceOpt">
        마이 AI 보이스
        <RadioButtonNumberInput
          text={['', '개 지급']}
          inputDisabled={inputDisabled}
          inputName={['COUNT', 'myVoiceCount']}
          value="COUNT"
          defaultCheckedCondition
          defaultValue={voucherDetail.myVoiceCount}
        />
      </label>

      <div
        css={css`
          display: flex;
          gap: 60px;
        `}
      >
        <Button form disabled={inputDisabled || isUpdating}>
          저장
        </Button>
        {/* 활성화 버튼 */}
        <ActivationButton voucherDetail={voucherDetail} />
      </div>
    </StyledForm>
  );
};
