import { Button } from '@admin/components/Button';
import { StyledInput } from '@admin/components/Styled/Input';
import { StyledSelect } from '@admin/components/Styled/Select';
import { VerticalDivider } from '@admin/components/VerticalDivider';
import {
  DISPLAY_NAMES_PLAN,
  DISPLAY_NAMES_VOICE,
  DISPLAY_NAMES_VOICE_TYPE,
} from '@admin/repository/constant';
import type { Voice } from '@admin/repository/types';
import { utils } from '@admin/utils';
import { css } from '@emotion/react';
import _ from 'lodash-es';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

const filterColumnKeys = {
  'display-name': 'displayName',
  name: 'name',
  'voice-id': 'voiceId',
  creatorEmail: 'creatorEmail',
} as const satisfies Record<string, keyof Voice>;

export const Filter = () => {
  const defaults = useMemo(() => {
    const currentSearchParams = new URLSearchParams(window.location.search);
    const query = currentSearchParams.get('query') ?? '';
    const column = currentSearchParams.get('column') ?? '';
    const voiceTypes = (currentSearchParams.get('voice-type') ?? '')
      .split(',')
      .filter((v) => !!v);
    const planName = (currentSearchParams.get('plan-name') ?? '')
      .split(',')
      .filter((v) => !!v);
    const isEnabled = currentSearchParams.get('is-enabled') ?? '';
    return { query, column, planName, voiceTypes, isEnabled };
  }, []);

  const [, setSearchParams] = useSearchParams();

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        const params = new URLSearchParams();
        params.set('query', formData.get('query') as string);
        params.set(
          'column',
          (formData.get('column') as string) ||
            filterColumnKeys['display-name'],
        );
        params.set('is-enabled', formData.get('is-enabled') as string);
        params.set(
          'voice-type',
          Array.from(formData.entries())
            .filter(([k]) => k.startsWith('voice-type-'))
            .map(([k]) => k.replace('voice-type-', ''))
            .sort()
            .join(','),
        );
        params.set(
          'plan-name',
          Array.from(formData.entries())
            .filter(([k]) => k.startsWith('plan-name-'))
            .map(([k]) => k.replace('plan-name-', ''))
            .sort()
            .join(','),
        );
        setSearchParams(params, { replace: true });
      }}
      css={css`
        display: flex;
        align-items: center;
        gap: 16px;
        user-select: none;
      `}
    >
      <StyledSelect name="column" defaultValue={defaults.column}>
        {Object.entries(filterColumnKeys).map(([k, v]) => (
          <option key={k} value={v}>
            {DISPLAY_NAMES_VOICE[v]}
          </option>
        ))}
      </StyledSelect>

      <StyledInput
        type="search"
        name="query"
        defaultValue={defaults.query}
        css={css`
          width: 300px;
        `}
      />

      <VerticalDivider />

      <section
        css={css`
          display: flex;
          flex-direction: column;
          gap: 4px;
          ${utils.style.font['text-s']};
        `}
      >
        <p>{DISPLAY_NAMES_VOICE.voiceType}</p>
        <div
          css={css`
            display: flex;
            gap: 4px;
          `}
        >
          {Object.entries(DISPLAY_NAMES_VOICE_TYPE).map(([k, v]) => {
            const key = k.toLowerCase();
            return (
              <label
                key={key}
                css={css`
                  display: flex;
                  align-items: center;
                  gap: 4px;
                `}
              >
                <input
                  type="checkbox"
                  name={`voice-type-${key}`}
                  defaultChecked={
                    _.isEmpty(defaults.voiceTypes) ||
                    defaults.voiceTypes.includes(key)
                  }
                />
                <span>{v}</span>
              </label>
            );
          })}
        </div>
      </section>

      <VerticalDivider />

      <section
        css={css`
          display: flex;
          flex-direction: column;
          gap: 4px;
          ${utils.style.font['text-s']};
        `}
      >
        <p>{DISPLAY_NAMES_VOICE.planName}</p>
        <div
          css={css`
            display: flex;
            gap: 4px;
          `}
        >
          {Object.entries({ None: 'None', ...DISPLAY_NAMES_PLAN }).map(
            ([k, v]) => {
              const key = k.toLowerCase();
              return (
                <label
                  key={key}
                  css={css`
                    display: flex;
                    align-items: center;
                    gap: 4px;
                  `}
                >
                  <input
                    type="checkbox"
                    name={`plan-name-${key}`}
                    defaultChecked={
                      _.isEmpty(defaults.planName) ||
                      defaults.planName.includes(key)
                    }
                  />
                  <span>{v}</span>
                </label>
              );
            },
          )}
        </div>
      </section>

      <VerticalDivider />

      <section
        css={css`
          display: flex;
          flex-direction: column;
          gap: 4px;
          ${utils.style.font['text-s']};
        `}
      >
        <p>{DISPLAY_NAMES_VOICE.isEnabled}</p>
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 4px;
          `}
        >
          <input
            defaultChecked={!defaults.isEnabled}
            type="radio"
            id="is-enabled-all"
            name="is-enabled"
            value=""
          />
          <label htmlFor="is-enabled-all">전체</label>
          <input
            defaultChecked={defaults.isEnabled === 'true'}
            type="radio"
            id="is-enabled-true"
            name="is-enabled"
            value="true"
          />
          <label htmlFor="is-enabled-true">활성</label>
          <input
            defaultChecked={defaults.isEnabled === 'false'}
            type="radio"
            id="is-enabled-false"
            name="is-enabled"
            value="false"
          />
          <label htmlFor="is-enabled-false">비활성</label>
        </div>
      </section>

      <Button form>검색</Button>
    </form>
  );
};
