import { ContentBox } from '@admin/components/ContentBox';
import { StyledH1 } from '@admin/components/Styled/H1';
import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import { useResetRecoilState } from 'recoil';

import { voiceTrainingFormAtoms } from './atom';
import { Script } from './Script';
import { SelectEmotion } from './SelectEmotion.tsx';
import { SelectFileButtons } from './SelectFileButtons';
import { StartButton } from './StartButton';

export type EmotionNameType = 'N' | 'A' | 'C' | 'H' | 'S';

export const TrainingForm = () => {
  const [currentEmotion, setCurrentEmotion] = useState<EmotionNameType>('N');

  const onChangeCurrentEmotion = (emotionName: EmotionNameType) => {
    setCurrentEmotion(emotionName);
  };

  const resetScriptData = useResetRecoilState(
    voiceTrainingFormAtoms['script-data'],
  );

  useEffect(() => () => resetScriptData(), [resetScriptData]);

  return (
    <ContentBox
      css={css`
        gap: 24px;
      `}
    >
      <StyledH1>보이스 새로 학습하기</StyledH1>

      <SelectEmotion
        currentEmotion={currentEmotion}
        onChangeCurrentEmotion={onChangeCurrentEmotion}
      />

      <SelectFileButtons
        voiceProps={undefined}
        currentEmotion={currentEmotion}
      />

      <Script />

      <StartButton voiceProps={undefined} currentEmotion={currentEmotion} />
    </ContentBox>
  );
};
