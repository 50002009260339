import { utils } from '@admin/utils';
import { css } from '@emotion/react';
import ReactPaginate from 'react-paginate';

export const Paginate = (props: {
  forcePage: number;
  onPageChange: (selected: number) => void;
  pageCount: number;
}) => {
  return (
    <ReactPaginate
      css={css`
        display: flex;
        justify-content: center;
        list-style-type: none;
        margin: 0;
        padding: 0;
        li a {
          padding: 0 10px;
          cursor: pointer;
        }
        li.active a {
          background-color: #0366d6;
          border-color: transparent;
          color: white;
          min-width: 32px;
        }
        li.selected a {
          color: white;
          background-color: ${utils.style.color.primary};
          border-radius: 5px;
        }
        li.selected,
        li.selected a {
          cursor: default;
        }
      `}
      breakLabel="..."
      nextLabel="다음 >"
      forcePage={props.forcePage}
      onPageChange={({ selected }) => {
        props.onPageChange(selected);
      }}
      pageRangeDisplayed={5}
      pageCount={props.pageCount}
      previousLabel="< 이전"
    />
  );
};
