import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { PromptDialog } from '@admin/components/Dialog/PromptDialog';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledH1 } from '@admin/components/Styled/H1';
import { StyledTable } from '@admin/components/Styled/Table';
import { repositoryClient } from '@admin/repository';
import { DISPLAY_NAMES_PLAN } from '@admin/repository/constant';
import type { Member } from '@admin/repository/types';
import { repositoryUtils } from '@admin/repository/utils';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import _ from 'lodash-es';
import { useRef } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

export const EventTable = () => {
  const { openModal, closeModal } = useModal();
  const { email: userEmail } = useParams();

  const [searchParams] = useSearchParams();

  const serviceCode = searchParams.get('service-code');

  const tableRef = useRef<HTMLTableElement>(null);

  const userQuery = useQuery({
    queryKey: ['/member/single', { serviceCode, userEmail }] as const,
    queryFn: ({ queryKey: [path, { serviceCode, userEmail }], signal }) =>
      repositoryClient.get<{ data: Member }>(`${path}/${userEmail}`, {
        params: { serviceCode },
        signal,
      }),
    select: ({ data: { data } }) => ({
      id: data.id,
    }),
  });

  invariant(userQuery.data, 'Invalid user query data.');

  const query = useQuery({
    queryKey: [
      `/subscription-promotion/voice/user/${userQuery.data.id}`,
    ] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{
        data: Readonly<{
          readonly eventName: string;
          readonly date: string | null;
          readonly planName: string | null;
          readonly participation: boolean;
        }>;
      }>(path, { signal }),
    select: ({ data: { data } }) => [data],
    enabled: !_.isUndefined(userQuery.data),
  });

  invariant(query.data, 'Invalid query data.');

  const query2 = useQuery({
    queryKey: [
      `/subscription-promotion/buy/user/${userQuery.data.id}`,
    ] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{
        data: Readonly<{
          readonly eventName: string;
          readonly date: string | null;
          readonly planName: string | null;
          readonly participation: boolean;
        }>;
      }>(path, { signal }),
    select: ({ data: { data } }) => [data],
    enabled: !_.isUndefined(userQuery.data),
  });

  invariant(query2.data, 'Invalid query data.');

  const { mutate: deleteEvent } = useMutation({
    mutationFn: () => {
      return repositoryClient.delete(
        `/subscription-promotion/voice/user/${userQuery.data.id}`,
      );
    },
    onError: (error) => {
      closeModal();
      openModal(
        <AlertDialog
          title="에러"
          message={repositoryUtils.getErrorMessage(error)}
        />,
      );
    },
    onSuccess: () => {
      closeModal();
      openModal(
        <AlertDialog title="알림" message="이용권이 회수되었습니다." />,
      );
      query.refetch();
    },
  });

  const { mutate: deleteEvent2 } = useMutation({
    mutationFn: () => {
      return repositoryClient.delete(
        `/subscription-promotion/buy/user/${userQuery.data.id}`,
      );
    },
    onError: (error) => {
      closeModal();
      openModal(
        <AlertDialog
          title="에러"
          message={repositoryUtils.getErrorMessage(error)}
        />,
      );
    },
    onSuccess: () => {
      closeModal();
      openModal(
        <AlertDialog title="알림" message="이용권이 회수되었습니다." />,
      );
      query.refetch();
    },
  });

  return (
    <ContentBox
      css={css`
        gap: 1rem;
      `}
    >
      <StyledH1>이벤트 이용권</StyledH1>

      <div
        css={css`
          min-height: 120px;
          height: 100%;
          max-height: 500px;
          overflow: auto;
        `}
      >
        <StyledTable ref={tableRef}>
          <thead>
            <tr>
              <th>No.</th>
              <th>이벤트명</th>
              <th>이용권 개수</th>
              <th>지급 일자</th>
              <th>회수</th>
            </tr>
          </thead>
          <tbody>
            {query.data.some(({ participation }) => participation) &&
              query.data.map(({ date, planName }) => {
                const parsedDate = repositoryUtils.parseDate(date);
                if (parsedDate === null) {
                  throw new Error(`Invalid date format: ${date}`);
                }
                return (
                  <tr key={`${parsedDate.toISOString()}}`}>
                    <td>1</td>
                    <td>{`${
                      DISPLAY_NAMES_PLAN[
                        (planName as 'Pro' | 'ProMax') || 'Pro'
                      ]
                    } 최초 구독`}</td>
                    <td>1</td>
                    <td>
                      {parsedDate.toLocaleDateString('ko-KR', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      })}
                    </td>
                    <td style={{ display: 'flex', justifyContent: 'center' }}>
                      <Button
                        onClick={() => {
                          openModal(
                            <PromptDialog
                              title="확인"
                              message="이용권을 회수하시겠습니까?"
                              onConfirm={() => {
                                deleteEvent();
                              }}
                            />,
                          );
                        }}
                      >
                        이용권 회수
                      </Button>
                    </td>
                  </tr>
                );
              })}
            {query2.data.some(({ participation }) => participation) &&
              query2.data.map(({ date }) => {
                const parsedDate = repositoryUtils.parseDate(date);
                if (parsedDate === null) {
                  throw new Error(`Invalid date format: ${date}`);
                }
                return (
                  <tr key={`${parsedDate.toISOString()}}`}>
                    <td>
                      {query.data.some(({ participation }) => participation)
                        ? 2
                        : 1}
                    </td>
                    <td>마이 AI 보이스 1+1 이벤트</td>
                    <td>1</td>
                    <td>
                      {parsedDate.toLocaleDateString('ko-KR', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      })}
                    </td>
                    <td style={{ display: 'flex', justifyContent: 'center' }}>
                      <Button
                        onClick={() => {
                          openModal(
                            <PromptDialog
                              title="확인"
                              message="이용권을 회수하시겠습니까?"
                              onConfirm={() => {
                                deleteEvent2();
                              }}
                            />,
                          );
                        }}
                      >
                        이용권 회수
                      </Button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </StyledTable>
      </div>
    </ContentBox>
  );
};
