import { utils } from '@admin/utils';
import axios, { AxiosError } from 'axios';
import CryptoJS from 'crypto-js';

import { repositoryUtils } from './utils';
import dayjs from 'dayjs';

const decryptionKey = CryptoJS.enc.Utf8.parse(import.meta.env.VITE_AES_KEY);

const decryptionIv = CryptoJS.enc.Hex.parse('0000000000000000');

export const repositoryClient = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  headers: { 'Service-Code': '001WEB001' },
});

repositoryClient.interceptors.request.use((config) => {
  if (!config.url) {
    return config;
  }
  if (!(config.data instanceof FormData)) {
    config.headers['Content-Type'] = 'application/json';
  }
  const { accessToken } = utils.token.get();
  if (accessToken) {
    if (
      utils.lastApiRequest.get() &&
      dayjs().diff(utils.lastApiRequest.get(), 'hours') >= 1
    ) {
      utils.token.clear();
      utils.lastApiRequest.remove();
      alert('1시간 동안 사용이 없어 자동 로그아웃 되었습니다.');
      return config;
    }
    const authorization = `Bearer ${accessToken}`;
    config.headers.Authorization = authorization;
    config.params = { ...config.params, Authorization: authorization };
  }
  return config;
});

repositoryClient.interceptors.response.use(
  (res) => {
    if (
      res.data &&
      typeof res.data === 'object' &&
      'data' in res.data &&
      typeof res.data.data === 'string' &&
      res.data.data
    ) {
      try {
        res.data.data = JSON.parse(
          CryptoJS.AES.decrypt(res.data.data, decryptionKey, {
            iv: decryptionIv,
          }).toString(CryptoJS.enc.Utf8),
        );
      } catch {
        // fall through
      }
    }

    utils.lastApiRequest.set(dayjs().toString());

    return res;
  },
  async (e) => {
    // 네트워크 에러가 아닌 경우 처리하지 않음
    if (!(e instanceof AxiosError)) {
      throw e;
    }
    const { config, response } = e;
    // API 요청이 아닌 경우 처리하지 않음
    if (!config || !response || !config.url) {
      throw e;
    }
    // 토큰 재발급
    if (
      utils.token.get().accessToken &&
      config.url !== '/token/reissue' &&
      response.status === 401
    ) {
      try {
        await utils.token.reissue();
        return repositoryClient.request(config); // 재요청
      } catch (e) {
        // 토큰 재발급 실패 시 로그인 페이지로 이동
        utils.token.clear();
        window.location.replace('/login');
      }
    }
    // 중복 로그인
    if (response.status === 432 && utils.token.get().accessToken) {
      alert(repositoryUtils.getErrorMessage(e));
      utils.token.clear();
      window.location.replace('/login');
    }
    if (response.status === 433) {
      utils.token.clear();
      window.location.replace('/login');
    }
    throw e;
  },
);
