import { Button } from '@admin/components/Button';
import { StyledTable } from '@admin/components/Styled/Table';
import { repositoryClient } from '@admin/repository';
import { DISPLAY_NAMES_MEMBER } from '@admin/repository/constant';
import type { Member } from '@admin/repository/types';
import { utils } from '@admin/utils';
import { invariant } from '@admin/utils/invariant';
import { routeName } from '@admin/utils/routeName';
import utilTime from '@admin/utils/utilTime';
import { css } from '@emotion/react';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash-es';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const MemberTable = ({ fetchYn }: { fetchYn: boolean }) => {
  const navigate = useNavigate();

  const { serviceCode } = useParams();

  const columns = useMemo(
    () =>
      [
        'id',
        'email',
        'name',
        'phone',
        'role',
        'registerDate',
        'isEnabled',
      ] as const,
    [],
  );

  const headerLabels = useMemo(
    () => _.pick(DISPLAY_NAMES_MEMBER, columns),
    [columns],
  );

  const serviceCodeMemberQuery = useQuery({
    queryKey: ['/crm/service-code', { serviceCode }] as const,
    queryFn: ({ queryKey: [path, { serviceCode }], signal }) =>
      repositoryClient.get<{ data: Member[] }>(`${path}/${serviceCode}`, {
        signal,
      }),
    select: ({ data: { data } }) =>
      data.map((member) => ({
        ..._.pick(member, columns),
        registerDate: utilTime.format(Date.parse(member.registerDate), false),
        isEnabled: member.isEnabled ? '활성' : '-',
      })),
    enabled: fetchYn,
  });

  if (fetchYn) {
    invariant(serviceCodeMemberQuery.data, 'Invalid query data.');

    return (
      <div
        css={css`
          height: 500px;
          overflow: auto;
        `}
      >
        <StyledTable>
          <thead>
            <tr>
              {Object.entries(headerLabels).map(([key, label]) => (
                <th key={key}>{label}</th>
              ))}
              <th />
            </tr>
          </thead>

          <tbody>
            {serviceCodeMemberQuery.data.map((member) => (
              <tr key={member.id}>
                {Object.keys(member).map((key) => (
                  <td key={key} id={key}>
                    {member[key as keyof typeof member]}
                  </td>
                ))}
                <td>
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    `}
                  >
                    <Button
                      disabled={!serviceCode}
                      baseColor={utils.style.color.subBlack}
                      onClick={() => {
                        const uriEncodedEmail = encodeURIComponent(
                          member.email,
                        );
                        navigate(
                          `/${routeName.user}/edit/${uriEncodedEmail}?service-code=${serviceCode}`,
                        );
                      }}
                    >
                      관리
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </StyledTable>
      </div>
    );
  }

  return (
    <div
      css={css`
        height: 500px;
        overflow: auto;
      `}
    >
      <StyledTable>
        <thead>
          <tr>
            {Object.entries(headerLabels).map(([key, label]) => (
              <th key={key}>{label}</th>
            ))}
            <th />
          </tr>
        </thead>

        <tbody></tbody>
      </StyledTable>
    </div>
  );
};
