import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { DeleteDialog } from '@admin/components/Dialog/DeleteDialog';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledInput } from '@admin/components/Styled/Input';
import { StyledLabel } from '@admin/components/Styled/Label';
import { repositoryClient } from '@admin/repository';
import type { VoiceMapping } from '@admin/repository/types';
import { repositoryUtils } from '@admin/repository/utils';
import { utils } from '@admin/utils';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const VoiceMappingEditorPage = () => {
  const { openModal } = useModal();

  const navigate = useNavigate();

  const { speaker_id } = useParams();

  const query = useQuery({
    queryKey: ['/tts/relations'] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ datas: VoiceMapping[] }>(path, { signal }),
    select: ({ data: { datas } }) =>
      datas.find((d) => d.speaker_id === Number(speaker_id)),
  });

  invariant(query.data, 'Invalid voice mapping data.');

  const [formValue, setFormValue] = useState<VoiceMapping>(query.data);

  const queryClient = useQueryClient();

  const { mutate: save, isLoading: isSaving } = useMutation({
    onError: (e) => {
      openModal(
        <AlertDialog
          title="에러"
          message={repositoryUtils.getErrorMessage(e)}
        />,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['/tts/relations'] });
      openModal(
        <AlertDialog
          title="알림"
          message="매핑 정보가 성공적으로 저장되었습니다."
        />,
      );
    },
    mutationFn: () => {
      const { speaker_id, voice_name } = formValue;
      return repositoryClient.put(`/tts/relation/${speaker_id}`, undefined, {
        params: { voice_name },
      });
    },
  });

  const { mutate: deleteMapping } = useMutation({
    onError: (e) => {
      openModal(
        <AlertDialog
          title="에러"
          message={repositoryUtils.getErrorMessage(e)}
        />,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['/tts/relations'] });
      navigate('..', { replace: true });
    },
    mutationFn: () => {
      if (!speaker_id) {
        throw new Error('invalid id');
      }
      return repositoryClient.delete(`/tts/relation/${speaker_id}`);
    },
  });

  return (
    <ContentBox
      css={css`
        gap: 20px;
      `}
    >
      <div
        css={css`
          font-weight: bold;
          font-size: 30px;
        `}
      >
        보이스 맵핑 정보
      </div>

      <StyledLabel>
        화자 ID
        <StyledInput readOnly type="number" value={formValue?.speaker_id} />
      </StyledLabel>

      <StyledLabel>
        보이스 이름
        <StyledInput
          value={formValue?.voice_name}
          onChange={({ currentTarget: { value: voice_name } }) =>
            setFormValue((prev) => prev && { ...prev, voice_name })
          }
        />
      </StyledLabel>

      <div
        css={css`
          display: flex;
          gap: 20px;
        `}
      >
        <Button disabled={isSaving || !formValue?.voice_name} onClick={save}>
          저장
        </Button>

        <Button
          baseColor={utils.style.color.highlight1}
          onClick={() => {
            invariant(query.data, 'Invalid voice mapping data.');
            openModal(
              <DeleteDialog
                type="보이스 맵핑"
                subjectText={query.data.speaker_id.toString()}
                confirmText={query.data.speaker_id.toString()}
                onConfirm={deleteMapping}
              />,
            );
          }}
        >
          삭제
        </Button>
      </div>
    </ContentBox>
  );
};
