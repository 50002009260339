import { utils } from '@admin/utils';
import axios from 'axios';
import dayjs, { type Dayjs } from 'dayjs';
import { isEmpty } from 'lodash-es';
import * as XLSX from 'xlsx';

interface UserData {
  voiceUid: string;
  voiceName: string;
  voiceDisplayName: string;
  characterCount: number;
  userCount: number;
  emailList: string[];
}

interface VoiceUsageAndUserCountStatisticsDto {
  voiceUsageAndUserCountStatisticsDtoList: UserData[];
}

interface ResponseStatData {
  allVoiceUsageStatisticsListDto: VoiceUsageAndUserCountStatisticsDto;
  failVoiceUsageStatisticsListDto: VoiceUsageAndUserCountStatisticsDto;
}

interface DataResponse {
  success: string;
  message: string;
  data: ResponseStatData;
}

const humeloAxios = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  headers: { 'Service-Code': '001WEB001' },
});

const download = async (
  month: string,
  successData: UserData[],
  failData: UserData[],
  includeCustom: boolean,
) => {
  const wb = XLSX.utils.book_new();

  new Array(2).fill(0).forEach((_, i) => {
    const headers = [['', '보이스명', '누적 합성 글자 수', '사용자 수']];
    const reduceData = (() => {
      const data = i === 0 ? successData : failData;
      return includeCustom
        ? data.filter((item) => item.voiceName.toUpperCase().includes('CUSTOM'))
        : data.filter(
            (item) => !item.voiceName.toUpperCase().includes('CUSTOM'),
          );
    })();
    const data = reduceData.map((item, i) => [
      i + 1,
      item.voiceDisplayName,
      item.characterCount,
      item.emailList.length,
    ]);
    const ws = XLSX.utils.aoa_to_sheet([...headers, ...data]);
    XLSX.utils.book_append_sheet(
      wb,
      ws,
      i === 0 ? 'AllVoiceUsage' : 'FailVoiceUsage',
    );
  });

  XLSX.writeFile(
    wb,
    `${
      includeCustom ? '마이 AI 보이스 ' : '일반 보이스 '
    }${month}월보이스웹사용내역.xlsx`,
  );
};

async function getStatData(token: string, since: number[], till: number[]) {
  try {
    const {
      data: { data },
    } = await humeloAxios.post<DataResponse>(
      '/statistics/entire/voice-usage/download',
      {
        serviceCode: '001WEB001',
        since,
        till,
      },
      {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return data;
  } catch (e) {
    console.log(`Error: ${e}`);
  }
}

export async function downloadExcel(
  type: 'all' | 'custom',
  searchDate: string,
) {
  const { accessToken: token } = utils.token.get();

  if (token) {
    const date: Dayjs[] = [];

    const today = dayjs(searchDate);
    const startDate = today.startOf('months');
    const endDate = today.endOf('months').startOf('days');

    while (
      isEmpty(date) ||
      (!isEmpty(date) && !endDate.isSame(date[date.length - 1]))
    ) {
      const newDate = isEmpty(date)
        ? startDate
        : date[date.length - 1]?.add(1, 'days');

      if (newDate) date.push(newDate);
    }

    const formatDate = date.map((item) => [
      item.get('years'),
      item.get('months') + 1,
      item.get('date'),
    ]);

    const iter = Math.floor(formatDate.length / 3);
    const iterCount = new Array(iter).fill(0).map((_, index) => index);

    const successData: UserData[] = [];
    const failData: UserData[] = [];

    const inputData = (res: ResponseStatData) => {
      res.allVoiceUsageStatisticsListDto.voiceUsageAndUserCountStatisticsDtoList.forEach(
        (item) => successData.push(item),
      );
      res.failVoiceUsageStatisticsListDto.voiceUsageAndUserCountStatisticsDtoList.forEach(
        (item) => failData.push(item),
      );
    };

    const dataSave = async (since?: number[], till?: number[]) => {
      if (since && till) {
        const res = await getStatData(token, since, till);
        if (res) inputData(res);
      }
    };

    const reduceData = (data: UserData[]) => {
      return data.reduce((acc, cur) => {
        const isDuplicate = acc.some((item) => item.voiceUid === cur.voiceUid);

        return isDuplicate
          ? acc.map((item) =>
              item.voiceUid === cur.voiceUid
                ? {
                    ...item,
                    characterCount: item.characterCount + cur.characterCount,
                    emailList: Array.from(
                      new Set([...item.emailList, ...cur.emailList]),
                    ),
                  }
                : item,
            )
          : [...acc, cur];
      }, [] as UserData[]);
    };

    for await (const iter of iterCount) {
      const sliceDate = formatDate.slice(iter * 3, (iter + 1) * 3);
      await dataSave(sliceDate[0], sliceDate[sliceDate.length - 1]);
    }
    if (formatDate.length % 3 > 0) {
      const sliceDate = formatDate.slice(iter * 3, formatDate.length);
      await dataSave(sliceDate[0], sliceDate[sliceDate.length - 1]);
    }

    const reduceSuccessData = reduceData(successData);
    const reduceFailData = reduceData(failData);

    if (type === 'custom') {
      download(
        String(today.get('months') + 1),
        reduceSuccessData,
        reduceFailData,
        true,
      );
      return;
    }

    download(
      String(today.get('months') + 1),
      reduceSuccessData,
      reduceFailData,
      false,
    );
  }
}
