import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledForm } from '@admin/components/Styled/Form';
import { StyledH1 } from '@admin/components/Styled/H1';
import { repositoryClient } from '@admin/repository';
import type { Member } from '@admin/repository/types';
import { repositoryUtils } from '@admin/repository/utils';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash-es';
import { useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import type { UpdateMemberPayload } from '../type';

type Payload = Pick<
  UpdateMemberPayload,
  | 'isBlocked'
  | 'isDeleted'
  | 'isEnabled'
  | 'isReceivedDebugEmail'
  | 'isReceivedErrorEmail'
  | 'loginFailCount'
  | 'isTestAccount'
>;

export const MemberFlagsForm = () => {
  const [searchParams] = useSearchParams();

  const serviceCode = searchParams.get('service-code');

  const { email: userEmail } = useParams();

  const { openModal } = useModal();

  const query = useQuery({
    queryKey: ['/member/single', { serviceCode, userEmail }] as const,
    queryFn: ({ queryKey: [path, { serviceCode, userEmail }], signal }) =>
      repositoryClient.get<{ data: Member }>(`${path}/${userEmail}`, {
        params: { serviceCode },
        signal,
      }),
    select: ({ data: { data } }) => ({
      isBlocked: data.isBlocked,
      isDeleted: data.isDeleted,
      isEnabled: data.isEnabled,
      isReceivedDebugEmail: data.isReceivedDebugEmail,
      isReceivedErrorEmail: data.isReceivedErrorEmail,
      loginFailCount: data.loginFailCount,
    }),
  });

  invariant(query.data, 'Invalid query data.');

  const testAccountQuery = useQuery({
    queryKey: ['/member-test', { serviceCode, userEmail }] as const,
    queryFn: ({ queryKey: [path, { serviceCode, userEmail }], signal }) =>
      repositoryClient.get<{ data: boolean }>(path, {
        params: { email: userEmail, serviceCode },
        signal,
      }),
    select: ({ data: { data } }) => data,
  });

  invariant(
    testAccountQuery.data !== undefined,
    'Invalid test account query data.',
  );

  const [payload, setPayload] = useState<Partial<Payload>>({
    ...query.data,
    isTestAccount: testAccountQuery.data,
  });

  const changedFields = useMemo<Partial<Payload>>(
    () =>
      _.fromPairs(
        _.differenceWith(
          _.toPairs(payload),
          _.toPairs({ ...query.data, isTestAccount: testAccountQuery.data }),
          _.isEqual,
        ),
      ),
    [payload, query.data, testAccountQuery.data],
  );

  const queryClient = useQueryClient();

  const { mutate: save, isLoading: isSaving } = useMutation({
    onError: (e) => {
      openModal(
        <AlertDialog
          title="에러"
          message={repositoryUtils.getErrorMessage(e)}
        />,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['/member/single', { serviceCode, userEmail }],
      });
      queryClient.invalidateQueries({
        queryKey: ['/member-test', { serviceCode, userEmail }],
      });
      openModal(
        <AlertDialog
          title="알림"
          message="변경 사항이 성공적으로 저장되었습니다."
        />,
      );
    },
    mutationFn: async () => {
      invariant(serviceCode, `Invalid service code: ${serviceCode}`);
      invariant(userEmail, `Invalid user email: ${userEmail}`);
      if ('isTestAccount' in changedFields) {
        await repositoryClient.post('/member-test', {
          serviceCode,
          email: userEmail,
        });
      }
      const payload = _.omit(changedFields, 'isTestAccount');
      if (!_.isEmpty(payload)) {
        await repositoryClient.patch(`/member/${userEmail}`, payload, {
          params: { serviceCode },
        });
      }
    },
  });

  return (
    <ContentBox
      css={css`
        gap: 1rem;
      `}
    >
      <StyledH1>플래그 설정</StyledH1>

      <StyledForm>
        <label>
          로그인 실패 횟수
          <input
            required
            type="number"
            min={0}
            value={payload.loginFailCount}
            onChange={({ currentTarget: { value } }) =>
              setPayload((prev) => ({ ...prev, loginFailCount: Number(value) }))
            }
          />
        </label>

        <div
          css={css`
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 8px;
          `}
        >
          <label>
            차단
            <input
              type="checkbox"
              checked={payload.isBlocked}
              onChange={({ currentTarget: { checked } }) =>
                setPayload((prev) => ({ ...prev, isBlocked: checked }))
              }
            />
          </label>

          <label>
            활성화
            <input
              type="checkbox"
              checked={payload.isEnabled}
              onChange={({ currentTarget: { checked } }) =>
                setPayload((prev) => ({ ...prev, isEnabled: checked }))
              }
            />
          </label>

          <label>
            삭제
            <input
              type="checkbox"
              checked={payload.isDeleted}
              onChange={({ currentTarget: { checked } }) =>
                setPayload((prev) => ({ ...prev, isDeleted: checked }))
              }
            />
          </label>

          <label>
            ERROR 알림 이메일 수신 여부
            <input
              type="checkbox"
              checked={payload.isReceivedErrorEmail}
              onChange={({ currentTarget: { checked } }) =>
                setPayload((prev) => ({
                  ...prev,
                  isReceivedErrorEmail: checked,
                }))
              }
            />
          </label>

          <label>
            DEBUG 알림 이메일 수신 여부
            <input
              type="checkbox"
              checked={payload.isReceivedDebugEmail}
              onChange={({ currentTarget: { checked } }) =>
                setPayload((prev) => ({
                  ...prev,
                  isReceivedDebugEmail: checked,
                }))
              }
            />
          </label>

          <label>
            테스트 계정
            <input
              type="checkbox"
              checked={payload.isTestAccount}
              onChange={({ currentTarget: { checked } }) =>
                setPayload((prev) => ({ ...prev, isTestAccount: checked }))
              }
            />
          </label>
        </div>

        <Button disabled={isSaving || _.isEmpty(changedFields)} onClick={save}>
          저장
        </Button>
      </StyledForm>
    </ContentBox>
  );
};
