import { Button } from '@admin/components/Button';
import { StyledTable } from '@admin/components/Styled/Table';
import { repositoryClient } from '@admin/repository';
import type { Repository } from '@admin/repository/types';
import { utils } from '@admin/utils';
import { invariant } from '@admin/utils/invariant';
import { routeName } from '@admin/utils/routeName';
import { css } from '@emotion/react';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';

export const VoucherMemberTable = ({
  voucherDetail,
}: {
  voucherDetail: Repository['voucher'];
}) => {
  const navigate = useNavigate();

  /* 바우처 적용된 멤버 조회 */
  const { data: voucherMemberList } = useQuery({
    queryKey: [`/admin/voucher-history/${voucherDetail.code}`] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ data: Repository['voucherMember'][] }>(path, {
        signal,
      }),
    select: ({ data: { data } }) => data,
  });

  invariant(voucherMemberList, 'Invalid voucher member list.');

  return (
    <div
      css={css`
        height: 500px;
        overflow: auto;
      `}
    >
      <StyledTable>
        <thead>
          <tr>
            <th>이름</th>
            <th>이메일</th>
            <th>유효 기간</th>
            <th>관리</th>
          </tr>
        </thead>
        <tbody>
          {voucherMemberList.map((user) => (
            <tr key={user.email}>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>{`${format(
                new Date(user.since.join('-')),
                'yyyy년 M월 d일',
              )} - ${format(
                new Date(user.till.join('-')),
                'yyyy년 M월 d일',
              )}`}</td>
              <td>
                <div
                  css={css`
                    display: flex;
                    justify-content: center;
                  `}
                >
                  <Button
                    onClick={() => {
                      const encodedEmail = encodeURIComponent(user.email);
                      navigate(
                        `/${routeName.user}/edit/${encodedEmail}?service-code=001WEB001`,
                      );
                    }}
                    baseColor={utils.style.color.subBlack}
                  >
                    관리
                  </Button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </div>
  );
};
