import { StyledH1 } from '@admin/components/Styled/H1';
import { StyledTable } from '@admin/components/Styled/Table';
import type { ServiceCodeVoiceSettlementType } from '@admin/routes/service-code/statistics/context/type';
import { css } from '@emotion/react';

export const SpecialVoiceUsage = ({
  serviceCodeVoiceSettlement,
}: {
  serviceCodeVoiceSettlement: ServiceCodeVoiceSettlementType[];
}) => {
  return (
    <>
      <StyledH1>보이스 사용량</StyledH1>

      <div
        css={css`
          height: 500px;
          overflow: auto;
        `}
      >
        <StyledTable>
          <thead>
            <tr>
              <th>보이스 이름</th>
              <th>실제 사용 글자 수</th>
              <th>청구 금액</th>
            </tr>
          </thead>
          <tbody>
            {serviceCodeVoiceSettlement.map((item) => (
              <tr key={item.voiceDisplayName}>
                <td>{item.voiceDisplayName}</td>
                <td>{item.textCount.toLocaleString()}자</td>
                <td>{item.billingAmount.toLocaleString()}원</td>
              </tr>
            ))}
          </tbody>
        </StyledTable>
      </div>
    </>
  );
};
