import { Button } from '@admin/components/Button';
import { DatePickerButton } from '@admin/components/DatePicker/DatePickerButton';
import { DatePickerHeader } from '@admin/components/DatePicker/DatePickerHeader';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { PromptDialog } from '@admin/components/Dialog/PromptDialog';
import { ErrorText } from '@admin/components/ErrorText';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledForm } from '@admin/components/Styled/Form';
import { StyledNumberInput } from '@admin/components/Styled/Input';
import { repositoryClient } from '@admin/repository';
import type { ServiceCodeMetaData } from '@admin/repository/types';
import { repositoryUtils } from '@admin/repository/utils';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import _ from 'lodash-es';
import { createElement, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Navigate, useParams } from 'react-router-dom';

export const InfoForm = () => {
  const { serviceCode } = useParams();

  const { openModal } = useModal();

  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: ['/crm/service-code'] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ data: ServiceCodeMetaData[] }>(path, { signal }),
    select: ({ data: { data } }) =>
      _.find(data, { serviceCodeName: serviceCode }),
  });
  invariant(query.data, 'invalid service-code-data');

  const { data: serviceCodeData } = useQuery({
    queryKey: [`/crm/service-code/${serviceCode}`] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{
        data: {
          code: string;
          firstSettlementDate: string;
          settlementDay: number;
          isUsingGenerateFileCache: boolean;
        };
      }>(path, { signal }),
    select: ({ data: { data } }) => data,
  });
  invariant(serviceCodeData, 'invalid service-code-detail');

  const [settlementDay, setSettlementDay] = useState(
    serviceCodeData.settlementDay,
  );

  const [firstSettlementDateRange, setFirstSettlementDateRange] = useState(
    () => {
      const date = new Date(
        serviceCodeData.firstSettlementDate ??
          new Date().toLocaleDateString('ko-KR'),
      );
      return date;
    },
  );

  const { mutate: save, isLoading: isSaving } = useMutation({
    mutationFn: () => {
      const payload = {
        firstSettlementDate: format(firstSettlementDateRange, 'yyyy-MM-dd'),
        settlementDay,
        // isUsingGenerateFileCache: !!formData.get('enableCacheServer'),// 현재는 저장되지 않으므로 주석처리 (2023-10)
      };
      return repositoryClient.patch(`/service-code/${serviceCode}`, payload);
    },
    onError: (error) =>
      openModal(
        <AlertDialog
          title="에러"
          message={repositoryUtils.getErrorMessage(error)}
        />,
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['/crm/service-code'] });
      queryClient.invalidateQueries({
        queryKey: [`/crm/service-code/${serviceCode}`],
      });
      openModal(
        <AlertDialog
          title="알림"
          message="변경 사항이 성공적으로 저장되었습니다."
        />,
      );
    },
  });

  if (!query.data) {
    return <Navigate to=".." replace />;
  }

  return (
    <StyledForm
      onSubmit={(e) => {
        e.preventDefault();
        openModal(
          <PromptDialog
            title="저장"
            message="변경 사항을 저장하시겠습니까?"
            onConfirm={save}
          />,
        );
      }}
    >
      <label htmlFor="serviceCode">
        코드
        <input
          disabled
          type="text"
          name="serviceCode"
          defaultValue={query.data.serviceCodeName}
        />
      </label>
      <label htmlFor="groupName">
        이름
        <input disabled name="groupName" defaultValue={query.data.groupName} />
      </label>

      <label htmlFor="firstSettlementDate">
        최초 정산일
        <ReactDatePicker
          dateFormat="yyyy년 M월 d일"
          selected={firstSettlementDateRange}
          onChange={(date: Date) => {
            setFirstSettlementDateRange(date);
          }}
          locale="ko-KR"
          customInput={createElement(DatePickerButton)}
          renderCustomHeader={DatePickerHeader}
        />
      </label>

      <label htmlFor="settlementDay">
        정산 기준일
        <div
          css={css`
            display: flex;
            align-items: center;
            input {
              margin-left: 10px;
              margin-right: 10px;
            }
          `}
        >
          매 월
          <StyledNumberInput
            type="number"
            min={1}
            max={31}
            maxLength={2}
            step={1}
            value={settlementDay}
            onChange={(e) => setSettlementDay(parseInt(e.target.value, 10))}
          />
          일
        </div>
        {(settlementDay > 31 || settlementDay < 1) && (
          <ErrorText errorText="입력 값 범위를 초과하였습니다." />
        )}
      </label>
      <label htmlFor="enableCacheServer">
        캐시 서버 사용 여부
        <input
          type="checkbox"
          name="enableCacheServer"
          defaultChecked={serviceCodeData.isUsingGenerateFileCache}
        />
      </label>
      <Button
        form
        disabled={isSaving}
        css={css`
          align-self: flex-start;
        `}
      >
        저장
      </Button>
    </StyledForm>
  );
};
