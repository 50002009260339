import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { Paginate } from '@admin/components/Paginate';
import { Search } from '@admin/components/Search';
import { StyledH1 } from '@admin/components/Styled/H1';
import { StyledTable } from '@admin/components/Styled/Table';
import { repositoryClient } from '@admin/repository';
import type { Notice, Pageable } from '@admin/repository/types';
import { utils } from '@admin/utils';
import { invariant } from '@admin/utils/invariant';
import utilTime from '@admin/utils/utilTime';
import { css } from '@emotion/react';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash-es';
import { useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const NoticeListPage = () => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const pageIndex = useMemo(() => {
    const p = Number(searchParams.get('page'));
    return Number.isNaN(p) ? 0 : p;
  }, [searchParams]);

  const noticeListQuery = useQuery({
    queryKey: [
      '/notice/private',
      {
        searchKeyword: searchParams.get('q'),
        page: pageIndex,
      },
    ] as const,
    queryFn: async ({ queryKey: [path, { page, searchKeyword }], signal }) =>
      repositoryClient.get<{ data: Pageable<{ dtos: Notice[] }> }>(path, {
        params: {
          page: page.toString(),
          ...(searchKeyword && { searchKeyword }),
        },
        signal,
      }),
    select: ({
      data: {
        data: {
          data: { dtos },
          totalElements,
          totalPages,
        },
      },
    }) => ({
      elements: _.orderBy(dtos, 'id', 'desc'),
      totalElements,
      totalPages: totalPages === 0 ? 1 : totalPages,
    }),
    onSuccess: ({ totalPages }) => {
      if (pageIndex >= totalPages) {
        setSearchParams(
          (prev) => {
            const params = new URLSearchParams(prev);
            if (totalPages > 1) {
              params.set('page', (totalPages - 1).toString());
            } else {
              params.delete('page');
            }
            return params;
          },
          { replace: true },
        );
      }
    },
  });

  invariant(noticeListQuery.data, 'Invalid query data.');

  return (
    <ContentBox
      css={css`
        gap: 24px;
        height: 100%;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <StyledH1>공지사항</StyledH1>

        <Button onClick={() => navigate('./create')}>공지사항 작성</Button>
      </div>

      <Search
        defaultValue={searchParams.get('q') || ''}
        onSubmit={(searchString) =>
          setSearchParams((prev) => {
            const params = new URLSearchParams(prev);
            if (searchString) {
              params.set('q', searchString);
            } else {
              params.delete('q');
            }
            return params;
          })
        }
      />

      <div
        css={css`
          height: 100%;
          overflow: auto;
        `}
      >
        <StyledTable>
          <thead>
            <tr>
              <th
                css={css`
                  width: 80px;
                `}
              >
                ID
              </th>
              <th
                css={css`
                  width: 100px;
                `}
              >
                카테고리
              </th>
              <th>제목</th>
              <th
                css={css`
                  width: 120px;
                `}
              >
                작성일자
              </th>
              <th
                css={css`
                  width: 120px;
                `}
              >
                수정일자
              </th>
            </tr>
          </thead>
          <tbody>
            {noticeListQuery.data.elements.map((notice) => (
              <tr
                key={notice.id}
                css={css`
                  &:hover {
                    background: ${utils.style.color.grey100};
                  }
                  cursor: pointer;
                `}
                onClick={() => navigate(`./edit/${notice.id}`)}
              >
                <td>{notice.id}</td>
                <td>{notice.category}</td>
                <td>{notice.title}</td>
                <td>{utilTime.format(Date.parse(notice.createdAt), true)}</td>
                <td>{utilTime.format(Date.parse(notice.modifiedAt), true)}</td>
              </tr>
            ))}
          </tbody>
        </StyledTable>
      </div>

      <div
        css={css`
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
        `}
      >
        <Paginate
          forcePage={Math.min(pageIndex, noticeListQuery.data.totalPages - 1)}
          onPageChange={(index) =>
            setSearchParams((prev) => {
              const params = new URLSearchParams(prev);
              if (index === 0) {
                params.delete('page');
              } else {
                params.set('page', index.toString());
              }
              return params;
            })
          }
          pageCount={noticeListQuery.data.totalPages}
        />
      </div>
    </ContentBox>
  );
};
