import { ContentBox } from '@admin/components/ContentBox';
import { Search } from '@admin/components/Search';
import { StyledH1 } from '@admin/components/Styled/H1';
import { repositoryClient } from '@admin/repository';
import type { Voice } from '@admin/repository/types';
import { utils } from '@admin/utils';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useResetRecoilState, useSetRecoilState } from 'recoil';

import { voiceTagFormAtoms } from './atom';
import { SaveButton } from './SaveButton';
import { SelectedTags } from './SelectedTags';
import { TagGrid } from './TagGrid';

export const TagForm = () => {
  const { name } = useParams();

  const setSearchKeyword = useSetRecoilState(
    voiceTagFormAtoms['search-keyword'],
  );

  const setSelectedTags = useSetRecoilState(voiceTagFormAtoms['selected-tags']);

  const resetSelectedTags = useResetRecoilState(
    voiceTagFormAtoms['selected-tags'],
  );

  const { data: voice } = useQuery({
    queryKey: ['/voice/all'] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ data: Voice[] }>(path, { signal }),
    select: ({ data: { data } }) => data.find((v) => v.name === name),
  });

  invariant(voice, 'Invalid voice data.');

  useEffect(() => {
    // 현재 보이스의 태그로 초기화
    setSelectedTags(voice.tags);
    return () => {
      resetSelectedTags();
    };
  }, [resetSelectedTags, setSelectedTags, voice.tags]);

  return (
    <ContentBox
      css={css`
        gap: 20px;
      `}
    >
      <StyledH1>태그</StyledH1>

      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 8px;
        `}
      >
        <Search onSubmit={(searchKeyword) => setSearchKeyword(searchKeyword)} />

        <div
          css={css`
            height: 400px;
            border: 1px solid ${utils.style.color.grey300};
            border-radius: 4px;
            display: flex;
          `}
        >
          <TagGrid />
        </div>
      </div>

      <SelectedTags />

      <SaveButton />
    </ContentBox>
  );
};
