import { css } from '@emotion/react';

export default {
  color: {
    background: '#F9FAFA',
    black: '#242424',
    highlight1: '#FF5F5F',
    highlight2: '#ECF6FF',
    highlight3: '#ff9696',
    primary: '#548BF6',
    secondary: '#454B66',
    subBlack: '#494949',
    white: '#FFFFFF',
    grey100: '#F5F5F5',
    grey200: '#EEEEEE',
    grey300: '#DFDFDF',
    grey400: '#C4C4C4',
    grey500: '#888888',
  },
  // 피그마에서 실제로 보이는 굵기에 맞추기 위해 weight를 100씩 줄임
  font: {
    'button-m': css`
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    `,
    'button-s': css`
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
    `,
    'text-l': css`
      font-weight: 300;
      font-size: 16px;
      line-height: 20px;
    `,
    'text-m': css`
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
    `,
    'text-s': css`
      font-weight: 300;
      font-size: 12px;
      line-height: 18px;
    `,
    'title-l': css`
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
    `,
    'title-m': css`
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    `,
    'title-s': css`
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    `,
  },
  'z-index': {
    cover: 100,
    modal: 500,
    'side-bar': 1000,
    'tool-tip': 300,
  },
} as const;
