import { Search } from '@admin/components/Search';
import { utils } from '@admin/utils';
import { css } from '@emotion/react';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export const SearchForm = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setSearchParams((prev) => {
      const currentSearchColumn = prev.get('c');
      if (currentSearchColumn === 'code' || currentSearchColumn === 'name') {
        return prev;
      }
      const newParams = new URLSearchParams(prev);
      newParams.set('c', 'code');
      return newParams;
    });
  }, [setSearchParams]);

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: 16px;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          ${utils.style.font['text-s']};

          user-select: none;

          & > label {
            display: flex;
            align-items: center;
            gap: 4px;
          }
        `}
      >
        <label>
          <input
            type="radio"
            name="searchColumn"
            defaultChecked={(searchParams.get('c') ?? 'code') === 'code'}
            onChange={({ currentTarget: { checked } }) => {
              if (!checked) {
                return;
              }
              setSearchParams((prev) => {
                const newParams = new URLSearchParams(prev);
                newParams.set('c', 'code');
                return newParams;
              });
            }}
          />
          코드
        </label>
        <label>
          <input
            type="radio"
            name="searchColumn"
            defaultChecked={(searchParams.get('c') ?? 'code') === 'name'}
            onChange={({ currentTarget: { checked } }) => {
              if (!checked) {
                return;
              }
              setSearchParams((prev) => {
                const newParams = new URLSearchParams(prev);
                newParams.set('c', 'name');
                return newParams;
              });
            }}
          />
          이름
        </label>
      </div>

      <Search
        defaultValue={searchParams.get('q') ?? ''}
        onSubmit={(searchKeyword) =>
          setSearchParams((prev) => {
            const newParams = new URLSearchParams(prev);
            if (!searchKeyword) {
              newParams.delete('q');
            } else {
              newParams.set('q', searchKeyword);
            }
            return newParams;
          })
        }
        placeholder="검색"
      />
    </div>
  );
};
