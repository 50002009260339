import { utils } from '@admin/utils';
import { css } from '@emotion/react';
import type { ReactNode } from 'react';

export const TagsBox = (props: { children: ReactNode; className?: string }) => {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: 8px;

        flex-wrap: wrap;

        min-height: 48px;
        padding: 8px;
        box-sizing: border-box;
        border: 1px solid ${utils.style.color.grey300};
        border-radius: 8px;
      `}
      className={props.className}
    >
      {props.children}
    </div>
  );
};
