import { ContentBox } from '@admin/components/ContentBox';
import { Divider } from '@admin/components/Divider';
import { StyledH1 } from '@admin/components/Styled/H1';
import { utils } from '@admin/utils';
import { css } from '@emotion/react';
import { useNavigate } from 'react-router-dom';

export const mailTemplateList = [
  {
    name: 'MAIL_AUTH',
    label: '가입 인증',
  },
  {
    name: 'MAIL_SUCCESS',
    label: '가입 완료',
  },
  {
    name: 'MAIL_CHANGE_PW',
    label: '패스워드 초기화',
  },
  {
    name: 'MAIL_INQUIRY',
    label: '문의',
  },
  {
    name: 'MAIL_CUSTOM_VOICE',
    label: '마이 AI 보이스 생성 완료',
  },
];

export const MailTemplatePage = () => {
  const navigate = useNavigate();

  return (
    <ContentBox
      css={css`
        gap: 20px;
        height: min-content;
      `}
    >
      <StyledH1>메일 템플릿</StyledH1>
      <section>
        {mailTemplateList.map((mail) => (
          <div
            key={mail.name}
            onClick={() => {
              navigate(`preview/${mail.name}`);
            }}
            css={css`
              padding: 10px;
              &:hover {
                background: ${utils.style.color.grey300};
              }
              cursor: pointer;
            `}
          >
            {mail.label}
          </div>
        ))}
      </section>
      <Divider />
      <StyledH1>이미지</StyledH1>
      <section>
        <div
          css={css`
            padding: 10px;
            &:hover {
              background: ${utils.style.color.grey300};
            }
            cursor: pointer;
          `}
          onClick={() => navigate('LOGO')}
        >
          로고 이미지
        </div>
        <div
          css={css`
            padding: 10px;
            &:hover {
              background: ${utils.style.color.grey300};
            }
            cursor: pointer;
          `}
          onClick={() => {
            navigate('SIGN_UP_LOGO');
          }}
        >
          가입 완료 이미지
        </div>
      </section>
      <Divider />
      <StyledH1>메일 제목</StyledH1>
      <section>
        <div
          css={css`
            padding: 10px;
            &:hover {
              background: ${utils.style.color.grey300};
            }
            cursor: pointer;
          `}
          onClick={() => {
            navigate('MAIL_TITLE');
          }}
        >
          메일 제목 리스트
        </div>
      </section>
    </ContentBox>
  );
};
