import { ContentBox } from '@admin/components/ContentBox';
import { StyledH1 } from '@admin/components/Styled/H1';
import { StyledTable } from '@admin/components/Styled/Table';
import { repositoryClient } from '@admin/repository';
import type { Voice } from '@admin/repository/types';
import { repositoryUtils } from '@admin/repository/utils';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useQuery } from '@tanstack/react-query';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';

export const MyAiVoiceTable = () => {
  const { email: userEmail } = useParams();

  const tableRef = useRef<HTMLTableElement>(null);

  const query = useQuery({
    queryKey: [`/voice/all/${userEmail}`] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ data: Voice[] }>(path, {
        signal,
      }),
    select: ({ data: { data } }) => data,
  });

  invariant(query.data, 'Invalid query data.');

  return (
    <ContentBox
      css={css`
        gap: 1rem;
      `}
    >
      <StyledH1>제작한 마이 AI 보이스</StyledH1>

      <div
        css={css`
          min-height: 120px;
          height: 100%;
          max-height: 500px;
          overflow: auto;
        `}
      >
        <StyledTable ref={tableRef}>
          <thead>
            <tr>
              <th>No.</th>
              <th>마이 AI 보이스 명</th>
              <th>제작 날짜</th>
            </tr>
          </thead>
          <tbody>
            {query.data.map(({ voiceId, displayName, createdAt }, i) => {
              const parsedDate = repositoryUtils.parseDate(createdAt);
              if (parsedDate === null) {
                throw new Error(`Invalid date format: ${createdAt}`);
              }
              return (
                <tr key={voiceId}>
                  <td>{i + 1}</td>
                  <td>{displayName}</td>
                  <td>
                    {parsedDate.toLocaleDateString('ko-KR', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    })}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </StyledTable>
      </div>
    </ContentBox>
  );
};
