import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { StyledH1 } from '@admin/components/Styled/H1';
import { StyledTable } from '@admin/components/Styled/Table';
import { repositoryClient } from '@admin/repository';
import type { Plan } from '@admin/repository/types';
import { utils } from '@admin/utils';
import { invariant } from '@admin/utils/invariant';
import { css } from '@emotion/react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

export const PlanListPage = () => {
  const navigate = useNavigate();

  const query = useQuery({
    queryKey: ['/plan'] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ data: Plan[] }>(path, { signal }),
    select: ({ data: { data } }) => data,
  });

  invariant(query.data, 'Invalid query data.');

  return (
    <ContentBox
      css={css`
        gap: 24px;
        height: 100%;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <StyledH1>요금제</StyledH1>

        <Button onClick={() => navigate('./statistics')}>요금제 통계</Button>
      </div>

      <div
        css={css`
          height: 100%;
          overflow: auto;
        `}
      >
        <StyledTable>
          <thead>
            <tr>
              <th>이름</th>
              <th>보이스 개수</th>
              <th>관리</th>
            </tr>
          </thead>
          <tbody>
            {query.data.map(({ uid, displayName, voiceFont }) => (
              <tr key={uid}>
                <td>{displayName}</td>
                <td>{voiceFont}</td>

                <td>
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    `}
                  >
                    <Button
                      baseColor={utils.style.color.subBlack}
                      onClick={() =>
                        navigate(`edit/${encodeURIComponent(uid)}`)
                      }
                    >
                      관리
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </StyledTable>
      </div>
    </ContentBox>
  );
};
